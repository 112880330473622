import React, {useRef} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  SvgIcon,
  TableSortLabel,
  Box,
  Grid,
  Tooltip, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {makeStyles} from '@material-ui/styles';
import {
  Trash as TrashIcon,
  Edit2 as Edit2Icon,
  Upload as UploadIcon,
  Pause as PauseIcon,
  Settings as SettingsIcon,
  Search as SearchIcon
} from 'react-feather';
import {
  ArrowHeaderTable as ArrowHeaderTableIcon,
} from '../../../../../../icons';
import {useHistory} from 'react-router-dom';
import Skeleton from "@material-ui/lab/Skeleton";
import {CustomTablePagination} from "../../../../../../components";

const useStyles = makeStyles(theme => ({
  form: {
    marginBottom: 30
  },
  gridLines: {
    alignItems: 'flex-end'
  },

  tableSortLabel: {
    '& .MuiTableSortLabel-icon': {
      opacity: 0.5
    }
  },
  tableCellAction: {
    display: 'flex',
    alignItems: 'center'
  },
  tableRow: {
    cursor: 'pointer'
  },

  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: '#fafafa'
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },

  image: {
    width: 65,
    height: 65,
    marginBottom: -10,

    position: 'relative',
    zIndex: 555
  },

  stickyActionsColumn: {
    '& table:first-child': {
      '& tr': {
        '&:hover td:last-child': {
          backgroundColor: theme.palette.background.default,
        },
        '& td:last-child, th:last-child': {
          backgroundColor: 'white',
          position: 'sticky',
          right: 0,
          zIndex: 999
        },
        '& th:last-child': {
          backgroundColor: 'rgb(237, 241, 246)',
        },
        '& th:first-child': {
          zIndex: 9999
        }
      }
    }
  },

  ceilColor: {
    width: 20,
    height: 45,
    borderRadius: 2
  },
}));

const TableComponents = props => {
  const {
    rows,
    columns,
    showColumns,
    isChangeRowPage,
    isLoading,
    filter,
    isChangeFilter,
    onDeleteList,
    onEditList,
    onUploadFile
  } = props;
  let {pagination} = props;
  const classes = useStyles();
  const refTable = useRef(null);
  const history = useHistory();

  const handleChangeRowPage = (event) => {
    pagination.rowsPerPage = event.target.value;

    isChangeRowPage(pagination)
  }
  const handleChangePage = (event, page) => {
    let newFilter = filter;
    newFilter['page'] = page;

    isChangeFilter(newFilter);
  }
  const createSortHandler = (field) => {
    const isAsc = pagination.orderBy === field && pagination.order === 'asc';
    pagination.order = isAsc ? 'desc' : 'asc';
    pagination.orderBy = field;

    isChangeRowPage(pagination);
  }
  const handleDeleteList = (row) => {
    onDeleteList(row)
  }
  const handleEditList = (row) => {
    onEditList(row)
  }
  const handleUploadFileList = (row) => {
    onUploadFile(row)
  }

  const handleOpenList = (row) => {
    history.push('/notification-list/' + row.id, {listName: row.title})
  }
  const handleOpenCoincidences = (row) => {
    history.push('/notification-lists-coincidences/' + row.id)
  }

  return (
    <>
      <CustomTablePagination
        rows={rows}
        filter={filter}
        pagination={pagination}
        onChangePage={(event, page) => handleChangePage(event, page + 1)}
        onChangeRowsPerPage={handleChangeRowPage}
      />

      <PerfectScrollbar className={'height-auto'}>
        <Box minWidth={1400}>
          <Table
            id={'basic-drive-table'}
            ref={refTable}
            className={'MuiTable-list'}
          >
            <TableHead className={'MuiTableHead-list'}>
              <TableRow>
                <TableCell className={classes.tableSortLabel} width={80}>Метка</TableCell>

                {
                  columns.map((column, idx) => {
                    if (showColumns.indexOf(column.field) > -1) {
                      let titleSort = column.label + ' по ' + (pagination.orderBy === column.field ? (pagination.order === 'desc') ? 'возрастанию' : 'убыванию' : 'возрастанию');

                      if (column.sort) {
                        return (
                          <TableCell
                            key={'table-cell-' + idx}
                            align={'left'}
                          >
                            <Tooltip title={titleSort}>
                              <TableSortLabel
                                IconComponent={ArrowHeaderTableIcon}
                                className={classes.tableSortLabel}
                                active={pagination.orderBy === column.field}
                                direction={pagination.orderBy === column.field ? pagination.order : 'asc'}
                                onClick={() => createSortHandler(column.field)}
                              >
                                {column.label}
                              </TableSortLabel>
                            </Tooltip>
                          </TableCell>
                        )
                      } else {
                        return (
                          <TableCell key={'table-cell-' + idx} align={'left'} className={classes.tableSortLabel}>
                            {column.label}
                          </TableCell>
                        )
                      }
                    }
                  })
                }

                <TableCell align={'right'} className={classes.tableSortLabel} width={80}>Редакт.</TableCell>
                <TableCell align={'right'} className={classes.tableSortLabel} width={80}>Загруз.</TableCell>
                <TableCell align={'right'} className={classes.tableSortLabel} width={80}>Удален.</TableCell>
                <TableCell align={'right'} className={classes.tableSortLabel} width={80}>Совпад.</TableCell>
                <TableCell align={'right'} className={classes.tableSortLabel} width={80}>Настройка</TableCell>
              </TableRow>
            </TableHead>

            {
              (!isLoading) ?
                (
                  <TableBody>
                    {rows.map((row, idx) => {
                      return (
                        <TableRow
                          key={row.name + '-' + idx}
                          className={'MuiTableRow-list'}
                          hover
                        >
                          <TableCell>
                            {
                              row['color'] && (
                                <div className={classes.ceilColor} style={{backgroundColor: row['color']}}></div>
                              )
                            }
                          </TableCell>

                          {
                            columns.map((item, idx) => {
                              if (showColumns.indexOf(item.field) === -1) {
                                return null
                              }
                              if (item.type === 'status') {
                                let value = '';

                                switch (row[item.field]) {
                                  case 10: {
                                    value = 'Активный'

                                    return (
                                      <TableCell key={item.field + '-' + idx} onClick={() => handleOpenList(row)}>
                                        <Typography style={{color: '#3CB11F'}}>
                                          {value}
                                        </Typography>
                                      </TableCell>
                                    )
                                  }
                                  case 0: {
                                    value = 'Неактивный'

                                    return (
                                      <TableCell key={item.field + '-' + idx} onClick={() => handleOpenList(row)}>
                                        <Typography style={{color: '#F59300'}}>
                                          {value}
                                        </Typography>
                                      </TableCell>
                                    )
                                  }
                                  case -1: {
                                    value = 'В архиве'

                                    return (
                                      <TableCell key={item.field + '-' + idx} onClick={() => handleOpenList(row)}>
                                        <Typography style={{color: '#717171'}}>
                                          {value}
                                        </Typography>
                                      </TableCell>
                                    )
                                  }
                                }
                              }
                              if (item.type === 'type') {
                                switch (row[item.field]) {
                                  case 10: {
                                    return (
                                      <TableCell key={item.field + '-' + idx} onClick={() => handleOpenList(row)}>
                                        <Typography style={{color: '#3CB11F'}}>
                                          Общий список
                                        </Typography>
                                      </TableCell>
                                    )
                                  }
                                  case 20: {
                                    return (
                                      <TableCell key={item.field + '-' + idx} onClick={() => handleOpenList(row)}>
                                        <Typography style={{color: '#F59300'}}>
                                          Личный
                                        </Typography>
                                      </TableCell>
                                    )
                                  }
                                }
                              }

                              if (typeof row[item.field] === 'object') {
                                return (
                                  <TableCell key={item.field + '-' + idx}>1</TableCell>
                                )
                              }

                              return (
                                <TableCell
                                  key={item.field + '-' + idx}
                                  value={row[item.field]}
                                  filter-name={item.filterName}
                                  style={item.filterName ? {cursor: 'context-menu'} : {}}
                                  onClick={() => handleOpenList(row)}
                                >{row[item.field]}</TableCell>
                              )
                            })
                          }


                          <TableCell align={'right'}>
                            <Tooltip title="Редактирование списка">
                              <IconButton onClick={() => handleOpenList(row)}>
                                <SvgIcon>
                                  <Edit2Icon color={'#8698B1'}/>
                                </SvgIcon>
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                          <TableCell align={'right'}>
                            <Tooltip title="Загрузка файла">
                              <IconButton onClick={() => handleUploadFileList(row)}>
                                <SvgIcon>
                                  <UploadIcon color={'#8698B1'}/>
                                </SvgIcon>
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                          <TableCell align={'right'}>
                            <Tooltip title="Удаление">
                              <IconButton onClick={() => handleDeleteList(row)}>
                                <SvgIcon>
                                  <TrashIcon color={'#8698B1'}/>
                                </SvgIcon>
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                          <TableCell align={'right'}>
                            <Tooltip title="Поиск совпадений по элементам">
                              <IconButton onClick={() => handleOpenCoincidences(row)}>
                                <SvgIcon>
                                  <SearchIcon color={'#8698B1'}/>
                                </SvgIcon>
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                          <TableCell align={'right'}>
                            <Tooltip title="Настроки списка">
                              <IconButton onClick={() => handleEditList(row)}>
                                <SvgIcon>
                                  <SettingsIcon color={'#8698B1'}/>
                                </SvgIcon>
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                )
                :
                (
                  <TableBody>
                    <TableRow className={'MuiTableRow-list'}>
                      <TableCell>
                        <Skeleton variant="rect" width={20} height={45}/>
                      </TableCell>

                      {
                        columns.map((item, idx) => {
                          if (showColumns.indexOf(item.field) === -1) {
                            return null
                          }
                          if (item.type === 'status') {
                            return (
                              <TableCell key={item.field + '-' + idx}>
                                <Skeleton variant="rect" height={40} width={60}/>
                              </TableCell>
                            )
                          }
                          if (item.type === 'type') {
                            return (
                              <Skeleton variant="rect"/>
                            )
                          }

                          return (
                            <TableCell key={item.field + '-' + idx}>
                              <Skeleton variant="rect"/>
                            </TableCell>
                          )
                        })
                      }


                      <TableCell align={'right'}/>
                      <TableCell align={'right'}/>
                      <TableCell align={'right'}/>
                      <TableCell align={'right'}/>
                    </TableRow>
                  </TableBody>
                )
            }
          </Table>
        </Box>
      </PerfectScrollbar>

      <CustomTablePagination
        rows={rows}
        filter={filter}
        pagination={pagination}
        onChangePage={(event, page) => handleChangePage(event, page + 1)}
        onChangeRowsPerPage={handleChangeRowPage}
      />
    </>
  );
};

TableComponents.propTypes = {
  columns: PropTypes.array,
  showColumns: PropTypes.array,
  rows: PropTypes.array,
  selected: PropTypes.array,
  pagination: PropTypes.object,
  isSelectRowTable: PropTypes.func,
  isDeleteRowTable: PropTypes.func,
  isChangeRowPage: PropTypes.func,
};
TableComponents.defaultProps = {
  showColumns: []
};

export default TableComponents;
