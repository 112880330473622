import React, {Component, createRef} from 'react';
import {
  Box,
  Grid,
  Typography,
  FormControl,
  TextField,
  OutlinedInput,
  Button, DialogActions,
} from '@material-ui/core';
import {
  Page,
  CustomCheckbox
} from '../../../components';
import * as Yup from 'yup';
import Alert from '@material-ui/lab/Alert';
import {Formik} from 'formik';
import {withStyles} from '@material-ui/styles';
import axios from "../../../plugins/axios";
import {store} from "react-notifications-component";
import theme from "../../../theme";
import clsx from "clsx";

const SettingClear = (props) => {
  const {form, onChangeForm, classes} = props;

  const handleSaveForm = (form) => {
    onChangeForm(form)
  }

  return (
    <Formik
      initialValues={form}
      validationSchema={Yup.object().shape({
        deleteTo: (form.clearOn) ? Yup.date('Не правильно заполнена дата.').required('Заполните поле') : '',
        saveDay: (form.clearOn) ? Yup.number().min(1, 'Количество дней не может быть меньше 1.').required('Заполните поле') : '',
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus
      }) => {
        try {
          handleSaveForm(values, setStatus, setErrors)
        } catch (error) {
          const message = (error.response && error.response.data.message) || 'Что-то пошло не так';

          setStatus({success: false});
          setErrors({submit: message});
        }
      }}
    >
      {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          autoComplete={'off'}
          style={{width: '100%'}}
        >
          <Grid container spacing={2} className={classes.form}>
            <Grid item xs={12}>
              <CustomCheckbox
                label={'Удаление включено'}
                error={Boolean(touched.clearOn && errors.clearOn)}
                fullWidth
                helperText={touched.clearOn && errors.clearOn}

                name="clearOn"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.clearOn}
              />
            </Grid>

            <Grid item xs={12} container>
              <Grid item xs={12}>
                <Typography variant={'formTitle'}>Удалять до</Typography>
                <TextField
                  error={Boolean(touched.deleteTo && errors.deleteTo)}
                  fullWidth

                  name="deleteTo"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.deleteTo}
                  variant="outlined"
                  disabled={!values.clearOn}
                />
              </Grid>
              <Grid item xs={12}>
                {
                  (Boolean(touched.deleteTo && errors.deleteTo)) && (
                    <Typography color={'error'} variant={'caption'}>{touched.deleteTo && errors.deleteTo}</Typography>
                  )
                }
              </Grid>
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs={12}>
                <Typography variant={'formTitle'}>Дней хранить</Typography>
                <TextField
                  error={Boolean(touched.saveDay && errors.saveDay)}
                  fullWidth

                  name="saveDay"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="number"
                  value={values.saveDay}
                  variant="outlined"
                  disabled={!values.clearOn}
                />
              </Grid>
              <Grid item xs={12}>
                {
                  (Boolean(touched.saveDay && errors.saveDay)) && (
                    <Typography color={'error'} variant={'caption'}>{touched.saveDay && errors.saveDay}</Typography>
                  )
                }
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  )
}

const SystemSetting = React.forwardRef((props) => {
  const {
    form,
    onChangeForm,
    classes
  } = props;

  const handleFormChange = (event) => {
    let newForm = form;
    let item = event.target;
    let name = item.name;
    let value = item.value;

    let object = newForm.find(item => item.key === name)
    object.value = value;

    onChangeForm(newForm)
  }

  return (
    <Grid container spacing={2} className={classes.form}>
      {
        form.map((setting, idx) => {

          return (
            <Grid
              key={'system-setting-' + idx}
              item
              xs={12}
              container
              className={clsx({
                [classes.formItemString]: setting.type == 'string'
              })}
            >
              <Grid item xs={12}>
                <Typography variant={'formTitle'}>{setting.title}</Typography>

                <FormControl
                  variant={'outlined'}
                  style={{marginLeft: 'auto'}}
                >
                  <OutlinedInput
                    name={setting.key}
                    value={setting.value}
                    onChange={handleFormChange}
                    fullWidth
                    type={(setting.type == 'integer') ? 'number' : ''}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
            </Grid>
          )
        })
      }
    </Grid>
  )
});

class SystemSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listSettings: [],

      groupSystem: [],

      formSystemSettings: {},
      formSettingClear: {
        clearOn: false,

        deleteTo: null,
        saveDay: 365
      },
    }
  }

  // инициализация раздела
  componentDidMount = () => {
    this.onLoadSettings()
  }

  // загрузка настроек системы с бека и запись в форму на фронте
  onLoadSettings = () => {
    axios('get', '/settings/get').then(response => {
      let settings = response.data;
      let groupSystem = [];

      for (let key in settings) {
        let setting = settings[key];

        if (setting.group === 'system') {
          groupSystem.push(setting)
        }
      }


      this.setState({
        groupSystem
      })
    }).catch(error => {

    })
  }

  // изменение настройки
  onChangeSystemSettings = (groupSystem) => {
    this.setState({
      groupSystem
    })
  }

  onChangeSettingClear = (formSettingClear) => {
    this.setState({
      formSettingClear
    })
  }

  // сохранение формы
  onSaveForm = () => {
    let body = {};

    this.state.groupSystem.map(item => {
      body[item.key] = item.value;
    })

    axios('post', '/settings/save', body).then(response => {
      store.addNotification({
        title: 'Успешно',
        message: 'Настройки системы изменены',
        type: 'success',
        insert: 'top',
        container: 'bottom-left',
        dismiss: {
          duration: 3000,
          onScreen: false,
          pauseOnHover: true,
          delay: 0
        }
      });

      this.onLoadSettings()
    })
  }

  // возврат на главную страницу
  toRouteHome = () => {
    this.props.history.push('/dashboard')
  }

  render() {
    const {classes} = this.props;

    return (
      <Page title={'Устройства'}>
        <Grid container justify={'center'}>
          <Grid item xs={8}>
            <Box mb={4}>
              <Typography variant={'h2'} style={{fontWeight: 500}} align={'center'}>Настройки</Typography>
            </Box>

            <Box mb={6}>
              <Grid container spacing={3} justify={'center'}>
                <Grid item xs={6} className={classes.settingContainer}>
                  <Box p={4} bgcolor={'white'} boxShadow={1} borderRadius={4}>
                    <Box mb={4}>
                      <Typography variant={'h3'}>Настройки системы</Typography>
                    </Box>

                    <SystemSetting
                      form={this.state.groupSystem}
                      onChangeForm={this.onChangeSystemSettings}
                      classes={classes}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Grid container justify={'center'} spacing={2}>
              <Button
                size="small"
                variant="formCancel"
                onClick={this.toRouteHome}
                style={{marginRight: 10}}
              >
                Отменить
              </Button>
              <Button
                onClick={this.onSaveForm}
                size="small"
                variant="formSubmit"
              >
                Сохранить изменения
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Page>
    );
  }
}

const styles = {
  form: {
    '& > .MuiGrid-root > .MuiGrid-root': {
      display: 'flex',
      alignItems: 'center'
    },
    '& .MuiTypography-formTitle': {
      fontSize: 14,
      lineHeight: '16px',
      fontWeight: 'normal',
      maxWidth: 250,
      width: '100%',
      marginBottom: 0
    },
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
      lineHeight: '16px',
      fontWeight: 'normal',
      width: '100%',
      marginBottom: 0
    },
    '& .MuiInputBase-root': {
      width: 100,
      marginLeft: 'auto'
    },
    '& .MuiOutlinedInput-input': {
      fontSize: 14,
      lineHeight: '16px',
      fontWeight: '500',
      color: '#212529',
      textAlign: 'center'
    },
    '& .MuiFormControlLabel-root': {
      width: '100%',
      margin: 0,
      flexDirection: 'row-reverse'
    },
    '& .MuiCheckbox-root': {
      padding: 0
    },
    '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label svg': {
      backgroundColor: '#f3f5f9',
      stroke: '#212529'
    }
  },

  divider: {
    width: '100%',
    margin: '32px 0 16px'
  },

  settingContainer: {
    [theme.breakpoints.between(0, 1599)]: {
      maxWidth: '83.33333%',
      flexBasis: '83.33333%'
    },
  },

  formItemString: {
    '& > .MuiGrid-root': {
      flexDirection: 'column',
      alignItems: 'baseline!important'
    },
    '& .MuiInputBase-root': {
      width: '100%!important'
    },
    '& .MuiFormControl-root': {
      marginLeft: '0!important',
      marginTop: 8,
      width: '100%'
    },
    '& .MuiOutlinedInput-input': {
      textAlign: 'left!important'
    }
  }
};

export default withStyles(styles)(SystemSettings)
