import React, {useState, useEffect, useRef} from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,

    Typography,

    Box,
    Grid,

    IconButton,
    Button,
    Tooltip, CircularProgress
} from "@material-ui/core";
import {
    makeStyles
} from "@material-ui/styles";
import {
    X as XIcon,
    Move as MoveIcon,
    Video as VideoIcon,
    VideoOff as VideoOffIcon
} from "react-feather";
import {ResizableBox} from 'react-resizable';
import Draggable from 'react-draggable';
import varibles from "../../varibles";
import sizesVideoCamera from "../../constant/sizesVideoCamera";

const resizableBoxVaribles = {
    width: 600,
    height: 620,
    lockAspectRatio: true
};

const ModalSensorCameraStream = (props) => {
    const {sensor, isOpen, onClose, onAddCameraDashboard, isHideButtonAdd} = props;

    useEffect(() => {
        setOpenConfirm(false);
        handleSetCameraDashboard();
    }, [isOpen]);

    const refImage = useRef();

    const [cameraDashboard, setCameraDashboard] = useState([]);
    const [isOpenConfirm, setOpenConfirm] = useState(false);
    const [positionDraggable, setPositionDraggable] = useState({x: 0, y: 0});
    const [cameraSize, setCameraSize] = useState({width: 1024, height: 768});
    const [resolution, setResolution] = useState("640x480");

    const handleSetCameraDashboard = () => {
        let cameraDashboard = localStorage.getItem('camera_dashboard') || '[]';

        setCameraDashboard(JSON.parse(cameraDashboard));
    }
    const handleChangeCameraDashboard = (isActive, isPermissions) => {
        let newCameraDashboard = [...cameraDashboard];

        if (!isActive && newCameraDashboard.length > 5 && !isPermissions) {
            setOpenConfirm(true);

            return null
        }
        if (!isActive && newCameraDashboard.length > 5 && isPermissions) {
            setOpenConfirm(false);

            newCameraDashboard.splice(0, 1);
        }

        if (isActive) {
            const index = cameraDashboard.findIndex((t) => t.id === sensor?.camera?.id);

            newCameraDashboard.splice(index, 1);
        } else {
            newCameraDashboard.push(sensor.camera);
        }


        localStorage.setItem('camera_dashboard', JSON.stringify(newCameraDashboard));
        setCameraDashboard(newCameraDashboard);
    }

    const handleOnResizeStop = () => {
        const { clientWidth } = refImage?.current;
        const imageSize = sizesVideoCamera.find((item, idx) => {
            const widthPrev = idx > 0 ? sizesVideoCamera[idx - 1].width : 1281;
            const width = item.width;
            const difference = (widthPrev - width) / 2;

            if ((width - difference) <= clientWidth) {
                return item
            }
        });

        setCameraSize(imageSize);
        setResolution(`${imageSize.width}x${imageSize.height}`)
    }

    const classes = useStyles();
    const isActive = cameraDashboard.find((t) => t.id === sensor?.camera?.id);
    const url = `${varibles.API_URL}/video/sensor-camera-stream/${sensor?.camera?.id}?token=${localStorage.getItem('session_id')}&resolution=${resolution}`;
    const IconButtonVideo = isActive ? VideoOffIcon : VideoIcon;

    if (!isOpen) {
        return null
    }

    return (
        <>

            <Draggable
                handle=".header-resizable-box"
                defaultPosition={positionDraggable}
                onStop={(event) => setPositionDraggable({x: event.layerX, y: event.layerY})}
            >
                <ResizableBox
                    {...resizableBoxVaribles}
                    className={classes.resizableBox}
                    minConstraints={[500, 500]}
                    maxConstraints={[1200, 1200]}
                    onResizeStop={handleOnResizeStop}
                >
                    <Box display="flex" flexDirection="column" height="100%">
                        <Box mb={2}>
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item>
                                    <Grid container spacing={2}>
                                        <Grid item className="header-resizable-box">
                                            <MoveIcon size={20} color="#8698B1"/>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1">{sensor?.title}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Tooltip
                                                title={isActive ? 'Убрать из мультиэкрана' : 'Добавить в мультиэкран'}
                                                PopperProps={{
                                                    className: classes.tooltip
                                                }}
                                            >
                                                <IconButton style={{padding: 0}} onClick={() => handleChangeCameraDashboard(isActive)}>
                                                    <IconButtonVideo/>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Tooltip
                                        title="Закрыть"
                                        PopperProps={{
                                            className: classes.tooltip
                                        }}
                                    >
                                        <IconButton onClick={onClose} style={{padding: 0}}>
                                            <XIcon size={20} color="#8698B1"/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mb={1}>
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1">Адрес: <Typography variant="body1">{sensor?.address}</Typography></Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1">Направление: <Typography variant="body1">{sensor?.camera?.direction || sensor?.camera?.uid}</Typography></Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box height="100%" maxHeight="100%" position="relative" mb={2}>
                            <Box position="absolute" width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress size={80} style={{color: '#212529'}}/>
                            </Box>

                            <img ref={refImage} src={url} className={classes.image}/>
                        </Box>
                    </Box>
                </ResizableBox>
            </Draggable>


            <Dialog open={isOpenConfirm} maxWidth="md" fullWidth onClose={() => setOpenConfirm(false)}>
                <DialogTitle>
                    <Typography variant="h3">Предупреждение</Typography>
                </DialogTitle>
                <DialogContent>
                    <Box mb={2}>
                        <Typography variant="subtitle1">Свободных ячеек не осталось. Вы можете удалить в ручную камеры,
                            или нажать продолжить что бы удалить первую камеру.</Typography>
                    </Box>

                    <Grid container spacing={2} justify="flex-end">
                        <Grid item>
                            <Button onClick={() => setOpenConfirm(false)} variant="outlined">Отмена</Button>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={() => handleChangeCameraDashboard(isActive, true)}
                                variant="contained"
                            >Продолжить</Button>
                        </Grid>
                    </Grid>

                </DialogContent>
            </Dialog>

        </>
    )
}

const useStyles = makeStyles((theme) => ({
    resizableBox: {
        zIndex: 99999999999,
        borderRadius: 10,
        backgroundColor: "white",
        padding: "12px 24px",
        position: "fixed",
        top: '10%',
        left: '10%',
        boxShadow: 'rgb(142 150 168 / 24%) 0px 2px 8px',

        '& .react-resizable-handle-se': {
            width: 30,
            height: 30,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            bottom: 0,
            right: 0,
            paddingLeft: 5,

            '&:after': {
                content: "'↔'",
                fontSize: 25,
                lineHeight: '30px',
                transform: 'rotate(45deg)',
                opacity: '0.5'
            }
        }
    },
    resizableBoxOpen: {},

    image: {
        width: '100%',
        height: '100%',
        borderRadius: 5,
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 8
    },

    tooltip: {
        zIndex: 999999999999,
    }
}));

export default ModalSensorCameraStream
