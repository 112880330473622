import React from 'react';
import {
  Grid,
  useTheme
} from '@material-ui/core';
import Chart from 'react-apexcharts';
import './index.scss';

const LineGraph = (props) => {
  const theme = useTheme();

  const {series, labels, propsColors} = props;
  let newSeries = series.slice();
  let color = (propsColors) ? propsColors : ['#1F87E6', '#FF5C7C', '#8698B1'];
  let sortColors = [];
  let sortSeries = [];

  newSeries.map((item, idx) => {
    item['color'] = color[idx]
    item['lastValue'] = item.data[item.data.length - 1]
  })

  const compare = ( a, b ) => {
    if ( a.lastValue < b.lastValue ){
      return -1;
    }
    if ( a.lastValue > b.lastValue ){
      return 1;
    }
    return 0;
  }

  sortSeries = newSeries.sort( compare );
  sortSeries.map(item => {
    sortColors.push(item.color)
  })

  const options = {
    chart: {
      background: theme.palette.background.paper,
      stacked: false,
      toolbar: {
        show: false,
      },
      zoom: false,
      fontFamily: 'Graphik-LCG',
      parentHeightOffset: 0,
      animations: {
        enabled: false,
      },
      width: '100%',
    },
    colors: [...sortColors],
    dataLabels: {
      enabled: false
    },
    grid: {
      borderColor: '#ebeef0',
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    legend: {
      show: false,
      position: 'top',
      horizontalAlign: 'left',
      offsetX: 0,
      labels: {
        colors: theme.palette.text.secondary
      }
    },
    markers: {
      size: 0,
      strokeColors: ['#1f87e6', '#27c6db'],
      strokeWidth: 0,
      shape: 'circle',
      radius: 2,
      hover: {
        size: undefined,
        sizeOffset: 2
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        type: 'vertical',
        shadeIntensity: 1,
        opacityFrom: 0.8,
        opacityTo: 0.8,
        stops: [100]
      }
    },
    stroke: {
      width: 0,
      curve: 'smooth',
      lineCap: 'butt'
    },
    theme: {
      mode: theme.palette.type
    },
    tooltip: {
      theme: theme.palette.type
    },
    xaxis: {
      axisBorder: {
        color: theme.palette.divider
      },
      axisTicks: {
        show: true,
        color: theme.palette.divider
      },
      categories: labels,
      labels: {
        style: {
          colors: theme.palette.text.secondary
        }
      }
    },
    yaxis: [
      {
        axisBorder: {
          show: false,
          color: theme.palette.divider
        },
        axisTicks: {
          show: false,
          color: theme.palette.divider
        },
        labels: {
          style: {
            colors: theme.palette.text.secondary
          }
        }
      }
    ]
  };

  return (
    <>
      <Grid container spacing={3}>
        {
          series.map((item, idx) => {
            return (
              <Grid item style={{ display: 'flex', alignItems: 'center', marginTop: 4 }}>
                <div style={{
                  background: item.color,
                  color: item.color,
                  height: 14,
                  width: 14,
                  borderWidth: 0,
                  borderColor: 'rgb(255, 255, 255)',
                  borderRadius: 12,
                  marginRight: 8
                }}></div>

                <span
                  style={{
                    fontSize: 12,
                    fontWeight: '400',
                    fontFamily: 'Graphik-LCG',
                    color: '#192A3E'
                  }}
                >{ item.name }</span>
              </Grid>
            )
          })
        }
      </Grid>

      <Chart
        type="area"
        height="300"
        options={options}
        series={sortSeries}
      />
    </>
  )
}

export default LineGraph
