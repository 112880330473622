import React, { useRef, useEffect } from 'react';
import { Polyline, withLeaflet } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-polylinedecorator';

const PolylineDecorator = withLeaflet(props => {
  const polyRef = useRef();
  const { connectDots } = props;

  useEffect(() => {
    const polyline = polyRef.current.leafletElement;
    const { map } = polyRef.current.props.leaflet;

    L.polylineDecorator(polyline, {
      patterns : props.patterns
    }).addTo(map);
  }, []);

  if (!connectDots){
    return null
  }

  return <Polyline ref={polyRef} {...props} />;
});

export default PolylineDecorator;
