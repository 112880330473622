// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Archive from './Archive';

export default compose(
  connect(
    state => ({}),
    dispatch => ({}),
  ),
)(Archive);
