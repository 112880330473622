import React, {useState} from 'react';

import {
  makeStyles,
  Grid,
  Button,
  Typography
} from '@material-ui/core';
import {
  DateRange,
  MultiSelectText,
  ModalSensorFilter
} from '../../../../../components';
import clsx from "clsx";
import convertEnToRu from "../../../../../helpers/convertEnToRu";

const useStyles = makeStyles((theme) => ({
  root: {},
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  queryField: {
    width: 500
  },
  categoryField: {
    width: '100%',
  },
  imageCell: {
    fontSize: 0,
    width: 68,
    flexBasis: 68,
    flexGrow: 0,
    flexShrink: 0
  },
  image: {
    height: 68,
    width: 68
  },

  label: {
    marginBottom: 5
  },

  buttonSpeedArrow: {
    minWidth: 'initial',
    padding: '10px 5px',
    backgroundColor: 'rgba(194, 207, 224, 0.2)'
  },
  buttonSpeedArrowActive: {
    backgroundColor: '#212529',

    '&:hover': {
      backgroundColor: '#1a237e',
    },
    '&:active': {
      backgroundColor: '#1a237e',
    },
  },

  clarificationForm: {
    fontSize: 12,
    lineHeight: '14px',
    color: theme.palette.gray.dark,

    '& sup': {
      position: 'relative',
      top: 0,
      color: theme.palette.primary.main,
    }
  },

  formBottom: {
    display: 'flex',
    alignItems: 'center'
  },

  filterContainer: {
    '& > .MuiGrid-grid-xs-4': {
      [theme.breakpoints.between(0, 1599)]: {
        maxWidth: '50%',
        flexBasis: '50%'
      },
    }
  }
}));

let timeOutEnterEvent = null;
const Filter = (props) => {
  const classes = useStyles();
  let {filters, onChange, sensorList, sensorFilter, onFilterSensor, onChangeFile, onStartFilter} = props;

  // Фунция изменения фильтра
  const handleChangeFilterName = (date, name) => {
    let newFilter = {...filters};
    newFilter[name] = date;
    onChange(newFilter, true)
  };
  const handleChangeFilterGrz = (value, name) => {
    let _value = (value || []).map((v) => convertEnToRu(v));
    let newFilter = {...filters};
    newFilter[name] = _value;
    onChange(newFilter, true);
  }

  // Функция изменения набора устройств
  const handleChangeDevices = (name, list, offUpdate) => {
    filters[name] = list;
    onChange(filters, true)
  }

  // Очистка всего фильтра
  const handleClearAll = () => {
    filters = {
      sensor_ids: [],
      license_plate_list: [],
      colors: [],
      license_plate_id: '',
      mark: '',
      model: '',
      license_plate_mask: '',
      datetime_start: null,
      datetime_end: null,
      sort_field: '',
      sort_direction: '',
      license_plate_empty: false,
      license_plate_foreign: false,
      license_plate_invalid: false,

      limit: 20
    }

    onChange(filters)
  }

  // Функция изменение даты (подается временной отрезок, возращается разбитый по датам)
  const handleChangeDate = (range) => {
    filters.datetime_start = range.startDate * 1000;
    filters.datetime_end = range.endDate * 1000;

    onChange(filters, true)
  }

  // Функциал загрузки фильтра файлом
  const handleChangeFile = (file) => {
    onChangeFile(file);
  }


  React.useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        clearTimeout(timeOutEnterEvent);
        timeOutEnterEvent = setTimeout(() => {
          props.onStartFilter();
        }, 500)
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  return (
    <form
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={5}>
        <Grid item container style={{flex: 1}} spacing={3}>
          <Grid xs={4} item>
            <Typography variant={'formTitle'}>ГРЗ</Typography>
            <MultiSelectText
              className={classes.categoryField}
              name={'license_plate_list'}
              value={filters.license_plate_list}
              variant="outlined"
              onChange={handleChangeFilterGrz}
              fileUpload={true}
              onChangeFile={handleChangeFile}
            />
          </Grid>
          <Grid xs={4} item>
            <Typography variant={'formTitle'}>Временной промежуток</Typography>
            <DateRange
              name='datetime_start'
              dateStart={filters.datetime_start}
              dateEnd={filters.datetime_end}
              onChange={handleChangeDate}
            />
          </Grid>
          <Grid xs={4} item>
            <Typography variant={'formTitle'}>&nbsp;</Typography>
            <ModalSensorFilter
              value={filters.sensor_ids}
              list={sensorList}
              onChange={(list) => handleChangeDevices('sensor_ids', list, true)}
              filter={sensorFilter}
              onFilter={(filter) => onFilterSensor(filter)}
              fullWidth

              onStartFilter={onStartFilter}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item>
            <Button variant="contained" onClick={props.onStartFilter}>
              Поиск
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={handleClearAll}>
              Очистить фильтр
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

export default Filter;
