import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Paper,
  makeStyles,
  IconButton, Grid, TextField, Box, Button,
} from '@material-ui/core';
import Draggable from 'react-draggable';
import {withStyles} from '@material-ui/core/styles';
import {X as XIcon} from 'react-feather';
import * as Yup from "yup";
import clsx from "clsx";
import {ColorPicker} from "../index";
import Alert from "@material-ui/lab/Alert";
import {Formik} from "formik";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    maxHeight: '65vh',
    paddingBottom: 24
  },

  buttonClear: {
    height: 38,
    padding: '5px 15px'
  }
}));

const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    transform: 'translate(0, -50%)',
    color: '#152435',
  },

  switchTypeTitle: {
    fontWeight: '500',
    fontFeatureSettings: "'ss01' on"
  },

  gridSwitch: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto'
  },
  gridTypes: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 42
  },
  typeTitle: {
    marginRight: 20,
    fontSize: 13,
    color: theme.palette.gray.dark
  },

  switch: {
    '& .MuiSwitch-colorSecondary': {
      color: '#485DD2'
    },
    '& .MuiSwitch-colorSecondary + .MuiSwitch-track': {
      backgroundColor: '#212529'
    },
  }
});

function PaperComponent(props) {
  return (
    <Draggable
      handle="#dialog-confirmation-deletion"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const MyDialogTitle = withStyles(styles)((props) => {
  const {children, classes, onClose, ...other} = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>

      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size={'small'}
        >
          <XIcon/>
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const ModalDevices = (props) => {
  const classes = useStyles();
  const {
    open,
    isClose,
    form,
    onAdd
  } = props;

  const handleSubmit = (form) => {
    onAdd(form)
  }

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth={'md'}
      onClose={isClose}
      PaperComponent={PaperComponent}
      aria-labelledby="dialog-confirmation-deletion"
    >
      <MyDialogTitle
        id="dialog-confirmation-deletion"
        onClose={isClose}
      >
        <Typography variant="h3">Добавление в избранное</Typography>
      </MyDialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Formik
          initialValues={form}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Заполните поле')
          })}
          onSubmit={async (values, {
            setErrors,
            setStatus
          }) => {
            try {
              handleSubmit(values, setStatus, setErrors)
            } catch (error) {
              const message = (error.response && error.response.data.message) || 'Что-то пошло не так';

              setStatus({success: false});
              setErrors({submit: message});
            }
          }}
        >
          {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
            <form
              noValidate
              className={clsx(classes.root)}
              onSubmit={handleSubmit}
              autoComplete={'off'}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant={'formTitle'}>Наименование<sup>*</sup></Typography>
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    autoFocus
                    helperText={touched.name && errors.name}
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={'formTitle'}>Цвет</Typography>
                  <ColorPicker
                    error={Boolean(touched.color && errors.color)}
                    fullWidth
                    helperText={touched.color && errors.color}
                    name="color"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.color}
                    variant="outlined"
                  />
                </Grid>
              </Grid>

              <Box mt={3}>
                <Grid justify={'flex-end'} style={{display: 'flex'}}>
                  <Button
                    size="large"
                    variant="formCancel"
                    onClick={() => isClose()}
                  >
                    Отменить
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    size="large"
                    variant="formSubmit"
                    style={{ marginLeft: 10 }}
                    onClick={handleSubmit}
                  >
                    Создать
                  </Button>
                </Grid>

                {errors.submit && (
                  <Box mt={2}>
                    <Alert severity="error">{errors.submit}</Alert>
                  </Box>
                )}
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default ModalDevices
