import React, {useState} from 'react';

import {
  TextField,
  makeStyles,
  Grid,
  InputAdornment,
  IconButton,
  Button,
  Typography,
  Tooltip
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {
  DateRange,
  ModalSensorFilter
} from '../../../../../components';
import {
  X as XIcon
} from 'react-feather'
import moment from 'moment';
import convertEnToRu from "../../../../../helpers/convertEnToRu";

const useStyles = makeStyles((theme) => ({
  root: {},
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  queryField: {
    width: 500
  },
  categoryField: {
    width: '100%',
  },
  imageCell: {
    fontSize: 0,
    width: 68,
    flexBasis: 68,
    flexGrow: 0,
    flexShrink: 0
  },
  image: {
    height: 68,
    width: 68
  },

  filterContainer: {
    '& > .MuiGrid-grid-xs-3': {
      [theme.breakpoints.between(0, 1599)]: {
        maxWidth: '33.333333%',
        flexBasis: '33.333333%'
      },
    }
  },
  filterGridAction: {
    [theme.breakpoints.between(0, 1599)]: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      marginLeft: 'auto',

      '& > *': {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: 0,
        width: 'auto'
      }
    }
  },

  clarificationForm: {
    fontSize: 12,
    lineHeight: '14px',
    color: theme.palette.gray.dark,

    '& sup': {
      position: 'relative',
      top: 0,
      color: theme.palette.primary.main,
    }
  },
}));

const Filter = (props) => {
  const classes = useStyles();
  let {filters, onChange, sensorList, sensorFilter, onFilterSensor, onSearch} = props;

  const handleChangeFilter = (event) => {
    const item = event.target;
    const name = item.name;
    let value = item.value;

    if (value === 'Все') {
      value = ''
    }

    filters[name] = value;

    onChange(filters)
  }
  const handleChangeFilterGrz = (event) => {
    const item = event.target;
    const name = item.name;
    let value = convertEnToRu(item.value);
    filters[name] = value;
    onChange(filters)
  }
  const handleChangeFilterName = (date, name) => {
    filters[name] = date;
    onChange(filters)
  }
  const handleClearFilter = (name, value) => {
    filters[name] = value;

    onChange(filters)
  }
  const handleClearAll = () => {
    let datetime_start = moment().subtract(6, 'days').set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0
    });
    let datetime_end = moment().set({
      hour: 23,
      minute: 59,
      second: 0,
      millisecond: 0
    });

    filters = {
      sensor_ids: [],
      license_plate: '',
      datetime_start: datetime_start,
      datetime_end: datetime_end,
      interval_before: 10,
      interval_after: 10,

      limit: 20
    }

    onChange(filters, true)
  }
  const handleChangeDate = (range) => {
    filters.datetime_start = range.startDate * 1000;
    filters.datetime_end = range.endDate * 1000;

    onChange(filters)
  }
  const handleChangeDevices = (name, list) => {
    filters[name] = list;

    onChange(filters)
  }

  return (
    <Grid container spacing={2} className={classes.filterContainer}>
      <Grid xs={3} item>
        <Typography variant={'formTitle'}>ГРЗ<sup>*</sup></Typography>
        <TextField
          className={classes.categoryField}
          name={'license_plate'}
          value={filters.license_plate}
          variant="outlined"
          onChange={handleChangeFilterGrz}
          placeholder={'Государственный номер'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <>
                  {
                    (filters.license_plate) && (
                      <Tooltip title="Очистить">
                        <IconButton onClick={() => handleClearFilter('license_plate', '')}>
                          <HighlightOffIcon/>
                        </IconButton>
                      </Tooltip>
                    )
                  }
                </>
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid xs={3} item container spacing={1}>
        <Grid item xs={6}>
          <Typography variant={'formTitle'}>Интервал до<sup>*</sup></Typography>
          <TextField
            className={classes.categoryField}
            name={'interval_before'}
            value={filters.interval_before}
            variant="outlined"
            type={'number'}
            placeholder={'В секундах'}
            onChange={handleChangeFilter}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <>
                    {
                      (filters.interval_before && filters.interval_before > 1) && (
                        <Tooltip title="Очистить">
                          <IconButton onClick={() => handleClearFilter('interval_before', 1)}>
                            <HighlightOffIcon/>
                          </IconButton>
                        </Tooltip>
                      )
                    }
                  </>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant={'formTitle'}>Интервал после<sup>*</sup></Typography>
          <TextField
            className={classes.categoryField}
            name={'interval_after'}
            value={filters.interval_after}
            variant="outlined"
            type={'number'}
            placeholder={'В секундах'}
            onChange={handleChangeFilter}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <>
                    {
                      (filters.interval_after && filters.interval_after > 1) && (
                        <Tooltip title="Очистить">
                          <IconButton onClick={() => handleClearFilter('interval_after', 1)}>
                            <HighlightOffIcon/>
                          </IconButton>
                        </Tooltip>
                      )
                    }
                  </>
                </InputAdornment>
              )
            }}
          />
        </Grid>
      </Grid>
      {Boolean(true) && (
        <Grid xs={3} item>
          <Typography variant={'formTitle'}>Временной промежуток<sup>*</sup></Typography>
          <DateRange
            name='datetime_start'
            dateStart={filters.datetime_start}
            dateEnd={filters.datetime_end}
            onChange={handleChangeDate}
            hideQuickSelect
          />
        </Grid>
      )}
      <Grid xs={3} item>
        <Typography variant={'formTitle'}>&nbsp;</Typography>
        <ModalSensorFilter
          value={filters.sensor_ids}
          list={sensorList}
          onChange={(list) => handleChangeDevices('sensor_ids', list)}
          filter={sensorFilter}
          onFilter={(filter) => onFilterSensor(filter)}
        />
      </Grid>


      <Grid xs={12} item className={classes.filterGridAction}>
        <Grid container spacing={1} alignItems={'flex-end'}>
          <Grid item>
            <Button variant='contained' onClick={onSearch}>Применить</Button>
          </Grid>
          <Grid item>
            <Button variant='outlined' onClick={handleClearAll}>
              Отмена
            </Button>
          </Grid>

          <Grid item style={{marginLeft: 'auto'}}>
            <Typography className={classes.clarificationForm}>
              <sup>*</sup>&nbsp;Поле является обязательным для заполнения</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Filter;
