import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  Map,
  TileLayer
} from 'react-leaflet'
import L from 'leaflet';
import 'leaflet-polylinedecorator';
import {EditControl} from 'react-leaflet-draw';
import PolylineDecorator from './PolylineDecorator';
import Points from './Points';
import clsx from "clsx";
import {MapTags} from "../../../../../components";

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
  leafletContainer: {
    width: '100%',
    height: '100%'
  },
  leafletNotDots: {
    '& .leaflet-interactive': {
      display: 'none'
    },
    '& .marker, .marker-cluster': {
      display: 'flex'
    },
  },
}));

const MapObject = (props) => {
  const arrow = [{
    offset: '95%',
    repeat: 0,
    symbol: L.Symbol.arrowHead({
      pixelSize: 20,
      pathOptions: {
        stroke: false,
        strokeColor: '#2979FF',
        weight: 10,
        fill: true,
        fillColor: '#2979FF',
        fillOpacity: 1,
        fillRule: ''
      }
    })
  }];
  const classes = useStyles();
  const {position, points, apiPoints, showCluster, openInfoMarker, connectDots, showPointNumbers} = props;

  const getListPolyline = function () {
    let list = []

    apiPoints.map((point, idx) => {
      if (idx < apiPoints.length - 1) {
        let currentGps = [point.gps.lat, point.gps.lon];
        let nextGps = [apiPoints[idx + 1].gps.lat, apiPoints[idx + 1].gps.lon];
        list.push([currentGps, nextGps])
      }
    })

    return list
  }

  const handleGetBounds = () => {
    let bounds = [];

    points.map(point => {
      bounds.push([
        point.position.lat,
        point.position.lon
      ])
    })

    if (bounds.length <= 0){
      return null
    }

    return bounds
  }

  return (
    <Map
      className={clsx({
        [classes.leafletContainer]: true,
        [classes.leafletNotDots]: !connectDots
      })}
      center={position}
      length={4}
      zoom={10}
      bounds={handleGetBounds()}
    >
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />

      <Points
        points={points}
        showCluster={showCluster}
        openInfoMarker={openInfoMarker}
        showPointNumbers={showPointNumbers}
      />

      {
        getListPolyline().map((polyline, idx) => (
          <PolylineDecorator
            key={'polyline-' + idx}
            patterns={arrow}
            interactive={false}
            positions={polyline}
            connectDots={connectDots}
          />
        ))
      }

      <MapTags/>

    </Map>
  );
};

export default MapObject;
