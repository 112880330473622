import React, {Component} from 'react';
import {Router} from 'react-router-dom';
import {Provider} from 'react-redux';
import {createBrowserHistory} from 'history';
import {Chart} from 'react-chartjs-2';
import {ThemeProvider} from '@material-ui/styles';
import validate from 'validate.js';
import {chartjs} from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
import storeRedux from './redux/store';
import ReactNotification from 'react-notifications-component'
import {BrowserRouter, HashRouter} from 'react-router-dom'
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {
  AlertChangeBrowser,
  PageError
} from './components';
import * as Sentry from '@sentry/react';
import Navigation from "./routes";

import MomentUtils from '@date-io/moment';

import 'react-notifications-component/dist/theme.css';
import {getLocalStorage} from "./helpers/localStorage";
import axios from "./plugins/axios";

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

const history = createBrowserHistory();

const FallbackComponent = (props) => {
  return (
    <PageError/>
  )
};

export default class App extends Component {
  render() {
    return (
      <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
        <ThemeProvider theme={theme}>
          <Provider store={storeRedux}>
            <MuiPickersUtilsProvider utils={MomentUtils}>

              <AlertChangeBrowser/>

              <ReactNotification/>

              <HashRouter>
                <Navigation />
              </HashRouter>
            </MuiPickersUtilsProvider>
          </Provider>
        </ThemeProvider>
      </Sentry.ErrorBoundary>
    );
  }
}
