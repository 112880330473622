import React, {Component} from 'react';
import {
    Box,
    Grid,
    Typography,

    Backdrop,
    CircularProgress,
} from '@material-ui/core';
import {
    Page
} from "../../../components";
import {
    Filter as FilterComponent,
    Map as MapComponent,
    Export as ExportComponent,
    AddressesList as AddressesListComponent
} from "./components";
import {sendMessages} from "../../../common/messages";
import axiosAgent from "../../../plugins/axios";
import urls from "../../../constant/urls";
import colorPalettes from "../../../constant/colorPalettes";
import {sendMessage} from "../../../utils/message";
import queryString from "query-string";

const initFilter = {
    license_plate: ""
};

class FrequentStops extends Component {
    constructor(props) {
        super(props);

        this.state = {
            parkingList: [],

            filter: {...initFilter},

            export: {},

            isShowBackdrop: false
        }
    }

    componentDidMount = async () => {
        const search = queryString.parse(this.props.location.search);

        if (!search.grz) {
            return null
        }

        let newFilter = {...this.state.filter};
        newFilter.license_plate = search.grz;

        await this.setState({ filter: newFilter });

        await this.getParkingList();
    }

    // Метод получения частых стоянок
    getParkingList = async () => {
        const filter = { ...this.state.filter };

        if (!filter.license_plate) {
            sendMessages({
                message: "Введите ГРЗ",
                type: "danger"
            });

            return null
        }

        this.setState({isShowBackdrop: true});


        // Получение списка
        let parkingList = await axiosAgent('post', urls['frequent-parkings-get'], filter).then((res) => {
            return res.data.parking_list || []
        }).catch((err) => {
            return []
        })

        if (parkingList.length <= 0) {
            sendMessages({
                message: "По вашему ГРЗ ничего не найдено",
                type: "warning"
            });
        }

        parkingList = parkingList.map((item, idx) => {
            return {
                ...item,
                color: colorPalettes[idx]
            }
        })

        this.setState({
            parkingList,
            isShowBackdrop: false
        });

        window.history.replaceState(null, null, `/#/frequent-stops?grz=${ filter.license_plate }`);
    }

    // Работа с фильтром
    changeFilter = (filter) => {
        this.setState({ filter });
    }
    clearFilter = () => {
        this.setState({ filter: initFilter });
    }

    // Создание задачи на экспорт документа
    export = async (props) => {
        this.setState({isShowBackdrop: true});

        let filter = {
            license_plate: this.state.filter.license_plate,
            type: props.type,
            encoding: Number(props.encoding),
        };

        const data = await axiosAgent('post', '/export/create', filter).then((response) => {
            return true
        }).catch((error) => {
            return { error: error?.response?.data?.error || "Ошибка сервера" }
        });

        this.setState({isShowBackdrop: false});

        if (data.error) {
            sendMessage({
                type: "danger",
                message: data.error
            });

            return null
        }

        sendMessage({
            type: "success",
            message: "Задача на выгрузку успешно создана"
        });
    }

    render() {
        const {
            parkingList,

            filter,

            isShowBackdrop
        } = this.state;

        return (
            <Page>

                <Box mb={2}>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="h1">Места частых стоянок ТС</Typography>
                        </Grid>
                        <Grid item>
                            <ExportComponent
                                disable={Boolean(parkingList.length <= 0)}
                                onExportFile={this.export}
                            />
                        </Grid>
                    </Grid>
                </Box>


                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <FilterComponent
                            filter={filter}
                            onChange={this.changeFilter}
                            onClear={this.clearFilter}
                            onSearch={this.getParkingList}
                        />

                        {Boolean(parkingList.length > 0) && (
                            <Box mt={2}>
                                <AddressesListComponent
                                    data={parkingList}
                                />
                            </Box>
                        )}
                    </Grid>
                    <Grid item xs={10}>
                        {Boolean(parkingList.length > 0) && (
                            <MapComponent
                                data={parkingList}
                            />
                        )}
                    </Grid>
                </Grid>

                <Backdrop open={isShowBackdrop} invisible={isShowBackdrop}>
                    <CircularProgress size={80} style={{color: 'white'}}/>
                </Backdrop>

            </Page>
        );
    }
}

export default FrequentStops
