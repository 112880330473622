import React from 'react';
import {useLocation, matchPath} from 'react-router';
import clsx from 'clsx';
import {Link as RouterLink} from 'react-router-dom';
import {
  Button,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {
  ChevronDown as ChevronDownIcon,
  ChevronUp as ChevronUpIcon
} from 'react-feather';
import NavItem from './NavItem';

const useStyles = makeStyles((theme) => ({
  root: {},

  button: {
    height: 64,
    color: 'white',
    padding: '10px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    borderRadius: 0,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: 16,
      lineHeight: '16px',
      fontFeatureSettings: "'ss01' on",

      [theme.breakpoints.between(0, 1599)]: {
        fontSize: 14
      },
    },
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium
      }
    }
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),

    [theme.breakpoints.between(0, 1599)]: {
      marginRight: 4,
    },
    [theme.breakpoints.between(0, 1399)]: {
      width: 18
    },
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    background: '#040405'
  },
  iconActive: {
    opacity: '1!important',

    '& > *': {
      opacity: '1!important'
    },
    '& > * > *': {
      opacity: '1!important'
    },
    '& > * > * > *': {
      opacity: '1!important'
    },
  },

  menu: {
    '& .MuiPopover-paper': {
      boxShadow: '0px 2px 8px rgba(142, 150, 168, 0.24)',
      borderRadius: '4px!important',
    },
    '& .MuiList-root': {
      display: 'flex',
      flexDirection: 'column'
    },
    '& .MuiPaper-rounded': {
      borderRadius: 0,
      top: '68px!important'
    }
  },

  menuItem: {
    padding: 0
  }
}));

const NavItemMulti = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const location = useLocation();
  const {user, Icon, title, href, routes, keyName, routesNames} = props;

  let pathname = location.pathname;

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheckPermission = (route) => {
    const roles = {
      [user?.role]: true
    };

    if (roles.admin) {
      return true
    }
    if (!route.roles || route.roles.length === 0) {
      return true
    }

    let isPermission = false;

    route.roles.map((role) => {
      Object.keys(roles).map((key) => {
        if (roles[role]) {
          isPermission = true
        }
      })
    })

    return isPermission
  }

  const open = routesNames.indexOf(pathname) > -1;

  return (
    <div key={keyName}>
      <Button
        to={'#'}
        component={RouterLink}
        className={(Boolean(open)) ? clsx(classes.button, classes.active) : classes.button}
        exact='true'

        onClick={handleOpenMenu}
      >
        {Icon && (
          <Icon
            className={clsx({
              [classes.icon]: true,
              [classes.iconActive]: Boolean(open)
            })}
            size="20"
          />
        )}
        <span className={classes.title}>
          {title}
        </span>

        {
          (Boolean(anchorEl)) ? (
              <ChevronUpIcon size={18} style={{marginLeft: 5}}/>
            ) :
            (
              <ChevronDownIcon size={18} style={{marginLeft: 5}}/>
            )
        }
      </Button>


      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        className={classes.menu}
      >
        {
          routes.map((route, idx) => {
            if (!handleCheckPermission(route)){
              return null
            }

            return (
                <MenuItem key={'button-header-menu-' + idx + '-' + keyName} className={classes.menuItem}>
                  <NavItem
                      Icon={route.icon}
                      title={route.title}
                      href={route.href}
                      menu
                  />
                </MenuItem>
            )
          })
        }
      </Menu>
    </div>
  )
}

export default NavItemMulti;
