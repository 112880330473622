import React from 'react';
import {
  makeStyles,
  Menu,
  MenuItem,
  Grid,
  Typography,
  Avatar,
  IconButton,
  Tooltip
} from '@material-ui/core';
import {
  Settings as SettingsIcon
} from '@material-ui/icons';
import {Link as RouterLink, useHistory} from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  Users as UsersIcon
} from 'react-feather';
import logo from '../../../../../../assets/image/logo.png';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiGrid-spacing-xs-2 > .MuiGrid-item': {
      [theme.breakpoints.between(0, 1599)]: {
        padding: 4
      },
    }
  },

  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  menu: {
    '& .MuiList-root': {
      display: 'flex',
      flexDirection: 'column'
    },
    '& .MuiPaper-rounded': {
      borderRadius: 0,
      top: '64px!important'
    }
  },
  menuItem: {
    color: 'inherit'
  },

  divider: {
    height: 32,
    width: 1,
    backgroundColor: 'white',
    opacity: 0.2
  },

  buttonSetting: {
    padding: 0,

    '& path': {
      fill: 'white'
    }
  },

  gridButtonContainerSetting: {
    [theme.breakpoints.between(0, 1399)]: {
      padding: '0!important'
    }
  }
}));
const useStylesUserContent = makeStyles(theme => ({
  root: {
    '& .MuiGrid-spacing-xs-2 > .MuiGrid-item': {
      [theme.breakpoints.between(0, 1599)]: {
        padding: 4
      },
    }
  },
  avatar: {
    cursor: 'pointer',
    backgroundColor: 'transparent',
  },
  name: {
    fontWeight: '500',
    fontSize: 14,
    lineHeight: '14px',
    letterSpacing: '-0.005em',
    color: 'white'
  },
  email: {
    fontSize: 12,
    lineHeight: '12px',
    letterSpacing: '-0.005em',
    color: theme.palette.gray.light,
    cursor: 'pointer',

    '&:hover': {
      color: 'white',
      opacity: 0.8
    }
  },

  avatarContent: {
    [theme.breakpoints.between(0, 1599)]: {
      display: 'none'
    },
  }
}));

const UserContent = (props) => {
  let {onClick, user} = props;
  const classes = useStylesUserContent();

  if (!user) {
    user = {}
  }

  return (
    <Grid
      id={'header-user-content'}
      container
      spacing={2}
      className={classes.root}
      wrap={'nowrap'}
      alignItems={'center'}
    >
      <Grid item className={classes.avatarContent}>
        <Tooltip title={'Дополнительная информация'}>
          <Avatar
            className={classes.avatar}
            onClick={onClick}
            variant={'square'}
          >
            <UsersIcon/>
          </Avatar>
        </Tooltip>
      </Grid>
      <Grid item>
        <Grid>
          {
            (user.full_name) ?
              (<Typography className={classes.name} onClick={onClick}>{user.full_name}</Typography>)
              :
              (<Skeleton variant="rect" width={50} height={10} style={{backgroundColor: '#b5b5b5', marginBottom: 5}}/>)
          }
        </Grid>
      </Grid>
    </Grid>
  )
}

const User = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElSetting, setAnchorElSetting] = React.useState(null);
  const {userExit, user} = props;
  const history = useHistory();
  const classes = useStyles();
  const isGlobalAdmin = Boolean(user?.role === "admin");

  const handleUserExit = () => {
    userExit()
  }

  const handleOpen = (event) => {
    let content = document.getElementById('header-user-content');

    setAnchorEl(content);
  };
  const handleOpenSetting = (event) => {
    setAnchorElSetting(event.target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseSetting = () => {
    setAnchorElSetting(null);
  };

  const handleToRoute = (path) => {
    history.push(path)

    handleCloseSetting()
  }

  return (
    <>
      <Grid
        className={classes.root}
        container
        spacing={2}
        alignItems={'center'}
      >
        <Grid item>
          <UserContent
            user={user}
            onClick={handleOpen}
          />
        </Grid>

        {Boolean(user?.role !== 'user') && (
          <>
            <Grid item>
              <div className={classes.divider}></div>
            </Grid>
            <Grid item className={classes.gridButtonContainerSetting}>
              <Tooltip title={'Настройки системы'}>
                <IconButton onClick={handleOpenSetting} className={classes.buttonSetting}>
                  <SettingsIcon fill="white"/>
                </IconButton>
              </Tooltip>
            </Grid>
          </>
        )}
      </Grid>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        className={classes.menu}
      >
        <MenuItem onClick={handleClose}>
          <RouterLink to={'/account'} className={classes.menuItem}>Профиль</RouterLink>
        </MenuItem>
        <MenuItem onClick={handleUserExit}>Выйти</MenuItem>
      </Menu>


      <Menu
        id="simple-menu"
        anchorEl={anchorElSetting}
        keepMounted
        open={Boolean(anchorElSetting)}
        onClose={handleCloseSetting}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        className={classes.menu}
      >
        <MenuItem onClick={() => handleToRoute('/users')}>
          <RouterLink className={classes.menuItem}>Пользователи</RouterLink>
        </MenuItem>
        <MenuItem onClick={() => handleToRoute(isGlobalAdmin ? '/users-groups' : `/users-groups/${ user?.group_id }`)}>
          <RouterLink className={classes.menuItem}>
            {isGlobalAdmin ? 'Группы пользователи' : 'Список ТС'}
          </RouterLink>
        </MenuItem>
        {Boolean(isGlobalAdmin) && (
          <>
            <MenuItem onClick={() => handleToRoute('/history-notifications')}>
              <RouterLink className={classes.menuItem}>Журнал уведомлений</RouterLink>
            </MenuItem>
            <MenuItem onClick={() => handleToRoute('/logging')}>
              <RouterLink className={classes.menuItem}>Логирование</RouterLink>
            </MenuItem>
          </>
        )}
      </Menu>
    </>
  );
};

export default User;
