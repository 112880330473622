import React from 'react';
import {
  List
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import NavItem from './NavItem';
import NavItemMulti from './NavItemMulti';
import {compose} from "recompose";
import {connect} from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {},

  list: {
    display: 'flex',

    '& .MuiBadge-anchorOriginTopRightRectangle': {
      transform: 'initial',
      right: 4,
      top: 4
    }
  }
}));

const HeaderNav = (props) => {
  const {routes, app} = props;
  const { user } = app;
  const classes = useStyles()

  const handleCheckSection = (section) => {
    const userRoles = user.roles;
    let isPermission = false;

    if (!user || Object.keys(user).length <= 0){
      return false
    }
    if (!section.roles || section.roles.length == 0){
      return true
    }
    if (user.roles['admin']){
      return true
    }
  }
  const handleCheckPermission = (route) => {
    const roles = {
      [user?.role]: true
    };

    if (!roles) {
      return null
    }
    if (roles?.admin) {
      return true
    }
    if (!route.roles || route.roles.length === 0) {
      return true
    }

    let isPermission = false;

    route.roles.map((role) => {
      Object.keys(roles).map((key) => {
        if (roles[role]) {
          isPermission = true
        }
      })
    })

    return isPermission
  }
  return (
    <div className={classes.list}>
      {
        routes.map((route, idx) => {
          if (!handleCheckPermission(route)){
            return null
          }

          if (route.routes && route.routes.length > 0) {
            return (
              <NavItemMulti
                key={'button-header-' + idx}
                keyName={'button-header-' + idx}
                Icon={route.icon}
                title={route.title}
                href={route.href}
                routes={route.routes}
                user={user}
                routesNames={route.routesNames}
              />
            )
          } else {
            return (
              <NavItem
                key={'button-header-' + idx}
                keyName={'button-header-' + idx}
                Icon={route.icon}
                title={route.title}
                href={route.href}
                routesNames={route.routesNames}
                badge={route.badge}
              />
            )
          }
        })
      }
    </div>
  )
}

export default compose(
  connect(
    state => ({
      app: state.app
    }),
    dispatch => ({}),
  ),
)(HeaderNav);
