import React, {useState} from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,

  Box,
  Grid,
  Tooltip,
  IconButton,
  Typography,

  Collapse
} from "@material-ui/core";
import {
  Skeleton,
  Pagination
} from "@material-ui/lab";
import {
  makeStyles
} from "@material-ui/styles";
import {
  ChevronDown as ChevronDownIcon,
  ChevronUp as ChevronUpIcon,
  Edit as EditIcon,
  Trash as TrashIcon
} from "react-feather";
import moment from "moment";
import clsx from "clsx";
import userRoles from "../../../../../constant/userRoles";
import {useHistory} from "react-router-dom";

const TableUsers = (props) => {
  const {rows, filter, pagination, isLoading, onChangeFilter, onDeleteUser} = props;

  const handleChangePagination = (event, page) => {
    let newFilter = {...filter};

    newFilter.page = page;

    onChangeFilter(newFilter, true);
  }

  return (
    <>

      <Box mb={2}>
        <Grid container justify="flex-end">
          <Pagination
            page={filter.page}
            count={pagination.page}

            onChange={handleChangePagination}
          />
        </Grid>
      </Box>

      <Box>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Заголовок</TableCell>
              <TableCell>Лимит пользователей</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {
              (isLoading) ? (
                <RowLoading/>
              ) : (
                <>

                  {
                    rows.map((rows, idx) => (
                      <Row key={`user-row-${idx}`} {...rows} onDeleteUser={onDeleteUser}/>
                    ))
                  }

                </>
              )
            }
          </TableBody>
        </Table>

      </Box>

      <Box mt={2}>
        <Grid container justify="flex-end">
          <Pagination
            page={filter.page}
            count={pagination.page}

            onChange={handleChangePagination}
          />
        </Grid>
      </Box>

    </>
  )
}
const Row = (row) => {
  const classes = useStyles();
  const history = useHistory();

  const handleOpenEdit = () => {
    history.push('/users-groups/' + row.id)
  }

  return (
    <TableRow>
      <TableCell>{row?.Title}</TableCell>
      <TableCell>{row?.user_limit}</TableCell>
      <TableCell>
        <Grid container spacing={1} justify="flex-end" wrap="nowrap">
          <Grid item>
            <Tooltip title="Редактировать запись">
              <IconButton onClick={handleOpenEdit}>
                <EditIcon/>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Удалить запись">
              <IconButton onClick={() => row.onDeleteUser(row, false)}>
                <TrashIcon color="#F86C6C"/>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  )
}
const RowLoading = (props) => {
  return (
    <>
      <TableRow>
        <TableCell><Skeleton height={20}/></TableCell>
        <TableCell><Skeleton height={20}/></TableCell>
        <TableCell>
          <Grid container spacing={1} justify="flex-end">
            <Grid item><Skeleton height={40} width={40} variant="circle"/></Grid>
            <Grid item><Skeleton height={40} width={40} variant="circle"/></Grid>
          </Grid>
        </TableCell>
      </TableRow>
    </>
  )
}

const useStyles = makeStyles(() => ({
  status: {
    width: 30,
    height: 30,
    borderRadius: 999,

    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.1)'
  },
  statusOn: {
    backgroundColor: '#6FF86C',
  },
  statusOff: {
    backgroundColor: '#F86C6C',
  },

  separate: {
    width: '100%',
    height: 1,
    backgroundColor: 'rgba(0, 0, 0, 0)'
  }
}));

export default TableUsers
