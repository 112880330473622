// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import WantedListPersonal from './WantedListPersonal';

export default compose(
  connect(
    state => ({
      app: state
    }),
    dispatch => ({}),
  ),
)(WantedListPersonal);
