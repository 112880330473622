import React from 'react';
import {
  makeStyles
} from '@material-ui/core';
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),

    minHeight: 'calc(100vh - 64px)'
  },
}));

const Page = (props) => {
  const {children, className} = props;
  const classes = useStyles();

  return (
    <div className={clsx([classes.root, className])}>
      {children}
    </div>
  )
}

export default Page
