import React, {Component} from 'react';
import {
  Box, CircularProgress,
  Grid,
  Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import queryString from 'query-string';
import axios from '../../../plugins/axios';
import {
  Page
} from '../../../components';
import {
  Filter,
  Table
} from './components';
import moment from 'moment';
import {withStyles} from '@material-ui/styles';
import clsx from 'clsx';

class TransportConnectionsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      report: [],
      rows: [],
      columns: [
        {
          label: '',
          field: 'action',
          type: 'action',
          align: 'left',
        },
        {
          label: 'ГРЗ',
          field: 'license_plate',
          align: 'left',
          sort: false,
          filterName: 'license_plate_list'
        },
      ],
      sensorList: [],
      showColumns: ['license_plate', 'action'],

      pagination: {
        rowsPerPage: 20,
        page: 0,
        orderBy: '',
        order: 'asc',
        totalCount: 0
      },
      filter: {
        sensor_ids: [],
        license_plate: '',
        datetime_start: null,
        datetime_end: null,
        interval_before: null,
        interval_after: null,

        limit: 20,
        page: 1,
      },
      modalInformation: {},
      sensorFilter: {
        address: '',
        direction: '',
        vendor: '',
        serial: ''
      },
      errorObject: {},

      internalFilter: {
        anomaly: null,
        license_plate: ''
      },

      isLoading: true,
      isShowTable: false,
      showModalInformation: false,
      showError: false,
      showTable: false,
      showNoData: false,

      sort: {
        orderBy: '',
        order: '',
      }
    }

    this.handleGetSensorList = null;
  }

  componentDidMount = () => {
    let params = queryString.parse(this.props.location.search);
    if (Object.keys(params).length > 0) {
      let filter = {...this.state.filter}

      for (let key in params) {
        filter[key] = params[key];

        if (key === 'limit') {
          filter[key] = Number(params[key])
        }
        if (key === 'page') {
          filter[key] = Number(params[key])
        }
        if (key === 'datetime_start' || key === 'datetime_end') {
          filter[key] = moment(Number(params[key]))
        }
      }

      this.setState({
        filter
      })
    }

    this.getSensorList();
  }

  // follow links
  getFilter = () => {
    const initialFilter = {...this.state.filter};
    let filter = {}

    for (let key in initialFilter) {
      let item = initialFilter[key];

      if (item || typeof item == 'number') {
        filter[key] = item;

        if (key === 'interval_before' || key === 'interval_after') {
          filter[key] = Number(item);
        }
        if (key === 'datetime_start' || key === 'datetime_end') {
          filter[key] = moment(item);
        }
      }
    }
    if (this.state.pagination.orderBy) {
      filter['sort_direction'] = this.state.pagination.order;
      filter['sort_field'] = this.state.pagination.orderBy;
    }

    return filter
  }
  getFollowLinks = () => {
    this.setState({
      isLoading: true,
      isShowTable: true,
      showNoData: false,
      showError: false
    });

    let filter = this.getFilter();
    // filter.limit = 100;
    // filter.datetime_end = moment();
    // filter.datetime_start = moment().subtract(10, 'd');

    axios('post', '/operator/report/follow-links', filter).then((response) => {
      let report = response.data
      let reportList = report?.link_list || []

      let pagination = this.state.pagination;
      pagination['totalCount'] = reportList.length;

      this.setState({
        pagination,
        report: reportList,
        showError: false,
        isLoading: false,
        isShowTable: false,
        showTable: report.link_count > 0,
        showNoData: report.link_count <= 0,
      })
    }).catch((error) => {
      this.setState({
        showError: true,
        showTable: false,
        showNoData: false,
        isLoading: false,
        isShowTable: false,

        errorObject: error.response.data
      })
    })
  }

  // sensor
  getFilterSensor = () => {
    let filter = {}

    for (let key in this.state.sensorFilter) {
      let item = this.state.sensorFilter[key];

      if (item) {
        filter[key] = item;
      }
    }

    return filter
  }
  getSensorList = () => {
    let filter = this.getFilterSensor();

    filter['page'] = 1;
    filter['limit'] = 999;

    axios('post', '/operator/sensor/find', filter).then((response) => {
      let sensorList = response.data

      if (!sensorList) {
        sensorList = []
      }

      this.setState({sensorList})
    }).catch((error) => {})
  }
  onFilterSensor = (sensorFilter) => {
    clearTimeout(this.handleGetSensorList)

    this.setState({sensorFilter});

    this.handleGetSensorList = setTimeout(() => {
      this.getSensorList()
    }, 500)
  }

  // other
  setFilterToUrl = () => {
    let filter = []

    for (let key in this.state.filter) {
      let item = this.state.filter[key];

      if (key === 'sensor_ids') {
        if (item.length <= 0) {
          item = null
        }
      }
      if (key === 'datetime_start' || key === 'datetime_end') {
        item = new Date(item).getTime()
      }

      if (item) {
        filter.push(key + '=' + item);
      }
    }

    this.props.history.replace('track-control?' + filter.join('&'))
  }
  changeFilters = (filter, update) => {
    this.setState({filter})

    this.setFilterToUrl()
    if (update) {
      this.onSearch()
    }
  }
  isChangeRowPage = (pagination) => {
    this.setState({
      pagination
    })

    this.getFollowLinks()
  }
  onSearch = () => {
    this.getFollowLinks()
  }

  isToMap = (url) => {
    this.props.history.push(url)
  }

  onChangeSort = (name) => {
    let sort = {...this.state.sort};
    let order, orderBy;

    if (sort.order != name) {
      order = name
      orderBy = 'desc'
    }
    if (sort.order == name && sort.orderBy === 'desc') {
      order = name
      orderBy = 'asc'
    }
    if (sort.order == name && sort.orderBy === 'asc') {
      order = ''
      orderBy = ''
    }

    this.setState({
      sort: {
        order,
        orderBy
      }
    })
  }

  getTableRows = () => {
    let rows = [...this.state.report];
    let { orderBy, order } = this.state.sort;

    if (!order){
      return rows
    }
    if (orderBy === 'desc'){
      return rows.sort((a,b) => {
        if (a.report_list.length < b.report_list.length){
          return 1
        }
        if (a.report_list.length > b.report_list.length){
          return -1
        }

        return 0
      })
    }
    if (orderBy === 'asc'){
      return rows.sort((a,b) => {
        if (a.report_list.length > b.report_list.length){
          return 1
        }
        if (a.report_list.length < b.report_list.length){
          return -1
        }

        return 0
      })
    }
  }

  render() {
    const {classes} = this.props;

    return (
      <Page title={'Устройства'}>
        <Grid container justify={'center'}>
          <Grid item xs={12}>
            <Box boxShadow={1} bgcolor={'white'} mb={4} borderRadius={4} p={2}>
              <Box mb={2}>
                <Typography variant={'h4'}>Трекер Контроль</Typography>
              </Box>
              <Box mb={2}>
                <Filter
                  filters={this.state.filter}
                  onChange={this.changeFilters}
                  sensorList={this.state.sensorList}
                  sensorFilter={this.state.sensorFilter}
                  onFilterSensor={(filter) => this.onFilterSensor(filter)}
                  onSearch={this.onSearch}
                />
              </Box>
            </Box>

            {
              this.state.showTable && (
                <Box mb={2}>
                  <Grid container alignItems={'center'} spacing={2}>
                    <Grid item>
                      <Typography variant={'h4'}>
                        Всего найдено:&nbsp;
                        {
                          (!this.state.isLoading) ? this.state.pagination.totalCount : (
                            <CircularProgress size={20} color={'inherit'}/>
                          )
                        }
                      </Typography>
                    </Grid>

                    <Grid item style={{marginLeft: 'auto', display: 'flex', alignItems: 'center'}}>
                      <Box mr={4}>
                        <div className={classes.helpInformation}>
                          <div className={clsx([classes.helpInformationRound, classes.helpInformationRoundBig])}></div>
                          <div className={classes.helpInformationText}>Высокая статистическая аномалия</div>
                        </div>
                      </Box>
                      <Box>
                        <div className={classes.helpInformation}>
                          <div
                            className={clsx([classes.helpInformationRound, classes.helpInformationRoundAverage])}></div>
                          <div className={classes.helpInformationText}>Средняя статистическая аномалия</div>
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              )
            }

            {
              (this.state.showError) && (
                <Box mb={2}>
                  <Alert severity="error">
                    <Typography color={'error'} variant={'subtitle1'}>
                      {this.state.errorObject.details || this.state.errorObject.error}
                    </Typography>
                  </Alert>
                </Box>
              )
            }
            {
              (this.state.showTable || this.state.isLoading && this.state.isShowTable) && (
                <Box mb={2}>
                  <Table
                    sort={this.state.sort}

                    columns={this.state.columns}
                    showColumns={this.state.showColumns}
                    rows={this.getTableRows()}
                    pagination={this.state.pagination}
                    filter={this.state.filter}
                    isLoading={this.state.isLoading}
                    isToMap={this.isToMap}

                    isSelectRowTable={this.isSelectRowTable}
                    isDeleteRowTable={this.isDeleteRowTable}
                    isChangeRowPage={this.isChangeRowPage}
                    isChangeFilter={this.changeFilters}

                    onChangeSort={this.onChangeSort}
                  />
                </Box>
              )
            }
            {
              (this.state.showNoData) && (
                <Box mb={2}>
                  <Alert severity="info">
                    <Typography variant={'subtitle1'}>Нет данных</Typography>
                  </Alert>
                </Box>
              )
            }
          </Grid>
        </Grid>
      </Page>
    );
  }
}

const styles = {
  helpInformation: {
    display: 'flex',
    alignItems: 'center'
  },
  helpInformationRound: {
    width: 18,
    height: 18,
    borderRadius: '100%',
    marginRight: 8
  },
  helpInformationText: {
    fontSize: 14,
    lineHeight: '100%',
    fontFeatureSettings: "'ss01' on",
    color: '#647083'
  },
  helpInformationRoundBig: {
    background: '#FFD8D6',
    border: '1px solid #F48F89'
  },
  helpInformationRoundAverage: {
    background: '#FFDDBE',
    border: '1px solid #F8B578'
  },
};

export default withStyles(styles)(TransportConnectionsList)
