import {getLocalStorage} from "./helpers/localStorage";

const UPDATE_USER = 'app/UPDATE_USER'
const UPDATE_NOTIFICATION = 'app/UPDATE_NOTIFICATION'
const UPDATE_FAVORITES = 'app/UPDATE_FAVORITES'
const UPDATE_OPEN_LEFT_SIDEBAR = 'app/UPDATE_OPEN_LEFT_SIDEBAR'
const UPDATE_COUNT_BADGE_WANTED = 'app/UPDATE_COUNT_BADGE_WANTED'

let openSidebarLeft = localStorage.getItem('openSidebarLeft');
openSidebarLeft = (document.documentElement.clientWidth <= 1399) ? false : openSidebarLeft;

let favoritesList = getLocalStorage('favorites');
if (!favoritesList) {
  favoritesList = '[]';
}
const favoritesListInit = JSON.parse(favoritesList);

const initialState = {
  user: {},
  notification: [],
  favorites: favoritesListInit,
  openSidebarLeft: (openSidebarLeft === 'true') ? true : false,

  countBadgeWanted: 0
};

export function setUser(user) {
  return {
    type: UPDATE_USER,
    user
  }
}

export function setNotification(notification) {
  return {
    type: UPDATE_NOTIFICATION,
    notification
  }
}

export function setFavorites(favorites) {
  return {
    type: UPDATE_FAVORITES,
    favorites
  }
}

export function setOpenLeftSidebar(openSidebarLeft) {

  return {
    type: UPDATE_OPEN_LEFT_SIDEBAR,
    openSidebarLeft
  }
}

export function setCountBadgeWanted(countBadgeWanted) {
  return {
    type: UPDATE_COUNT_BADGE_WANTED,
    countBadgeWanted
  }
}

// Reducer
export default function AppState(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_USER: {
      let user = action.user

      return {
        ...state,
        user
      }
    }
    case UPDATE_NOTIFICATION: {
      let notification = action.notification

      return {
        ...state,
        notification
      }
    }
    case UPDATE_FAVORITES: {
      let favorites = action.favorites;

      return Object.assign({}, state, {
        favorites: favorites
      })
    }
    case UPDATE_OPEN_LEFT_SIDEBAR: {
      let openSidebarLeft = action.openSidebarLeft

      return {
        ...state,
        openSidebarLeft
      }
    }
    case UPDATE_COUNT_BADGE_WANTED: {
      let countBadgeWanted = action.countBadgeWanted

      return {
        ...state,
        countBadgeWanted
      }
    }
    default:
      return state;
  }
}
