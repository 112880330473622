import React, {useState} from "react";
import {
    Box,
    Grid,
    Typography,

    Button
} from "@material-ui/core";

import ModalSensor from "./ModalSensor";

const Filter = (props) => {
    const {
        sensors,
        sensorsInitial,
        activeFilter,
        filterSensor,
        onChangeFilterSensors,
        onChangeActiveFilter,
        onSetActiveFilter
    } = props;

    const [isOpenModalSensors, setOpenModalSensors] = useState(false);

    const handleCloseSensors = () => {
        let newFilterSensor = {
            address: '',
            direction: '',
            city: '',
            limit: 999,
            page: 1
        };

        onChangeFilterSensors(newFilterSensor);
        setOpenModalSensors(false);
    }
    const handleSetActiveFilter = () => {
        onSetActiveFilter();
        setOpenModalSensors(false);
    }

    return (
        <Box>

            <Button
                variant="contained"

                onClick={() => setOpenModalSensors(true)}
            >Устройства ({activeFilter.sensor_ids.length})</Button>

            <ModalSensor
                isOpen={isOpenModalSensors}

                sensors={sensors}
                sensorsInitial={sensorsInitial}
                filterSensor={filterSensor}
                activeFilter={activeFilter}

                onClose={handleCloseSensors}
                onChangeFilterSensors={onChangeFilterSensors}
                onChangeActiveFilter={onChangeActiveFilter}
                onSetActiveFilter={handleSetActiveFilter}
            />

        </Box>
    )
};

export default Filter
