import React, {useRef, useState} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  SvgIcon,
  TableSortLabel,
  Box,
  Tooltip,
  Switch
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {makeStyles} from '@material-ui/styles';
import {
  Trash as TrashIcon,
  Edit2 as Edit2Icon,
  ChevronDown as ChevronDownIcon,
  ChevronUp as ChevronUpIcon
} from 'react-feather';
import {
  ArrowHeaderTable as ArrowHeaderTableIcon,
} from '../../../../../../icons'
import {
  CustomMoment as Moment,
  CustomTablePagination,
} from '../../../../../../components';
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  form: {
    marginBottom: 30
  },
  gridLines: {
    alignItems: 'flex-end'
  },

  tableSortLabel: {
    '& .MuiTableSortLabel-icon': {
      opacity: 0.5
    }
  },
  tableCellAction: {
    display: 'flex',
    alignItems: 'center'
  },
  tableRow: {
    cursor: 'pointer'
  },

  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: '#fafafa'
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },

  image: {
    width: 65,
    height: 65,
    marginBottom: -10,

    position: 'relative',
    zIndex: 555
  },

  stickyActionsColumn: {
    '& table:first-child': {
      '& tr': {
        '& td:last-child, th:last-child': {
          backgroundColor: 'white',
          position: 'sticky',
          right: 0,
          zIndex: 999
        },
        '& th:last-child': {
          backgroundColor: '#edf1f6',
        },
        '& th:first-child': {
          zIndex: 9999
        }
      }
    }
  },

  ceilColor: {
    width: 20,
    height: 45,
    borderRadius: 2
  },
  сellComment: {
    maxWidth: 350,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  сellCommentOpen: {
    whiteSpace: 'normal',
    textOverflow: 'initial',
    overflow: 'initial',
    width: 350
  },

  buttonOpenDescription: {
    width: 34,
    height: 34,
    backgroundColor: '#edf1f6',
    borderRadius: 2,
    marginLeft: 16,

    border: 'none',
    outline: 'none',
    cursor: 'pointer',
  },
}));

const TableComponents = props => {
  const {
    rows,
    columns,
    showColumns,
    isChangeRowPage,
    filter,
    isChangeFilter,
    onDeleteList,
    onEditList,
    onChangeStatusItem,

    isLoading
  } = props;
  let {pagination} = props;
  const classes = useStyles();
  const refTable = useRef(null);
  const [opensRowDescription, setOpensRowDescription] = useState([]);

  const handleChangeRowPage = (event) => {
    pagination.rowsPerPage = event.target.value;

    isChangeRowPage(pagination)
  }
  const handleChangePage = (event, page) => {
    let newFilter = filter;
    newFilter['page'] = page;

    isChangeFilter(newFilter);
  }
  const createSortHandler = (field) => {
    const isAsc = pagination.orderBy === field && pagination.order === 'asc';
    pagination.order = isAsc ? 'desc' : 'asc';
    pagination.orderBy = field;

    isChangeRowPage(pagination);
  }
  const handleDeleteList = (row) => {
    onDeleteList(row)
  }
  const handleEditList = (row) => {
    onEditList(row)
  }

  const handleOnChangeStatus = (row) => {
    let status = row.status;

    if (status == 10){
      status = -1
    }else{
      status = 10
    }

    row.status = status
    onChangeStatusItem(row)
  }

  const handleOpenDescription = (row) => {
    let newOpensRowDescription = [...opensRowDescription];

    if (newOpensRowDescription.indexOf(row.id) > -1) {
      newOpensRowDescription.splice(newOpensRowDescription.indexOf(row.id), 1);
    } else {
      newOpensRowDescription.push(row.id);
    }

    setOpensRowDescription(newOpensRowDescription)
  }
  const handleShowButtonDescription = (row) => {
    if (row.comment && row.comment.length >= 47) {
      return true
    }

    return false
  }

  return (
    <>
      <CustomTablePagination
        rows={rows}
        filter={filter}
        pagination={pagination}
        onChangePage={(event, page) => handleChangePage(event, page + 1)}
        onChangeRowsPerPage={handleChangeRowPage}
      />

      <PerfectScrollbar style={{height: 'auto'}}>
        <Box minWidth={1400}>
          <Table
            id={'basic-drive-table'}
            ref={refTable}
            className={'MuiTable-list'}
          >
            <TableHead className={'MuiTableHead-list'}>
              <TableRow>
                {
                  columns.map((column, idx) => {
                    if (showColumns.indexOf(column.field) > -1) {
                      let titleSort = column.label + ' по ' + (pagination.orderBy === column.field ? (pagination.order === 'desc') ? 'возрастанию' : 'убыванию' : 'возрастанию');

                      if (column.sort) {
                        return (
                          <TableCell
                            key={'table-cell-' + idx}
                            align={'left'}
                          >
                            <Tooltip title={titleSort}>
                              <TableSortLabel
                                IconComponent={ArrowHeaderTableIcon}
                                className={classes.tableSortLabel}
                                active={pagination.orderBy === column.field}
                                direction={pagination.orderBy === column.field ? pagination.order : 'asc'}
                                onClick={() => createSortHandler(column.field)}
                              >
                                {column.label}
                              </TableSortLabel>
                            </Tooltip>
                          </TableCell>
                        )
                      } else {
                        return (
                          <TableCell key={'table-cell-' + idx} align={'left'} className={classes.tableSortLabel}>
                            {column.label}
                          </TableCell>
                        )
                      }
                    }
                  })
                }

                <TableCell align={'right'} className={classes.tableSortLabel} width={80}>Редакт.</TableCell>
                <TableCell align={'right'} className={classes.tableSortLabel} width={80}>Удален.</TableCell>
              </TableRow>
            </TableHead>

            {
              (!isLoading) ?
                (
                  <TableBody>
                    {rows.map((row, idx) => {
                      return (
                        <TableRow
                          key={row.name + '-' + idx}
                          className={'MuiTableRow-list'}
                        >
                          {
                            columns.map((item, idx) => {
                              if (showColumns.indexOf(item.field) === -1) {
                                return null
                              }
                              if (item.type === 'grz') {
                                let grz = row[item.field];

                                return (
                                  <TableCell
                                    key={item.field + '-' + idx}
                                    value={row[item.field]}
                                    filter-name={item.filterName}
                                    style={item.filterName ? {cursor: 'context-menu'} : {}}
                                  >
                                    {grz}
                                  </TableCell>
                                )
                              }
                              if (item.type === 'date') {
                                return (
                                  <TableCell key={item.field + '-' + idx}>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                      <Moment
                                        format="DD.MM.YY"
                                        value={row[item.field]}
                                        filter-name={item.filterName}
                                        style={item.filterName ? {cursor: 'context-menu'} : {}} local
                                      >
                                        {row[item.field]}
                                      </Moment>
                                      <Moment
                                        format="HH:mm:ss"
                                        value={row[item.field]}
                                        filter-name={item.filterName}
                                        style={item.filterName ? {cursor: 'context-menu'} : {}}
                                      >
                                        {row[item.field]}
                                      </Moment>
                                    </div>
                                  </TableCell>
                                )
                              }
                              if (item.type === 'markModel') {
                                return (
                                  <TableCell key={item.field + '-' + idx}>
                                    {row['mark']} {row['model']}
                                  </TableCell>
                                )
                              }
                              if (item.type === 'color') {
                                return (
                                  <TableCell key={item.field + '-' + idx}>
                                    {
                                      row[item.field] && (
                                        <div className={classes.ceilColor}
                                             style={{backgroundColor: row[item.field]}}></div>
                                      )
                                    }
                                  </TableCell>
                                )
                              }
                              if (item.type === 'status') {
                                switch (row[item.field]) {
                                  case 10: {
                                    return (
                                      <TableCell key={item.field + '-' + idx}>
                                        <Switch
                                          checked={true}
                                          color="primary"
                                          onChange={() => handleOnChangeStatus(row)}
                                        />
                                      </TableCell>
                                    )
                                  }
                                  case -1: {
                                    return (
                                      <TableCell key={item.field + '-' + idx}>
                                        <Switch
                                          checked={false}
                                          color="primary"
                                          onChange={() => handleOnChangeStatus(row)}
                                        />
                                      </TableCell>
                                    )
                                  }
                                  case 99: {
                                    return (
                                      <TableCell key={item.field + '-' + idx}>
                                        <Switch
                                          checked={false}
                                          color="primary"
                                          onChange={() => handleOnChangeStatus(row)}
                                        />
                                      </TableCell>
                                    )
                                  }
                                }
                              }
                              if (item.type === 'comment') {
                                return (
                                  <TableCell key={item.field + '-' + idx} width={350}>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                      <div
                                        className={clsx({
                                          [classes.сellComment]: true,
                                          [classes.сellCommentOpen]: opensRowDescription.indexOf(row.id) > -1
                                        })}
                                      >
                                        {row[item.field]}
                                      </div>
                                      {
                                        (handleShowButtonDescription(row)) && (
                                          <button
                                            className={classes.buttonOpenDescription}
                                            onClick={() => handleOpenDescription(row)}
                                          >
                                            {
                                              (opensRowDescription.indexOf(row.id) > -1) ? (
                                                <ChevronUpIcon color={'#8698B1'}/>) : (
                                                <ChevronDownIcon color={'#8698B1'}/>)
                                            }
                                          </button>
                                        )
                                      }
                                    </div>
                                  </TableCell>
                                )
                              }

                              if (typeof row[item.field] === 'object') {
                                return (
                                  <TableCell key={item.field + '-' + idx}>1</TableCell>
                                )
                              }

                              return (
                                <TableCell
                                  key={item.field + '-' + idx}
                                  value={row[item.field]}
                                  filter-name={item.filterName}
                                  style={item.filterName ? {cursor: 'context-menu'} : {}}
                                >{row[item.field]}</TableCell>
                              )
                            })
                          }

                          <TableCell align={'right'}>
                            <IconButton onClick={() => handleEditList(row)}>
                              <Tooltip title={'Редактирование'}>
                                <SvgIcon>
                                  <Edit2Icon color={'#8698B1'}/>
                                </SvgIcon>
                              </Tooltip>
                            </IconButton>
                          </TableCell>
                          <TableCell align={'right'}>
                            <IconButton onClick={() => handleDeleteList(row)}>
                              <Tooltip title={'Удалить'}>
                                <SvgIcon>
                                  <TrashIcon color={'#8698B1'}/>
                                </SvgIcon>
                              </Tooltip>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                )
                :
                (
                  <TableBody>
                    <TableRow className={'MuiTableRow-list'}>
                      {
                        columns.map((item, idx) => {
                          if (showColumns.indexOf(item.field) === -1) {
                            return null
                          }
                          if (item.type === 'grz') {
                            return (
                              <TableCell key={item.field + '-' + idx}>
                                <Skeleton variant="rect"/>
                              </TableCell>
                            )
                          }
                          if (item.type === 'date') {
                            return (
                              <TableCell key={item.field + '-' + idx}>
                                <Skeleton variant="rect"/>
                              </TableCell>
                            )
                          }
                          if (item.type === 'markModel') {
                            return (
                              <TableCell key={item.field + '-' + idx}>
                                <Skeleton variant="rect"/>
                              </TableCell>
                            )
                          }
                          if (item.type === 'color') {
                            return (
                              <TableCell key={item.field + '-' + idx}>
                                <Skeleton variant="rect" width={20} height={45}/>
                              </TableCell>
                            )
                          }
                          if (item.type === 'status') {
                            return (
                              <TableCell key={item.field + '-' + idx}>
                                <Skeleton variant="rect" width={60} height={40}/>
                              </TableCell>
                            )
                          }

                          return (
                            <TableCell key={item.field + '-' + idx}><Skeleton variant="rect"/></TableCell>
                          )
                        })
                      }

                      <TableCell align={'right'}/>
                      <TableCell align={'right'}/>
                    </TableRow>
                  </TableBody>
                )
            }
          </Table>
        </Box>
      </PerfectScrollbar>

      <CustomTablePagination
        rows={rows}
        filter={filter}
        pagination={pagination}
        onChangePage={(event, page) => handleChangePage(event, page + 1)}
        onChangeRowsPerPage={handleChangeRowPage}
      />
    </>
  );
};

TableComponents.propTypes = {
  columns: PropTypes.array,
  showColumns: PropTypes.array,
  rows: PropTypes.array,
  selected: PropTypes.array,
  pagination: PropTypes.object,
  isSelectRowTable: PropTypes.func,
  isDeleteRowTable: PropTypes.func,
  isChangeRowPage: PropTypes.func,
};
TableComponents.defaultProps = {
  showColumns: []
};

export default TableComponents;
