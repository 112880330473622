import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {
  makeStyles,
  Typography,
  Grid,
  IconButton
} from '@material-ui/core';
import {
  X as XIcon
} from 'react-feather';
import {setLocalStorage} from '../../../../../../helpers/localStorage';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import {setFavorites} from '../../../../../../AppState';

const useStyles = makeStyles(() => ({
  list: {
    marginBottom: 15,
    marginLeft: -5
  },
  box: {
    display: 'flex',
    alignItems: 'center',

    marginLeft: 5,
    marginBottom: 5,

    borderRadius: 1000,
    padding: '5px 10px',

    backgroundColor: '#8698B1',

    '&:hover': {
      opacity: 0.7
    }
  },
  name: {
    fontSize: 14,
    lineHeight: '16px',
    color: 'white',
    fontWeight: '500'
  }
}));

const Favorites = (props) => {
  const {favorites, setFavorites} = props;
  const classes = useStyles();

  const handleRemoveFavorite = (idx) => {
    let newFavorites = [...favorites];

    newFavorites.splice(idx, 1);
    setLocalStorage('favorites', JSON.stringify(newFavorites));
    setFavorites(newFavorites);
  }

  return (
    <>
      <Grid container className={classes.list}>
        {
          favorites.map((item, idx) => (
            <Grid item key={'favorites-' + idx}>
              <div className={classes.box} style={{backgroundColor: item.color}}>
                <RouterLink to={item.url}>
                  <Typography className={classes.name}>
                    {item.name}
                  </Typography>
                </RouterLink>

                <IconButton size={'small'} onClick={() => handleRemoveFavorite(idx)}>
                  <XIcon size={15} color={'white'}/>
                </IconButton>
              </div>
            </Grid>
          ))
        }
      </Grid>
    </>
  )
}

export default compose(
  connect(
    state => ({
      favorites: state.app.favorites
    }),
    dispatch => ({
      setFavorites: (favorites) => dispatch(setFavorites(favorites)),
    }),
  ),
)(Favorites);
