import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Collapse,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core';
import {
  ChevronDown as ChevronDownIcon,
  ChevronUp as ChevronUpIcon
} from 'react-feather';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    alignItems: 'center',

    fontSize: 15,
    lineHeight: '17px',
    fontFeatureSettings: "'ss01' on",
    color: theme.palette.black,

    [theme.breakpoints.between(0, 1599)]: {
      fontSize: 14,
      lineHeight: '16px',
    },
  },
  titleClose: {
    cursor: 'pointer'
  },
  titleActive: {
    textShadow: '0 0 1px #192A3E'
  },
  leftIcon: {
    color: theme.palette.gray.light,
    marginRight: 8
  },
  leftIconClose: {
    color: theme.palette.gray.default,
    margin: '0 auto'
  },
  iconOpen: {
    width: 21,
    color: theme.palette.black
  },

  collapse: {
    paddingLeft: 20
  }
}));

const SidebarMenuItem = (props) => {
  const {
    title,
    children,
    openSidebar,
    iconName,
    initOpenWidgets,
    classRoot
  } = props;
  const Icon = iconName;
  const classes = useStyles();
  const [openWidgets, setOpenWidgets] = useState((initOpenWidgets) ? initOpenWidgets : false);

  if (!openSidebar){
    return (
      <Box className={classRoot}>
        <Typography
          variant={'body1'}
          className={clsx([classes.title, classes.titleClose])}
          onClick={() => { setOpenWidgets(true) }}
        >
          <Icon width={20} className={clsx([classes.leftIcon, classes.leftIconClose])}/>
        </Typography>
      </Box>
    )
  }

  return (
    <Box>
      <Typography
        variant={'body1'}
        className={clsx([classRoot, classes.title])}
        onClick={() => { setOpenWidgets(!openWidgets) }}
        style={{cursor: 'pointer'}}
      >
        <Icon className={classes.leftIcon}/>
        <span className={(openWidgets) ? classes.titleActive: ''}>{ title }</span>
        <IconButton style={{marginLeft: 'auto'}} size={'small'} className={classes.iconOpen}>
          {openWidgets ? <ChevronUpIcon width={15} height={15}/> : <ChevronDownIcon width={15} height={15}/>}
        </IconButton>
      </Typography>
      <Collapse in={openWidgets} timeout="auto" unmountOnExit className={classes.collapse}>
        <Box mt={2}>
          { children }
        </Box>
      </Collapse>
    </Box>
  )
}

SidebarMenuItem.propTypes = {
  Icon: PropTypes.node,
  children: PropTypes.node,
  title: PropTypes.string
};


export default SidebarMenuItem
