import React, {useState} from 'react';
import {
  IconButton,
  makeStyles,
  TextField, Tooltip
} from '@material-ui/core';
import {
  CalendarToday
} from '@material-ui/icons';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import MaskedInput from 'react-text-mask';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './index.scss';
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const useStyles = makeStyles((theme) => ({
  dateRange: {
    position: 'relative',

    '& .MuiInputAdornment-root': {
      display: 'none'
    },
  },

  rightContent: {
    padding: '0 15px',
    borderLeft: '1px solid rgba(100, 112, 131, 0.2)'
  },
  iconCalendar: {
    width: 17,
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },

  quickSelectList: {
    position: 'absolute',
    top: 'calc(100% + 5px)',
    display: 'flex',
    alignItems: 'center',
    marginLeft: -10,

    '& > *': {
      marginLeft: 10,
      fontSize: 14,
      borderBottom: '1px dotted',
      color: '#647085',
      cursor: 'pointer',

      '&:hover': {
        color: '#212529'
      }
    }
  }
}));

function TextMaskCustom(props) {
  const {inputRef, ...other} = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/, '—', /\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/,]}
      placeholder={'01.01.2000 00:00 — 01.01.2000 00:00'}
      placeholderChar={'_'}
    />
  );
}

const DateRange = (props) => {
  const {dateStart, dateEnd, label, onChange, minimal} = props;
  const classes = useStyles();

  const handleChange = (event, range) => {
    let startDate = moment(range.startDate.toISOString()).unix();
    let endDate = moment(range.endDate.toISOString()).unix();

    onChange({
      startDate,
      endDate
    })
  }


  let date = {
    startDate: (dateStart) ? dateStart : moment().set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0
    }),
    endDate: (dateEnd) ? dateEnd : moment().set({
      hour: 23,
      minute: 59,
      second: 0,
      millisecond: 0
    })
  };
  let showDateInput = (dateStart) ? true : false;
  let dateString = showDateInput ? moment(date.startDate).format('DD.MM.YYYY HH:mm') + ' — ' + moment(date.endDate).format('DD.MM.YYYY HH:mm') : '';


  const ranges = {
    'Сегодня': [
      moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      }),
      moment().set({
        hour: 23,
        minute: 59,
        second: 0,
        millisecond: 0
      })
    ],
    'Вчерашний день': [
      moment().subtract(1, 'days').set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      }),
      moment().subtract(1, 'days').set({
        hour: 23,
        minute: 59,
        second: 0,
        millisecond: 0
      })
    ],
    'Последние 7 дней': [moment().subtract(6, 'days').set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0
    }), moment().set({
      hour: 23,
      minute: 59,
      second: 0,
      millisecond: 0
    })],
    'Последние 30 дней': [moment().subtract(29, 'days').set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0
    }), moment().set({
      hour: 23,
      minute: 59,
      second: 0,
      millisecond: 0
    })],
    'Этот месяц': [moment().startOf('month').set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0
    }), moment().endOf('month').set({
      hour: 23,
      minute: 59,
      second: 0,
      millisecond: 0
    })],
    'Прошлый месяц': [moment().subtract(1, 'month').startOf('month').set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0
    }), moment().subtract(1, 'month').endOf('month').set({
      hour: 23,
      minute: 59,
      second: 0,
      millisecond: 0
    })]
  }

  const template = `<div class="daterangepicker" style="display: none">
                <div class="daterangepicker-left">
                  <div class="ranges"></div>
                </div>
                <div class="daterangepicker-right">
                  <div class="daterangepicker-right-top">
                    <div class="drp-calendar left">
                      <div class="calendar-table"></div>
                      <div class="calendar-time">
                        <span class="calendar-time-title">Время</span>
                      </div>
                    </div>
                    <div class="drp-calendar right">
                      <div class="calendar-table"></div>
                      <div class="calendar-time">
                        <span class="calendar-time-title">Время</span>
                      </div>
                    </div>
                  </div>
                  <div class="daterangepicker-right-bottom">
                    <div class="drp-buttons">
                      <span class="drp-selected"></span>
                      <button class="cancelBtn" type="button"></button>
                      <button class="applyBtn" disabled="disabled" type="button"></button>
                    </div>
                  </div>
                </div>
              </div>`;

  const handleGetDate = (date) => {
    return moment(date).format('DD.MM.YYYY HH:mm');
  }
  const handleInputDateRange = (event) => {
    let values = event.target.value.split('—');

    if (values.length === 2) {

      let dateStart = moment(values[0], 'DD.MM.YYYY HH:mm', true);
      let dateEnd = moment(values[1], 'DD.MM.YYYY HH:mm', true);

      if (dateStart.isValid() && dateEnd.isValid()) {
        let startDate = moment(values[0], 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD HH:mm');
        let endDate = moment(values[1], 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD HH:mm');

        onChange({
          startDate: moment(startDate).unix(),
          endDate: moment(endDate).unix()
        })
      }
    }
  }

  const handleClearFilter = () => {
    onChange({
      startDate: null,
      endDate: null
    })
  }
  const handleShowDateRangePicker = (event) => {
    let daterangepicker = document.getElementsByClassName('daterangepicker')[0];


  }

  const handleSetQuick = (format) => {
    let dateTimeStart = null;
    let dateTimeEnd = null;

    switch (format) {
      case 'hour': {
        dateTimeStart = moment().subtract(1, 'hour');
        dateTimeEnd = moment();

        break
      }
      case '12_hours': {
        dateTimeStart = moment().subtract(12, 'hour');
        dateTimeEnd = moment();

        break
      }
      case 'day': {
        dateTimeStart = moment().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0
        })
        dateTimeEnd = moment().set({
          hour: 23,
          minute: 59,
          second: 0,
          millisecond: 0
        })

        break
      }
      case 'week': {
        dateTimeStart = moment().subtract(1, 'week').set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0
        });
        dateTimeEnd = moment().set({
          hour: 23,
          minute: 59,
          second: 0,
          millisecond: 0
        })

        break
      }
      case 'month': {
        dateTimeStart = moment().subtract(1, 'month').set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0
        });
        dateTimeEnd = moment().set({
          hour: 23,
          minute: 59,
          second: 0,
          millisecond: 0
        })

        break
      }
    }

    dateTimeStart = moment(dateTimeStart.toISOString()).unix()
    dateTimeEnd = moment(dateTimeEnd.toISOString()).unix()

    onChange({
      startDate: dateTimeStart,
      endDate: dateTimeEnd
    })
  }

  if (minimal){
    return (
      <DateRangePicker
        startDate={handleGetDate(date.startDate)}
        endDate={handleGetDate(date.endDate)}
        locale={{
          format: 'DD.MM.YYYY HH:mm',
          separator: ' — ',
          applyLabel: 'Применить',
          cancelLabel: 'Отменить',
          fromLabel: 'до',
          toLabel: 'от',
          customRangeLabel: 'Свой промежуток',
          daysOfWeek: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
          monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
          firstDay: 1
        }}
        ranges={ranges}
        onApply={handleChange}
        showDropdowns
        timePicker
        timePicker24Hour
        alwaysShowCalendars
        showCustomRangeLabel
        opens={'left'}
        maxDate={moment().add(1, 'days')}
        template={template}

        onShow={handleShowDateRangePicker}
      >
        <Tooltip title="Выбор временного промежутка">
          <CalendarToday className={classes.iconCalendar}/>
        </Tooltip>
      </DateRangePicker>
    )
  }

  return (
    <div className={classes.dateRange}>
      <TextField
        value={dateString}
        label={label}
        InputProps={{
          inputComponent: TextMaskCustom,
          endAdornment: (
            <div style={{display: 'flex', alignItems: 'center'}}>

              {
                (dateString) && (
                  <Tooltip title="Очистить">
                    <IconButton onClick={handleClearFilter} style={{ padding: 0, marginRight: 10 }}>
                      <HighlightOffIcon/>
                    </IconButton>
                  </Tooltip>
                )
              }

              <DateRangePicker
                startDate={handleGetDate(date.startDate)}
                endDate={handleGetDate(date.endDate)}
                locale={{
                  format: 'DD.MM.YYYY HH:mm',
                  separator: ' — ',
                  applyLabel: 'Применить',
                  cancelLabel: 'Отменить',
                  fromLabel: 'до',
                  toLabel: 'от',
                  customRangeLabel: 'Свой промежуток',
                  daysOfWeek: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
                  monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                  firstDay: 1
                }}
                ranges={ranges}
                onApply={handleChange}
                showDropdowns
                timePicker
                timePicker24Hour
                alwaysShowCalendars
                showCustomRangeLabel
                opens={'left'}
                maxDate={moment().add(1, 'days')}
                minDate={Boolean(props.minDate) ? props.minDate : null}
                template={template}

                onShow={handleShowDateRangePicker}
              >
                <Tooltip title="Выбор временного промежутка">
                  <div className={classes.rightContent}><CalendarToday className={classes.iconCalendar}/></div>
                </Tooltip>
              </DateRangePicker>
            </div>
          )
        }}
        variant={'outlined'}
        fullWidth
        onChange={handleInputDateRange}
        handleClearFilter={handleClearFilter}
      />

      {!Boolean(props.hideQuickSelect) && (
        <div className={classes.quickSelectList}>
          <span onClick={() => handleSetQuick('hour')}>час</span>
          <span onClick={() => handleSetQuick('12_hours')}>12 часов</span>
          <span onClick={() => handleSetQuick('day')}>сутки</span>
          <span onClick={() => handleSetQuick('week')}>неделя</span>
          <span onClick={() => handleSetQuick('month')}>месяц</span>
        </div>
      )}
    </div>
  )
}

export default DateRange
