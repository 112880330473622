import React, {Component} from 'react';
import {
  Box,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  Page
} from '../../../components';
import {
  Table,
  Filter
} from './components';
import axios from '../../../plugins/axios';
import {
  CustomMoment as Moment
} from '../../../components';
import moment from 'moment';
import {withStyles} from '@material-ui/styles';
import theme from '../../../theme';

const styles = {
  searchContent: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  searchInput: {
    backgroundColor: 'white',
    width: '100%',
    maxWidth: 350,
    borderRadius: 4,
    overflow: 'hidden',

    "& .MuiOutlinedInput-input": {
      backgroundColor: 'white'
    }
  },

  filterContainer: {
    '& > .MuiGrid-grid-xs-4': {
      [theme.breakpoints.between(0, 1599)]: {
        maxWidth: '50%',
        flexBasis: '50%'
      },
    }
  }
};

class Logging extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      columns: [
        {
          label: 'Дата',
          field: 'created',
          type: 'dateTime',
          align: 'left',
          width: '150'
        },
        {
          label: 'Тип операции',
          field: 'type',
          type: 'type',
          align: 'left',
          width: '300'
        },
        {
          label: 'Информация',
          field: 'payload',
          type: 'payload',
          align: 'left',
        }
      ],
      showColumns: ['ip', 'type',  'username', 'payload', 'created'],

      pagination: {
        rowsPerPage: 20,
        page: 1,
        orderBy: null,
        order: 'asc',
        totalCount: 0
      },
      filter: {
        limit: 20,
        page: 1,

        datetime_from: null,
        datetime_to: null,

        type: null
      },
      createNewList: {
        title: '',
        color: '',
        type: null
      },
      confirmationDeleteList: {},
      editList: {},
      uploadFileList: {},
      fileUploadInfo: {
        failed: 0,
        imported: 0
      },

      dateTimeRequest: null,
      searchTableName: '',

      isLoading: true,
      openModalFileUpload: false,
      openModalCreateList: false,
      openModalConfirmationDelete: false,
      openModalEdit: false,
      openModalNotifications: false
    }

    this.timeoutChangeFilter = null;
  }

  componentDidMount = () => {
    this.getLastNotifications()
  }

  // конвертация фильтра со фронта на бек
  getFilter = () => {
    let filter = {};
    let filters = {...this.state.filter};

    for(let key in filters){
      filter[key] = filters[key];

      if (key === 'datetime_from' || key === 'datetime_to'){
        filter[key] = null

        if (filter[key]){
          filter[key] = moment(filter[key])
        }
      }
      if (key === 'type'){
        filter[key] = (filter[key]) ? filter[key].value : null
      }
    }

    return filter
  }

  // получение списка последних уведомлений
  getLastNotifications = () => {
    let filter = this.getFilter();
    this.setState({
      isLoading: true,
      rows: []
    })

    axios('post', '/notifications/get-last', filter).then(response => {
      let rows = response.data;
      let totalCount = response.headers['x-pagination-total-count'];
      let pagination = this.state.pagination;

      if (!rows) {
        rows = []
      }

      pagination.totalCount = totalCount;

      this.setState({
        rows,
        pagination,
        isLoading: false,
        dateTimeRequest: moment(),
      })
    }).catch(error => {
    })
  }

  isChangeRowPage = (pagination) => {
    this.setState({
      pagination
    })

    if (this.state.workFile) {
      this.specifyReportRearch()
    } else {
      this.getDriveList();
    }
  }

  // изменение фильтра
  changeFilters = (filter) => {
    clearTimeout(this.timeoutChangeFilter)
    this.setState({
      filter
    })


    this.timeoutChangeFilter = setTimeout(() => {
      this.getLastNotifications()
    }, 500)
  }

  render() {
    const {classes} = this.props;

    return (
      <Page title={'Устройства'}>
        <Grid container justify={'center'}>
          <Grid item xs={12}>
            <Box mb={2}>
              <Grid container alignItems={'center'}>
                <Box mr={2}>
                  <Typography variant={'h3'}>
                    Журнал уведомлений
                  </Typography>
                </Box>

                <Box>
                  <Typography style={smallTextGray}>
                    Данные актуальны на&nbsp;
                    <Moment
                      format="DD.MM.YYYY HH:mm:ss"
                      value={this.state.dateTimeRequest}
                    >
                      {this.state.dateTimeRequest}
                    </Moment>
                  </Typography>
                </Box>
              </Grid>
            </Box>

            <Box
              boxShadow={1}
              bgcolor={'white'}
              borderRadius={4}
              p={2}
              pb={4}
              mb={2}
              className={classes.blockFilter}
            >
              <Box>
                <Filter
                  filters={this.state.filter}
                  onChange={(filter, offUpdate) => this.changeFilters(filter, offUpdate)}
                />
              </Box>
            </Box>

            <Table
              columns={this.state.columns}
              showColumns={this.state.showColumns}
              rows={this.state.rows}
              pagination={this.state.pagination}
              filter={this.state.filter}
              isLoading={this.state.isLoading}

              isChangeRowPage={this.isChangeRowPage}
              isChangeFilter={this.changeFilters}
            />
          </Grid>
        </Grid>
      </Page>
    );
  }
}

const smallTextGray = {
  color: '#647083',
  fontSize: 16
};

export default withStyles(styles)(Logging)
