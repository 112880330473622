import React, {useState} from 'react';

import {
  TextField,
  makeStyles,
  Grid,
  Button,
  Typography,
  InputAdornment,

  Tooltip,
  IconButton,

  Box,

} from '@material-ui/core';
import {
  DateRange
} from '../../../../../components';
import Autocomplete from '@material-ui/lab/Autocomplete';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const typesOptions = [
  {
    title: 'Авторизация пользователя',
    value: 'logLogin'
  },
  {
    title: 'Применение фильтра проездов',
    value: 'logReportSearch'
  },
  {
    title: 'Применение фильтра проездов ( список )',
    value: 'logBulkReportSearch'
  },
  {
    title: 'Создание элемента уведомлений',
    value: 'logSearchItemCreate'
  },
  {
    title: 'Загрузка списка элементов уведомлений',
    value: 'logSearchListUpload'
  },
  {
    title: 'Принудительное заверешение пользовательской сессии.',
    value: 'forceLogout'
  }
]

const Filter = (props) => {
  const classes = useStyles();
  let {filters, onChange} = props;

  const handleChangeFilter = (event) => {
    let newFilter = filters;
    let item = event.target;
    let name = item.name;
    let value = item.value;

    newFilter[name] = value;

    newFilter.page = 1;

    onChange(newFilter)
  };
  const handleClearAll = () => {
    filters = {
      limit: 20,
      page: 1,

      license_plate: "",
      datetime_from: null,
      datetime_to: null,

      type: null
    }

    onChange(filters)
  }
  const handleChangeDate = (range) => {
    filters.datetime_from = range.startDate * 1000;
    filters.datetime_to = range.endDate * 1000;

    filters.page = 1;

    onChange(filters)
  }

  const handleChangeAutocomplite = (name, values) => {
    let newFilters = {...filters};

    newFilters[name] = values;

    newFilters.page = 1

    onChange(newFilters)
  }
  const handleRanderOptionLabel = (option) => {
    return option.title
  }

  return (
    <>
      <Box mb={5}>
        <Grid container spacing={3} className={classes.filterContainer}>
          <Grid xs={3} item>
            <Typography variant={'formTitle'}>ГРЗ</Typography>
            <TextField
              name={'license_plate'}
              value={filters.license_plate}
              variant="outlined"
              fullWidth
              onChange={handleChangeFilter}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <>
                      {
                        (filters.license_plate) && (
                          <Tooltip title="Очистить">
                            <IconButton onClick={() => handleChangeAutocomplite('license_plate', '')}>
                              <HighlightOffIcon/>
                            </IconButton>
                          </Tooltip>
                        )
                      }
                    </>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid xs={3} item>
            <Typography variant={'formTitle'}>Временной промежуток</Typography>
            <DateRange
              name='datetime_start'
              dateStart={filters.datetime_from}
              dateEnd={filters.datetime_to}
              onChange={handleChangeDate}
            />
          </Grid>
          <Grid xs={3} item>
            <Typography variant={'formTitle'}>Тип</Typography>

            <Autocomplete
              openText={'Открыть список'}
              clearText={'Очистить'}
              closeText={'Закрыть'}
              value={filters.type}
              name={'type'}
              options={typesOptions}
              fullWidth
              getOptionLabel={handleRanderOptionLabel}
              noOptionsText={'Не найдено'}
              onChange={(event, values) => handleChangeAutocomplite('type', values)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant={'outlined'}
                  onChange={handleChangeFilter}
                />
              )}
            />

          </Grid>
          <Grid xs={3} item>
            <Typography variant={'formTitle'}>Имя</Typography>
            <TextField
              name={'username'}
              value={filters.username}
              variant="outlined"
              fullWidth
              onChange={handleChangeFilter}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <>
                      {
                        (filters.username) && (
                          <Tooltip title="Очистить">
                            <IconButton onClick={() => handleChangeAutocomplite('username', '')}>
                              <HighlightOffIcon/>
                            </IconButton>
                          </Tooltip>
                        )
                      }
                    </>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Button variant='outlined' onClick={handleClearAll} className={classes.buttonClear}>
        Сбросить
      </Button>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {},
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  queryField: {
    width: 500
  },
  categoryField: {
    width: '100%',
  },
  imageCell: {
    fontSize: 0,
    width: 68,
    flexBasis: 68,
    flexGrow: 0,
    flexShrink: 0
  },
  image: {
    height: 68,
    width: 68
  },

  label: {
    marginBottom: 5
  },

  buttonSpeedArrow: {
    minWidth: 'initial',
    padding: '10px 5px',
    backgroundColor: 'rgba(194, 207, 224, 0.2)'
  },
  buttonSpeedArrowActive: {},

  clarificationForm: {
    fontSize: 12,
    lineHeight: '14px',
    color: theme.palette.gray.dark,

    '& sup': {
      position: 'relative',
      top: 0,
      color: theme.palette.primary.main,
    }
  },

  formBottom: {
    display: 'flex',
    alignItems: 'center'
  },

  filterContainer: {
    '& > .MuiGrid-grid-xs-4': {
      [theme.breakpoints.between(0, 1599)]: {
        maxWidth: '50%',
        flexBasis: '50%'
      },
    }
  },

  buttonClear: {
    paddingLeft: 32,
    paddingRight: 32
  }
}));

export default Filter;
