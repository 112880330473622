import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  makeStyles,
  Grid,
  Paper,
  Box,
  IconButton,
  Button,
  TextField, DialogActions
} from '@material-ui/core';
import Draggable from 'react-draggable';
import {withStyles} from '@material-ui/core/styles';
import {
  X as XIcon
} from 'react-feather';

const useStyles = makeStyles((theme) => ({
  fileLabel: {
    flex: 1,
    display: 'flex'
  },
  fileTextField: {
    flex: 1
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    position: 'relative'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    transform: 'translate(0, -50%)',
    color: '#152435',
  }
});

function PaperComponent(props) {
  return (
    <Draggable
      handle="#dialog-confirmation-deletion"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const MyDialogTitle = withStyles(styles)((props) => {
  const {children, classes, onClose, ...other} = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      {children}
    </DialogTitle>
  );
});

const ModalDelete = (props) => {
  const classes = useStyles();
  const {
    isDelete,
    item,
    open,
    isClose
  } = props;


  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth={'sm'}
      onClose={isClose}
      PaperComponent={PaperComponent}
      aria-labelledby="dialog-confirmation-deletion"
    >
      <MyDialogTitle
        id="dialog-confirmation-deletion"
        onClose={isClose}
      >
        <Grid>
          <Grid container alignItems={'center'}>
            <Grid item>
              <Typography variant='h5'>
                Удаление "{item.title}"
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </MyDialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          Вы действительно хотите удалить запись?
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button
          size="small"
          variant="formCancel"
          onClick={isClose}
        >
          Отмена
        </Button>
        <Button
          onClick={isDelete}
          size="small"
          type="submit"
          variant="formSubmit"
        >
          Удалить
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalDelete
