import React, {useEffect, useState} from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,

    Typography,

    Box,

    Grid,

    Button,
    Tooltip,

    TextField,
    FormControl,
    Select,
    MenuItem,

    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,

    Switch
} from "@material-ui/core";
import {
    Skeleton
} from "@material-ui/lab";
import {
    makeStyles
} from "@material-ui/styles";
import {CustomCheckbox} from "../../../../../components";
import clsx from "clsx";

const ModalSensor = (props) => {
    const {sensors, sensorsInitial, activeFilter, filterSensor, isOpen, onClose, onChangeFilterSensors, onChangeActiveFilter, onSetActiveFilter} = props;

    const [listCities, setListCities] = useState([]);
    const [isShowOnlyActive, setShowOnlyActive] = useState(false);

    useEffect(() => {
        handleSetCities();
        setShowOnlyActive(false);
    }, [sensorsInitial]);
    useEffect(() => {
        setShowOnlyActive(false);
    }, [isOpen]);

    const handleSetCities = () => {
        let cities = [];

        sensorsInitial.map((sensor) => {
            const city = sensor.city.replace(/\s$/, '');

            if (cities.indexOf(city) <= -1 && !!city) {
                cities.push(city)
            }
        });

        setListCities(cities);
    }
    const handleOnChange = ({target}) => {
        const {name, value} = target;

        let newFilterSensor = {...filterSensor};
        newFilterSensor[name] = value;

        // handleResetSensor();
        onChangeFilterSensors(newFilterSensor);
    }

    const handleClearFilter = () => {
        const newFilterSensor = {
            address: '',
            direction: '',
            city: '',
            limit: 999,
            page: 1
        };
        setShowOnlyActive(false);

        onChangeFilterSensors(newFilterSensor)
    }

    const handleSetCurrentSensors = () => {
        let newActiveFilter = {...activeFilter};
        let sensor_ids = [];

        sensor_ids = sensors.map(t  => {
            return t.id
        });

        newActiveFilter.sensor_ids = sensor_ids;

        onChangeActiveFilter(newActiveFilter);
    }
    const handleAddSensor = ({ id }) => {
        let newActiveFilter = {...activeFilter};
        let sensor_ids = newActiveFilter.sensor_ids;

        if (sensor_ids.indexOf(id) > -1) {
            sensor_ids.splice(sensor_ids.indexOf(id), 1)
        }else{
            sensor_ids.push(id)
        }

        newActiveFilter.sensor_ids = sensor_ids;

        onChangeActiveFilter(newActiveFilter);
    }
    const handleResetSensor = () => {
        let newActiveFilter = {...activeFilter};
        newActiveFilter.sensor_ids = [];
        onChangeActiveFilter(newActiveFilter);
    }

    return (
        <Dialog
            open={isOpen}

            maxWidth="lg"

            fullWidth

            onClose={onClose}
        >

            <DialogTitle>
                <Box mb={1}>
                    <Typography variant="h3">Устройства</Typography>
                </Box>

                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Typography variant="formTitle">Город</Typography>

                        <FormControl fullWidth>
                            <Select
                                name="city"
                                variant="outlined"
                                value={filterSensor.city}
                                onChange={handleOnChange}
                            >
                                <MenuItem value="">Сбросить</MenuItem>

                                {
                                    listCities.map((city) => (
                                        <MenuItem value={city}>{city}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="formTitle">Адрес</Typography>

                        <TextField
                            value={filterSensor.address}
                            placeholder="Ленина"
                            name="address"
                            variant="outlined"

                            fullWidth

                            onChange={handleOnChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="formTitle">Отображать устройства</Typography>

                        <Grid container spacing={1} alignItems="center">
                            <Grid item><Typography>Все</Typography></Grid>
                            <Grid item>
                                <Switch checked={isShowOnlyActive} onChange={() => setShowOnlyActive(!isShowOnlyActive)}/>
                            </Grid>
                            <Grid item><Typography>Только активные</Typography></Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justify="flex-end">
                            <Grid item>
                                <Typography variant="formTitle">&nbsp;</Typography>
                                <Button
                                    variant="outlined"
                                    style={{height: 44}}

                                    onClick={handleClearFilter}
                                >Очистить фильтр</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent style={{paddingTop: 0}}>
                <Table stickyHeader className="MuiTable-list-modal">
                    <TableHead className="MuiTableHead-list-modal">
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Город</TableCell>
                            <TableCell width="45%">Адрес</TableCell>
                            <TableCell>Серия</TableCell>
                            <TableCell>Статус устройства</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            sensors.map((sensor, idx) => {
                                const isActive = activeFilter.sensor_ids.indexOf(sensor.id) > -1;
                                if (isShowOnlyActive && !isActive) {
                                    return null
                                }

                                return (
                                    <Row
                                        key={`sensor-${idx}`}

                                        isActive={isActive}

                                        onAddSensor={handleAddSensor}

                                        {...sensor}
                                    />
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </DialogContent>

            <DialogActions style={{padding: '12px 24px'}}>
                <Grid container spacing={2}>
                    <Grid item>
                        <Tooltip title="Установить выбранные устройства">
                            <Button onClick={onSetActiveFilter} variant="contained">Выбрать</Button>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title="Сбрасывает текущие устройства, устанавливает все устройства по выборке">
                            <Button onClick={handleSetCurrentSensors} variant="contained">Выбрать текущие</Button>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title="Сбросить фильтр по устройствам">
                            <Button onClick={handleResetSensor} variant="outlined">Сбросить</Button>
                        </Tooltip>
                    </Grid>
                </Grid>
            </DialogActions>

        </Dialog>
    )
};

const RowLoading = () => {
    return (
        <TableRow>
            <TableCell>
                <Skeleton height={20}/>
            </TableCell>
            <TableCell>
                <Skeleton height={20}/>
            </TableCell>
            <TableCell>
                <Skeleton height={20}/>
            </TableCell>
            <TableCell>
                <Skeleton height={20}/>
            </TableCell>
        </TableRow>
    )
}
const Row = (props) => {

    const classes = useStyles();

    return (
        <TableRow
            selected={props.isActive}
            className={'MuiTableRow-list-modal MuiTableRow-dark-hover'}
            hover

            onClick={() => props.onAddSensor(props)}
        >
            <TableCell>
                <CustomCheckbox
                    checked={props.isActive}
                    type="circle"
                />
            </TableCell>
            <TableCell>{props.city}</TableCell>
            <TableCell>{props.address}</TableCell>
            <TableCell>{props.serial}</TableCell>
            <TableCell>
                <Tooltip title={(props?.status === 10) ? "Активный" : (props?.status === 0) ? "Не в сети" : "Отключен"}>
                    <div className={clsx({
                        [classes.status]: true,
                        [classes.statusActive]: props?.status === 10,
                        [classes.statusOffline]: props?.status === 0,
                        [classes.statusError]: props?.status === -1,
                    })}/>
                </Tooltip>
            </TableCell>
        </TableRow>
    )
}

const useStyles = makeStyles(() => ({
    status: {
        width: 30,
        height: 30,
        borderRadius: 999,
        borderColor: 'rgba(255, 255, 255, 0.1)'
    },
    statusActive: {
        backgroundColor: '#3CB11F'
    },
    statusOffline: {
        backgroundColor: "#F59300"
    },
    statusError: {
        backgroundColor: '#8698B1'
    },
}));

export default ModalSensor
