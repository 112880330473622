import React, {useEffect, useState} from "react";
import {
    Box,
    Grid,
    Tooltip,
    Divider,
    Collapse,
    IconButton,
    Typography,
} from "@material-ui/core";
import {
    makeStyles
} from "@material-ui/styles";
import {
    ChevronUp as ChevronUpIcon,
    ChevronDown as ChevronDownIcon
} from "react-feather";
import {useHistory} from "react-router-dom";
import clsx from "clsx";

const Navigation = (props) => {
    const { navigations } = props;
    const classes = useStyles();
    const path = `/reference`;
    const history = useHistory();
    const isActive = history.location.pathname === path;

    const navigate = (path) => {
        history.push(path);
    }

    return (
        <Box p={2} bgcolor="white" borderRadius={4}>

            <Box pb={2}>
                <Grid container spacing={1} alignItems="center" justify="space-between" wrap="nowrap">
                    <Grid item className={classes.accordionButton} onClick={() => navigate(path)}>
                        <Typography className={clsx({
                            [classes.accordionTitle]: true,
                            [classes.accordionTitleActive]: isActive,
                        })}>Главная</Typography>
                    </Grid>
                </Grid>
            </Box>

            {
                Object.keys(navigations).map((key) => (
                    <AccordionItem navigate={navigate} path={path} {...navigations[key]}/>
                ))
            }
        </Box>
    )
}

const AccordionItem = (props) => {
    const {navigate} = props;
    const classes = useStyles();
    const [isOpen, setOpen] = useState(false);
    const path = `${props.path}/${props?.slug}`;
    const history = useHistory();
    const isActive = history.location.pathname === path;
    const Icon = isOpen ? ChevronUpIcon : ChevronDownIcon;

    useEffect(() => {
        setOpen(history.location.pathname.indexOf(path) > -1);
    }, []);

    return (
        <>

            <Divider/>

            <Box>
                <Box py={2}>
                    <Grid container spacing={1} alignItems="center" justify="space-between" wrap="nowrap">
                        <Grid item className={classes.accordionButton} onClick={() => navigate(path)}>
                            <Typography className={clsx({
                                [classes.accordionTitle]: true,
                                [classes.accordionTitleActive]: isActive,
                            })}>{props?.title}</Typography>
                        </Grid>
                        {
                            (Object.keys(props.items).length > 0) && (
                                <Grid item>
                                    <Tooltip title={isOpen ? 'Свернуть' : 'Развернуть'}>
                                        <IconButton disabled={Object.keys(props.items).length <= 0} onClick={() => setOpen(!isOpen)} style={{padding: 0}}>
                                            <Icon/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            )
                        }
                    </Grid>
                </Box>

                <Collapse in={isOpen}>
                    <Box pl={2}>
                        {
                            Object.keys(props.items).map((key) => {
                                return (
                                    <AccordionItem navigate={navigate} path={path} {...props.items[key]}/>
                                )
                            })
                        }
                    </Box>
                </Collapse>

            </Box>

        </>
    )
}

const useStyles = makeStyles(() => ({
    accordionButton: {
        cursor: "pointer",
        "&:hover": {
            "& p": {
                color: "#212529"
            }
        }
    },
    accordionTitle: {
        fontSize: 15,
        lineHeight: '18px',
        color: "#152435",
        fontFeatureSettings: "'ss01' on"
    },
    accordionTitleActive: {
        color: "#152435",
        textShadow: "0px 1px 1px #152435, 0px 0px 1px #152435;"
    },
}));

export default Navigation
