import React, {useState, useEffect} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Grid,
  Paper,
  makeStyles,
  Button,
  IconButton, Card, Tooltip, Popover, Menu, MenuItem
} from '@material-ui/core';
import Draggable from 'react-draggable';
import {
  Map,
  Table
} from '../index';
import {withStyles} from '@material-ui/core/styles';
import {Video as VideoIcon, X as XIcon} from 'react-feather';
import {CustomCheckbox, CustomMoment as Moment, Image} from '../../../index';
import clsx from "clsx";
import {checkPointFromPoligon} from "../../../../helpers/checkPointPoligon";
import varibles from "../../../../varibles";
import PopupState, {bindMenu, bindPopover, bindTrigger} from "material-ui-popup-state";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    height: '100vh',
    maxHeight: '65vh',
    padding: 0,
    position: 'relative'
  },
  buttonClear: {
    height: 38,
    padding: '5px 15px'
  }
}));
const useStylesFixed = makeStyles((theme => ({
  root: {
    position: 'absolute',
    left: 42,
    bottom: 14,
    zIndex: 999,
    width: 270,
    padding: 18,
    boxSizing: 'border-box',

    "& > *": {
      padding: 0 + '!important'
    }
  },
  rootTopLeft: {
    left: 42,
    top: 14,
    bottom: 'initial',
  },
  rootTopRight: {
    right: 42,
    top: 14,
    bottom: 'initial',
    left: 'initial',
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    marginBottom: 12,
    cursor: 'grab',

    '&:active': {
      cursor: 'grabbing',
    }
  },
  headerTitle: {
    fontSize: 14,
    lineHeight: '100%',
    letterSpacing: '0.01em',
    fontFeatureSettings: "'ss01'on",
    color: theme.palette.gray.dark
  },
  headerClose: {
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translate(0px, -50%)',
    padding: 0,
    marginRight: -5
  },

  imageContent: {
    marginBottom: 18
  },
  image: {
    width: '100%',
    height: 135,
    objectFit: 'cover',
    borderRadius: 2
  },

  body: {},
  bodyText: {
    fontSize: 14,
    lineHeight: '18px',
    color: theme.palette.black
  },
  bodyTextBold: {
    fontWeight: '500'
  },
  bodyTextGray: {
    color: '#8698b0',
    fontWeight: '500'
  },
})));
const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    padding: '10px 24px',
    paddingRight: 50,
    position: 'relative'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    transform: 'translate(0, -50%)',
    color: '#152435',
  }
});

const InformationFixed = (props) => {
  const {
    sensor, onClose, onCardEdit, sensorsCameraIds,
    onOpenSensorCameraStream, roleSensorStream
  } = props;
  const classes = useStylesFixed();

  const [isCameras, setIsCameras] = useState(false);

  useEffect(() => {
    if (!roleSensorStream) {
      setIsCameras(false);
      return null
    }
    const cameras = sensorsCameraIds[sensor.id]['items'];

    let isCameras = false;
    cameras.map((camera) => {
      if (!!camera.video_url){
        isCameras = true;
      }
    })

    setIsCameras(isCameras)
  }, [sensor.id]);

  const camera = (roleSensorStream) ? sensorsCameraIds[sensor.id] : false;
  const handleOpenCamera = (sensor, camera) => {
    onOpenSensorCameraStream({...sensor, camera})
  }


  return (
    <Draggable bounds="parent" handle=".card-header-draggable">
      <Card
        className={clsx(
          {
            [classes.root]: true
          })
        }
      >
        <Grid className={clsx([classes.header, 'card-header-draggable'])}>
          <Typography className={classes.headerTitle}>
            Устройство
          </Typography>

          <IconButton size={'small'} className={classes.headerClose} onClick={onClose}>
            <XIcon color={'#8698B1'}/>
          </IconButton>
        </Grid>

        <Grid container spacing={1} className={classes.body}>
          <Grid item xs={12}>
            <Typography className={clsx([classes.bodyText, classes.bodyTextBold])}>{ sensor.title } { sensor.serial }</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.bodyText}>
              <Typography className={classes.bodyTextGray}>Местоположение: </Typography> { sensor.address }
              <Typography className={classes.bodyTextGray}>Направление: </Typography> { sensor.direction }
            </Typography>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Button
                variant={'contained'}
                fullWidth
                onClick={() => onCardEdit(sensor)}
              >Редактировать</Button>
            </Grid>
            {
              Boolean(camera && isCameras) && (
                  <Grid item xs={12}>
                    <PopupState variant="popover">
                      {(popupState) => (
                          <div>
                            <Tooltip title="Запусть видео с камеры">
                              <Button variant="outlined" fullWidth {...bindTrigger(popupState)}>
                                Камеры
                              </Button>
                            </Tooltip>
                            <Popover
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                            >
                              <div style={{ minWidth: 242 }}>
                                {
                                  camera.items.map((camera, idx) => {
                                    if (!camera.video_url){
                                      return null
                                    }

                                    return (
                                        <MenuItem onClick={() => handleOpenCamera(sensor, camera)}>
                                          {camera?.direction || camera?.uid}
                                        </MenuItem>
                                    )
                                  })
                                }
                              </div>
                            </Popover>
                          </div>
                      )}
                    </PopupState>
                  </Grid>
              )
            }
          </Grid>
        </Grid>
      </Card>
    </Draggable>
  )
}

function PaperComponent(props) {
  return (
    <Draggable
      handle="#dialog-confirmation-deletion"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
const MyDialogTitle = withStyles(styles)((props) => {
  const {children, classes, onClose, ...other} = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>

      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size={'small'}
        >
          <XIcon/>
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const ModalDevices = (props) => {
  const classes = useStyles();
  const {
    open,
    isClose,
    devicesSensor,
    onClick,

    selected,
    cardSensor,
    showCardSensor,
    onCardEdit,
    onCloseCard,

    mapPolygons,
    onChangeMapPolygons,
    toEditFromMap,

    sensorsCameraIds,
    roleSensorStream,
    onOpenSensorCameraStream
  } = props;

  const activeCard = (cardSensor) ? cardSensor.id : null;

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth={'lg'}
      onClose={isClose}
      PaperComponent={PaperComponent}
      aria-labelledby="dialog-confirmation-deletion"
    >
      <MyDialogTitle id="dialog-confirmation-deletion" onClose={isClose}>
        <Typography variant='h3'>Устройства</Typography>
      </MyDialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Map
          position={[56.8519000, 60.6122000]}
          devicesSensor={devicesSensor}
          onClick={onClick}
          activeCard={activeCard}

          mapPolygons={mapPolygons}
          onChangeMapPolygons={onChangeMapPolygons}
          selected={selected}
        />

        {
          showCardSensor && (
            <InformationFixed
                sensorsCameraIds={sensorsCameraIds}
              sensor={cardSensor}
              onClose={onCloseCard}
              activeCard={activeCard}
              onCardEdit={onCardEdit}
                roleSensorStream={roleSensorStream}

                onOpenSensorCameraStream={onOpenSensorCameraStream}
            />
          )
        }
      </DialogContent>
      <DialogContent>
        <Grid container spacing={2} justify={'flex-end'}>
          <Grid item>
            <Button
              onClick={isClose}
              size="small"
              variant="formCancel"
              style={{ marginRight: 10 }}
              className={classes.buttonClear}
            >
              Закрыть
            </Button>
            <Button
              disabled={selected.length <= 0}
              onClick={toEditFromMap}
              size="small"
              variant="formSubmit"
              className={classes.buttonClear}
            >
              Редактировать
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default ModalDevices
