import React, {Component} from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  Button, Tooltip, TextField, InputAdornment, IconButton
} from '@material-ui/core';
import {
  Page
} from '../../../components';
import {
  Table,
  ModalInformation
} from './components';
import axios from '../../../plugins/axios';
import {
  CustomMoment as Moment
} from '../../../components';
import {
  ArrowLeft as ArrowLeftIcon, Plus as PlusIcon
} from 'react-feather';
import {
  withStyles
} from '@material-ui/styles';
import moment from 'moment';
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import iconv from "iconv-lite";
import queryString from "query-string";

class WantedListsCoincidences extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],

      filter: {
        list_ids: [],
        limit: 20,
        page: 1
      },
      pagination: {
        rowsPerPage: 20,
        page: 1,
        orderBy: null,
        order: 'asc',
        totalCount: 0
      },
      modalInformation: {},

      nameList: '',

      itemActive: null,

      isLoading: true,
      showModalInformation: false
    }
  }

  componentDidMount = () => {
    let filter = this.state.filter;
    let itemActive = null;
    filter.list_ids.push(this.props.match.params.list_id);

    let params = queryString.parse(this.props.location.search);
    if (Object.keys(params).length > 0) {
      if (params.item_id){
        itemActive = params.item_id;
      }
    }

    this.setState({
      filter,
      itemActive
    });

    this.isLoadSearchListCoincidences()
  }
  isLoadSearchListCoincidences = () => {
    this.setState({
      isLoading: true,
      rows: []
    })

    axios('post', '/search/result/get', this.state.filter).then(response => {
      let rows = response.data;
      let totalCount = response.headers['x-pagination-total-count'];
      let pagination = this.state.pagination;
      let nameList = response.headers['x-list-title'];

      if (!rows) {
        rows = []
      }

      pagination.totalCount = totalCount;

      this.setState({
        rows,
        pagination,
        isLoading: false,
        nameList: iconv.decode(nameList, 'utf-8'),
        dateTimeRequest: moment()
      })
    }).catch(error => {})
  }

  onOpenModalInformation = (modalInformation) => {
    this.setState({
      modalInformation,
      showModalInformation: true
    })
  }

  changeFilters = (filter) => {
    this.setState({ filter })

    this.isLoadSearchListCoincidences()
  }

  routeBack = () => {
    this.props.history.goBack()
  }

  render() {
    const {classes} = this.props;

    return (
      <Page title={'Устройства'}>
        <Grid container justify={'center'}>
          <Grid item xs={12}>
            <Box mb={2}>
              <Grid container alignItems={'center'}>
                <Box mr={2}>
                  <Typography variant={'h3'}>
                    { this.state.nameList }:&nbsp;
                    {
                      (!this.state.isLoading) ? this.state.pagination.totalCount : (
                        <CircularProgress size={20} color={'inherit'}/>
                      )
                    }
                  </Typography>
                </Box>

                <Box>
                  <Typography style={smallTextGray}>
                    Данные актуальны на&nbsp;
                    <Moment
                      format="DD.MM.YYYY HH:mm:ss"
                      value={this.state.dateTimeRequest}
                    >
                      {this.state.dateTimeRequest}
                    </Moment>
                  </Typography>
                </Box>

                {
                  false && (
                    <Box className={classes.searchContent}>
                      <TextField
                        name={'searchTableGrz'}
                        value={this.state.searchTableGrz}
                        variant="outlined"
                        onChange={this.onChangeSearchTableGrz}
                        placeholder={'Поиск по ГРЗ'}
                        className={classes.searchInput}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <>
                                {
                                  (this.state.searchTableGrz) && (
                                    <IconButton onClick={() => {
                                      this.setState({searchTableGrz: ''})
                                    }}>
                                      <HighlightOffIcon/>
                                    </IconButton>
                                  )
                                }
                              </>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Box>
                  )
                }
              </Grid>
            </Box>

            <Table
              rows={this.state.rows}
              pagination={this.state.pagination}
              filter={this.state.filter}
              isLoading={this.state.isLoading}

              isChangeRowPage={this.isChangeRowPage}
              isChangeFilter={this.changeFilters}
              onDeleteList={this.onDeleteList}
              onEditList={this.onOpenEdit}
              onOpenModalInformation={this.onOpenModalInformation}

              itemActive={this.state.itemActive}
            />
          </Grid>
        </Grid>

        <ModalInformation
          open={this.state.showModalInformation}
          coincidences={this.state.modalInformation}
          isClose={() => {
            this.setState({showModalInformation: false})
          }}
        />
      </Page>
    );
  }
}

const styles = {
  backTypography: {
    fontSize: 18,
    lineHeight: '18px',
    marginLeft: 16,
    color: '#152435'
  },
  backButton: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    minWidth: 'initial'
  },

  searchContent: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  searchInput: {
    backgroundColor: 'white',
    width: '100%',
    maxWidth: 350,
    borderRadius: 4,
    overflow: 'hidden',

    "& .MuiOutlinedInput-input": {
      backgroundColor: 'white'
    }
  },
};

const smallTextGray = {
  color: '#647083',
  fontSize: 16
};

export default withStyles(styles)(WantedListsCoincidences)
