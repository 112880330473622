import React, { useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  makeStyles,
  Grid,
  Paper,
  Box,
  IconButton,
  Button,
  TextField
} from '@material-ui/core';
import Draggable from 'react-draggable';
import {withStyles} from '@material-ui/core/styles';
import {
  X as XIcon
} from 'react-feather';
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import {CalendarToday} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  fileLabel: {
    flex: 1,
    display: 'flex'
  },
  fileTextField: {
    flex: 1
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
    position: 'relative'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    transform: 'translate(0, -50%)',
    color: '#152435',
  }
});

function PaperComponent(props) {
  return (
    <Draggable
      handle="#dialog-confirmation-deletion"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const MyDialogTitle = withStyles(styles)((props) => {
  const {children, classes, onClose, ...other} = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <XIcon/>
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const ModalLoadFile = (props) => {
  const classes = useStyles();
  const {onClose, open, onLoadFile, onChangeLoadFile, form} = props;
  const fileUploadRef = useRef();

  const handleChangeFile = () => {
    let newForm = form;
    let files = fileUploadRef.current.files;

    if (files && files.length > 0){
      newForm['file'] = files[0];

      onChangeLoadFile(newForm)
    }
  }
  const handleRemoveFile = () => {
    let newForm = form;
    newForm['file'] = null;

    onChangeLoadFile(newForm)
  }

  const fileName = (form && form.file) ? form.file.name : '';

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth={'md'}
      onClose={onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="dialog-confirmation-deletion"
    >
      <MyDialogTitle
        id="dialog-confirmation-deletion"
        onClose={onClose}
      >
        <Grid>
          <Grid container alignItems={'center'}>
            <Grid item>
              <Typography variant='h3'>
                Загрузка файла
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </MyDialogTitle>
      <DialogContent style={{padding: 0, paddingBottom: 26}}>
        <Box pl={3} pr={3} mb={4}>
          <Grid container className={classes.gridHeight} spacing={2}>
            <Grid item xs={12}>
              <Typography variant={'subtitle1'}>Выберите файл для загрузки в список (.csv)</Typography>
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="upload-photo" className={classes.fileLabel}>
                <TextField
                  value={fileName}
                  className={classes.fileTextField}
                  variant={'outlined'}
                  disabled
                />

                <input
                  ref={fileUploadRef}
                  style={{display: 'none'}}
                  id="upload-photo"
                  name="upload-photo"
                  type="file"
                  accept=".csv"
                  onInput={handleChangeFile}
                />

                <Button variant="contained" component="span">
                  Выбрать файл
                </Button>
              </label>
            </Grid>
            <Grid item xs={12} justify={'flex-end'} style={{ display: 'flex' }}>
              <Typography variant={'caption'}>Файл должен содержать два столбца: ГРЗ, Комментарий</Typography>
            </Grid>
          </Grid>
        </Box>


        <Box pl={3} pr={3}>
          <Grid justify={'flex-end'} style={{ display: 'flex' }}>
            <Button
              size="large"
              style={{ marginRight: 10 }}
              variant="formCancel"
              onClick={() => onClose()}
            >
              Отменить
            </Button>
            <Button
              disabled={!form || !form.file}
              size="large"
              type="submit"
              variant="formSubmit"
              onClick={onLoadFile}
            >
              Загрузить
            </Button>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default ModalLoadFile
