import React from "react";
import {
    Box,
    Grid,
    Typography,
    TextField,

    Tooltip,
    Button,

    FormControl,
    Select,
    MenuItem,
} from "@material-ui/core";
import userRoles from "../../../../../constant/userRoles";

const Filter = (props) => {
    const { filter, initialFilter, onChange, onSearch } = props;

    const handleChange = ({ target }) => {
        const { name, value } = target;
        let newFilter = {...filter};

        newFilter[name] = value;
        newFilter.page = 1;

        onChange(newFilter);
    }
    const handleClear = () => {
        onChange(initialFilter, true);
    }

    return (
        <>

            <Box mb={2}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Typography variant="formTitle">Заголовок</Typography>

                        <TextField
                            placeholder="..."
                            variant="outlined"
                            name="title"
                            value={filter.title}
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
            </Box>

            <Box>
                <Grid container spacing={2}>
                    <Grid item>
                        <Tooltip title="Поиск">
                            <Button variant="contained" onClick={onSearch}>Поиск</Button>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title="Очистить фильтр">
                            <Button variant="outlined" onClick={handleClear}>Очистить</Button>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
};

export default Filter
