const getMessageError = (error) => {
    const response = error.response;
    let message = '';

    if ( response.status === 502 ) {
        return 'Сервер недоступен'
    }

    return message
}

export default getMessageError