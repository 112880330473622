import React from "react";
import {
    Box,
    Grid,
    Typography
} from "@material-ui/core";

const AddressesList = (props) => {
    const { data } = props;

    console.log('data: ', data);

    return (
        <Box bgcolor="white" borderRadius={4} p={2}>
            <Grid container spacing={3}>
                {data.map((item, idx) => (
                    <Grid item xs={12} key={`frequent-stops-address-${idx}`}>
                        <Box>
                            <Box width={30} height={30} borderRadius={999} bgcolor={item.color} display="flex" alignItems="center" justifyContent="center" style={{float: "left"}} mr={2}>
                                <Typography variant="h6" style={{color: 'white'}}>{ item.frequency }</Typography>
                            </Box>
                            <Typography variant="body1">{ item.address }</Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default AddressesList
