import React, {Component} from 'react';
import {
    Box,
    Grid,
    IconButton,
    Typography,
    Tooltip
} from '@material-ui/core';
import {
    ContentNotification,
    Page
} from '../../../components';
import {
    Filter,
    Table,
    ExportCreate,
    PersonalSearchList,
    ModalCreateListPersonalSearch
} from './components'
import axios from '../../../plugins/axios';
import {withStyles} from '@material-ui/styles';
import {store} from 'react-notifications-component';
import notificationSound from '../../../assets/audio/notification-sound.mp3';
import {getLocalStorage, setLocalStorage} from '../../../helpers/localStorage';
import varibles from '../../../varibles';
import theme from "../../../theme";
import Alert from "@material-ui/lab/Alert";
import {X as XIcon} from "react-feather";
import clsx from "clsx";
import {
    HelpOutline as HelpOutlineIcon
} from '@material-ui/icons';

class WantedList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rows: [],
            columns: [
                {
                    label: 'Фиксация',
                    field: 'datetime',
                    align: 'left',
                    sort: false,
                    type: 'date',
                    width: 100
                },
                {
                    label: 'Фото',
                    field: 'photo',
                    align: 'left',
                    width: 70
                },
                {
                    label: 'ГРЗ',
                    field: 'grz',
                    align: 'left',
                    sort: false,
                    type: 'grz',
                    width: 150
                },
                {
                    label: 'Место фиксации',
                    field: 'address',
                    align: 'left',
                    sort: false,
                    width: 200
                }
            ],
            showColumns: ['datetime', 'photo', 'grz', 'address'],
            sensorList: [],
            listWanted: [],
            listPersonalSearch: [],
            listNewWantedItems: [],

            personalSearch: {},
            pagination: {
                rowsPerPage: 20,
                page: 1,
                orderBy: null,
                order: 'asc',
                totalCount: 0
            },
            sensorFilter: {
                address: '',
                direction: '',
                vendor: '',
                serial: ''
            },
            filter: {
                sensor_ids: [],
                list_id: "",

                limit: 20,
                page: 1,
            },
            createNewListPersonalSearch: {
                list_id: null,
                license_plate: '',
                color: '',
                mark: '',
                model: '',
                type: '',
                comment: ''
            },
            settings: {
                notificationOn: false,
                soundOn: false,
                accumulate: false,
                isUpdatePage: false
            },


            isLoading: true,
            openModalCreate: false,

            onLoadSensor: false,
            onLoadWantedList: true,
            onLoadSearchList: true,

            isActiveFilter: false
        }

        this.webSocketNotification = null;
    }

    componentDidMount = async () => {
        let localWantedSetting = getLocalStorage('local-wanted-setting');
        if (localWantedSetting) {
            localWantedSetting = JSON.parse(localWantedSetting);

            this.onChangeSettings(localWantedSetting, false)
        }
        this.getSensorList();
        this.props.setCountBadgeWanted(0);
      await this.getPersonalSearch();
      this.onCheckNewWanted();
      this.getSearchResult();
    }

    getPersonalSearch = async () => {
      let filter = {...this.state.filter};
      const res = await axios('get', '/search/list/get-personal').then((res) => {
        return res.data
      }).catch(() => {
        return null
      });
      filter.list_id = res?.id || "";
      await this.setState({ filter })
    }

    // table
    getSearchResultFilter = () => {
        let filter = {};

        for (let key in this.state.filter) {
            filter[key] = this.state.filter[key]
        }

        return filter
    }
    getSearchResult = () => {
        if (!this.state.onLoadSensor || !this.state.onLoadWantedList || !this.state.onLoadSearchList) {
            return null
        }

        let filter = this.getSearchResultFilter();

        axios('post', '/search/result/get', filter).then((response) => {
            let rows = response.data;
            let totalCount = response.headers['x-pagination-total-count'];
            let pagination = this.state.pagination;

            if (!rows) {
                rows = []
            }

            pagination.totalCount = totalCount;

            this.setState({
                rows,
                pagination,
                isLoading: false
            })
        })
    }
    isChangeRowPage = (pagination) => {
        this.setState({
            pagination
        })

        this.getSearchResult()
    }
    // ---

    // sensor
    getFilterSensor = () => {
        let filter = {}

        for (let key in this.state.sensorFilter) {
            let item = this.state.sensorFilter[key];

            if (item) {
                filter[key] = item;
            }
        }

        return filter
    }
    getSensorList = () => {
        let filter = this.getFilterSensor();

        filter['page'] = 1;
        filter['limit'] = 999;

        axios('post', '/operator/sensor/find', filter).then((response) => {
            let sensorList = response.data;
            let stateFilter = {...this.state.filter};
            let localStorageParams = this.getLocalStorageParams();

            if (!sensorList) {
                sensorList = []
            }

            this.setState({
                sensorList,
                onLoadSensor: true
            });

            if (localStorageParams && localStorageParams.sensor_ids.length > 0) {
                stateFilter['sensor_ids'] = localStorageParams.sensor_ids;

                this.setState({
                    filter: stateFilter
                })

                this.onCheckActiveFilter(stateFilter)
            }

            this.getSearchResult()
        }).catch((error) => {})
    }
    onFilterSensor = (sensorFilter) => {
        clearTimeout(this.handleGetSensorList)

        this.setState({sensorFilter});

        this.handleGetSensorList = setTimeout(() => {
            this.getSensorList()
        }, 500)
    }
    // ---


    // filter
    setFilterToUrl = () => {
        let filter = []

        for (let key in this.getSearchResultFilter()) {
            let item = this.state.filter[key];

            if (key === 'sensor_ids' || key === 'list_ids' && item) {
                if (item.length <= 0) {
                    item = null
                }
            }

            if (item) {
                filter.push(key + '=' + item);
            }
        }

        this.props.history.replace('wanted?' + filter.join('&'))
    }
    onChangeFilter = (filter) => {
        clearTimeout(this.handleGetListFilter)

        filter['page'] = 1

        this.setState({filter})

        this.onCheckActiveFilter();

        this.setLocalStorageParams(filter);

        this.handleGetListFilter = setTimeout(() => {
            this.getSearchResult()
        }, 500)
    }
    onClearFitter = () => {
        let filter = {...this.state.filter};

        filter['sensor_ids'] = [];

        this.setState({filter});

        this.onCheckActiveFilter(filter);
        this.setLocalStorageParams(filter);

        this.handleGetListFilter = setTimeout(() => {
            this.getSearchResult()
        }, 500)
    }
    onChangeFilterTable = (filter) => {
        clearTimeout(this.handleGetListFilter)

        this.setState({filter})

        this.handleGetListFilter = setTimeout(() => {
            this.getSearchResult()
        }, 500)
    }

    getLocalStorageParams = () => {
        let storageFilterWanted = localStorage.getItem('storage_filter_wanted');

        if (storageFilterWanted) {
            storageFilterWanted = JSON.parse(storageFilterWanted);
        }

        return storageFilterWanted
    }
    setLocalStorageParams = (filter = this.state.filter) => {
        localStorage.setItem('storage_filter_wanted', JSON.stringify(filter));
    }
    // ---

    // list wanted
    onLoadListWanted = () => {
        let filter = {
            limit: 999,
            page: 0
        };
        let newFilter = this.state.filter;


        axios('post', '/search/list/get-all', filter).then(response => {
            let listWanted = response.data;
            let localStorageParams = this.getLocalStorageParams();


            if (!listWanted) {
                listWanted = [];
            }

            listWanted.map(item => {
                if (localStorageParams && localStorageParams.list_ids.length > 0) {
                    if (localStorageParams.list_ids.indexOf(item.id) > -1) {
                        newFilter.list_ids.push(item.id)
                    }
                } else {
                    newFilter.list_ids.push(item.id)
                }
            })

            this.setState({
                listWanted,
                filter: newFilter,
                onLoadWantedList: true
            })

            this.getSearchResult()
        })
    }
    onChangeListWanted = (listWanted) => {
        this.setState({
            listWanted
        })
    }
    // ---

    // list personal search
    onLoadListPersonalSearch = () => {
        let filter = {
            limit: 999,
            page: 1,
            type: 20
        };
        let newFilter = this.state.filter;

        axios('post', '/search/list/get-all', filter).then(response => {
            let listPersonalSearch = response.data;
            let localStorageParams = this.getLocalStorageParams();

            if (!listPersonalSearch) {
                listPersonalSearch = []
            }

            listPersonalSearch.map(item => {
                if (localStorageParams && localStorageParams.list_ids.length > 0) {
                    if (localStorageParams.list_ids.indexOf(item.id) > -1) {
                        newFilter.list_ids.push(item.id)
                    }
                } else {
                    newFilter.list_ids.push(item.id)
                }
            })

            this.setState({
                listPersonalSearch,
                onLoadSearchList: true,
                filter: newFilter
            })
        }).catch(error => {})
    }
    onOpenCreatePersonalSearch = (list_id) => {
        let createNewListPersonalSearch = this.state.createNewListPersonalSearch;

        createNewListPersonalSearch['list_id'] = list_id;

        this.setState({
            createNewListPersonalSearch,
            openModalCreate: true
        })
    }
    onCreateListPersonalSearch = () => {
        this.setState({
            isLoading: true,
            openModalCreate: false
        });

        store.addNotification({
            title: 'Успешно',
            message: 'Персональный список изменен',
            type: 'success',
            insert: 'top',
            container: 'bottom-left',
            dismiss: {
                duration: 3000,
                onScreen: false,
                pauseOnHover: true,
                delay: 0
            }
        });
    }
    // ---

    // notification
    connectWebSocket = (showNotification) => {
        if (window["WebSocket"]) {
            const sessionId = getLocalStorage('session_id');
            let apiHost = varibles.API_URL;
            let self = this;
            apiHost = apiHost.split('//')[1];

            this.webSocketNotification = new WebSocket(`wss://${apiHost}/notifications/stream?token=${sessionId}`);
            this.webSocketNotification.onmessage = function (evt) {
                self.onMessageWebSocket(evt)
            };
            this.webSocketNotification.error = function (error) {};
            this.webSocketNotification.onclose = function () {
                self.closeWebSocket()
            }
            this.webSocketNotification.onopen = function () {
                if (showNotification) {
                    store.addNotification({
                        title: 'Успешно',
                        message: 'Уведомления включены.',
                        type: 'success',
                        insert: 'top',
                        container: 'bottom-left',
                        dismiss: {
                            duration: 3000,
                            onScreen: false,
                            pauseOnHover: true,
                            delay: 0
                        }
                    });
                }
            }

            window.onbeforeunload = function () {
                self.closeWebSocket()
            };
        } else {}
    }
    closeWebSocket = (evt) => {
        this.webSocketNotification.close();

        store.addNotification({
            title: 'Успешно',
            message: 'Уведомления выключены.',
            type: 'success',
            insert: 'top',
            container: 'bottom-left',
            dismiss: {
                duration: 3000,
                onScreen: false,
                pauseOnHover: true,
                delay: 0
            }
        });
    }
    onMessageWebSocket = (event) => {
        var audio = new Audio(notificationSound);

        let notifications = JSON.parse(event.data)
        for (let i = 0; i < notifications.length; i++) {
            let notification = notifications[i];

            if (notification.type === 'search') {
                if (this.state.settings.soundOn) {
                    audio.play();
                }

                let newNotification = this.props.notification;
                let payload = notification.payload;

                let notificationData = {
                    title: notifications.title,
                    mark: `${payload.item.mark} ${payload.item.model}`,
                    goznak: payload.item.license_plate,
                    type: payload.item.license_plate.type,
                    travel: payload.report.address,
                    deviceCode: payload.report.sensor_serial,

                    dateTime: payload.report.datetime,

                    images: payload.report.images,

                    notification_id: payload.id,
                    list_id: payload.list_id,
                }

                store.addNotification({
                    content: (
                        <ContentNotification
                            notification={notificationData}
                            click={() => {
                                this.props.history.push(`/notification-lists-coincidences/${payload.list_id}?item_id=${payload.id}`)
                            }}
                        />
                    ),
                    onScreen: false,
                    insert: 'top',
                    container: 'top-right',
                    dismiss: {
                        duration: 3000,
                        pauseOnHover: true
                    },
                });

                newNotification.unshift(notificationData);

                this.props.setNotification(newNotification)


                if (this.props.match.path != '/notification') {
                    this.props.setCountBadgeWanted(newNotification.length + 1)
                }
            }
        }
    }

    onCheckNewWanted = () => {
        if (window["WebSocket"]) {
            const sessionId = getLocalStorage('session_id');
            let apiHost = varibles.API_URL;
            let self = this;
            apiHost = apiHost.split('//')[1];

            this.webSocketNotification = new WebSocket(`wss://${apiHost}/notifications/stream?token=${sessionId}`);
            this.webSocketNotification.onmessage = function (evt) {
                self.onMessageUpdateWebSocket(evt)
            };

            window.onbeforeunload = function () {
                self.closeWebSocket()
            };
        }
    }
    onMessageUpdateWebSocket = (event) => {
        let notifications = JSON.parse(event.data)
        for (let i = 0; i < notifications.length; i++) {
            let notification = notifications[i];

            if (notification.type === 'search') {
                if (this.props.match.path == '/notification' && this.state.settings.isUpdatePage) {
                    let filter = {...this.state.filter};
                    let listNewWantedItems = [...this.state.listNewWantedItems];
                    let payload = notification.payload;

                    filter['page'] = 1;

                    listNewWantedItems.push(payload.id)

                    this.setState({
                        listNewWantedItems
                    })

                    this.onChangeFilter(filter)
                }
            }
        }
    }
    // ---

    // settings
    onChangeSettings = (settings, showNotification = true) => {
        let localWantedSetting = getLocalStorage('local-wanted-setting');

        if (!localWantedSetting) {
            localWantedSetting = {};
        } else {
            localWantedSetting = JSON.parse(localWantedSetting);
        }

        if (settings.notificationOn != this.state.settings.notificationOn) {
            if (settings.notificationOn) {
                this.connectWebSocket(showNotification)
            } else {
                this.closeWebSocket()
            }
        }
        if (settings.soundOn != this.state.settings.soundOn) {
            if (settings.soundOn) {
                if (showNotification) {
                    store.addNotification({
                        title: 'Успешно',
                        message: 'Звуковое уведомление включено.',
                        type: 'success',
                        insert: 'top',
                        container: 'bottom-left',
                        dismiss: {
                            duration: 3000,
                            onScreen: false,
                            pauseOnHover: true,
                            delay: 0
                        },
                        limit: 1
                    });
                }
            } else {
                store.addNotification({
                    title: 'Успешно',
                    message: 'Звуковое уведомление выключено.',
                    type: 'success',
                    insert: 'top',
                    container: 'bottom-left',
                    dismiss: {
                        duration: 3000,
                        onScreen: false,
                        pauseOnHover: true,
                        delay: 0
                    },
                    limit: 1
                });
            }
        }

        localWantedSetting = settings;

        setLocalStorage('local-wanted-setting', JSON.stringify(localWantedSetting))
        this.setState({settings})
    }
    onCheckActiveFilter = (filter = this.state.filter) => {
        let {sensor_ids} = filter;

        this.setState({
            isActiveFilter: sensor_ids.length > 0
        })
    }
    // ---

    // export document
    exportDocument = async (body) => {
        let data = {};

        data['type'] = body.type;
        data['encoding'] = Number(body.encoding);
        data['list_id'] = this.state.filter.list_id;

        const res = await axios('post', '/export/create', data).then(response => {
            return response.data
        }).catch(error => {
          return {error: error.response}
        });
        if (res.error) {
          store.addNotification({
            title: 'Ошибка',
            message: res.error?.data?.details,
            type: 'danger',
            insert: 'top',
            container: 'bottom-left',
            dismiss: {
              duration: 3000,
              onScreen: false,
              pauseOnHover: true,
              delay: 0
            }
          });
          return
        }

        store.addNotification({
        title: 'Успешно',
        message: 'Задача на выгрузку успешно создана',
        type: 'success',
        insert: 'top',
        container: 'bottom-left',
        dismiss: {
          duration: 3000,
          onScreen: false,
          pauseOnHover: true,
          delay: 0
        }
      });
        // this.props.history.push('/unloading-tasks');
    }
    //

    render() {
        const {classes} = this.props;
        return (
            <Page title={'Устройства'}>
                <Grid container justify={'center'}>
                    <Grid item xs={12} spacing={3}>
                        <Box mb={3}>
                            <Typography className={classes.pageTitle}>Уведомления</Typography>
                        </Box>
                    </Grid>

                    <Box mb={4} style={{width: '100%'}}>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} className={classes.headerControlLeft}>
                                    <Filter
                                        filter={this.state.filter}
                                        onChange={this.onChangeFilter}

                                        settings={{...this.state.settings}}
                                        onChangeSettings={this.onChangeSettings}

                                        sensorList={this.state.sensorList}
                                        sensorFilter={this.state.sensorFilter}
                                        onFilterSensor={(filter) => this.onFilterSensor(filter)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Box mt={2}>

                            <ExportCreate
                                onExportFile={this.exportDocument}
                            />

                        </Box>

                    </Box>

                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={8} style={{marginTop: -8}}>

                                {
                                    (this.state.isActiveFilter) && (
                                        <Box mb={3}>
                                            <Alert severity="info" className={classes.alertMessageFitler}>
                                                <Grid container spacing={2} alignItems={'center'} style={{flex: 1}}>
                                                    <Grid item>
                                                        <Typography variant={'h5'}>
                                                            Ведется работа с уточняющими параметрами:
                                                            {this.state.filter.sensor_ids.length > 0 && (
                                                                <b>&nbsp;Выбранно устройств
                                                                    — {this.state.filter.sensor_ids.length}</b>)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item style={{marginLeft: 'auto'}}>
                                                        <Tooltip title={'Сбросить'}>
                                                            <IconButton style={{padding: 0}}
                                                                        onClick={this.onClearFitter}>
                                                                <XIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </Alert>
                                        </Box>
                                    )
                                }

                                <Grid container justify={'space-between'} wrap={'nowrap'}>
                                    <Box style={{display: 'flex', alignItems: 'center'}}>
                                        <Typography variant={'formTitle'}>
                                            Работа с поступающими данными ведется в режиме реального времени
                                        </Typography>

                                        <Tooltip title={
                                            <React.Fragment>
                                                <Typography color="inherit" style={{maxWidth: 400}}>
                                                    Для поиска по всем материалам в системе, необходимо использовать
                                                    главный раздел сервиса
                                                </Typography>
                                            </React.Fragment>
                                        }>
                                            <HelpOutlineIcon style={{marginTop: -5, marginLeft: 8}}/>
                                        </Tooltip>
                                    </Box>
                                    <Box>
                                        <div className={classes.helpInformation}>
                                            <div
                                                className={clsx([classes.helpInformationRound, classes.helpInformationRoundAverage])}></div>
                                            <div className={classes.helpInformationText}>Новые записи</div>
                                        </div>
                                    </Box>
                                </Grid>

                                <Table
                                    columns={this.state.columns}
                                    showColumns={this.state.showColumns}
                                    rows={this.state.rows}
                                    pagination={this.state.pagination}
                                    filter={this.state.filter}
                                    loading={this.state.tableLoader}
                                    listWanted={this.state.listWanted}

                                    listNewWantedItems={this.state.listNewWantedItems}

                                    isChangeRowPage={this.isChangeRowPage}
                                    isChangeFilter={this.onChangeFilterTable}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <PersonalSearchList
                                    filter={this.state.filter}
                                    personalSearch={this.state.personalSearch}

                                    onChangeFilter={this.onChangeFilter}

                                    onOpenCreatePersonalSearch={this.onOpenCreatePersonalSearch}
                                    listPersonalSearch={this.state.listPersonalSearch}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>


                <ModalCreateListPersonalSearch
                    open={this.state.openModalCreate}
                    onClose={() => {
                        this.setState({openModalCreate: false})
                    }}

                    form={this.state.createNewListPersonalSearch}
                    onCreateList={this.onCreateListPersonalSearch}
                />
            </Page>
        );
    }
}

const styles = {
    pageTitle: {
        fontWeight: '500',
        fontSize: 30,
        lineHeight: '100%',
        fontFeatureSettings: "'ss01' on",
        color: '#152435',
    },

    headerControlLeft: {
        [theme.breakpoints.between(0, 1599)]: {
            maxWidth: '100%',
            flexBasis: 'auto'
        }
    },
    headerControlRight: {
        [theme.breakpoints.between(0, 1599)]: {
            display: 'none'
        },
    },

    alertMessageFitler: {
        '& .MuiAlert-message': {
            flex: 1
        }
    },

    helpInformation: {
        display: 'flex',
        alignItems: 'center'
    },
    helpInformationRound: {
        width: 18,
        height: 18,
        borderRadius: '100%',
        marginRight: 8
    },
    helpInformationText: {
        fontSize: 14,
        lineHeight: '100%',
        fontFeatureSettings: "'ss01' on",
        color: '#647083'
    },
    helpInformationRoundBig: {
        background: '#FFD8D6',
        border: '1px solid #F48F89'
    },
    helpInformationRoundAverage: {
        background: '#FFDDBE',
        border: '1px solid #F8B578'
    },
};

export default withStyles(styles)(WantedList)
