import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,

    Box,
    Grid,
    Button,
    IconButton,
    Typography
} from "@material-ui/core";
import {
    makeStyles
} from "@material-ui/styles";
import {
    X as XIcon
} from "react-feather";
import {Map, Marker, TileLayer} from "react-leaflet";
import {
    Image
} from "../../../../../components";

import varibles from "../../../../../varibles";
import moment from "moment";

const DialogNotification = (props) => {
    const {isOpen, notification} = props;

    const classes = useStyles();

    let keyImages = !!notification ? Object.keys(notification?.payload?.report?.images) : [];
    if (keyImages.length >= 2) {
        keyImages = keyImages.splice(0, 2);
    }

    const position = [notification?.payload?.report?.gps?.lat, notification?.payload?.report?.gps?.lon];

    return (
        <Dialog
            open={isOpen}

            maxWidth="lg"

            fullWidth

            onClose={props.onClose}
        >

            <DialogTitle>
                <Grid container alignItems="center" justify="space-between">
                    <Grid item>
                        <Typography variant="h3">Сработка по номеру "{notification?.payload?.item?.license_plate}"</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton style={{ padding: 5 }} onClick={props.onClose}>
                            <XIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>

                <Box mb={2}>
                    <Grid container spacing={2} style={{minHeight: 400}}>
                        {
                            [...keyImages, "map"].map((key, idx) => {
                                const width = 12 / (keyImages.length + 1);
                                if (key === "map") {
                                    return (
                                        <Grid item xs={width}>
                                            <Map center={position} length={4} zoom={14}
                                                 style={{width: '100%', height: '100%', borderRadius: 4}}>
                                                <TileLayer
                                                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                    url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'/>
                                                <Marker position={position}/>
                                            </Map>
                                        </Grid>
                                    )
                                }

                                return (
                                    <Grid item xs={width}>
                                        <Image
                                            classContainer={classes.image}
                                            src={`${varibles.IMAGE_MOBILE_URL}${notification?.payload?.report?.images?.[key]}`}
                                        />
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Box>

                <Box p={2} borderRadius={4} bgcolor="#f3f5f9" >
                    <Grid container spacing={2} wrap="nowrap">
                        <Grid item style={{ maxWidth: 300 }}>
                            <Typography className={classes.informationTitle}>Список уведомлений</Typography>
                            <Typography className={classes.informationMessage}>{ notification?.title }</Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.informationTitle}>Время фиксации</Typography>
                            <Typography className={classes.informationMessage}>{ moment(notification?.created).format('DD.MM.YYYY') }</Typography>
                            <Typography className={classes.informationMessage}>{ moment(notification?.created).format('HH:mm:ss') }</Typography>
                        </Grid>
                        <Grid item style={{ maxWidth: 300 }}>
                            <Typography className={classes.informationTitle}>Комментарий</Typography>
                            <Typography className={classes.informationMessage}>{ notification?.payload?.item?.comment }</Typography>
                        </Grid>
                        <Grid item style={{ maxWidth: 300 }}>
                            <Typography className={classes.informationTitle}>Местоположение</Typography>
                            <Typography className={classes.informationMessage}>{ notification?.payload?.report?.address }</Typography>
                        </Grid>
                    </Grid>
                </Box>

            </DialogContent>

        </Dialog>
    )
};

const useStyles = makeStyles((theme) => ({
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'content',
        borderRadius: 4,
        overflow: 'hidden'
    },

    informationTitle: {
        fontSize: 14,
        lineHeight: '18px',
        marginBottom: 6,
        color: '#8698B1'
    },
    informationMessage: {
        fontSize: 16,
        lineHeight: '21px',
        color: "#152435",
        fontWeight: '500'
    },
}));

export default DialogNotification
