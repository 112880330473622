import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Drawer,
  IconButton
} from '@material-ui/core';
import { SidebarNav } from './components';
import {
  X as XIcon
} from 'react-feather';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 270,
    boxShadow: '6px 0px 18px rgba(0, 0, 0, 0.06)',
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    },
    [theme.breakpoints.between(0, 1599)]: {
      width: 224,
    },
    [theme.breakpoints.between(0, 1399)]: {
      width: 270,

      marginTop: 0,
      height: '100%'
    },
  },
  drawerClose: {
    width: 70,
    boxShadow: '6px 0px 18px rgba(0, 0, 0, 0.06)',
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    },
  },

  root: {
    backgroundColor: '#f4f6f8',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '23px 10px'
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  },

  containerCloseSidebarHeader: {
    display: 'none',
    justifyContent: 'flex-end',
    marginTop: -10,
    marginBottom: 14,


    [theme.breakpoints.between(0, 1399)]: {
      display: 'flex',
    },
  }
}));

const Sidebar = props => {
  const { open, setOpenSidebarLeft, variant, onClose, className, dashboard, setFavorites, favorites, personalListId, ...rest } = props;
  const classes = useStyles();

  return (
    <Drawer
      anchor="left"
      classes={{ paper: (open)? classes.drawer: classes.drawerClose }}
      onClose={setOpenSidebarLeft}
      open={(variant === 'persistent') ? true : open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <div className={classes.containerCloseSidebarHeader}>
          <IconButton onClick={setOpenSidebarLeft} style={{ padding: 0 }}>
            <XIcon/>
          </IconButton>
        </div>

        <SidebarNav
          className={classes.nav}
          dashboard={dashboard}
          open={open}
          setOpenSidebarLeft={setOpenSidebarLeft}
          setFavorites={setFavorites}
          favorites={favorites}
          personalListId={personalListId}
        />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
