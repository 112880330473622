import React, { useState, useEffect } from "react";
import {
    Box
} from "@material-ui/core";
import {
    makeStyles
} from "@material-ui/styles";
import {
    Map,
    Popup,
    Marker,
    TileLayer
} from "react-leaflet";
import {
    divIcon,
    latLngBounds
} from "leaflet";
import {renderToStaticMarkup} from "react-dom/server";

const MapComponent = (props) => {
    const { data } = props;
    const [mapCenter, setMapCenter] = useState({lat: 56.8519000, lon: 60.6122000});
    const [bounds, setBounds] = useState(null);
    const classes = useStyles();

    useEffect(() => {
        initMapCenter();
        initBounds();
    }, [data]);

    // Инициализация центра карта (Екатеринбург, Оренбург)
    const initMapCenter = () => {
        let envMapCenter = process.env.REACT_APP_MAP_CENTER;

        if (!envMapCenter) {
            return null
        }

        setMapCenter({
            lat: Number.parseFloat(envMapCenter.split(',')[0]),
            lon: Number.parseFloat(envMapCenter.split(',')[1]),
        })
    }

    // Получение центра карты
    const initBounds = () => {
        const gps = data.map((item) => {
            return [item.gps.lat, item.gps.lon]
        })
        const bounds = latLngBounds([...gps]);

        setBounds(bounds);
    }

    return (
        <Box height="1200px" maxHeight="calc(100vh - 185px)" display="flex" borderRadius={4} overflow="hidden" className={classes.map}>
            <Map
                style={{flex: 1}}
                center={mapCenter}
                length={4}
                zoom={12}
                bounds={bounds}
                boundsOptions={{
                    maxZoom: 14
                }}
            >

                {
                    data.map((marker, idx) => (
                        <MarketPoint
                            key={`frequent-stops-marker-point-${idx}`}
                            marker={marker}
                        />
                    ))
                }

                <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                />
            </Map>
        </Box>
    )
}

const MarketPoint = (props) => {
    const { marker } = props;
    const styles = useStyles();
    const position = marker.gps;

    const _renderMarker = () => {
        return renderToStaticMarkup(
            <div
                className={styles.marker}
                style={{
                    background: `${marker.color}`,
                    boxShadow: `0 0 25px 25px ${marker.color}`
                }}
            >
                <span className={styles.markerTitle}>{ marker.frequency }</span>
            </div>
        )
    }

    return (
        <Marker
            position={position}
            icon={divIcon({
                html: _renderMarker(),
                style: "background: transparent",
                iconSize: [40, 40]
            })}
        >
            <Popup>{ marker.address }</Popup>
        </Marker>
    )
}

const useStyles = makeStyles(() => ({
    leaflet: {
        backgroundColor: "transparent"
    },
    marker: {
        width: 40,
        height: 40,

        borderRadius: 999,

        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    markerTitle: {
        fontSize: '16px',
        fontWeight: "bold",
        color: "white"
    },

    map: {
        '& .leaflet-div-icon': {
            backgroundColor: 'transparent!important',
            border: 'none!important'
        },

        '& .leaflet-popup': {
            marginBottom: 40
        }
    },
}));

export default MapComponent
