// @flow
import {compose} from 'recompose';
import {connect} from 'react-redux';

import TransportConnectionRoute from './TransportConnectionRoute';

export default compose(
  connect(
    state => ({}),
    dispatch => ({}),
  ),
)(TransportConnectionRoute);
