import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  Map,
  TileLayer
} from 'react-leaflet'
import L from 'leaflet';
import 'leaflet-polylinedecorator';
import PolylineDecorator from './PolylineDecorator';
import Points from './Points';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
  leafletContainer: {
    width: '100%',
    height: '100%'
  },
  leafletNotDots: {
    '& .leaflet-interactive': {
      display: 'none'
    },
    '& .marker, .marker-cluster': {
      display: 'flex!important'
    },
  },
}));

const MapObject = (props) => {
  const arrow = [{
    offset: '95%',
    repeat: 0,
    symbol: L.Symbol.arrowHead({
      pixelSize: 20,
      pathOptions: {
        stroke: false,
        strokeColor: '#212529',
        weight: 10,
        fill: true,
        fillColor: '#212529',
        fillOpacity: 1,
        fillRule: ''
      }
    })
  }];
  const classes = useStyles();
  const {
    position, points, apiPoints,
    showCluster, openInfoMarker, connectDots,
    showPointNumbers, zoom, activeIndexLinePoint,
    onMoveend, isLoading, refMap
  } = props;

  const getListPolyline = function () {
    let list = []

    apiPoints.map((point, idx) => {
      if (idx < apiPoints.length - 1) {
        let currentGps = [point.gps.lat, point.gps.lon];
        let nextGps = [apiPoints[idx + 1].gps.lat, apiPoints[idx + 1].gps.lon];
        list.push([currentGps, nextGps])
      }
    })

    return list
  }
  const handleGetBounds = () => {
    let bounds = [];

    points.map(point => {
      bounds.push([
        point.position.lat,
        point.position.lon
      ])
    })

    if (bounds.length <= 0) {
      return null
    }

    return bounds
  }

  if (isLoading){
    return (
      <></>
    )
  }

  return (
    <Map
      ref={refMap}
      className={clsx({
        [classes.leafletContainer]: true,
        [classes.leafletNotDots]: !connectDots
      })}
      center={position}
      length={4}
      zoom={zoom}
      bounds={handleGetBounds()}
      zoomend={onMoveend()}
    >
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />

      <Points
        points={points}
        showCluster={showCluster}
        openInfoMarker={openInfoMarker}
        showPointNumbers={showPointNumbers}
        activeIndexLinePoint={activeIndexLinePoint}
      />

      {
        getListPolyline().map((polyline, idx) => (
          <PolylineDecorator
            key={'polyline-' + idx}
            patterns={arrow}
            interactive={false}
            positions={polyline}
            connectDots={connectDots}
          />
        ))
      }
    </Map>
  );
};

export default MapObject;
