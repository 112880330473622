import React from 'react';
import {useLocation, matchPath} from 'react-router';
import clsx from 'clsx';
import {Link as RouterLink} from 'react-router-dom';
import {
  Badge,
  Button
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {Notifications as NotificationsIcon} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {},

  button: {
    height: 64,
    color: 'white',
    padding: '10px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    borderRadius: 0,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: 16,
      lineHeight: '16px',
      fontFeatureSettings: "'ss01' on",

      [theme.breakpoints.between(0, 1599)]: {
        fontSize: 14
      }
    },

    [theme.breakpoints.between(0, 1399)]: {
      padding: '10px 8px',
    }
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium
      }
    }
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),

    [theme.breakpoints.between(0, 1599)]: {
      marginRight: 4,
    },
    [theme.breakpoints.between(0, 1399)]: {
      width: 18
    },
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    background: '#040405'
  },

  iconActive: {
    opacity: '1!important',

    '& > *': {
      opacity: '1!important'
    },
    '& > * > *': {
      opacity: '1!important'
    },
    '& > * > * > *': {
      opacity: '1!important'
    },
  },
}));
const useStylesMenu = makeStyles((theme) => ({
  root: {},

  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',

    '& $title': {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: 16,
      lineHeight: '16px',
      fontFeatureSettings: "'ss01' on",
      color: '#152435'
    },
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium
      }
    }
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: theme.palette.secondary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.secondary.main
    }
  },
}));

const NavItem = (props) => {
  const {Icon, title, href, menu, keyName, badge} = props;
  const location = useLocation();
  const classesTop = useStyles();
  const classesMenu = useStylesMenu();
  const classes = (menu) ? classesMenu : classesTop;

  let pathname = location.pathname;

  const open = matchPath(pathname, {
    path: href,
    exact: false
  });

  if (badge && badge > 0) {
    return (
      <Badge
        badgeContent={badge}
        color='secondary'
      >
        <Button
          key={keyName}
          to={href}
          component={RouterLink}
          className={(Boolean(open)) ? clsx(classes.button, classes.active) : classes.button}
          exact='true'
        >
          {Icon && (
            <Icon
              className={clsx({
                [classes.icon]: true,
                [classes.iconActive]: Boolean(open),
              })}
            />
          )}
          <span className={classes.title}>{title}</span>
        </Button>
      </Badge>
    )
  }

  return (
    <Button
      key={keyName}
      to={href}
      component={RouterLink}
      className={(Boolean(open)) ? clsx(classes.button, classes.active) : classes.button}
      exact='true'
    >
      {Icon && (
        <Icon
          className={clsx({
            [classes.icon]: true,
            [classes.iconActive]: Boolean(open),
          })}
        />
      )}
      <span className={classes.title}>
        {title}
      </span>
    </Button>
  )
}

export default NavItem;
