import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,

  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,

  Box,
  Grid,
  Button,
  Tooltip,
  TextField,
  Typography,
  IconButton
} from "@material-ui/core";
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from "@material-ui/icons";
import axiosAgent from "../../plugins/axios";
import {clear} from "core-js/internals/task";

class ModalSensorMap extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      fullItems: [],
      selected: [],
      isOpen: false
    };
    this.filter = {address: "", direction: ""};
    this.timeOutFilter = null;
  }

  componentDidMount = async () => {
    await this.initSelected();
  }

  open = () => {
    this.setState({
      isOpen: true
    }, async () => {
      await this.initSelected();
      await this.getItems();
    });
  }
  close = () => {
    this.setState({
      isOpen: false
    })
    this.props.onClose();
  }

  getItems = async () => {
    const res = await axiosAgent('post', '/operator/sensor/find-cameras', {
      limit: 999,
      status: 10,
      with_cameras: true
    }).then((res) => {
      return res.data
    }).catch(() => {
      return []
    });
    this.setState({
      items: res,
      fullItems: res
    });
  }

  initSelected = () => {
    const selected = localStorage.getItem('camera_dashboard') || '[]';
    this.setState({
      selected: JSON.parse(selected).map((t) => t?.id).filter((t) => !!t)
    })
  }
  changeSelected = (id) => {
    let selected = [...this.state.selected];
    if (selected.includes(id)) {
      selected.splice(selected.findIndex((t) => t === id), 1)
    } else {
      selected.push(id);
    }
    this.setState({ selected })

    const cameraDashboard = (this.state.items || []).filter((item) => selected.includes(item.id));
    localStorage.setItem('camera_dashboard', JSON.stringify(cameraDashboard));
  }

  changeFilter = ({ target }) => {
    clearTimeout(this.timeOutFilter);
    const { name, value } = target;
    let filter = {...this.filter};
    filter[name] = value;
    this.filter = filter;
    this.timeOutFilter = setTimeout(() => {
      this.itemsFilter();
    }, 1000);
  }
  itemsFilter = () => {
    const address = String(this.filter.address || '').toLowerCase();
    const direction = String(this.filter.direction || '').toLowerCase();

    const items = [...this.state.fullItems]
      .filter((item) => {
        const itemAddress = String(item?.address || '').toLowerCase();
        const itemDirection = String(item?.direction || '').toLowerCase();

        const isAddress = Boolean(!address) ? true : String(itemAddress).includes(address);
        const isDirection = Boolean(!direction) ? true : String(itemDirection).includes(direction);

        return Boolean(isAddress && isDirection)
      });

    this.setState({ items });
  }

  render () {
    const {
      items,
      selected,

      isOpen
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth="md"
        onClose={this.close}
      >
        <DialogTitle>
          <Typography variant="h3">Камеры</Typography>
        </DialogTitle>
        <DialogContent>

          <Box mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  name="address"
                  label="Местоположение"
                  variant="outlined"
                  fullWidth
                  onChange={this.changeFilter}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="direction"
                  label="Направление"
                  variant="outlined"
                  fullWidth
                  onChange={this.changeFilter}
                />
              </Grid>
            </Grid>
          </Box>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Местоположение</TableCell>
                <TableCell>Направление</TableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => <TableRowData
                key={`TableRowData-${item.id}`}
                item={item}
                isSelected={selected.includes(item.id)}
                onChange={this.changeSelected.bind(this, item.id)}
              />)}
            </TableBody>
          </Table>

        </DialogContent>
        <DialogActions>
          <Box px={2}>
            <Grid container justify="flex-end">
              <Grid item>
                <Button variant="contained" onClick={this.close}>
                  Применить
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      </Dialog>
    )
  }
}
const TableRowData = React.memo((props) => {
  const {
    item,
    isSelected,
    onChange
  } = props;

  return (
    <TableRow>
      <TableCell>{ item.address }</TableCell>
      <TableCell>{ item.direction }</TableCell>
      <TableCell>
        <Grid container justify="flex-end">
          <Grid item>
            <Tooltip title={isSelected ? 'Убрать с мультиэкрана' : 'Добавить на мультиэкран'} arrow>
              <IconButton onClick={onChange}>
                {Boolean(isSelected) ? <VisibilityOffIcon/> : <VisibilityIcon/>}
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  )
})

export default ModalSensorMap
