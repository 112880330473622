import React from 'react';
import {
  Grid,
  Switch,
  Select,
  Button,
  MenuItem,
  Typography,
  makeStyles,
  FormControl,
  Tooltip,
  FormControlLabel,
} from '@material-ui/core';
import {
  CustomCheckbox
} from '../../../../../components';

const useStyles = makeStyles(theme => ({
  divider: {
    height: 25,
    width: 1,
    backgroundColor: '#8698B1',
    opacity: 0.2,
    margin: '0 16px'
  },

  titleDirection: {
    marginRight: 12,

    fontWeight: '500',
    fontSize: 15,
    lineHeight: '100%',
    fontFeatureSettings: "'ss01' on",
    color: '#152435',
  },
  selectDirection: {
    width: 170,
  },

  buttonSensor: {
    fontSize: 14,
    height: 30,
    padding: '8px 10px'
  },

  sensorFilter: {},

  switch: {
    marginLeft: 0,

    '& .MuiFormControlLabel-label': {
      fontSize: 15
    }
  },

  checkBox: {
    marginLeft: 16,

    '& .MuiCheckbox-root': {
      padding: 0,
      marginLeft: 6
    },
    '& .MuiFormControlLabel-root': {
      flexDirection: 'row-reverse',
      margin: 0
    },
    '& .MuiCheckbox-root .MuiIconButton-label svg': {
      width: 20,
      height: 20
    },
    '& .MuiFormControlLabel-label': {
      fontSize: 15,
      lineHeight: '24px',
      color: '#647083',
      fontWeight: 'normal'
    },
    '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label svg': {
      backgroundColor: 'white!important',
      padding: 2,
      stroke: '#212529'
    }
  }
}))

const Filter = (props) => {
  const {
    filter,
    onChange,

    settings,
    onChangeSettings,

    sensorList,
    sensorFilter,
    onFilterSensor
  } = props;
  const classes = useStyles();

  const handleChangeFilter = (event) => {
    let newFilter = filter;
    let item = event.target;
    let name = item.name;
    let value = item.value;

    newFilter[name] = value;

    onChange(newFilter)
  }
  const handleChangeSwitch = (value, name) => {
    let newFilter = filter;
    newFilter[name] = value;

    onChange(newFilter)
  }
  const handleChangeCheckbox = (value, name) => {
    let newFilter = filter;

    newFilter[name] = value;

    onChange(newFilter)
  }

  const handleChangeDevices = (name, list) => {
    let newFilter = filter;
    newFilter[name] = list;

    onChange(newFilter)
  }

  const handleChangeSwitchSettings = (value, name) => {
    let newSettings = settings;

    newSettings[name] = value;

    onChangeSettings(newSettings)
  }
  const handleChangeCheckboxSettings = (value, name) => {
    let newSettings = settings;

    newSettings[name] = value;

    onChangeSettings(newSettings)
  }

  return (
    <Grid container alignItems={'center'} wrap={'nowrap'}>
      <Grid item>
        <Tooltip
          title={(settings.isUpdatePage) ? 'Выключить обновление страницы при новых данных' : 'Включить обновление страницы при новых данных'}>
          <FormControlLabel
            control={
              <Switch
                checked={settings.isUpdatePage}
                name="soundOn"
                onChange={() => handleChangeSwitchSettings(!settings.isUpdatePage, 'isUpdatePage')}
              />
            }
            className={classes.switch}
            labelPlacement="start"
            label="Обновление контента"
          />
        </Tooltip>
      </Grid>

      <div className={classes.divider}></div>

      <Grid item>
        <Tooltip
          title={(settings.soundOn) ? 'Выключить звуковое сопровождение уведомлений' : 'Включить звуковое сопровождение уведомлений'}>
          <FormControlLabel
            control={
              <Switch
                checked={settings.soundOn}
                name="soundOn"
                onChange={() => handleChangeSwitchSettings(!settings.soundOn, 'soundOn')}
              />
            }
            className={classes.switch}
            labelPlacement="start"
            label="Звук"
          />
        </Tooltip>
      </Grid>

      <div className={classes.divider}></div>

      <Grid item>
        <Grid container alignItems={'center'} wrap={'nowrap'}>
          <Grid item>
            <Tooltip
              title={(settings.notificationOn) ? 'Выключить уведомления' : 'Включить уведомления'}>
              <FormControlLabel
                control={
                  <Switch
                    checked={settings.notificationOn}
                    onChange={() => handleChangeSwitchSettings(!settings.notificationOn, 'notificationOn')}
                    name="notificationOn"
                  />
                }
                className={classes.switch}
                labelPlacement="start"
                label="Уведомления"
              />
            </Tooltip>
          </Grid>
          {
            (false) && (
              <Grid item className={classes.checkBox}>
                <CustomCheckbox
                  checked={Boolean(settings.accumulate)}
                  name="accumulate"
                  color="primary"
                  label="Накапливаются"
                  onChange={() => handleChangeCheckboxSettings(!Boolean(settings.accumulate), 'accumulate')}

                  type={'circle'}
                />
              </Grid>
            )
          }
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Filter
