import React, {useRef} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box
} from '@material-ui/core';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {makeStyles} from '@material-ui/styles';
import {
  CustomMoment as Moment, CustomTablePagination, Image,
} from '../../../../../components';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import varibles from '../../../../../varibles';

const useStyles = makeStyles(theme => ({
  form: {
    marginBottom: 30
  },
  gridLines: {
    alignItems: 'flex-end'
  },

  tableSortLabel: {
    '& .MuiTableSortLabel-icon': {
      opacity: 0.5
    }
  },
  tableCellAction: {
    display: 'flex',
    alignItems: 'center'
  },
  tableRow: {
    cursor: 'pointer'
  },

  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: '#fafafa'
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },

  image: {
    width: 65,
    height: 65,
    marginTop: -5,
    marginBottom: -10,

    position: 'relative',
    zIndex: 555
  },

  stickyActionsColumn: {
    '& table:first-child': {
      '& tr': {
        '&:hover td:last-child': {
          backgroundColor: theme.palette.background.default,
        },
        '& td:last-child, th:last-child': {
          backgroundColor: 'white',
          position: 'sticky',
          right: 0,
          zIndex: 999
        },
        '& th:last-child': {
          backgroundColor: 'rgb(237, 241, 246)',
        },
        '& th:first-child': {
          zIndex: 9999
        }
      }
    }
  },

  ceilColor: {
    width: 20,
    height: 20,
    borderRadius: '100%',
    border: '1px solid rgba(25, 42, 62, 0.2)',
    marginRight: 8
  },

  lineActive: {
    '& td': {
      backgroundColor: 'rgba(255, 221, 190, 0.5)'
    }
  }
}));

const TableComponents = props => {
  const {
    rows,
    filter,
    isLoading,
    itemActive,
    isChangeFilter,
    isChangeRowPage,
    onOpenModalInformation
  } = props;
  let {pagination} = props;
  const classes = useStyles();
  const refTable = useRef(null);

  const handleChangePage = (event, page) => {
    let newFilter = filter;
    newFilter['page'] = page;

    isChangeFilter(newFilter);
  }
  const handleGetGrz = (grz) => {
    return (
      <div>{grz}</div>
    )
  }

  return (
    <>
      <CustomTablePagination
        rows={rows}
        filter={filter}
        pagination={pagination}
        onChangePage={(event, page) => handleChangePage(event, page + 1)}
      />

      <PerfectScrollbar className={'height-auto'}>
        <Box minWidth={1400}>
          <Table
            id={'basic-drive-table'}
            ref={refTable}
            className={'MuiTable-list'}
          >
            <TableHead className={'MuiTableHead-list'}>
              <TableRow>
                <TableCell align={'left'}>
                  Дата фиксации
                </TableCell>
                <TableCell align={'left'}>
                  Фото
                </TableCell>
                <TableCell align={'left'}>
                  ГРЗ
                </TableCell>
                <TableCell align={'left'}>
                  Местоположение
                </TableCell>
                <TableCell align={'left'}>
                  Направление
                </TableCell>
              </TableRow>
            </TableHead>

            {
              (!isLoading) ?
                (
                  <TableBody>
                    {rows.map((row, idx) => {
                      let active = row.id === itemActive;

                      return (
                        <TableRow
                          key={row.name + '-' + idx}
                          className={
                            clsx({
                              'MuiTableRow-list': true,
                              [classes.lineActive]: active
                            })
                          }
                          hover
                        >
                          <TableCell onClick={() => onOpenModalInformation(row)}>
                            <p><Moment format="DD.MM.YYYY">{row.report.datetime}</Moment></p>
                            <p><Moment format="HH:mm:ss">{row.report.datetime}</Moment></p>
                          </TableCell>
                          <TableCell>
                            <Image
                              src={varibles.IMAGE_URL + row.report.images.main}
                              classImage={classes.image}
                            />
                          </TableCell>
                          <TableCell onClick={() => onOpenModalInformation(row)}>
                            {handleGetGrz(row.item.license_plate)}
                          </TableCell>
                          <TableCell onClick={() => onOpenModalInformation(row)}>{row.report.address}</TableCell>
                          <TableCell onClick={() => onOpenModalInformation(row)}>{row.report.direction}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                )
                :
                (
                  <TableBody>
                    <TableRow className={'MuiTableRow-list'}>
                      <TableCell><Skeleton variant="rect"/></TableCell>
                      <TableCell><Skeleton variant="rect"/></TableCell>
                      <TableCell><Skeleton variant="rect"/></TableCell>
                      <TableCell><Skeleton variant="rect"/></TableCell>
                      <TableCell><Skeleton variant="rect"/></TableCell>
                    </TableRow>
                  </TableBody>
                )
            }
          </Table>
        </Box>
      </PerfectScrollbar>

      <CustomTablePagination
        rows={rows}
        filter={filter}
        pagination={pagination}
        onChangePage={(event, page) => handleChangePage(event, page + 1)}
      />
    </>
  );
};

TableComponents.propTypes = {
  columns: PropTypes.array,
  showColumns: PropTypes.array,
  rows: PropTypes.array,
  selected: PropTypes.array,
  pagination: PropTypes.object,
  isSelectRowTable: PropTypes.func,
  isDeleteRowTable: PropTypes.func,
  isChangeRowPage: PropTypes.func,
};
TableComponents.defaultProps = {
  showColumns: []
};

export default TableComponents;
