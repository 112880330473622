import React, { useState } from "react";
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,

    Box,
    Grid,
    Typography, IconButton, Tooltip, Collapse
} from "@material-ui/core";
import {
    Skeleton,
    Pagination
} from "@material-ui/lab";
import moment from "moment";
import {Image} from "../../../../../components";
import varibles from "../../../../../varibles";
import {ChevronDown as ChevronDownIcon, ChevronUp as ChevronUpIcon} from "react-feather";
import {makeStyles} from "@material-ui/styles";
import userRoles from "../../../../../constant/userRoles";
import {formatMoney} from "../../../../../helpers/format";
import {ExportCreate as ExportCreateComponent} from "../index";

const TableComponent = (props) => {
    const { data, pagination, filter, isLoading, onChangeFilter, exportDocument } = props;

    const handleChangePagination = (event, page) => {
        let newFilter = {...filter};

        newFilter.page = page;

        onChangeFilter(newFilter, true);
    }

    return (
        <>

            <Box mb={2}>
                <Grid container spacing={2}>
                    <Grid item>
                        <Typography variant="subtitle1" style={{fontWeight: "500"}}>Всего найдено: {formatMoney(pagination.totalItems, 0, '')} элементов</Typography>
                    </Grid>
                    <Grid item>
                        <ExportCreateComponent disabled={data.length <= 0} onExportFile={exportDocument}/>
                    </Grid>
                </Grid>
            </Box>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell width={40}></TableCell>
                        <TableCell width={50}>Метка</TableCell>
                        <TableCell width={150}>Фиксация</TableCell>
                        <TableCell width={140}>Фото</TableCell>
                        <TableCell width={100}>ГРЗ</TableCell>
                        <TableCell>Место фиксации</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {
                        isLoading && (
                            <RowLoading/>
                        )
                    }
                    {
                        !isLoading && (
                            <RowBody data={data}/>
                        )
                    }
                </TableBody>
            </Table>

            <Box mt={2}>
                <Grid container justify="flex-end">
                    <Grid item>
                        <Pagination
                            page={filter.page}
                            count={pagination.page}

                            onChange={handleChangePagination}
                        />
                    </Grid>
                </Grid>
            </Box>

        </>
    )
};

const RowLoading = () => {
    return (
        <TableRow>
            <TableCell width={40}><Skeleton/></TableCell>
            <TableCell><Skeleton/></TableCell>
            <TableCell><Skeleton/></TableCell>
            <TableCell><Skeleton/></TableCell>
            <TableCell><Skeleton/></TableCell>
            <TableCell><Skeleton/></TableCell>
        </TableRow>
    )
}
const RowBody = (props) => {
    const { data } = props;

    return (
        <>
            {
                data.map((row, idx) => {
                    return (
                        <ComponentRow {...row}/>
                    )
                })
            }
        </>
    )
}
const ComponentRow = (row) => {
    const [isOpen, setOpen] = useState(false);
    const classes = useStyles();

    const handleGetUrlImage = (images) => {
        let hostImage = varibles.IMAGE_MOBILE_URL;

        if (images){
            hostImage += images.main;
        }

        return hostImage
    }
    const handleOpen = () => {
        setOpen(!isOpen);
    }

    return (
        <>
            <TableRow>
                <TableCell width={40}>
                    <Tooltip title={isOpen ? 'Свернуть' : 'Развернуть'}>
                        <IconButton onClick={handleOpen}>
                            {isOpen ? <ChevronUpIcon/> : <ChevronDownIcon/>}
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell>
                    <Box width={20} height={45} borderRadius={2} bgcolor={row.color || '#212529'}/>
                </TableCell>
                <TableCell>
                    <Typography>{moment(row.created).format("DD.MM.YYYY")}</Typography>
                    <Typography>{moment(row.created).format("HH:mm:ss")}</Typography>
                </TableCell>
                <TableCell>
                    <Box width={100} height={100} borderRadius={4} overflow="hidden">
                        <Image src={handleGetUrlImage(row.report.images)}/>
                    </Box>
                </TableCell>
                <TableCell>
                    <Typography>{row.report.license_plate}</Typography>
                </TableCell>
                <TableCell>
                    <Typography>{row.report.address}</Typography>
                </TableCell>
            </TableRow>

            <TableCell colSpan={6} style={{ padding: 0 }}>
                <Collapse in={isOpen}>
                    <Box bgcolor="white" px={4} py={2} style={{ borderRadius: '0 0 10px 10px', marginBottom: 4 }}>
                        <Box mb={2}>
                            <Typography variant="subtitle1">Список уведомлений</Typography>

                            <Typography variant="body1">
                                {`Наименование - ${row?.list?.title}; Статус - ${row?.list?.status == 10 ? "Активный" : "Неактивный"};`}
                            </Typography>
                        </Box>
                        <Box mb={2}>
                            <Typography variant="subtitle1">Элемент уведомлений</Typography>

                            <Typography variant="body1">
                                {
                                    `
                                    Дата создания - ${moment(row?.item?.created).format("DD.MM.YYYY HH:mm")};
                                    Статус - ${row?.item?.status == 10 ? "Активный" : "Неактивный"};
                                    Цвет - ${row?.item?.color || 'Не задано'};
                                    `
                                }
                                <br/>
                                Комментарий - {row?.item?.comment || 'Не задано'};
                            </Typography>
                        </Box>
                    </Box>
                </Collapse>
            </TableCell>

            <div className={classes.separate}/>

        </>
    )
}

const useStyles = makeStyles(() => ({
    separate: {
        width: '100%',
        height: 1,
        backgroundColor: 'rgba(0, 0, 0, 0)'
    }
}));

export default TableComponent
