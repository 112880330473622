import React, {Component} from 'react';
import {
  Backdrop,
  Box,
  Button, CircularProgress,
  Grid,
  Tooltip,
  Typography,

} from '@material-ui/core';
import {
  Page
} from "../../../components";
import {
  Map,
  Table,
  Filter,
  WantedList,
  DialogNotification,
  DialogImageNotification
} from "./components";
import axios from "../../../plugins/axios";
import urls from "../../../constant/urls";
import notificationSound from "../../../assets/audio/notification-sound.mp3";
import clsx from "clsx";
import {withStyles} from "@material-ui/styles";

class WantedMobileList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sensors: [],
      sensorsInitial: [],
      wanted: [],
      notifications: [],

      activeFilter: {
        list_ids: [],
        sensor_ids: []
      },
      activeFilterInitial: {
        list_ids: [],
        sensor_ids: []
      },
      filterSensor: {
        address: '',
        direction: '',
        city: '',
        limit: 999,
        page: 1
      },
      filterWanted: {
        limit: 999,
        page: 1
      },
      lastNotification: {},
      modalNotification: null,

      view: "table",

      isShowBackdrop: false,
      isOpenNotification: false
    };

    this.isProcessing = false;
    this.refUpdateSensors = null;
    this.refMap = React.createRef();
  }

  componentDidMount = () => {
    this.getActiveFilter();
    this.getSensors(true);
    this.getWanted();

    const processing_mobile_wanted = localStorage.getItem("processing_mobile_wanted");
    if (processing_mobile_wanted) {
      this.onConnectSocket();
    }
  }
  componentWillUnmount = async () => {
    this.isProcessing = false;
    localStorage.removeItem("processing_mobile_wanted");
  }

  // Логика получени данные
  getActiveFilter = () => {
    axios('get', urls["mobile-get-filter"]).then((response) => {
      let activeFilter = {
        list_ids: response.data.list_ids || [],
        sensor_ids: response.data.sensor_ids || [],
      };

      this.setState({
        activeFilter,
        activeFilterInitial: JSON.parse(JSON.stringify({...activeFilter})),
        isShowBackdrop: false
      })
    });
  }
  getSensors = (initial = false) => {
    axios('post', urls["mobile-sensor-get-list"], this.state.filterSensor).then((response) => {
      this.setState({
        sensors: response.data || [],
        sensorsInitial: initial ? response.data || [] : this.state.sensorsInitial,
      })
    })
  }
  getWanted = () => {
    axios('post', urls["mobile-list-get-all"], this.state.filterWanted).then((response) => {
      this.setState({
        wanted: response.data || []
      })
    })
  }

  getSensorsFromFilter = () => {
    const filterSensor = {...this.state.filterSensor};
    const sensorsInitial = [...this.state.sensorsInitial];

    let sensors = sensorsInitial.filter((sensor) => {
      if (
        (sensor.city).toLowerCase().indexOf(filterSensor.city.toLowerCase()) > -1 &&
        (sensor.address).toLowerCase().indexOf(filterSensor.address.toLowerCase()) > -1
      ) {
        return sensor
      }
    });

    this.setState({
      sensors
    })
  };

  // Логика работы с фильтром
  onChangeFilterSensors = (filterSensor) => {
    this.setState({
      filterSensor
    }, () => {
      this.getSensorsFromFilter();
    })
  }
  onChangeActiveFilter = (activeFilter) => {
    this.setState({
      activeFilter: activeFilter
    })
  }

  onSetActiveFilter = () => {
    this.setState({isShowBackdrop: true})

    axios('post', urls["mobile-filter-set-filter"], this.state.activeFilter).then((response) => {
      this.getActiveFilter();
    }).catch((error) => {

    });
  }


  // Логика работы со сработкми
  onConnectSocket = () => {
    const session_id = localStorage.getItem('session_id');
    const url = `${urls["web-socket"]}?mobile=1&token=${session_id}`;
    const self = this;

    this.setState({isProcessing: true});
    this.isProcessing = true;
    localStorage.setItem("processing_mobile_wanted", "1");

    let webSocketNotification = new WebSocket(url);
    webSocketNotification.error = function (error) {
      self.onConnectSocket();
    };
    webSocketNotification.onmessage = async (event) => {
      const self = this;

      if (event.data === '{}') {
        return null
      }

      const data = JSON.parse(event.data);

      if (!data || data.length <= 0) {
        return null
      }
      if (!self.isProcessing || !self.state.isProcessing) {
        return null
      }

      if (self.state.view === 'map') {
        self.addMapNotification(data);
      }
      if (self.state.view === 'fullScreen') {
        data.map((lastNotification) => {
          self.setState({
            lastNotification
          })
        })
      }

      const notifications = [...data, ...self.state.notifications.slice(0, 99)];

      const audio = new Audio(notificationSound);
      audio.play();

      self.setState({
        notifications: self.getNotifications(notifications)
      })
    };
    webSocketNotification.onclose = function () {
      localStorage.removeItem("processing_mobile_wanted");
    };
  }
  getNotifications = (notifications) => {
    return notifications.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
  }
  onCloseSocket = () => {
    this.isProcessing = false;
    localStorage.removeItem('processing_mobile_wanted');
    this.setState({isProcessing: false})
  }
  addMapNotification = (notification) => {


    this.refMap.current.addNotification(notification);
  }

  updateStoreNotification = () => {
  }

  onOpenNotification = (notification) => {
    this.setState({
      isOpenNotification: true,
      modalNotification: notification
    })
  }

  render() {
    const {
      sensors, sensorsInitial, wanted, notifications, activeFilter,
      filterSensor, isOpenNotification, modalNotification, isProcessing,
      view, lastNotification
    } = this.state;
    const {classes} = this.props;
    const isWantedDisabled = Boolean((this.state.activeFilterInitial.list_ids.length <= 0) || (this.state.activeFilterInitial.sensor_ids.length <= 0));

    return (
      <Page>

        <Box mb={2}>
          <Typography variant="h1">Мобильный розыск</Typography>
        </Box>

        <Box mb={4}>
          <Filter
            sensors={sensors}
            sensorsInitial={sensorsInitial}

            filterSensor={filterSensor}
            activeFilter={activeFilter}

            onChangeFilterSensors={this.onChangeFilterSensors}
            onChangeActiveFilter={this.onChangeActiveFilter}
            onSetActiveFilter={this.onSetActiveFilter}
          />
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={8}>

            <Box mb={2}>
              <Box>
                <Tooltip title={isWantedDisabled ? 'Не заполнены фильтры' : 'Запустить сработки'}>
                  <Button
                    variant="contained"
                    disabled={isWantedDisabled}

                    onClick={isProcessing ? this.onCloseSocket : this.onConnectSocket}
                  >{isProcessing ? "Остановить" : "Запустить"}</Button>
                </Tooltip>
              </Box>
            </Box>

            <Box width="100%" pr={2}>
              <Grid container justify="space-between" alignItems="flex-end">
                <Grid item>
                  <Box mb={1}>
                    <Typography variant="formTitle">Работа с поступающими данными ведется в режиме
                      реального времени</Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Grid container alignItems="flex-end">
                    <Grid>
                      <Button
                        variant={'contained'}
                        className={clsx({
                          [classes.buttonControl]: true,
                          [classes.buttonControlActive]: view === 'fullScreen'
                        })}
                        onClick={() => this.setState({view: 'fullScreen'})}
                      >Полноэкранный режим</Button>
                    </Grid>
                    <Grid>
                      <Button
                        variant={'contained'}
                        className={clsx({
                          [classes.buttonControl]: true,
                          [classes.buttonControlActive]: view === 'map'
                        })}
                        onClick={() => this.setState({view: 'map'})}
                      >Карта</Button>
                    </Grid>
                    <Grid>
                      <Button
                        variant={'contained'}
                        className={clsx({
                          [classes.buttonControl]: true,
                          [classes.buttonControlActive]: view === 'table'
                        })}
                        onClick={() => this.setState({view: 'table'})}
                      >Таблица</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>


            {
              Boolean(view === "table" || view === "fullScreen") && (
                <Table
                  notifications={notifications}
                  onOpen={this.onOpenNotification}
                />
              )
            }

            {
              Boolean(view === "map") && (
                <Map
                  ref={this.refMap}

                  notifications={notifications}
                  onOpen={this.onOpenNotification}
                />
              )
            }


          </Grid>
          <Grid xs={4}>

            <Box mb={2} style={{opacity: 0, pointerEvents: "none"}}>
              <Box>
                <Tooltip title={isWantedDisabled ? 'Не заполнены фильтры' : 'Запустить сработки'}>
                  <Button
                    variant="contained"
                    disabled={isWantedDisabled}

                    onClick={isProcessing ? this.onCloseSocket : this.onConnectSocket}
                  >{isProcessing ? "Остановить" : "Запустить"}</Button>
                </Tooltip>
              </Box>
            </Box>
            <Box mb={1} style={{opacity: 0, pointerEvents: "none"}}>
              <Typography variant="formTitle">Работа с поступающими данными ведется в режиме реального
                времени</Typography>
            </Box>

            <Box style={{marginTop: 16}}>
              <WantedList
                wanted={wanted}
                activeFilter={activeFilter}

                onChangeActiveFilter={this.onChangeActiveFilter}
                onSetActiveFilter={this.onSetActiveFilter}
              />
            </Box>
          </Grid>
        </Grid>

        <DialogNotification
          isOpen={isOpenNotification}

          notification={modalNotification}

          onClose={() => this.setState({isOpenNotification: false})}
        />

        <DialogImageNotification
          isOpen={view === "fullScreen"}
          onClose={() => this.setState({view: 'table'})}
          notification={lastNotification}
        />

        <Backdrop open={this.state.isShowBackdrop} invisible={this.state.isShowBackdrop}>
          <CircularProgress size={80} style={{color: 'white'}}/>
        </Backdrop>

      </Page>
    );
  }
}

const styles = {
  buttonControl: {
    backgroundColor: '#f4f6f8',
    borderRadius: '4px 4px 0 0',

    padding: '0 15px',

    color: '#212529',

    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#212529',
    borderBottomWidth: 0,

    height: 34,
    fontSize: 14,

    '&:hover': {
      color: 'white',
    },
  },
  buttonControlLeft: {
    borderRadius: '4px 0 0 0',
  },
  buttonControlRight: {
    borderRadius: '0 4px 0 0',
  },
  buttonControlActive: {
    color: 'white',

    borderRadius: '4px 4px 0 0',
    height: 40,

    fontSize: 18,

    backgroundColor: '#212529'
  }
};

export default withStyles(styles)(WantedMobileList)
