import React from "react";
import {
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,

    Box,

    Typography,

    Tooltip,

    IconButton,

    Checkbox
} from '@material-ui/core';
import {
    makeStyles
} from '@material-ui/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from "clsx";
import {formatMoney} from "../../../../../helpers/format";
import {
    Edit2 as Edit2Icon,
    Upload as UploadIcon,
    Trash as TrashIcon,
    Users as UsersIcon,
    Check as CheckIcon
} from "react-feather";

const TableList = (props) => {
    const {rows, isLoading} = props;
    const classes = useStyles();

    return (
        <Box>

            <PerfectScrollbar>
                <Table className="MuiTable-list">

                    <TableHead className="MuiTableHead-list">

                        <TableRow>
                            <TableCell>Название списка</TableCell>
                            <TableCell>Статус</TableCell>
                            <TableCell>Количество строк</TableCell>
                            <TableCell>Назначено пользователям</TableCell>
                            <TableCell>Загружать и<br/> отображать фотографии</TableCell>
                            <TableCell width="60">Редакт.</TableCell>
                            <TableCell width="60">Загруз.</TableCell>
                            <TableCell width="60">Удален.</TableCell>
                            <TableCell width="60">Пользо.</TableCell>
                        </TableRow>

                    </TableHead>

                    <TableBody>

                        {
                            isLoading && (<RowLoading items={[0, 1, 2, 3, 4, 5, 6, 7]}/>)
                        }

                        {
                            rows.map((row, idx) => (
                                <Row
                                    {...props}
                                    row={row}
                                />
                            ))
                        }

                    </TableBody>

                </Table>
            </PerfectScrollbar>

        </Box>
    )

}

const RowLoading = ({items}) => {
    return (
        <>
            {
                items.map((item) => (
                    <TableRow>

                        <TableCell><Skeleton height={30}/></TableCell>
                        <TableCell><Skeleton height={30}/></TableCell>
                        <TableCell><Skeleton height={30}/></TableCell>
                        <TableCell><Skeleton height={30}/></TableCell>
                        <TableCell><Skeleton height={30}/></TableCell>
                        <TableCell><Skeleton height={30}/></TableCell>
                        <TableCell><Skeleton height={30}/></TableCell>
                        <TableCell><Skeleton height={30}/></TableCell>
                        <TableCell><Skeleton height={30}/></TableCell>

                    </TableRow>
                ))
            }
        </>
    )
}
const Row = (props) => {
    const {row} = props;
    const classes = useStyles();

    const titleStatusTooltip = (row.status === 10) ? 'Активный' : (row.status === 0) ? 'Неактивный' : 'Архив';

    return (
        <TableRow>

            <TableCell>

                <Typography>{row.title}</Typography>

            </TableCell>

            <TableCell>

                <Tooltip
                    title={titleStatusTooltip}
                >
                    <div className={clsx({
                        [classes.status]: true,
                        [classes.statusActive]: row.status === 10,
                        [classes.statusDisabled]: row.status === 0,
                        [classes.statusArhive]: row.status === -1,
                    })}/>
                </Tooltip>

            </TableCell>

            <TableCell>

                <Typography>{formatMoney(row.item_count)}</Typography>

            </TableCell>

            <TableCell>

                <Typography>{formatMoney(row.user_list.length)}</Typography>

            </TableCell>

            <TableCell>

                <Checkbox
                    checkedIcon={<CheckIcon/>}
                    checked={row.store_archive}
                />

            </TableCell>



            <TableCell align="right">

                <Tooltip title="Редактирование списка уведомлений">

                    <IconButton onClick={() => props.onOpenEditWanedList(row)}>
                        <Edit2Icon color={'#8698B1'}/>
                    </IconButton>

                </Tooltip>

            </TableCell>

            <TableCell align="right">

                <Tooltip title="Загрузить элементы списка уведомлений">

                    <IconButton onClick={() => props.onOpenUploadFileToWantedList(row)}>
                        <UploadIcon color={'#8698B1'}/>
                    </IconButton>

                </Tooltip>

            </TableCell>

            <TableCell align="right">

                <Tooltip title="Удалить список уведомлений">

                    <IconButton onClick={() => props.onOpenDeleteWantedList(row)}>
                        <TrashIcon color={'#8698B1'}/>
                    </IconButton>

                </Tooltip>

            </TableCell>

            <TableCell align="right">

                <Tooltip title="Назначить пользователей на список уведомлений">

                    <IconButton onClick={() => props.onOpenModalSetUsersWantedList(row)}>
                        <UsersIcon color={'#8698B1'}/>
                    </IconButton>

                </Tooltip>

            </TableCell>

        </TableRow>
    )
}

const useStyles = makeStyles((theme) => ({

    status: {
        width: 22,
        height: 22,

        borderRadius: 999,

        borderWidth: 1,
        borderStyle: 'solid'
    },
    statusActive: {
        borderColor: 'rgba(0, 0, 0, 0.1)',
        backgroundColor: '#6FF86C'
    },
    statusDisabled: {
        borderColor: 'rgba(0, 0, 0, 0.1)',
        backgroundColor: '#F86C6C'
    },
    statusArhive: {
        borderColor: 'rgba(0, 0, 0, 0.1)',
        backgroundColor: '#C2CFE0'
    },
}));

export default TableList
