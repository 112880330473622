import React, {useState} from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,

  Box,
  Grid,
  Tooltip,
  IconButton,
  Typography,

  Collapse
} from "@material-ui/core";
import {
  Skeleton,
  Pagination
} from "@material-ui/lab";
import {
  makeStyles
} from "@material-ui/styles";
import {
  ChevronDown as ChevronDownIcon,
  ChevronUp as ChevronUpIcon,
  Edit as EditIcon,
  Trash as TrashIcon
} from "react-feather";
import moment from "moment";
import clsx from "clsx";
import userRoles from "../../../../../constant/userRoles";
import {useHistory} from "react-router-dom";

const TableUsers = (props) => {
  const {rows, filter, pagination, isLoading, onChangeFilter, onDeleteUser} = props;

  const handleChangePagination = (event, page) => {
    let newFilter = {...filter};

    newFilter.page = page;

    onChangeFilter(newFilter, true);
  }

  return (
    <>

      <Box mb={2}>
        <Grid container justify="flex-end">
          <Pagination
            page={filter.page}
            count={pagination.page}

            onChange={handleChangePagination}
          />
        </Grid>
      </Box>

      <Box>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={40}></TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Полное имя</TableCell>
              <TableCell>Логин</TableCell>
              <TableCell>Организация</TableCell>
              <TableCell>Статус</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {
              (isLoading) ? (
                <RowLoading/>
              ) : (
                <>

                  {
                    rows.map((rows, idx) => (
                      <Row key={`user-row-${idx}`} {...rows} onDeleteUser={onDeleteUser}/>
                    ))
                  }

                </>
              )
            }
          </TableBody>
        </Table>

      </Box>

      <Box mt={2}>
        <Grid container justify="flex-end">
          <Pagination
            page={filter.page}
            count={pagination.page}

            onChange={handleChangePagination}
          />
        </Grid>
      </Box>

    </>
  )
}
const Row = (row) => {
  const classes = useStyles();
  const history = useHistory();

  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!isOpen);
  }
  const handleOpenEdit = () => {
    history.push('/users/edit/' + row.id)
  }

  return (
    <>

      <TableRow>
        <TableCell width={40}>
          <Tooltip title={isOpen ? 'Свернуть' : 'Развернуть'}>
            <IconButton onClick={handleOpen}>
              {isOpen ? <ChevronUpIcon/> : <ChevronDownIcon/>}
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell>{row?.email}</TableCell>
        <TableCell>{row?.full_name}</TableCell>
        <TableCell>{row?.username}</TableCell>
        <TableCell>{row?.organization || 'Н/Д'}</TableCell>
        <TableCell>
          <div className={clsx({
            [classes.status]: true,
            [classes.statusOn]: row.status === 1,
            [classes.statusOff]: row.status === 0,
          })}/>
        </TableCell>
        <TableCell>
          <Grid container spacing={1} justify="flex-end" wrap="nowrap">
            <Grid item>
              <Tooltip title="Редактировать запись">
                <IconButton onClick={handleOpenEdit}>
                  <EditIcon/>
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Удалить запись">
                <IconButton onClick={() => row.onDeleteUser(row, false)}>
                  <TrashIcon color="#F86C6C"/>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>

      <TableCell colSpan={7} style={{padding: 0}}>
        <Collapse in={isOpen}>
          <Box bgcolor="white" px={4} py={2} style={{borderRadius: '0 0 10px 10px', marginBottom: 4}}>
            <Grid wrap="nowrap" container spacing={2}>
              <Grid item>Разрешенные IP адреса: </Grid>
              <Grid item>{(row?.allowed_ips || []).join('; ')}</Grid>
            </Grid>
            <Grid wrap="nowrap" container spacing={2}>
              <Grid item>Роли: </Grid>
              <Grid item>
                <Typography variant="subtitle2">{userRoles[row.role]};</Typography>
              </Grid>
            </Grid>
            <Grid wrap="nowrap" container spacing={2}>
              <Grid item>Последня дата авторизации: </Grid>
              <Grid item>
                <Typography variant="subtitle2">{moment(row?.last_login).format('DD.MM.YYYY HH:mm:ss')}</Typography>
              </Grid>
            </Grid>
            <Grid wrap="nowrap" container spacing={2}>
              <Grid item>Должность:</Grid>
              <Grid item>{row?.job_title || 'Н/Д'}</Grid>
            </Grid>
          </Box>
        </Collapse>
      </TableCell>

      <div className={classes.separate}/>

    </>
  )
}
const RowLoading = (props) => {
  return (
    <>
      <TableRow>
        <TableCell><Skeleton height={20}/></TableCell>
        <TableCell><Skeleton height={20}/></TableCell>
        <TableCell><Skeleton height={20}/></TableCell>
        <TableCell><Skeleton height={20}/></TableCell>
        <TableCell><Skeleton height={20}/></TableCell>
        <TableCell><Skeleton height={20}/></TableCell>
        <TableCell>
          <Grid container spacing={1} justify="flex-end">
            <Grid item><Skeleton height={40} width={40} variant="circle"/></Grid>
            <Grid item><Skeleton height={40} width={40} variant="circle"/></Grid>
          </Grid>
        </TableCell>
      </TableRow>
    </>
  )
}

const useStyles = makeStyles(() => ({
  status: {
    width: 30,
    height: 30,
    borderRadius: 999,

    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.1)'
  },
  statusOn: {
    backgroundColor: '#6FF86C',
  },
  statusOff: {
    backgroundColor: '#F86C6C',
  },

  separate: {
    width: '100%',
    height: 1,
    backgroundColor: 'rgba(0, 0, 0, 0)'
  }
}));

export default TableUsers
