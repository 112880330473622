import React, {Component} from 'react';
import {
  Box,
  Grid,
  Typography
} from '@material-ui/core';
import {
  LineGraph,
  PieGraph
} from './components';
import {Page} from '../../../components';
import moment from 'moment';
import axios from '../../../plugins/axios';

class LoadersList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listDataSensor: [],
      listLabelsSensor: [],

      totalMetricaSensor: 0,

      metricsSensor: {
        sensorWorkByHour: [],
        sensorErrorByHour: [],
        sensorOffByHour: []
      },
      isLoadMetricsSensorWork: false,
      isLoadMetricsSensorError: false,
      isLoadMetricsSensorOff: false,
    }
  }

  componentDidMount = () => {
    this.getMetricsSensor()
  }

  getMetricsSensor = () => {
    let datetime_from = moment().subtract(24, 'hours');
    let datetime_to = moment();
    let limit = 12;

    let filterWorkByHour = {
      type: 'sensorWorkByHour',
      datetime_from,
      datetime_to,
      limit
    };
    let filterErrorByHour = {
      type: 'sensorErrorByHour',
      datetime_from,
      datetime_to,
      limit
    };
    let filterOffByHour = {
      type: 'sensorOffByHour',
      datetime_from,
      datetime_to,
      limit
    };

    axios('post', '/metrics/get', filterWorkByHour).then(response => {
      let data = response.data;

      if (!data) {
        data = []
      }

      let metricsSensor = this.state.metricsSensor;

      metricsSensor['sensorWorkByHour'] = data;

      this.setState({
        metricsSensor,
        isLoadMetricsSensorWork: true
      })

      this.onCollectMetricData()
    })
    axios('post', '/metrics/get', filterErrorByHour).then(response => {
      let data = response.data;

      if (!data) {
        data = []
      }

      let metricsSensor = this.state.metricsSensor;

      metricsSensor['sensorErrorByHour'] = data;

      this.setState({
        metricsSensor,
        isLoadMetricsSensorError: true
      })

      this.onCollectMetricData()
    })
    axios('post', '/metrics/get', filterOffByHour).then(response => {
      let data = response.data;

      if (!data) {
        data = []
      }

      let metricsSensor = this.state.metricsSensor;

      metricsSensor['sensorOffByHour'] = data;

      this.setState({
        metricsSensor,
        isLoadMetricsSensorOff: true
      })

      this.onCollectMetricData()
    })
  }
  onCollectMetricData = () => {
    let {metricsSensor, isLoadMetricsSensorWork, isLoadMetricsSensorError, isLoadMetricsSensorOff} = this.state;

    if (isLoadMetricsSensorWork && isLoadMetricsSensorError && isLoadMetricsSensorOff) {
      let listDataSensor = [
        {
          name: 'Работает',
          data: []
        },
        {
          name: 'В ошибке',
          data: []
        },
        {
          name: 'Выключено',
          data: []
        }
      ];
      let listLabelsSensor = [];
      let totalMetricaSensor = 0;

      let sensorWorkByHour = metricsSensor.sensorWorkByHour.reverse();
      let sensorErrorByHour = metricsSensor.sensorErrorByHour.reverse();
      let sensorOffByHour = metricsSensor.sensorOffByHour.reverse();

      sensorWorkByHour.map(item => {
        listDataSensor[0].data.push(item.value)
        listLabelsSensor.push(moment(item.created).add(1, 'minutes').format('HH:mm'))
      })
      sensorErrorByHour.map(item => {
        listDataSensor[1].data.push(item.value)
      })
      sensorOffByHour.map(item => {
        listDataSensor[2].data.push(item.value)
      })

      listDataSensor.map(item => {

        totalMetricaSensor += item.data[item.data.length - 1]
      })

      this.setState({
        listDataSensor,
        listLabelsSensor,
        totalMetricaSensor
      })
    }
  }
  getLastMetricaSensorValue = () => {
    let list = this.state.listDataSensor;

    if (list.length <= 0) {
      return '0'
    }

    list = list[0]['data'];

    if (list.length <= 0) {
      return '0'
    }

    return list[list.length - 1]
  }

  render() {
    return (
      <Page title={'Устройства'}>
        <Grid container justify={'center'}>
          <Grid item xs={12}>
            <Box mb={1}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box boxShadow={1} bgcolor={'white'} borderRadius={4} p={3} style={{position: 'relative'}}>
                    <Box mb={1}>
                      <Typography variant={'h5'}>
                        Обзор по { this.state.totalMetricaSensor } устройствам за последние 12 часов
                      </Typography>
                    </Box>

                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <LineGraph
                          series={this.state.listDataSensor}
                          labels={this.state.listLabelsSensor}
                          propsColors={['#6FF86C', '#F86C6C', '#bfbfbf']}
                        />
                      </Grid>
                      <Grid item xs={4} alignItems={'center'} justify={'center'} style={{ display: 'flex' }}>
                        <div>
                          <PieGraph
                            series={this.state.listDataSensor}
                            labels={this.state.listLabelsSensor}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Page>
    );
  }
}

export default LoadersList
