import React, {useState} from 'react';

import {
  TextField,
  makeStyles,
  Grid,
  Button,
  Typography,
} from '@material-ui/core';
import {
  DateRange
} from '../../../../../components';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  root: {},
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  queryField: {
    width: 500
  },
  categoryField: {
    width: '100%',
  },
  imageCell: {
    fontSize: 0,
    width: 68,
    flexBasis: 68,
    flexGrow: 0,
    flexShrink: 0
  },
  image: {
    height: 68,
    width: 68
  },

  label: {
    marginBottom: 5
  },

  buttonSpeedArrow: {
    minWidth: 'initial',
    padding: '10px 5px',
    backgroundColor: 'rgba(194, 207, 224, 0.2)'
  },
  buttonSpeedArrowActive: {},

  clarificationForm: {
    fontSize: 12,
    lineHeight: '14px',
    color: theme.palette.gray.dark,

    '& sup': {
      position: 'relative',
      top: 0,
      color: theme.palette.primary.main,
    }
  },

  formBottom: {
    display: 'flex',
    alignItems: 'center'
  },

  filterContainer: {
    '& > .MuiGrid-grid-xs-4': {
      [theme.breakpoints.between(0, 1599)]: {
        maxWidth: '50%',
        flexBasis: '50%'
      },
    }
  }
}));

const typesOptions = [
  {
    title: 'Уведомления',
    value: 'search'
  },
  // {
  //   title: 'Устройства',
  //   value: 'sensor'
  // },
  // {
  //   title: 'Дубликаты',
  //   value: 'duplicate'
  // }
]

const Filter = (props) => {
  const classes = useStyles();
  let {filters, onChange} = props;

  const handleChangeFilter = (event) => {
    let newFilter = filters;
    let item = event.target;
    let name = item.name;
    let value = item.value;

    newFilter[name] = value;

    onChange(newFilter)
  };
  const handleClearAll = () => {
    filters = {
      limit: 20,
      page: 1,

      datetime_from: null,
      datetime_to: null,

      type: null
    }

    onChange(filters)
  }
  const handleChangeDate = (range) => {
    filters.datetime_from = range.startDate * 1000;
    filters.datetime_to = range.endDate * 1000;

    onChange(filters)
  }

  const handleChangeAutocomplite = (name, values) => {
    let newFilters = {...filters};

    newFilters[name] = values;

    onChange(newFilters)
  }
  const handleRanderOptionLabel = (option) => {
    return option.title
  }

  return (
    <>
      <Grid container spacing={3} className={classes.filterContainer}>
        <Grid xs={4} item>
          <Typography variant={'formTitle'}>Тип</Typography>

          <Autocomplete
            openText={'Открыть список'}
            clearText={'Очистить'}
            closeText={'Закрыть'}
            value={filters.type}
            name={'type'}
            options={typesOptions}
            fullWidth
            getOptionLabel={handleRanderOptionLabel}
            onChange={(event, values) => handleChangeAutocomplite('type', values)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant={'outlined'}
                onChange={handleChangeFilter}
              />
            )}
          />

        </Grid>
        <Grid xs={4} item>
          <Typography variant={'formTitle'}>Временной промежуток</Typography>
          <DateRange
            name='datetime_start'
            dateStart={filters.datetime_from}
            dateEnd={filters.datetime_to}
            onChange={handleChangeDate}
          />
        </Grid>
        <Grid item>
          <Typography variant={'formTitle'}>&nbsp;</Typography>
          <Grid container spacing={1} justify={'flex-end'}>
            <Grid item>
              <Button variant='outlined' onClick={handleClearAll} style={{ height: 44 }}>
                Сбросить
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Filter;
