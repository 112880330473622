import React, {Component} from 'react';
import {
    Box,
    Grid,
    Tooltip,
    Backdrop,
    TextField,
    IconButton,
    Typography,
    CircularProgress,
} from '@material-ui/core';
import {
    Plus as PlusIcon,
    Save as SaveIcon,
    Edit2 as Edit2Icon,
    Trash as TrashIcon
} from "react-feather";
import {
    Table as TableComponent,
    Context as ContextComponent,
    Navigation as NavigationComponent,
    DialogCreated as DialogCreatedComponent
} from "./components";
import {
    Page,
    DialogConfirmation
} from "../../../components";
import axiosAgent from "../../../plugins/axios";
import urls from "../../../constant/urls";
import {sendMessage} from "../../../utils/message";
import {url_slug} from "cyrillic-slug";
import moment from "moment";
import {getNavigation} from "../../../helpers/reference";

class Reference extends Component {
    constructor(props) {
        super(props);

        this.state = {
            categories: [],

            content: {},
            navigations: {},
            deleteCategory: {},

            level: 1,

            isEdit: false,
            isEditContext: false,
            isOpenCreate: false,
            isShowBackdrop: false,
            isOpenDeletePermission: false
        }
    }

    componentDidMount = () => {
        this.initRoute();
    }
    componentDidUpdate = (prevProps) => {
        const prevRouteParams = prevProps.match.params;
        const currentRouteParams = this.props.match.params;

        if (
            prevRouteParams.category !== currentRouteParams.category ||
            prevRouteParams.level2 !== currentRouteParams.level2 ||
            prevRouteParams.level3 !== currentRouteParams.level3
        ) {
            this.initRoute();
        }
    }

    // Определение текущиего уровня и его слаг
    initRoute = () => {
        const routeParams = this.props.match.params;
        let level = (!routeParams.category && !routeParams.level2) ? 1 : (!routeParams.level3) ? 2 : 3;

        this.setState({level, isEditContext: false});

        this.getCategories();
        if (level > 1) {
            this.getContent();
        }
    }

    // Получение категорий для построение бокового меню
    getCategories = () => {
        axiosAgent('get', urls["help-get-list"]).then((res) => {
            let categories = (res?.data || []);
            categories = categories.sort(function (a, b) {
                return a.rank - b.rank;
            });

            this.setState({
                categories: categories,
                isLoading: false,
                isShowBackdrop: false,
                isOpenCreate: false,
            }, () => {
                this._setCategoriesNavigation();
            })
        })
    }

    // Получение контента по слагу
    getContent = () => {
        let slug = this.props.match?.params?.level3 || this.props.match?.params?.level2 || this.props.match?.params?.category;

        axiosAgent('get', `${urls["help-get-item-slug"]}/${slug}`).then((res) => {
            this.setState({
                content: res.data,
                isShowBackdrop: false
            })
        })
    }

    // Логика работый создания / удаления категории
    onCreateCategory = ({name, slug}) => {
        this.setState({isShowBackdrop: true});

        const categories = [...this.state.categories];
        const routeParams = this.props.match.params;
        const parentCategory = routeParams?.level3 || routeParams?.level2 || routeParams?.category || "000000000000000000000000";
        const parentId = categories.find((item) => {
            if (item.slug === parentCategory) {
                return true
            }
        })
        const isLinkBusy = Boolean(categories.find((item) => {
            if (item.slug === slug) {
                return item
            }
        }));

        if (isLinkBusy) {
            sendMessage({
                type: "warning",
                message: "Данная ссылка на раздел уже занята"
            })

            return null
        }

        axiosAgent("post", urls["help-create"], {
            parent_id: parentId?.id || '000000000000000000000000',
            title: name,
            slug: slug,
            content: ""
        }).then((res) => {
            this.getCategories();
        }).catch((error) => {

        });
    }
    onDeleteCategory = (category, isPermission, isRedirect) => {
        if (!isPermission) {
            this.setState({
                deleteCategory: category,
                isOpenDeletePermission: true
            })

            return null
        }

        this.setState({isShowBackdrop: true});

        axiosAgent("get", `${urls["help-delete"]}/${category.id}`).then((res) => {
            this.setState({
                deleteCategory: {},
                isOpenDeletePermission: false
            });

            sendMessage({
                message: `Раздел "${category.title}" успешно удален.`,
                type: "success"
            });

            this.getCategories();

            this.props.history.replace('/reference');
        })
    }
    // ----------

    // Логика работы с контентом
    onSaveContent = () => {
        const categories = [...this.state.categories];
        const content = {...this.state.content};
        const routeParams = this.props.match.params;
        const category = routeParams?.level3 || routeParams?.level2 || routeParams?.category || "000000000000000000000000";
        const idContent = categories.find((item) => {
            if (item.slug === category) {
                return true
            }
        })

        if (!content.title || !content.slug) {
            sendMessage({
                title: "Системное предупреждение",
                message: "Наименование и ссылка на раздел не могут быть пустыми",
                type: "warning"
            });

            return null
        }

        const similarLink = categories.find((item) => {
            if (item.slug === content.slug) {
                return item
            }
        });
        const isLinkBusy = Boolean(similarLink && similarLink.id !== content.id);
        if (isLinkBusy) {
            sendMessage({
                type: "warning",
                message: "Данная ссылка на раздел уже занята"
            })

            return null
        }

        this.setState({isShowBackdrop: true});

        axiosAgent("post", `${urls["help-edit"]}/${idContent?.id || '000000000000000000000000'}`, {
            title: content.title,
            slug: content.slug,
            rank: Number(content?.rank || "0"),
            content: content.content,
        }).then(() => {
            this.getCategories();
            this.getContent();
            this.setState({isEditContext: false});

            this.props.history.replace(content.slug)
        })
    }
    onChangeContent = (content) => {
        this.setState({content})
    }
    // ------------


    _setEditContent = () => {
        if (this.state.isEditContext) {
            this.onSaveContent();

            return null
        }

        this.setState({isEditContext: true})
    }
    _setCategoriesNavigation = () => {
        const categories = [...this.state.categories];
        let navigations = getNavigation(categories);

        console.log('navigations: ', navigations);

        this.setState({
            navigations
        })
    }
    _getCategoriesContext = () => {
        const routeParams = this.props.match.params;
        const parentCategory = routeParams?.level3 || routeParams?.level2 || routeParams?.category || "000000000000000000000000";

        return this.state.categories.filter((category) => {
            if (category.parent_id === parentCategory) {
                return true
            }

            return false
        })
    }
    _changeTitleContent = ({target}) => {
        const {name, value} = target;
        let content = {...this.state.content};
        content[name] = value;

        if (name === "title") {
            content.slug = url_slug(value);
        }

        this.onChangeContent(content);
    }

    render() {
        const {
            level,
            content,
            isLoading,
            categories,
            navigations,
            isOpenCreate,
            isEditContext,
            deleteCategory,
            isOpenDeletePermission
        } = this.state;
        const {user} = this.props;
        const isAdmin = Boolean(user?.role === 'admin');

        return (
            <Page>

                <Grid container spacing={4} wrap="nowrap">
                    <Grid item xs={2} style={{minWidth: 305}}>
                        <NavigationComponent
                            navigations={navigations}
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <Box mb={3}>
                            <Grid container alignItems="center" justify="space-between">
                                <Grid item style={{flex: 1}}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item style={{flex: 1}}>
                                            {
                                                isEditContext ? (
                                                    <Grid container spacing={1}>

                                                        <Grid item xs={1}>
                                                            <TextField
                                                                value={content.rank}
                                                                variant="outlined"
                                                                placeholder="Индекс"
                                                                name="rank"
                                                                type="number"
                                                                fullWidth
                                                                onChange={this._changeTitleContent}
                                                            />
                                                            <Typography variant="caption">Чем больше индекс, тем ниже будет элемент справки</Typography>
                                                        </Grid>
                                                        <Grid item xs={11}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        value={content.title}
                                                                        variant="outlined"
                                                                        placeholder="Наименование"
                                                                        name="title"
                                                                        fullWidth
                                                                        onChange={this._changeTitleContent}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        value={content.slug}
                                                                        variant="outlined"
                                                                        placeholder="Ссылка на раздел"
                                                                        name="slug"
                                                                        fullWidth
                                                                        onChange={this._changeTitleContent}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                ) : (
                                                    <>

                                                        <Typography
                                                            variant="h1">{level > 1 ? content?.title || "Идет загрузка..." : "Справочник"}</Typography>

                                                        {
                                                            level > 1 && (
                                                                <Box mt={1}>
                                                                    <Typography variant="caption">Дата последнего
                                                                        редактирование
                                                                        - {moment(content.updated).format("DD.MM.YYYY HH:mm")}</Typography>
                                                                </Box>
                                                            )
                                                        }

                                                    </>
                                                )
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {
                                    isAdmin && (
                                        <Grid item>
                                            <Grid container spacing={2}>
                                                {
                                                    level > 1 && (
                                                        <Grid item>
                                                            <Tooltip
                                                                title={isEditContext ? "Сохранить" : "Редактировать"}>
                                                                <IconButton onClick={this._setEditContent}>
                                                                    {
                                                                        isEditContext ? (<SaveIcon/>) : (<Edit2Icon/>)
                                                                    }
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    )
                                                }
                                                {
                                                    level > 1 && (
                                                        <Grid item>
                                                            <Tooltip title="Удалить">
                                                                <IconButton
                                                                    onClick={() => this.onDeleteCategory(content, undefined, true)}>
                                                                    <TrashIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    )
                                                }

                                                {
                                                    level < 3 && (
                                                        <Grid item>
                                                            <Tooltip title="Добавить категорию">
                                                                <IconButton onClick={() => this.setState({isOpenCreate: true})}>
                                                                    <PlusIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    )
                                                }
                                            </Grid>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Box>

                        {
                            level === 1 ? (
                                <TableComponent
                                    isAdmin={isAdmin}
                                    rows={categories}
                                    isLoading={isLoading}

                                    onDelete={this.onDeleteCategory}
                                />
                            ) : (
                                <ContextComponent
                                    categories={this._getCategoriesContext()}
                                    content={content}
                                    history={this.props.history}
                                    isEdit={isEditContext}
                                    onEdit={this.onChangeContent}
                                />
                            )
                        }
                    </Grid>
                </Grid>

                <DialogCreatedComponent
                    isOpen={isOpenCreate}
                    onClose={() => this.setState({isOpenCreate: false})}
                    onCreate={this.onCreateCategory}
                />

                <DialogConfirmation
                    isOpen={isOpenDeletePermission}
                    comment={`Вы действительно хотите удалить раздел "${deleteCategory?.title}"?`}
                    buttonConfirmation="Да, удалить"
                    onClose={() => this.setState({isOpenDeletePermission: false})}
                    onConfirmation={() => this.onDeleteCategory(deleteCategory, true)}
                />

                <Backdrop open={this.state.isShowBackdrop} invisible={this.state.isShowBackdrop}>
                    <CircularProgress size={80} style={{color: 'white'}}/>
                </Backdrop>

            </Page>
        );
    }
}

export default Reference
