import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,

  Box,
  Grid,
  Button,
  Typography
} from "@material-ui/core";

class PopUpConfirm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      message: "",
      isOpen: false,
      onSubmit: null
    }
  }

  open = ({ message, onSubmit }) => {
    this.setState({
      message,
      onSubmit,

      isOpen: true
    })
  }
  close = () => {
    this.setState({
      message: "",
      isOpen: false,
      onSubmit: null
    })
  }

  submit = () => {
    this.state.onSubmit();
    this.close();
  }

  render () {
    const {
      message,
      isOpen
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <Typography variant="h3">Подтверждение</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6">
            { message }
          </Typography>
        </DialogContent>
        <DialogActions>
          <Box px={1}>
            <Grid container justify="flex-end" spacing={1}>
              <Grid item>
                <Button variant="outlined" onClick={this.close}>
                  Отменить
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={this.submit}>
                  Подтвердить
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      </Dialog>
    )
  }
}

export default PopUpConfirm
