// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Reference from './Reference';

export default compose(
  connect(
    state => ({
      user: state.app.user
    }),
    dispatch => ({}),
  ),
)(Reference);
