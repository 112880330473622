import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  makeStyles,
  Grid,
  Paper,
  Box,
  IconButton,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem
} from '@material-ui/core';
import Draggable from 'react-draggable';
import {withStyles} from '@material-ui/core/styles';
import {
  ChevronDown as ChevronDownIcon,
  X as XIcon
} from 'react-feather';
import {Formik} from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import Alert from '@material-ui/lab/Alert';
import axios from '../../../../../../plugins/axios';
import {
  ColorPicker
} from '../../../../../../components';

const useStyles = makeStyles((theme) => ({
  fileLabel: {
    flex: 1,
    display: 'flex'
  },
  fileTextField: {
    flex: 1
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
    position: 'relative'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    transform: 'translate(0, -50%)',
    color: '#152435',
  }
});

function PaperComponent(props) {
  return (
    <Draggable
      handle="#dialog-confirmation-deletion"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const MyDialogTitle = withStyles(styles)((props) => {
  const {children, classes, onClose, ...other} = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <XIcon/>
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const ModalEditList = (props) => {
  const classes = useStyles();
  const {
    onClose,
    open,
    form,
    onSaveList
  } = props;

  const handleSubmit = (values, setStatus, setErrors) => {
    axios('post', '/search/item/edit/' + values.id, values).then(response => {
      onSaveList()
    }).catch(error => {
      const message = (error.response && error.response.data.message) || 'Что-то пошло не так';

      setStatus({success: false});
      setErrors({submit: message});
    })
  }

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth={'md'}
      onClose={onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="dialog-confirmation-deletion"
    >
      <MyDialogTitle
        id="dialog-confirmation-deletion"
        onClose={onClose}
      >
        <Grid>
          <Grid container alignItems={'center'}>
            <Grid item>
              <Typography variant='h3'>
                Редактирование элемента списка уведомлений
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </MyDialogTitle>
      <DialogContent style={{padding: 0, paddingBottom: 26}}>
        <Box pl={3} pr={3}>
          <Formik
            initialValues={form}
            validationSchema={Yup.object().shape({
              list_id: Yup.string().required('Заполните поле'),
              license_plate: Yup.string().required('Заполните поле')
            })}
            onSubmit={async (values, {
              setErrors,
              setStatus
            }) => {
              try {
                handleSubmit(values, setStatus, setErrors)
              } catch (error) {
                const message = (error.response && error.response.data.message) || 'Что-то пошло не так';

                setStatus({success: false});
                setErrors({submit: message});
              }
            }}
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
              <form
                noValidate
                className={clsx(classes.root)}
                onSubmit={handleSubmit}
                autoComplete={'off'}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant={'formTitle'}>ГРЗ<sup>*</sup></Typography>
                    <TextField
                      error={Boolean(touched.license_plate && errors.license_plate)}
                      fullWidth
                      autoFocus
                      helperText={touched.license_plate && errors.license_plate}
                      name="license_plate"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.license_plate}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'formTitle'}>Статус</Typography>

                    <FormControl fullWidth>
                      <Select
                        error={Boolean(touched.status && errors.status)}
                        fullWidth
                        helperText={touched.status && errors.status}
                        name="status"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.status}
                        variant="outlined"
                      >
                        <MenuItem value={10}>Активный</MenuItem>
                        <MenuItem value={-1}>Неактивный</MenuItem>
                      </Select>
                    </FormControl>

                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'formTitle'}>Цвет</Typography>
                    <ColorPicker
                      error={Boolean(touched.color && errors.color)}
                      fullWidth
                      helperText={touched.color && errors.color}
                      name="color"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.color}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'formTitle'}>Марка</Typography>
                    <TextField
                      error={Boolean(touched.mark && errors.mark)}
                      fullWidth
                      helperText={touched.mark && errors.mark}
                      name="mark"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.mark}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'formTitle'}>Модель</Typography>
                    <TextField
                      error={Boolean(touched.model && errors.model)}
                      fullWidth
                      helperText={touched.model && errors.model}
                      name="model"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.model}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'formTitle'}>Комментарий</Typography>
                    <TextField
                      error={Boolean(touched.comment && errors.comment)}
                      fullWidth
                      helperText={touched.comment && errors.comment}
                      name="comment"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.comment}
                      variant="outlined"
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>

                <Box mt={3}>
                  <Grid justify={'flex-end'} style={{display: 'flex'}}>
                    <Button
                      size="large"
                      variant="formCancel"
                      style={{ marginRight: 10 }}
                      onClick={() => onClose()}
                    >
                      Отменить
                    </Button>
                    <Button
                      disabled={!values.license_plate || values.license_plate === ''}
                      size="large"
                      type="submit"
                      variant="formSubmit"
                      onClick={handleSubmit}
                    >
                      Применить
                    </Button>
                  </Grid>

                  {errors.submit && (
                    <Box mt={2}>
                      <Alert severity="error">{errors.submit}</Alert>
                    </Box>
                  )}
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default ModalEditList
