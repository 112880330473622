import React from "react";
import {

} from "@material-ui/core";
import {TextWYSIWYG} from "../../../../../components";

const Editor = (props) => {
    const { html, onChange } = props;

    return (
        <TextWYSIWYG
            value={html}
            onChange={onChange}
        />
    )
}

export default Editor
