import React, {useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {
  Button, CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  TextField,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import {
  X as XIcon,
  Check as CheckIcon
} from 'react-feather';
import Draggable from 'react-draggable';
import * as Yup from 'yup';
import {Formik} from 'formik';
import axios from "../../plugins/axios";

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  dialogContent: {},

  contentLoading: {
    backgroundColor: 'rgba(24, 31, 72, 0.5)',
    position: 'absolute',
    zIndex: 9999,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& .MuiCircularProgress-circleIndeterminate': {
      color: 'white'
    }
  },
  contentSuccess: {
    backgroundColor: 'white',
    position: 'absolute',
    zIndex: 9999,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));
const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    paddingBottom: 0
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    transform: 'translate(0, -50%)',
    color: '#152435',
  }
});

const PaperComponent = (props) => {
  return (
    <Draggable handle="#dialog-feedback" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}
const MyDialogTitle = withStyles(styles)((props) => {
  const {children, classes, onClose, ...other} = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      {children}

      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size={'small'}
        >
          <XIcon/>
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const initialForm = {
  name: '',
  email: '',
  text: ''
};

const ModalFormFeedback = (props) => {
  const {
    open,
    onClose
  } = props;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);


  const handleSendForm = (formData) => {
    setIsLoading(true);

    axios('post', '/feedback/create', formData).then(response => {
      setIsLoading(false);
      setIsSuccess(true)
    }).catch(error => {
      setIsLoading(false);
      setIsError(false);
    })
  }
  const handleOnClose = () => {
    if (isLoading){
      return null
    }

    setIsLoading(false);
    setIsSuccess(false);
    setIsError(false);

    onClose()
  }

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth={'sm'}
      onClose={handleOnClose}
      PaperComponent={PaperComponent}
      aria-labelledby="dialog-feedback"
    >
      <MyDialogTitle id="dialog-feedback" onClose={handleOnClose}>
        <Typography variant="h3">Форма обратной связи</Typography>
      </MyDialogTitle>

      <Formik
        initialValues={initialForm}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required('Обязательно для заполнения'),
          email: Yup.string().required('Обязательно для заполнения').email('Неверный формат email')
        })}
        onSubmit={async (values, {
          setErrors,
          setStatus
        }) => {
          try {
            handleSendForm(values, setStatus, setErrors)
          } catch (error) {
            const message = (error.response && error.response.data.message) || 'Что-то пошло не так';

            setStatus({success: false});
            setErrors({submit: message});
          }
        }}
      >
        {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
          <form
            noValidate
            className={clsx(classes.root)}
            onSubmit={handleSubmit}
            autoComplete={'off'}
          >
            <DialogContent className={classes.dialogContent}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant={'formTitle'}>Имя<sup>*</sup></Typography>
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    variant="outlined"
                    placeholder={'Ваше имя'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={'formTitle'}>E-mail<sup>*</sup></Typography>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                    variant="outlined"
                    placeholder={'Почта для ответа'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={'formTitle'}>Комментарий</Typography>
                  <TextField
                    error={Boolean(touched.text && errors.text)}
                    fullWidth
                    helperText={touched.text && errors.text}
                    name="text"
                    onChange={handleChange}
                    value={values.text}
                    variant="outlined"
                    multiline
                    rows={4}
                    placeholder={'Комментарий'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={'caption'}>Обращаться если вы не нашли нужную вам информацию в разделе
                    "Справка"</Typography>
                </Grid>
              </Grid>

              {
                (isSuccess) && (
                  <div className={classes.contentSuccess}>
                    <CheckIcon size={80} color={'#28a745'}/>
                    <Typography variant={'h6'}>Ваша заявка поступила в обработку</Typography>
                  </div>
                )
              }
            </DialogContent>
            <DialogContent>
              <Grid container spacing={2} justify={'flex-end'}>
                <Grid item>
                  <Button
                    onClick={handleOnClose}
                    style={{marginRight: 10}}
                    variant="formCancel"
                  >
                    Отмена
                  </Button>


                  <Button
                    size="small"
                    variant="formSubmit"
                    type="submit"
                  >
                    Отправить
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </form>
        )}
      </Formik>

      {
        (isLoading) && (
          <div className={classes.contentLoading}>
            <CircularProgress size={60}/>
          </div>
        )
      }
    </Dialog>
  )
}

export default ModalFormFeedback
