import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  Map,
  TileLayer,
  FeatureGroup,
  Marker,
  Tooltip
} from 'react-leaflet'
import L from 'leaflet';
import 'leaflet-polylinedecorator';
import {EditControl} from 'react-leaflet-draw';
import {withStyles} from '@material-ui/core/styles';
import {divIcon} from 'leaflet';
import {renderToStaticMarkup} from 'react-dom/server';
import {Box, Grid, Typography} from '@material-ui/core';
import clsx from 'clsx';
import {MapTags} from "../../../index";

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
  leafletContainer: {
    width: '100%',
    height: '100%'
  },

  marker: {
    borderRadius: '100%',
    backgroundColor: '#212529',
    border: '1px solid rgba(21, 36, 53, 0.1)',
    padding: 5,
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgba(72, 93, 210, 0.4)',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  markerActive: {
    backgroundColor: '#3CB11F',
    boxShadow: '0px 4px 4px rgba(60, 177, 31, 0.4)'
  },
  markerError: {
    backgroundColor: '#F86C6C'
  },
  markerStatusError: {
    boxShadow: '0 0 0 6px #F86C6C',
  },
  markerStatusNotActive: {
    boxShadow: '0 0 0 6px #bfbfbf'
  },
  markerImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },

  popup: {
    backgroundColor: '#212529',
    padding: '5px 10px',
    border: 'none',
    boxShadow: '0px 2px 8px rgba(142, 150, 168, 0.24)',
    borderRadius: 4,

    '& .MuiTypography-body2': {
      margin: 0,
      fontWeight: '500',
      fontSize: 15,
      lineHeight: '100%',
      color: 'white'
    }
  },

  buttonAccept: {
    position: 'absolute',
    zIndex: 999999999,
    outline: 'none',
    backgroundColor: '#fff',
    borderBottom: '1px solid #ccc',
    color: 'black',
    bottom: 10,
    right: 10,
    boxShadow: '0 1px 5px rgba(0,0,0,0.65)',
    borderRadius: 4,
  }
}));

const typeMap = {
  'google-gibrid': 'http://mt1.google.com/vt/lyrs=y,h&x={x}&y={y}&z={z}'
};

const CustomMarker = withStyles()(props => {
  const classes = useStyles();
  const {items, position, idx, device, onClickPoint, active} = props;

  let iconMarkup;

  if ( device.type === 10 ){
    iconMarkup = renderToStaticMarkup(
      <>
        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.75702 9.82978L12.7975 3.78934L9.84266 0.834507C9.61931 0.611121 9.25734 0.610816 9.03361 0.833668L0 9.82978H6.75702Z"
            fill="white"/>
          <path
            d="M18.6389 9.5242H17.3027C16.3553 9.5242 15.5846 10.2949 15.5846 11.2423V13.1894H14.1923C13.8433 12.1564 12.6626 11.6731 11.695 12.1551L10.7058 11.1659L12.6981 9.17357C13.3679 8.50375 13.3679 7.41393 12.6981 6.74411L12.0802 6.12622L7.39926 10.8073C7.29187 10.9146 7.14621 10.975 6.99434 10.975H3.71143L6.95524 14.2188C7.18344 14.3913 7.50258 14.3692 7.7047 14.167L9.89595 11.9758L10.8851 12.9649C10.2943 14.1511 11.1615 15.5565 12.4921 15.5565C13.2813 15.5565 13.9527 15.0441 14.1923 14.3347H15.5846V16.2819C15.5846 17.2292 16.3554 17.9999 17.3027 17.9999H18.639C18.9553 17.9999 19.2117 17.7435 19.2117 17.4272V10.0969C19.2116 9.78061 18.9552 9.5242 18.6389 9.5242Z"
            fill="white"/>
          <path
            d="M2.62967 11.5134L2.00758 12.1355C1.78393 12.3591 1.78393 12.7218 2.00758 12.9454L4.14562 15.0834C4.36927 15.3071 4.73186 15.3071 4.95551 15.0834L5.5776 14.4614L2.62967 11.5134Z"
            fill="white"/>
        </svg>
      </>
    );
  }
  if ( device.type === 20 ){
    iconMarkup = renderToStaticMarkup(
      <>
        <svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.0029 2.06638H5.23595V1.07007C5.23595 0.887631 5.08807 0.739746 4.90563 0.739746H2.07403C1.8916 0.739746 1.7437 0.887631 1.7437 1.07007V2.06638H0.517338C0.334896 2.06638 0.187012 2.21426 0.187012 2.3967V10.3378C0.187012 10.5202 0.334896 10.6681 0.517338 10.6681H4.24765H10.2726H14.0029C14.1854 10.6681 14.3332 10.5202 14.3332 10.3378V2.3967C14.3332 2.21426 14.1854 2.06638 14.0029 2.06638ZM7.23986 9.18736C6.08785 9.18736 5.09851 8.49139 4.66899 7.49705C4.52206 7.15695 4.4405 6.78203 4.4405 6.388C4.4405 4.84196 5.69381 3.58863 7.23984 3.58863C8.7859 3.58863 10.0392 4.84196 10.0392 6.388C10.0392 6.76893 9.96301 7.13203 9.82518 7.46306C9.4037 8.47551 8.40497 9.18736 7.23986 9.18736Z" fill="white"/>
          <path d="M9.76343 12.1899C9.87202 12.0586 9.97039 11.9187 10.0576 11.7712H4.46393C4.55109 11.9186 4.64944 12.0586 4.75805 12.1899L0.598338 17.8892C0.51412 18.0045 0.59654 18.1667 0.739378 18.1667H1.78482C1.89684 18.1667 2.00206 18.113 2.06774 18.0222L5.7177 12.9787C5.99675 13.1296 6.30041 13.2408 6.62109 13.3049V17.7995C6.62109 17.9924 6.77745 18.1487 6.97033 18.1487H7.51055C7.70345 18.1487 7.85979 17.9924 7.85979 17.7995V13.3127C8.19546 13.2501 8.51297 13.136 8.80372 12.9786L12.4537 18.0223C12.5194 18.113 12.6246 18.1667 12.7366 18.1667H13.7821C13.9249 18.1667 14.0073 18.0046 13.9231 17.8892L9.76343 12.1899Z" fill="white"/>
        </svg>
      </>
    );
  }
  if ( device.type === 0 ){
    iconMarkup = renderToStaticMarkup(
      <>
        <span style={{ color: 'white' }}>!</span>
      </>
    );
  }

  const customMarkerIcon = divIcon({
    html: iconMarkup,
    className: clsx({
      [classes.marker]: true,
      [classes.markerActive]: active,
      [classes.markerError]: device.type === 0,
      [classes.markerStatusError]: device.status === 20,
      [classes.markerStatusNotActive]: device.status === 0,
    }),
    iconSize: [35, 35],
  });

  L.drawLocal = {
    draw: {
      toolbar: {
        actions: {
          title: 'Отменить рисунок',
          text: 'Отмена'
        },
        finish: {
          title: 'Закончить рисунок',
          text: 'Закончить'
        },
        undo: {
          title: 'Удалить последнюю нарисованную точку',
          text: 'Удалить последнюю точку'
        },
        buttons: {
          polyline: 'Нарисуйте ломаную линию',
          polygon: 'Нарисуйте многоугольник',
          rectangle: 'Нарисуйте прямоугольник',
          circle: 'Нарисуйте прямоугольник',
          marker: 'Нарисуйте маркер',
          circlemarker: 'Нарисуйте круговой маркер'
        }
      },
      handlers: {
        circle: {
          tooltip: {
            start: 'Щелкните и перетащите, чтобы нарисовать круг.'
          },
          radius: 'Радиус'
        },
        circlemarker: {
          tooltip: {
            start: 'Щелкните карту, чтобы разместить маркер круга.'
          }
        },
        marker: {
          tooltip: {
            start: 'Щелкните карту, чтобы разместить маркер.'
          }
        },
        polygon: {
          tooltip: {
            start: 'Щелкните, чтобы начать рисовать фигуру.',
            cont: 'Щелкните, чтобы продолжить рисование формы.',
            end: 'Щелкните первую точку, чтобы закрыть эту форму.'
          }
        },
        polyline: {
          error: '<strong>Ошибка:</strong> края формы не могут пересекаться!',
          tooltip: {
            start: 'Щелкните, чтобы начать рисовать линию.',
            cont: 'Щелкните, чтобы продолжить рисование линии.',
            end: 'Щелкните последнюю точку, чтобы закончить линию.'
          }
        },
        rectangle: {
          tooltip: {
            start: 'Щелкните и перетащите, чтобы нарисовать прямоугольник.'
          }
        },
        simpleshape: {
          tooltip: {
            end: 'Отпустите мышь, чтобы закончить рисование.'
          }
        }
      }
    },
    edit: {
      toolbar: {
        actions: {
          save: {
            title: 'Сохранить изменения',
            text: 'Сохранить'
          },
          cancel: {
            title: 'Отменить редактирование, отменить все изменения',
            text: 'Отменить'
          },
          clearAll: {
            title: 'Очистить все слои',
            text: 'Очистить всё'
          }
        },
        buttons: {
          edit: 'Редактировать слой',
          editDisabled: 'Нет слоев для редактирования',
          remove: 'Удалить слой',
          removeDisabled: 'Нет слоев для удаления'
        }
      },
      handlers: {
        edit: {
          tooltip: {
            text: 'Перетащите ручки или маркеры, чтобы редактировать объекты.',
            subtext: 'Нажмите «Отмена», чтобы отменить изменения.'
          }
        },
        remove: {
          tooltip: {
            text: 'Щелкните объект, который нужно удалить.'
          }
        }
      }
    }
  };

  return (
    <>
      <Marker
        key={'device-point' + idx}
        position={position}
        icon={customMarkerIcon}
        onClick={() => onClickPoint(device)}
      >
        <Tooltip className={classes.popup} >
          <Grid container spacing={1}>
            <Grid item>
              <Box mb={1}>
                <Typography variant={'body2'}>Камер ({items.length})</Typography>
              </Box>

              {
                items.map((item, idx) => (
                    <Typography variant={'body2'}>{item.serial} {item.title}</Typography>
                ))
              }
            </Grid>
          </Grid>
        </Tooltip>
      </Marker>
    </>
  )
})

const MapObject = (props) => {
  const classes = useStyles();
  const {position, devicesSensor, selected, isSelectRowTable, isChangeView, polygons, setPolygons, setRef, setRefEditControl} = props;

  const [complexes, setComplexes] = useState([]);

  useEffect(() => {
    let complexes = {};

    devicesSensor.map((sensor) => {
      if (!complexes[`${sensor.gps.lat}-${sensor.gps.lon}`]){
        complexes[`${sensor.gps.lat}-${sensor.gps.lon}`] = {
          items: [],
          count: 0
        }
      }

      complexes[`${sensor.gps.lat}-${sensor.gps.lon}`]['items'].push(sensor);
      complexes[`${sensor.gps.lat}-${sensor.gps.lon}`]['count']++;
    });

    setComplexes(complexes);

  }, [devicesSensor]);

  const _onCreate = function (event) {
    let polygonList = event.layer._latlngs;
    let list = [];
    let newSelected = [];

    setPolygons([...polygons, ...polygonList]);
  }
  const _onDeleted = function (event) {}

  const handleClickPoint = (props) => {
    let newSelected = [...selected];

    props.items.map((point) => {
      if (newSelected.indexOf(point.id) > -1) {
        newSelected.splice(newSelected.indexOf(point.id), 1)
      } else {
        newSelected.push(point.id)
      }
    })

    isSelectRowTable(newSelected);
  }

  const handleGetBounds = () => {
    if (selected.length > 0){
      let bounds = [];

      devicesSensor.map(point => {
        if (selected.indexOf(point.id) > -1){
          bounds.push([
            point.gps.lat,
            point.gps.lon
          ])
        }
      })

      if (bounds.length <= 0){
        return null
      }

      return bounds
    }

    return null
  }

  return (
    <Map
      ref={setRef}
      className={classes.leafletContainer}
      center={position}
      length={4}
      zoom={12}
      bounds={handleGetBounds()}
    >
      <TileLayer
        attribution=''
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />


      {
        Object.keys(complexes).map((key, idx) => {
          const item = complexes[key];
          const device = item.items[0];
          let position = [device.gps.lat, device.gps.lon];
          let active = selected.indexOf(device.id) > -1;

          return (
            <CustomMarker
              idx={idx}
              items={item.items}
              device={device}
              position={position}
              active={active}

              onClickPoint={() => handleClickPoint(item)}
            />
          )
        })
      }

      <FeatureGroup>
        <EditControl
          ref={setRefEditControl}

          position='topright'
          onCreated={_onCreate}
          onDeleted={_onDeleted}
          draw={{
            polyline: false,
            rectangle: true,
            circle: false,
            marker: false,
            circlemarker: false
          }}

        />
      </FeatureGroup>

      <MapTags/>

    </Map>
  );
};

export default MapObject;
