import React from "react";
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell, Box
} from "@material-ui/core";
import {
    Skeleton
} from "@material-ui/lab";

import moment from "moment";
import urls from "../../../../../constant/urls";

const WantedTable = (props) => {
    const { notifications, onOpen } = props;


    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Фиксация</TableCell>
                    <TableCell>Фото</TableCell>
                    <TableCell>ГРЗ</TableCell>
                    <TableCell>Описание</TableCell>
                    <TableCell>Место фиксации</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    notifications.map((notification, idx) => {
                        const { payload } = notification;

                        return (
                            <TableRow
                                key={`wanted-item-${ idx }`}

                                hover

                                onClick={() => onOpen(notification)}
                            >
                                <TableCell>
                                    { moment(notification.created).format('DD.MM.YYYY') }<br/>
                                    { moment(notification.created).format('HH:mm:ss') }
                                </TableCell>
                                <TableCell>
                                    <img src={ `${ urls["mobile-image-url"] }${ payload?.report?.images?.main }` } width={60} height={60} style={{ borderRadius: 4 }}/>
                                </TableCell>
                                <TableCell>{ payload?.report?.license_plate }</TableCell>
                                <TableCell>
                                    <Box maxWidth={500}>{ payload?.item?.comment }</Box>
                                </TableCell>
                                <TableCell>
                                    <Box maxWidth={500}>{ payload?.report?.address }</Box>
                                </TableCell>
                            </TableRow>
                        )
                    })
                }
            </TableBody>
        </Table>
    )
};

export default WantedTable
