import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'fast-text-encoding/text';
import 'core-js/stable'
import 'core-js/es/map';
import 'core-js/es/set';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import * as serviceWorker from './serviceWorker';
import App from './App';

// Sentry.init({
//   dsn: 'https://b7223f6cdbe7447a88ec80084dac47fd@o272399.ingest.sentry.io/5407927',
//   integrations: [
//     new Integrations.BrowserTracing(),
//   ],
//   tracesSampleRate: 1.0,
// });

ReactDOM.render(<App/>, document.getElementById('root'));

serviceWorker.unregister();
