import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,

  Box,
  Grid,
  Tooltip,
  Checkbox,
  IconButton,
  Typography
} from "@material-ui/core";
import {
  Skeleton,
  Pagination
} from "@material-ui/lab";
import {
  Edit as EditIcon,
  Check as CheckIcon,
  Delete as DeleteIcon
} from "@material-ui/icons";
import moment from "moment";

const TableCustom = (props) => {
  const {
    data,
    total,
    isLoad,
    current,

    onEdit,
    onDelete,
    onChangePage,
    onChangeStatusWanted
  } = props;

  return (
    <>

      <Grid container justify="flex-end">
        <Grid item>
          <Pagination
            page={Number.parseFloat(current || 1)}
            count={Number.parseFloat(total)}
            onChange={(e, p) => onChangePage(p)}
          />
        </Grid>
      </Grid>

      <Box mb={2}/>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ГРЗ</TableCell>
            <TableCell>Марка</TableCell>
            <TableCell>Модель</TableCell>
            <TableCell>Цвет</TableCell>
            <TableCell>Тип</TableCell>
            <TableCell>Статус</TableCell>
            <TableCell width="25%">Комментарий</TableCell>
            <TableCell align="right">Дата добавления</TableCell>
            <TableCell align="right"/>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoad && data.map((t) => <RowData
            key={`RowData-${t.id}`}
            data={t}

            onEdit={onEdit}
            onDelete={onDelete}
            onChangeStatusWanted={onChangeStatusWanted}
          />)}
          {isLoad && <RowsLoading/>}
        </TableBody>
      </Table>

      <Box mt={2}/>

      <Grid container justify="flex-end">
        <Grid item>
          <Pagination
            page={Number.parseFloat(current || 1)}
            count={Number.parseFloat(total)}
            onChange={(e, p) => onChangePage(p)}
          />
        </Grid>
      </Grid>

    </>
  )
}
const RowData = React.memo((props) => {
  const {
    data,

    onEdit,
    onDelete,
    onChangeStatusWanted
  } = props;

  const handleChangeStatus = (event, value) => {
    onChangeStatusWanted({
      ...data,
      status: Boolean(value) ? 10 : -1
    })
  }

  return (
    <TableRow>
      <TableCell>{data.license_plate}</TableCell>
      <TableCell>{data.mark || "-"}</TableCell>
      <TableCell>{data.model || "-"}</TableCell>
      <TableCell>{data.color || "-"}</TableCell>
      <TableCell>{data.type || "-"}</TableCell>
      <TableCell>
        <Checkbox
          value={Boolean(data.status === 10)}
          checked={Boolean(data.status === 10)}
          checkedIcon={<CheckIcon/>}
          onChange={handleChangeStatus}
        />
      </TableCell>
      <TableCell>
        <Typography>
          {data.comment || ""}
        </Typography>
      </TableCell>
      <TableCell align="right">
        {moment(data.created).format("DD.MM.YYYY HH:mm:ss")}
      </TableCell>
      <TableCell align="right">
        <Grid container justify="flex-end">
          <Grid item>
            <Tooltip title="Редактировать" arrow>
              <IconButton onClick={onEdit.bind(this, data, false)}>
                <EditIcon color="EditIcon"/>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Удалить" arrow>
              <IconButton onClick={onDelete.bind(this, data, false)}>
                <DeleteIcon color="error"/>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  )
})
const RowsLoading = React.memo(() => {
  return new Array(10).fill(0).map((t) => (
    <TableRow>
      <TableCell><Skeleton/></TableCell>
      <TableCell><Skeleton/></TableCell>
      <TableCell><Skeleton/></TableCell>
      <TableCell><Skeleton/></TableCell>
      <TableCell><Skeleton/></TableCell>
      <TableCell><Skeleton/></TableCell>
      <TableCell><Skeleton/></TableCell>
      <TableCell><Skeleton/></TableCell>
      <TableCell><Skeleton/></TableCell>
    </TableRow>
  ))
})

export default React.memo(TableCustom)
