import React, {Component} from "react";
import {
	Box,
	Backdrop,
	Typography, CircularProgress
} from "@material-ui/core";
import {
	Page
} from "../../../components";
import {
	Form
} from "./components";
import axios from "../../../plugins/axios";
import urls from "../../../constant/urls";
import {sendMessages} from "../../../common/messages";

class UserCreate extends Component {
	constructor(props) {
		super(props);

		this.state = {
			userGroups: [],

			form: {
				username: '',
				full_name: '',
				email: '',
				phone: '',
				password: '',
				allowed_ips: "",
				role: "",
				group_id: "",
				job_title: '',
				organization: '',
				status: 0,
				additional_roles: {},
				send_notifications: false,
				enable_2fa: false,
			},

			isShowBackdrop: false,
      isLoadInitForm: Boolean(props?.app?.user?.role !== 'admin'),
      isUserAdmin: Boolean(props?.app?.user?.role === 'admin')
    };

		this.refFormik = React.createRef();
	}

	componentDidMount = async () => {
    await this.initForm();
		await this.getUserGrups();
	}

  initForm = async () => {
    if (this.state.isUserAdmin) {
      return
    }
    let form = {...this.state.form};
    form.role = 'user';
    await this.setState({
      form,
      isLoadInitForm: false
    });
  }
	getUserGrups = async () => {
		const res = await axios('post', '/user/group/get-list', {
			limit: 9999
		}).then((res) => {
			return res?.data || []
		}).catch(() => {
			return []
		});
		this.setState({
			userGroups: res
		});
	}

	// функция создания пользователя
	onCreateUser = (form) => {
		const body = this.getBody(form);
		axios('post', urls["user-create"], body).then((response) => {
			sendMessages({
				message: "Пользователь успешно создан",
				type: "success"
			});

			this.props.history.goBack();
		}).catch((err) => {
			sendMessages({
				message: err?.response?.data?.details || "Ошибка сервера",
				type: "danger"
			});
		});
	}

	// конвертация тела создания пользователя
	// со фронта на бек
	getBody = (form) => {
		let body = {};

		Object.keys(form).map((key) => {
			let value = form[key];

			if (key === "status") {
				value = !!value ? 1 : 0
			}
			if (key === "send_notifications") {
				value = Boolean(value)
			}
			if (key === "phone" && value) {
				value = `+${value.replace(/[^\d]/g, '')}`;
			}
			if (key === "allowed_ips") {
				value = (value || "").split(",").map((address) => address.split(" ").join("")).join(",")
			}

			if (
				value ||
				key === "role" ||
				key === "status" ||
				key === "enable_2fa" ||
				key === "send_notifications"
			) {
				body[key] = value;
			}
		});

		return body
	}

	// изменение формы
	onChangeForm = (form) => {
		this.refFormik.current.setValues(form);
		this.setState({form});
	}

	render() {
		const {
			form,
			userGroups,
      isUserAdmin,
			isShowBackdrop,
      isLoadInitForm
		} = this.state;

		return (
			<Page>

				<Box maxWidth={1200} margin="0 auto">
					<Box mb={4}>
						<Typography variant="h1">Добавление пользователя</Typography>
					</Box>

					<Box py={4} borderRadius={4} boxShadow={1} bgcolor="white">

            {Boolean(!isLoadInitForm) && (
              <Form
                innerRef={this.refFormik}
                initialValues={form}
                userGroups={userGroups}
                isUserAdmin={isUserAdmin}

                onChange={this.onChangeForm}
                onSubmit={this.onCreateUser}
              />
            )}

					</Box>
				</Box>


				<Backdrop open={this.state.isShowBackdrop} invisible={this.state.isShowBackdrop}>
					<CircularProgress size={80} style={{color: 'white'}}/>
				</Backdrop>

			</Page>
		)
	}
}

export default UserCreate
