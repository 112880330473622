import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    makeStyles,
    Grid,
    Paper,
    Box,
    IconButton,
    Button, InputAdornment, TextField, FormControlLabel, Checkbox
} from '@material-ui/core';
import Draggable from 'react-draggable';
import {withStyles} from '@material-ui/core/styles';
import {
    Search as SearchIcon,
    X as XIcon
} from 'react-feather';
import {
    CustomCheckbox
} from "../../../../../components";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {Check as CheckIcon} from "@material-ui/icons";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(3),
        position: 'relative'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: '50%',
        transform: 'translate(0, -50%)',
        color: '#152435',
    }
});

function PaperComponent(props) {
    return (
        <Draggable
            handle="#dialog-confirmation-deletion"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const MyDialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <DialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <XIcon/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
});

const ModalSetUser = (props) => {
    const classes = useStyles();
    const {isOpen, onClose, onSetUsers, usersSystem, filterUserList, onChangeFilterUserList, formSetUserToWanted, onChangeForm} = props;

    const handleOpenList = (userId) => {
        let newFormSetUserToWanted = {...formSetUserToWanted};

        if (newFormSetUserToWanted.user_list.indexOf(userId) > -1) {
            newFormSetUserToWanted.user_list.splice(newFormSetUserToWanted.user_list.indexOf(userId), 1);
        } else {
            newFormSetUserToWanted.user_list.push(userId);
        }

        onChangeForm(newFormSetUserToWanted);
    }

    const handleOnChangeFilter = ({target}) => {
        const name = target.name;
        const value = target.value;

        let newFilterUserList = {...filterUserList};

        newFilterUserList[name] = value;

        onChangeFilterUserList(newFilterUserList)
    }
    const handleOnChangeFilterBoolean = ({target}) => {
        const name = target.name;
        const value = target.value === 'true';

        let newFilterUserList = {...filterUserList};

        newFilterUserList[name] = value;

        onChangeFilterUserList(newFilterUserList)
    }

    return (
        <Dialog
            fullWidth
            open={isOpen}
            maxWidth={'md'}
            onClose={onClose}
            PaperComponent={PaperComponent}
            aria-labelledby="dialog-confirmation-deletion"
        >
            <MyDialogTitle
                id="dialog-confirmation-deletion"
                onClose={onClose}
            >
                <Grid>
                    <Grid container alignItems={'center'}>
                        <Grid item>
                            <Typography variant='h3'>
                                Назначение пользователей
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </MyDialogTitle>

            <Box px={3} py={2} pt={0}>

                <Grid container spacing={2}>

                    <Grid item xs={4}>

                        <TextField
                            fullWidth
                            placeholder={'ФИО пользователя'}
                            variant={'outlined'}
                            size={'small'}
                            value={filterUserList.full_name}
                            name={'full_name'}

                            onChange={handleOnChangeFilter}
                        />

                    </Grid>

                    <Grid item xs={4}>

                        <TextField
                            fullWidth
                            placeholder={'E-mail пользователя'}
                            variant={'outlined'}
                            size={'small'}
                            value={filterUserList.email}
                            name={'email'}

                            onChange={handleOnChangeFilter}
                        />

                    </Grid>

                </Grid>

            </Box>

            <DialogContent style={{padding: 0, paddingBottom: 26}}>

                <Box p={2}>

                    <Box mb={1}>
                        <Typography variant="h4">Найдено пользователей: {usersSystem.length}</Typography>
                    </Box>

                    <Grid container spacing={2}>

                        {
                            usersSystem.map((user, idx) => {
                                const checked = (!!formSetUserToWanted.user_list) ? formSetUserToWanted.user_list.indexOf(user.id) > -1 : false;

                                return (
                                    <Grid item xs={6}>

                                        <Box
                                            className={classes.cardUser}
                                            onClick={() => handleOpenList(user.id)}
                                        >

                                            <Typography>{ user?.full_name || user?.email}</Typography>

                                            <CustomCheckbox checked={checked} type="circle"/>

                                        </Box>

                                    </Grid>
                                )
                            })
                        }

                    </Grid>

                </Box>
            </DialogContent>

            <Box px={3} py={2}>
                <Grid justify={'flex-end'} style={{display: 'flex'}}>
                    <Button
                        size="large"
                        variant="formCancel"
                        style={{marginRight: 10}}
                        onClick={() => onClose()}
                    >
                        Отменить
                    </Button>
                    <Button
                        size="large"
                        type="submit"
                        variant="formSubmit"
                        onClick={onSetUsers}
                    >
                        Применить
                    </Button>
                </Grid>
            </Box>

        </Dialog>
    )
}

const useStyles = makeStyles((theme) => ({
    fileLabel: {
        flex: 1,
        display: 'flex'
    },
    fileTextField: {
        flex: 1
    },

    cardUser: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        width: '100%',
        padding: 16,
        borderRadius: 4,
        backgroundColor: '#f3f5f9'
    }
}));

export default ModalSetUser
