import React, { useState } from 'react';
import {
  Box,
  Grid,
  Checkbox,
  Typography,
  IconButton,
  makeStyles,
  Tooltip,
  Button,
  FormControlLabel
} from '@material-ui/core';
import {
  Check as CheckIcon,
  AddRounded as AddRoundedIcon
} from '@material-ui/icons';
import {
  ChevronDown as ChevronDownIcon,
  ChevronUp as ChevronUpIcon,
  Edit2 as Edit2Icon
} from 'react-feather';
import {useHistory} from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';

const useStyles = makeStyles(theme => ({
  listCheckbox: {
    '& .Mui-checked': {
      opacity: '1!important'
    },
    '& .MuiIconButton-root': {
      borderRadius: 4,
      opacity: 0.3
    },
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
      lineHeight: '16px',
      fontWeight: 'normal',
      width: '100%',
      marginBottom: 0,
      marginLeft: 12,
      color: '#b9bec3'
    },
    '& .MuiFormControlLabel-root': {
      width: '100%',
      margin: 0
    },
    '& .MuiCheckbox-root': {
      padding: 0
    },
    '& .MuiCheckbox-root .MuiIconButton-label svg': {
      backgroundColor: '#212529'
    },
    '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label svg': {
      stroke: '#FFFFFF'
    },
    '& .MuiCheckbox-root.Mui-checked ~ .MuiFormControlLabel-label': {
      color: '#152435'
    },
    '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label path': {
      color: 'white'
    }
  },
  checkbox: {}
}));

const PersonalSearchList = (props) => {
  const {
    onOpenCreatePersonalSearch,

    listPersonalSearch,

    filter,
    onChangeFilter
  } = props;
  const classes = useStyles();
  const history = useHistory();

  const handleChangeListWanted = (wantedId) => {
    let newFilter = filter;
    let list_ids = newFilter.list_ids;

    if (list_ids.indexOf(wantedId) > -1) {
      list_ids.splice(list_ids.indexOf(wantedId), 1)
    } else {
      list_ids.push(wantedId)
    }

    newFilter.list_ids = list_ids;

    onChangeFilter(newFilter)
  }

  const handleToList = () => {
    history.push('/notification-list-personal')
  }

  const handleGetCheckedIcon = (wanted) => {
    let active = filter.list_ids.indexOf(wanted.id) > -1;

    return (
      <CheckIcon style={{
        backgroundColor: wanted.color,
        opacity: (active) ? 1 : 0.3
      }}/>
    )
  }

  return (
    <Box borderRadius={4} boxShadow={1} bgcolor={'white'} p={3}>
      <Box mb={3}>
        <Grid container justify={'space-between'} alignItems={'center'}>
          <Grid item container style={{width: 'auto'}} alignItems={'center'}>
            <Typography variant={'h3'}>Персональный список</Typography>
          </Grid>
          <Grid item>

            <Tooltip title="Открыть раздел личного списка уведомлений">
              <Button variant={'contained'} onClick={handleToList}>
                Редактировать
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={2} className={classes.listCheckbox}>

        {
          listPersonalSearch.map((wanted, idx) => {
            let active = filter.list_ids.length > 0 && filter.list_ids.indexOf(wanted.id) > -1;

            return (
              <Grid item xs={6} key={'list-wanted-' + idx}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={wanted.id}
                      onChange={() => handleChangeListWanted(wanted.id)}
                      checked={active}

                      checkedIcon={handleGetCheckedIcon(wanted)}
                      className={classes.checkbox}
                      style={{backgroundColor: wanted.color}}
                    />
                  }
                  label={wanted.title}
                />
              </Grid>
            )
          })
        }
      </Grid>
    </Box>
  )
}

export default PersonalSearchList
