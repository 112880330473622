import React, {Component} from 'react';
import {
  Backdrop,
  Box,

  Button, CircularProgress,

  Grid,

  Tooltip,

  Typography,
} from '@material-ui/core';
import {
  Page
} from '../../../components';
import {
  Table,
  ModalDelete,
  ModalSetUser,
  ModalEditList,
  ModalLoadFile,
  ModalCreateList
} from './components';
import axios from "../../../plugins/axios";
import urls from '../../../constant/urls';
import {store} from 'react-notifications-component';
import getMessageError from "../../../helpers/getMessageError";
import WithoutAccess from "../../../components/WithoutAccess";

class MobileWanted extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {
        page: 1,
        limit: 20
      },
      filterUserList: {
        email: '',
        full_name: '',
        isRoles: false,

        limit: 3000,
        page: 1,
      },
      formCreatingList: {},
      formEditList: {},
      itemDeleteList: {},
      formUploadWantedFile: {},
      formSetUserToWanted: {},

      wantedList: [],
      usersSystem: [],
      initUsersSystem: [],

      isOpenCreateModal: false,
      isLoadingList: true,
      isOpenBackdrop: false,
      isOpenModalEdit: false,
      isOpenModalUploadFile: false,
      isOpenModalDeleteWantedList: false,
      isOpenModalSetUserWantedList: false
    };

    this.onApplyFilterUser = null;
  }

  componentDidMount = () => {
    this.onGetMobileListWanted();
    this.onGetAllUsersSystem();
  }

  // Получение списка мобильный розысков
  onGetMobileListWanted = () => {
    const filter = this.onGetFilter();

    axios('post', urls["get-mobile-list-wanted"], filter).then((response) => {
      this.setState({
        wantedList: response?.data || [],

        isLoadingList: false,
      })
    });
  }
  onGetFilter = () => {
    return this.state.filter
  }

  // Логика созданиия мобильного списка розывска
  onCreateList = () => {
    this.setState({
      isOpenBackdrop: true,
      isOpenCreateModal: false
    });

    let form = {...this.state.formCreatingList};

    axios('post', urls["create-mobile-list-wanted"], form).then((response) => {

      this.setState({
        isOpenBackdrop: false
      });

      store.addNotification({
        title: 'Успешно',
        message: 'Список уведомлений успешно создан.',
        type: 'success',
        insert: 'top',
        container: 'bottom-left',
        dismiss: {
          duration: 3000,
          onScreen: false,
          pauseOnHover: true,
          delay: 0
        }
      });

      this.onGetMobileListWanted();
    }).catch((error) => {
      const messageError = getMessageError(error);

      this.setState({
        isOpenBackdrop: false
      });

      store.addNotification({
        title: 'Ошибка',
        message: messageError,
        type: 'danger',
        insert: 'top',
        container: 'bottom-left',
        dismiss: {
          duration: 3000,
          onScreen: false,
          pauseOnHover: true,
          delay: 0
        }
      });
    })
  }
  onOpenModalCreateList = () => {
    this.setState({
      isOpenCreateModal: true,
      formCreatingList: {},
    })
  }
  onCloseModalCreateList = () => {
    this.setState({
      isOpenCreateModal: false,
      formCreatingList: {},
    })
  }

  // Логика редактиорвания мобильного списка розыска
  onOpenEditWanedList = (wantedList) => {
    this.setState({
      isOpenModalEdit: true,
      formEditList: {...wantedList}
    })
  }
  onChangeWantedList = () => {
    this.setState({isOpenBackdrop: true});

    const form = {...this.state.formEditList};

    axios('post', `${urls["edit-mobile-list-wanted"]}/${form.id}`, form).then((response) => {
      this.setState({
        isOpenBackdrop: false,
        isOpenModalEdit: false
      });

      store.addNotification({
        title: 'Успешно',
        message: 'Список уведомлений успешно изменен.',
        type: 'success',
        insert: 'top',
        container: 'bottom-left',
        dismiss: {
          duration: 3000,
          onScreen: false,
          pauseOnHover: true,
          delay: 0
        }
      });

      this.onGetMobileListWanted();
    }).catch((error) => {
      error = error.response.data;

      this.setState({isOpenBackdrop: false});

      store.addNotification({
        title: 'Ошибка',
        message: error.details,
        type: 'danger',
        insert: 'top',
        container: 'bottom-left',
        dismiss: {
          duration: 3000,
          onScreen: false,
          pauseOnHover: true,
          delay: 0
        }
      });
    });
  }

  // Логика удаления мобильного списка розыска
  onOpenDeleteWantedList = (wantedList) => {
    this.setState({
      itemDeleteList: {...wantedList},
      isOpenModalDeleteWantedList: true
    })
  }
  onDeleteWantedList = () => {
    this.setState({isOpenBackdrop: true});

    const form = {...this.state.itemDeleteList};

    axios('get', `${urls["delete-mobile-list-wanted"]}/${form.id}`).then((response) => {
      this.setState({
        isOpenBackdrop: false,
        isOpenModalDeleteWantedList: false
      });

      store.addNotification({
        title: 'Успешно',
        message: 'Список уведомлений успешно удален.',
        type: 'success',
        insert: 'top',
        container: 'bottom-left',
        dismiss: {
          duration: 3000,
          onScreen: false,
          pauseOnHover: true,
          delay: 0
        }
      });

      this.onGetMobileListWanted();
    }).catch((error) => {
      error = error.response.data;

      this.setState({isOpenBackdrop: false});

      store.addNotification({
        title: 'Ошибка',
        message: error.details,
        type: 'danger',
        insert: 'top',
        container: 'bottom-left',
        dismiss: {
          duration: 3000,
          onScreen: false,
          pauseOnHover: true,
          delay: 0
        }
      });
    });
  }

  // Логика загрузки файла
  onOpenUploadFileToWantedList = (formUploadWantedFile) => {
    this.setState({
      formUploadWantedFile,
      isOpenModalUploadFile: true
    })
  }
  onUploadFileToWantedList = () => {
    this.setState({isOpenBackdrop: true});

    let form = this.state.formUploadWantedFile;
    let json = {
      list_id: form.id,
      type: 'csv'
    };

    let data = new FormData();

    data.append('file', form.file);
    data.append('data', JSON.stringify(json));

    axios('post', `${urls["upload-mobile-list-wanted"]}`, data).then(response => {
      this.setState({
        isOpenBackdrop: false,
        isOpenModalUploadFile: false
      });

      store.addNotification({
        title: 'Успешно',
        message: 'Элементы списка уведомлений успешно загруженны.',
        type: 'success',
        insert: 'top',
        container: 'bottom-left',
        dismiss: {
          duration: 3000,
          onScreen: false,
          pauseOnHover: true,
          delay: 0
        }
      });

      this.onGetMobileListWanted();
    }).catch((error) => {
      error = error.response.data;

      this.setState({isOpenBackdrop: false});

      store.addNotification({
        title: 'Ошибка',
        message: error.details,
        type: 'danger',
        insert: 'top',
        container: 'bottom-left',
        dismiss: {
          duration: 3000,
          onScreen: false,
          pauseOnHover: true,
          delay: 0
        }
      });
    })
  }

  // Получение всех пользователей (для устоновки на список)
  onGetAllUsersSystem = () => {
    axios('post', `${urls["user-get-list"]}`, this.state.filterUserList).then((response) => {
      let usersSystem = response.data;

      if (!usersSystem) {
        usersSystem = [];
      }

      this.setState({
        initUsersSystem: usersSystem
      }, () => {
        this.onChangeFilterUsers();
      })
    })
  }

  onOpenModalSetUsersWantedList = (formSetUserToWanted) => {
    this.setState({
      formSetUserToWanted,
      isOpenModalSetUserWantedList: true
    })
  }
  onSetModalSetUsersWantedList = () => {
    this.setState({isOpenBackdrop: true});

    const form = {...this.state.formSetUserToWanted};

    axios('post', `${urls["edit-mobile-list-wanted"]}/${form.id}`, form).then((response) => {
      this.setState({
        isOpenBackdrop: false,
        isOpenModalSetUserWantedList: false
      });

      store.addNotification({
        title: 'Успешно',
        message: 'Пользователи успешно назначены.',
        type: 'success',
        insert: 'top',
        container: 'bottom-left',
        dismiss: {
          duration: 3000,
          onScreen: false,
          pauseOnHover: true,
          delay: 0
        }
      });

      this.onGetMobileListWanted();
    }).catch((error) => {
      error = error.response.data;

      this.setState({isOpenBackdrop: false});

      store.addNotification({
        title: 'Ошибка',
        message: error.details,
        type: 'danger',
        insert: 'top',
        container: 'bottom-left',
        dismiss: {
          duration: 3000,
          onScreen: false,
          pauseOnHover: true,
          delay: 0
        }
      });
    });
  }
  onCloseModalSetUsers = () => {
    this.setState({
      isOpenModalSetUserWantedList: false,
      filterUserList: {
        email: '',

        limit: 999,
        page: 1,
      }
    }, () => {
      this.onChangeFilterUsers();
    })
  }
  onChangeFilterUsers = (filterUserList = this.state.filterUserList) => {
    let initUsersSystem = [...this.state.initUsersSystem];

    let usersSystem = initUsersSystem.filter((user) => {
      if (!!filterUserList.email && user.email.indexOf(filterUserList.email) <= -1) {
        return null
      }
      if (!!filterUserList.full_name && user.full_name.toLowerCase().indexOf(filterUserList.full_name.toLowerCase()) <= -1) {
        return null
      }
      if (!user?.roles?.['mobile.base']) {
        return null
      }

      return user
    })

    this.setState({
      usersSystem,
      filterUserList
    })
  }

  _isShowButtonCreateList = () => {
    const userRoles = this.props.user?.roles || {};

    return Boolean(userRoles["mobile.list"] || userRoles["admin"])
  }

  render() {
    return (
      <Page>

        <Box mb={2}>
          <Grid container alignItems={'center'}>
            <Box mr={2}>
              <Typography variant={'h3'}>
                Мобильные списки уведомлений
              </Typography>
            </Box>

            {Boolean(this._isShowButtonCreateList()) && (
              <Box ml="auto">
                <Tooltip title={'Добавление списка уведомлений'}>
                  <Button variant={'contained'} onClick={this.onOpenModalCreateList}>
                    Добавить список
                  </Button>
                </Tooltip>
              </Box>
            )}
          </Grid>
        </Box>

        <Box>
          <Table
            rows={this.state.wantedList}

            isLoading={this.state.isLoadingList}

            onOpenEditWanedList={this.onOpenEditWanedList}
            onOpenDeleteWantedList={this.onOpenDeleteWantedList}
            onOpenUploadFileToWantedList={this.onOpenUploadFileToWantedList}
            onOpenModalSetUsersWantedList={this.onOpenModalSetUsersWantedList}
          />
        </Box>

        <ModalCreateList
          open={this.state.isOpenCreateModal}
          form={this.state.formCreatingList}

          onChangeForm={(formCreatingList) => this.setState({formCreatingList})}
          onCreateList={this.onCreateList}
          onClose={this.onCloseModalCreateList}
        />

        <ModalLoadFile
          open={this.state.isOpenModalUploadFile}
          onClose={() => {
            this.setState({isOpenModalUploadFile: false})
          }}
          form={this.state.formUploadWantedFile}
          onChangeLoadFile={(formUploadWantedFile) => this.setState({formUploadWantedFile})}
          onLoadFile={this.onUploadFileToWantedList}
        />

        <ModalEditList
          open={this.state.isOpenModalEdit}
          onClose={() => {
            this.setState({isOpenModalEdit: false})
          }}

          form={this.state.formEditList}
          onSaveList={this.onChangeWantedList}
          onChangeForm={(formEditList) => this.setState({formEditList})}
        />

        <ModalDelete
          isDelete={this.onDeleteWantedList}
          item={this.state.itemDeleteList}
          open={this.state.isOpenModalDeleteWantedList}
          isClose={() => {
            this.setState({isOpenModalDeleteWantedList: false})
          }}
        />

        <ModalSetUser
          isOpen={this.state.isOpenModalSetUserWantedList}

          usersSystem={this.state.usersSystem}
          filterUserList={this.state.filterUserList}
          formSetUserToWanted={this.state.formSetUserToWanted}

          onClose={this.onCloseModalSetUsers}
          onSetUsers={this.onSetModalSetUsersWantedList}
          onChangeForm={(formSetUserToWanted) => this.setState({formSetUserToWanted})}
          onChangeFilterUserList={this.onChangeFilterUsers}
        />

        <Backdrop open={this.state.isOpenBackdrop} invisible={this.state.isOpenBackdrop}>
          <CircularProgress size={80} style={{color: 'white'}}/>
        </Backdrop>

      </Page>
    );
  }
}

export default MobileWanted
