import React, {Component} from 'react';
import {
    Box,
    Grid,
    Button,
    Tooltip,
    Typography
} from '@material-ui/core';
import {
    Page,
    BlockWantedLists
} from "../../../components";
import {
    Table as TableComponent,
    Filter as FilterComponent,
    ExportCreate as ExportCreateComponent
} from "./components";
import {getPageFromCount} from "../../../common/pagination";

import axios from "../../../plugins/axios";
import urls from "../../../constant/urls";
import {Filter} from "../WantedMobileList/components";
import {addNotification} from "../../../helpers/notification";
import moment from "moment";
import {formatMoney} from "../../../helpers/format";
import {store} from "react-notifications-component";

class Archive extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            mobileWantedList: [],
            sensors: [],
            sensorsInitial: [],

            filter: {
                list_ids: [],
                sensor_ids: [],

                license_plate: "",

                datetime_start: "",
                datetime_end: "",

                limit: 20,
                page: 1
            },
            filterSensor: {
                address: '',
                direction: '',
                city: '',
                limit: 999,
                page: 1
            },
            pagination: {
                page: 1,
                totalItems: 0
            },

            isLoading: false,
            isEmptyWantedList: false
        }
    }

    componentDidMount = async () => {
        this.getSensors(true);
        await this.getWanted();
    }

    // initial
    getSensors = (initial = false) => {
        axios('post', urls["mobile-sensor-get-list"], this.state.filterSensor).then((response) => {
            this.setState({
                sensors: response.data || [],
                sensorsInitial: initial ? response.data || [] : this.state.sensorsInitial,
            })
        })
    }
    getWanted = async () => {
        const allList = await axios('post', urls["mobile-list-get-all"], {limit: 999, page: 1}).then((response) => {
            return response?.data || []
        });

        this.setState({
            mobileWantedList: allList
        })
    }

    // Логика работы с архивными данными
    getArchive = () => {
        if (this.state.filter.list_ids.length <= 0){
            addNotification({
                title: "Фильтр",
                message: "Выберите список уведомлений"
            })

            return null
        }

        this.setState({isLoading: true});
        const filter = this.getFilter();

        axios('post', urls["mobile-archive-get"], filter).then((response) => {
            const { data, headers } = response;
            let pagination = {...this.state.pagination};
            pagination.page = getPageFromCount(headers['x-pagination-total-count'], this.state.filter.limit);
            pagination.totalItems = headers['x-pagination-total-count'];

            this.setState({
                data: data || [],
                pagination: pagination,
                isLoading: false
            });
        }).catch((error) => {
            const { response } = error;

            addNotification({
                title: "Ошибка",
                message: response.data.details,
                type: "danger"
            })

            this.setState({
                data: [],
                isLoading: false
            });
        });
    }
    getFilter = () => {
        const filterInit = {...this.state.filter};
        let filter = {};

        Object.keys(filterInit).map((key) => {
            if (
                filterInit[key] &&
                key !== "datetime_start" &&
                key !== "datetime_end"
            ) {
                filter[key] = filterInit[key];
            }

            if (filterInit[key] && (key === "datetime_start" || key === "datetime_end")) {
                filter[key] = moment(filterInit[key]).toISOString();
            }
        });

        return filter
    }
    onReset = () => {
        const filter =  {
            list_ids: [],
                sensor_ids: [],

                license_plate: "",

                datetime_start: "",
                datetime_end: "",

                limit: 20,
                page: 1
        };

        this.onChangeFilter(filter, true);
    }

    onChangeWantedList = (list_ids) => {
        let newFilter = {...this.state.filter};
        newFilter.list_ids = list_ids;
        newFilter.page = 1;

        this.onChangeFilter(newFilter);
    }

    // Логика работы с фильтром
    onChangeFilter = (filter, isFastStart) => {
        this.setState({filter}, () => {
            if (isFastStart) {
                this.getArchive();
            }
        })
    }
    onChangeFilterSensors = (filterSensor) => {
        this.setState({
            filterSensor
        }, () => {
            const filterSensor = {...this.state.filterSensor};
            const sensorsInitial = [...this.state.sensorsInitial];

            let sensors = sensorsInitial.filter((sensor) => {
                if (
                    (sensor.city).toLowerCase().indexOf(filterSensor.city.toLowerCase()) > -1 &&
                    (sensor.address).toLowerCase().indexOf(filterSensor.address.toLowerCase()) > -1
                ) {
                    return sensor
                }
            });

            this.setState({
                sensors
            })
        })
    }

    // Экспорт документа
    exportDocument = (props) => {
        let filter = this.getFilter();
        delete filter.limit;
        let data = {...filter};

        data['type'] = props.type;
        data['encoding'] = Number(props.encoding);

        axios('post', '/mobile/export/create', data).then(response => {
            store.addNotification({
                title: 'Успешно',
                message: 'Задача на выгрузку успешно создана',
                type: 'success',
                insert: 'top',
                container: 'bottom-left',
                dismiss: {
                    duration: 3000,
                    onScreen: false,
                    pauseOnHover: true,
                    delay: 0
                }
            });
        }).catch(error => {

        })
    }

    render() {
        const { data, filter, pagination, isLoading, isEmptyWantedList } = this.state;

        if (isEmptyWantedList) {
            return (
                <Page>
                    <Box mb={3}>
                        <Typography variant="h1">Архив</Typography>
                    </Box>

                    <Box>
                        <Typography variant="h4">У вас нет списков уведомлений.</Typography>
                    </Box>
                </Page>
            )
        }

        return (
            <Page>
                <Box mb={3}>
                    <Typography variant="h1">Архив</Typography>
                </Box>

                <Box mb={4}>
                    <FilterComponent
                        filter={this.state.filter}

                        sensors={this.state.sensors}
                        sensorsInitial={this.state.sensorsInitial}
                        filterSensor={this.state.filterSensor}


                        onChange={this.onChangeFilter}
                        onChangeFilterSensors={this.onChangeFilterSensors}
                        onSetActiveFilter={this.onSetActiveFilter}

                        onSearch={this.getArchive}
                        onReset={this.onReset}
                    />
                </Box>

                <Box>
                    <Grid container spacing={3}>
                        <Grid item xs={8}>
                            <TableComponent
                                data={data}
                                filter={filter}
                                pagination={pagination}
                                isLoading={isLoading}

                                onChangeFilter={this.onChangeFilter}
                                exportDocument={this.exportDocument}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Box mb={2}>
                                <Grid container alignItems="center" justify="space-between">
                                    <Grid item>
                                        <Typography variant="subtitle1" style={{fontWeight: "500"}}>&nbsp;</Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            <BlockWantedLists
                                items={this.state.mobileWantedList}
                                itemsActive={filter?.list_ids || []}

                                onChange={this.onChangeWantedList}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Page>
        );
    }
}

export default Archive
