import React, {useState} from "react";
import {
    Box,
    Grid,
    Typography,

    Button,
    TextField
} from "@material-ui/core";

import ModalSensor from "./ModalSensor";
import {DateRange} from "../../../../../components";

const Filter = (props) => {
    const {
        sensors,
        sensorsInitial,
        filter,
        filterSensor,
        onChangeFilterSensors,
        onChange,
        onSearch,
        onReset,
        mobileWantedList
    } = props;

    const [isOpenModalSensors, setOpenModalSensors] = useState(false);

    const handleCloseSensors = () => {
        let newFilterSensor = {
            address: '',
            direction: '',
            city: '',
            limit: 999,
            page: 1
        };

        onChangeFilterSensors(newFilterSensor);
        setOpenModalSensors(false);
    }
    const handleSetActiveFilter = () => {
        setOpenModalSensors(false);
    }

    const handleChangeFilter = ({target}) => {
        const { value, name } = target;
        let newFilter = {...filter};
        newFilter[name] = value;
        newFilter.page = 1;

        onChange(newFilter)
    }
    const handleChangeDate = (dateRange) => {
        let newFilter = {...filter};
        newFilter.datetime_start = dateRange.startDate * 1000;
        newFilter.datetime_end = dateRange.endDate * 1000;
        newFilter.page = 1;

        onChange(newFilter)
    }

    return (
        <Box mb={4} bgcolor="white" borderRadius={4} p={3}>

            <Box mb={2}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="formTitle">Устройства</Typography>

                        <Button
                            fullWidth
                            variant="contained"

                            onClick={() => setOpenModalSensors(true)}
                        >
                            Выбрано {filter.sensor_ids.length} из {sensorsInitial.length}
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="formTitle">ГРЗ</Typography>

                        <TextField
                            value={filter.license_plate}
                            name="license_plate"
                            variant="outlined"
                            placeholder="В888ВВ88"
                            fullWidth

                            onChange={handleChangeFilter}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="formTitle">Временной промежуток</Typography>

                        <DateRange
                            dateStart={filter.datetime_start}
                            dateEnd={filter.datetime_end}
                            onChange={handleChangeDate}
                        />
                    </Grid>
                </Grid>
            </Box>

            <Grid container spacing={2}>
                <Grid item>
                    <Button variant="contained" onClick={onSearch}>Поиск</Button>
                </Grid>
                <Grid item>
                    <Button variant="outlined" onClick={onReset}>Сбросить</Button>
                </Grid>
            </Grid>

            <ModalSensor
                isOpen={isOpenModalSensors}

                sensors={sensors}
                sensorsInitial={sensorsInitial}
                filterSensor={filterSensor}
                activeFilter={filter}

                onClose={handleCloseSensors}
                onChangeFilterSensors={onChangeFilterSensors}
                onChangeActiveFilter={onChange}
                onSetActiveFilter={handleSetActiveFilter}
            />

        </Box>
    )
};

export default Filter
