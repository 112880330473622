import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    Grid,
    Typography,
    TextField,
    Button,
    Tooltip,
} from "@material-ui/core";
import { url_slug } from 'cyrillic-slug'

const DialogCreated = (props) => {
    const { isOpen, onClose, onCreate } = props;

    const [name, setName] = useState("");
    const [slug, setSlug] = useState("");
    const [isError, setError] = useState(false);

    const handleOnChange = ({target}) => {
        const { value } = target;

        setName(value);
        setSlug(url_slug(value));
    }
    const handleOnChangeSlug = ({target}) => {
        const { value } = target;
        setSlug(value);
    }
    const handleOnCreate = () => {
        if (!name || !slug) {
            setError(true);

            return null
        }

        onCreate({name, slug})
    }

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle>
                <Typography variant="h3">Создание категории</Typography>
            </DialogTitle>

            <DialogContent>
                <Box mb={2}>
                    <Typography variant="formTitle">Наименование</Typography>
                    <TextField
                        value={name}
                        variant="outlined"
                        placeholder="..."
                        fullWidth
                        onChange={handleOnChange}
                    />
                    {
                        (isError && !name) && (
                            <Typography variant="caption" color="error">Наименование не может быть пустым</Typography>
                        )
                    }
                </Box>
                <Box>
                    <Typography variant="formTitle">Ссылка на раздел</Typography>
                    <TextField
                        value={slug}
                        variant="outlined"
                        placeholder="..."
                        fullWidth
                        onChange={handleOnChangeSlug}
                    />

                    {
                        (isError && !slug) && (
                            <Typography variant="caption" color="error">Ссылка на раздел не может быть пустой</Typography>
                        )
                    }
                </Box>
            </DialogContent>

            <DialogActions>
                <Box px={2} width="100%">
                    <Grid container spacing={2}>
                        <Grid item>
                            <Button variant="contained" onClick={handleOnCreate}>Создать</Button>
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" onClick={onClose}>Отмена</Button>
                        </Grid>
                    </Grid>
                </Box>
            </DialogActions>

        </Dialog>
    )
}

export default DialogCreated
