import React, {Component} from 'react';
import {
	Box,
	Grid,
	Button,
	Backdrop,
	TextField,
	Typography,
	FormHelperText,
	CircularProgress,
} from '@material-ui/core';
import {
	makeStyles
} from "@material-ui/styles";
import { Formik } from 'formik';
import * as Yup from 'yup';
import clsx from "clsx";
import agent from "../../../plugins/axios";
import {sendMessage} from "../../../utils/message";

const initValues = {
	file: null,
	reason: "",
	license_plate: "",
};
class Outdoor extends Component {
	constructor(props) {
		super(props);

		this.state = {
			formValues: JSON.parse(JSON.stringify({...initValues})),
			isBackdrop: false
		};
		this.refFormik = React.createRef();
	}

	componentDidMount = () => {
	}

	changeValues = ({ target }) => {
		const { name, value } = target;
		let values = this.refFormik.current.values || {};
		values[name] = value;
		this.refFormik.current.setValues(values);
	}

	createAdditionalLp = async (values) => {
		this.setState({ isBackdrop: true });

		const body = {...values};
		const formData = new FormData();

		formData.append("file", body.file);
		delete body.file;

		formData.append("data", JSON.stringify(body));

		const res = await agent('post', '/operator/create-additional-lp', formData).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		});
		if (res.error) {
			this.setState({ isBackdrop: false });
			sendMessage({
				message: "Произошла ошибка, обратитесь к администратору.",
				type: "danger"
			})
			return
		}

		this.refFormik.current.setValues(JSON.parse(JSON.stringify({ ...initValues })));
		this.refFormik.current.setErrors({});
		this.setState({ isBackdrop: false });
		sendMessage({
			message: "ГРЗ успешно отправлен",
			type: "success"
		})
	}

	render() {
		const {
			formValues,
			isBackdrop
		} = this.state;

		return (
			<>

				<Formik
					innerRef={this.refFormik}
					initialValues={formValues}
					validationSchema={validationSchema}
					onSubmit={this.createAdditionalLp}
				>{(params) => {
					const {
						values,
						errors,
						touched,
						handleSubmit
					} = params;
					console.log('params: ', params);

					return (
						<Grid container spacing={4}>
							<Grid item xs={12}>
								<FileUploadComponent
									value={values.file}
									error={Boolean(errors.file && touched.file)}
									helperText={touched.file && errors.file}
									onChange={this.changeValues}
								/>
							</Grid>
							<Grid item xs={12}>
								<Box mb={1}>
									<Typography variant="h6">ГРЗ автомобиля <sup>*</sup></Typography>
								</Box>
								<TextField
									value={values.license_plate}
									name="license_plate"
									placeholder="Введите ГРЗ автомобиля"
									variant="outlined"
									fullWidth
									error={Boolean(errors.license_plate && touched.license_plate)}
									helperText={touched.license_plate && errors.license_plate}
									onChange={this.changeValues}
								/>
							</Grid>
							<Grid item xs={12}>
								<Box mb={1}>
									<Typography variant="h6">Причина внесения ТС <sup>*</sup></Typography>
								</Box>
								<TextField
									value={values.reason}
									name="reason"
									placeholder="Введите причину внесения ТС"
									variant="outlined"
									fullWidth
									multiline
									rows={3}
									error={Boolean(errors.reason && touched.reason)}
									helperText={touched.reason && errors.reason}
									onChange={this.changeValues}
								/>
							</Grid>
							<Grid item xs={12}>
								<Button
									fullWidth
									variant="contained"
									onClick={handleSubmit}
								>
									Отправить
								</Button>
							</Grid>
						</Grid>
					)
				}}</Formik>

				<Backdrop open={isBackdrop} invisible={isBackdrop}>
					<CircularProgress/>
				</Backdrop>
			</>
		);
	}
}
const FileUploadComponent = React.memo((props) => {
	const {
		value,
		error,
		helperText,
		onChange
	} = props;
	const classes = useStyles();

	const handleSelectImage = (event) => {
		const file = event.target?.files?.[0] || null;
		if (!file) {
			return
		}

		onChange({
			target: {
				name: 'file',
				value: file
			}
		})
	}

	return (
		<>
			<Box mb={1}>
				<Typography variant="h4">Фото</Typography>
			</Box>
			<Box
				className={clsx({
					[classes.boxImageSelected]: true,
					'--selected': Boolean(value),
					'--not-selected': Boolean(!value),
				})}
			>
				{Boolean(!value) && (
					<Typography variant="caption">Сделайте фото</Typography>
				)}
				{Boolean(value) && (
					<img
						src={URL.createObjectURL(value)}
					/>
				)}
			</Box>
			{Boolean(error) && (
				<FormHelperText error style={{position: "initial"}}>
					{helperText}
				</FormHelperText>
			)}
			<Box mt={1}>
				<label>
					<Button variant="contained" size="small" fullWidth component="span">
						Сделать фото
					</Button>
					<input type="file" accept="image/*" capture="environment" hidden onChange={handleSelectImage}/>
				</label>
			</Box>
		</>
	)
})

const useStyles = makeStyles(() => ({
	boxImageSelected: {
		width: "100%",
		height: 180,
		borderRadius: "8px",
		position: "relative",
		overflow: "hidden",
		border: "1px solid rgba(0,0,0,0.1)",

		"&.--not-selected": {
			display: "flex",
			alignItems: "center",
			justifyContent: "center"
		},
		"&.--selected": {
			display: "flex",
			alignItems: "center",
			justifyContent: "center"
		},
		"&.--selected img": {
			width: "100%",
			maxWidth: "100%",
			height: "100%",
			maxHeight: "100%",
			objectFit: "contain"
		},
	}
}));
const validationSchema = Yup.object().shape({
	file: Yup.mixed().nullable().required('Выберите фото'),
	reason: Yup.string().required('Введите причину внесения'),
	license_plate: Yup.string().required('Введите ГРЗ'),
});

export default Outdoor
