import React from "react";
import {
    Box,
    Grid,
    Button,
    Tooltip,
    IconButton,
    Typography,
} from "@material-ui/core";
import {
    Edit2 as Edit2Icon,
    Trash as TrashIcon
} from "react-feather";
import {
    Skeleton
} from "@material-ui/lab";
import {
    makeStyles
} from "@material-ui/styles";
import {useHistory} from "react-router-dom";

const TableList = (props) => {
    const {rows, isAdmin, isLoading, onDelete} = props;
    const classes = useStyles();
    const history = useHistory();

    if (isLoading) {
        return (
            <Grid container spacing={2}>
                {[0, 1, 2, 3, 4].map((category, idx) => (
                    <Box width="100%" borderRadius={5} bgcolor="white" px={3} py={2} mb={2}>
                        <Grid container justify="space-between" wrap="nowrap" alignItems="center">
                            <Grid item>
                                <Skeleton height="48"/>
                            </Grid>
                            {
                                isAdmin && (
                                    <Grid item>
                                        <Grid container spacing={1} wrap="nowrap">
                                            <Grid item>
                                                <Skeleton height="48" width="48" variant="circle"/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Box>
                ))}
            </Grid>
        )
    }

    const handleRoute = (slug, {target}) => {
        const isCancel = Boolean(target.closest('#cancel-click'));

        if (isCancel) {
            return null
        }

        history.push(`/reference/${slug}`);
    }

    return (
        <Grid container spacing={2}>
            {rows.map((category, idx) => {
                if (category.parent_id !== '000000000000000000000000') {
                    return null
                }

                return (
                    <Box
                        key={`category-${idx}`} width="100%" borderRadius={5} bgcolor="white" px={3} py={2} mb={2}
                        className={classes.category}
                        onClick={(event) => handleRoute(category.slug, event)}
                    >
                        <Grid container justify="space-between" wrap="nowrap" alignItems="center">
                            <Grid item>
                                <Typography variant="h4">{category.title}</Typography>
                            </Grid>

                            {
                                isAdmin && (
                                    <Grid item>
                                        <Grid container spacing={1} wrap="nowrap">
                                            <Grid item>
                                                <Tooltip title="Удалить">
                                                    <IconButton id="cancel-click" onClick={() => onDelete(category)}>
                                                        <TrashIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Box>
                )
            })}
        </Grid>
    )
}

const useStyles = makeStyles((theme) => ({
    category: {
        boxShadow: "0px 2px 8px rgba(142, 150, 168, 0.24)",
        transition: "all 0.5s",
        cursor: "pointer",

        '&:hover': {
            boxShadow: "0px 2px 8px rgba(142, 150, 168, 0.60)",
        }
    }
}));

export default TableList
