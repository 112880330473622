import React, {useState, useEffect, useRef} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Grid,
    Paper,
    makeStyles,
    Button,
    IconButton,
    FormControlLabel,
    Switch, DialogActions, Checkbox
} from '@material-ui/core';
import Draggable from 'react-draggable';
import {
    Map,
    Table
} from '../index';
import {withStyles} from '@material-ui/core/styles';
import {
    X as XIcon,
    Check as CheckIcon
} from 'react-feather';
import {CustomCheckbox} from '../../../index';
import clsx from "clsx";
import {checkPointFromPoligon} from "../../../../helpers/checkPointPoligon";
import {ref} from "yup";

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        height: '100vh',
        maxHeight: '65vh',
        padding: 0
    },

    buttonClear: {
        height: 38,
        padding: '5px 15px'
    }
}));

const styles = (theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        margin: 0,
        padding: '10px 24px',
        paddingRight: 50,
        position: 'relative'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: '50%',
        transform: 'translate(0, -50%)',
        color: '#152435',
    },

    switchTypeTitle: {
        fontWeight: '500',
        fontFeatureSettings: "'ss01' on"
    },

    gridSwitch: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
        width: 'auto'
    },
    gridTypes: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 42
    },
    typeTitle: {
        marginRight: 20,
        fontSize: 13,
        lineHeight: '17px',
        color: theme.palette.gray.dark
    },
    switchTypeButton: {
        fontSize: 15,
        lineHeight: '15px',
        color: theme.palette.gray.dark,
        cursor: 'pointer',
        borderBottom: '1px solid transparent',

        '&:hover': {
            color: theme.palette.gray.light,
        }
    },
    switchTypeButtonActive: {
        color: theme.palette.primary.main,
        fontWeight: '500',
        borderBottom: '1px solid'
    },

    switch: {
        '& .MuiSwitch-colorSecondary': {
            color: '#485DD2'
        },
        '& .MuiSwitch-colorSecondary + .MuiSwitch-track': {
            backgroundColor: '#212529'
        },
    }
});

function PaperComponent(props) {
    return (
        <Draggable
            handle="#dialog-confirmation-deletion"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const MyDialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, showMap, isChangeView, ...other} = props;
    return (
        <DialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>

            <Grid spacing={2} className={classes.gridSwitch} container>
                <Grid item>
                    <Typography className={classes.switchTypeTitle} variant={'subtitle1'}>Отображать </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        className={
                            clsx({
                                [classes.switchTypeButton]: true,
                                [classes.switchTypeButtonActive]: !showMap
                            })
                        }
                        variant={'subtitle1'}
                        onClick={() => isChangeView(false)}
                    >списком</Typography>
                </Grid>
                <Grid item>
                    <Typography
                        className={
                            clsx({
                                [classes.switchTypeButton]: true,
                                [classes.switchTypeButtonActive]: showMap
                            })
                        }
                        variant={'subtitle1'}
                        onClick={() => isChangeView(true)}
                    >на карте</Typography>
                </Grid>
            </Grid>

            {
                false && (
                    <Grid spacing={1} className={classes.gridTypes}>
                        <Typography variant={'body2'} className={classes.typeTitle}>Показать: </Typography>
                        <CustomCheckbox
                            label={'Стационарные'}
                        />
                        <CustomCheckbox
                            label={'Переносные'}
                        />
                    </Grid>
                )
            }


            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                    size={'small'}
                >
                    <XIcon/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
});

const ModalDevices = (props) => {
    const classes = useStyles();
    const [polygons, setPolygons] = useState([]);
    const [showMap, setShowMap] = useState(false);
    const refMap = useRef(null);
    const refEditControl = useRef(null);
    const {
        open,
        isClose,
        devicesSensor,
        devicesSensorActive,
        isChangeDevicesSensor,

        filter,
        onFilter,
        onStartFilter
    } = props;

    const [isShowOnlySelected, setShowOnlySelected] = useState(false);

    useEffect(() => {
        if (polygons.length > 0) {
            handlePolygonFilter(false)
        }
    }, [polygons]);

    const handleSelectRowTable = (devices) => {
        isChangeDevicesSensor(devices)
    }
    const handleClearDevices = () => {
        isChangeDevicesSensor([])
        setPolygons([])

        if (refMap && refMap.current) {
            let layerContainer = refEditControl.current.leafletElement.options.edit.featureGroup;
            let layers = layerContainer._layers;
            let layer_ids = Object.keys(layers);

            layer_ids.forEach(id => {
                let layer = layers[id]
                layerContainer.removeLayer(layer);
            })
        }
    }

    const handlePolygonFilter = (closeMap) => {
        let listPolygon = polygons;
        let listSensor = devicesSensor;
        let newSelected = [];

        listPolygon.map(polygon => {
            let list = [];

            polygon.map(point => {
                list.push(point)
            })

            listSensor.map(point => {
                point['lat'] = point['gps']['lat'];
                point['lng'] = point['gps']['lon'];

                let checkPointPoligon = checkPointFromPoligon(list, point);

                if (checkPointPoligon) {
                    newSelected.push(point.id)
                }
            })
        })

        devicesSensorActive.map(item => {
            if (newSelected.indexOf(item) <= -1) {
                newSelected.push(item)
            }
        })

        handleSelectRowTable(newSelected);

        if (closeMap) {
            setPolygons([]);
            setShowMap(false)
        }
    }

    const handleFilter = () => {
        onStartFilter()
        isClose()
    }

    const handleSetPoligons = (poligons) => {
        setPolygons(poligons);

        handlePolygonFilter(false)
    }

    return (
        <Dialog
            fullWidth
            open={open}
            maxWidth={'lg'}
            onClose={isClose}
            PaperComponent={PaperComponent}
            aria-labelledby="dialog-confirmation-deletion"
        >
            <MyDialogTitle
                id="dialog-confirmation-deletion"
                onClose={isClose}
                showMap={showMap}
                isChangeView={(value) => setShowMap(value)}
            >
                <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                        <Typography variant='h3'>Выбор устройства</Typography>
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isShowOnlySelected}
                                    checkedIcon={<CheckIcon/>}
                                    onClick={() => setShowOnlySelected(!isShowOnlySelected)}
                                />
                            }
                            labelPlacement="start"
                            label="Показать только выбранные"
                        />
                    </Grid>
                </Grid>
            </MyDialogTitle>
            <DialogContent className={classes.dialogContent}>
                {
                    (showMap) ?
                        (
                            <Map
                                setRef={refMap}
                                setRefEditControl={refEditControl}

                                position={[56.8519000, 60.6122000]}
                                devicesSensor={devicesSensor}
                                selected={devicesSensorActive}
                                isSelectRowTable={handleSelectRowTable}
                                isChangeView={() => setShowMap(!showMap)}

                                polygons={polygons}
                                setPolygons={handleSetPoligons}
                            />
                        )
                        :
                        (
                            <Table
                                rows={devicesSensor}
                                selected={devicesSensorActive}
                                isSelectRowTable={handleSelectRowTable}
                                filter={filter}
                                onFilter={(filter) => onFilter(filter)}

                                isShowOnlySelected={isShowOnlySelected}
                            />
                        )
                }
            </DialogContent>
            <DialogContent>
                <Grid container spacing={2} justify={'flex-end'}>
                    <Grid item>
                        <Button
                            onClick={handleClearDevices}
                            className={classes.buttonClear}

                            style={{marginRight: 10}}
                            disabled={!devicesSensorActive || devicesSensorActive.length <= 0}
                            variant="formCancel"
                        >
                            Сбросить
                        </Button>


                        {
                            (showMap) && (
                                <Button
                                    onClick={() => handlePolygonFilter(true)}
                                    size="small"
                                    variant="formSubmit"
                                    className={classes.buttonClear}
                                >
                                    Применить
                                </Button>
                            )
                        }

                        {
                            (!showMap) && (
                                <Button
                                    onClick={handleFilter}
                                    size="small"
                                    variant="formSubmit"
                                    className={classes.buttonClear}
                                >
                                    Применить
                                </Button>
                            )
                        }
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default ModalDevices
