import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  makeStyles,
  Grid,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Tabs,
  Tab,
  Box,
  IconButton,
  TableHead,
  Button
} from '@material-ui/core';
import Draggable from 'react-draggable';
import {Map, TileLayer, Marker} from 'react-leaflet';
import ReactPlayer from 'react-player/lazy'
import {withStyles} from '@material-ui/core/styles';
import {
  X as XIcon,
  Copy as CopyIcon
} from 'react-feather';
import {
  Image,
  CustomMoment as Moment
} from '../../../../../components';
import {store} from 'react-notifications-component';

const useStyles = makeStyles((theme) => ({
  buttonDelete: {
    color: 'rgba(0, 0, 0, 0.4)'
  },
  leafletContainer: {
    width: '100%',
    height: '100%'
  },

  image: {
    maxHeight: '100%',
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },

  tabsContent: {
    backgroundColor: theme.palette.background.paper
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  headerTime: {
    color: theme.palette.gray.dark,
    marginLeft: 20
  },

  tabPanel: {
    '& .MuiBox-root': {
      padding: 0,
      paddingTop: 15,
    },
    '& .MuiGrid-item > *': {
      borderRadius: 4,
      overflow: 'hidden'
    }
  },


  table: {
    backgroundColor: '#f3f5f9',
    borderRadius: 4,
    overflow: 'hidden'
  },
  tableHeader: {},
  tableHeaderRow: {
    backgroundColor: '#f3f5f9!important'
  },
  tableBodyRow: {
    backgroundColor: '#f3f5f9',

    '& .MuiTableCell-root': {
      fontSize: 16,
      lineHeight: '21px!important',
      fontWeight: '500!important'
    }
  },
  tableHeaderCell: {},

  ceilColor: {
    width: 20,
    height: 20,
    borderRadius: '100%',
    border: '1px solid rgba(25, 42, 62, 0.2)',
    marginRight: 8
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
    paddingBottom: 0,
    position: 'relative'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    transform: 'translate(0, -50%)',
    color: '#152435',
  },
  image: {
    maxHeight: 325,
    width: '100%',
    height: '100%'
  },
  leafletContainer: {
    width: '100%',
    height: '100%'
  },
});

function PaperComponent(props) {
  return (
    <Draggable
      handle="#dialog-confirmation-deletion"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{flex: 1}}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          {children}
        </Box>
      )}
    </div>
  );
}

const ImageMapInformation = withStyles(styles)((props) => {
  const {images, mapPosition, classes} = props;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Map
          className={classes.leafletContainer}
          center={mapPosition}
          length={4}
          zoom={14}
          style={{width: '100%', minHeight: 325, height: '100%'}}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          />

          <Marker position={mapPosition}/>

        </Map>
      </Grid>
    </Grid>
  )
})

const MyDialogTitle = withStyles(styles)((props) => {
  const {children, classes, onClose, ...other} = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <XIcon/>
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const ModalInformation = (props) => {
  const classes = useStyles();
  const {isClose, open, coincidences} = props;
  const [tab, setTab] = React.useState(0);
  const {item, report} = coincidences;

  if (!item || Object.keys(item).length <= 0) {
    return null
  }

  const mapPosition = [report.gps.lat, report.gps.lon];

  let grz = item.license_plate;

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };
  const handleCopyGrz = () => {
    navigator.clipboard.writeText(item.license_plate)
      .then(() => {
        store.addNotification({
          message: 'ГРЗ скопирован в буфер обмена',
          type: 'info',
          insert: 'top',
          container: 'bottom-left',
          dismiss: {
            duration: 3000,
            onScreen: false,
            pauseOnHover: true,
            delay: 0
          }
        });
      })
      .catch(err => {});
  }

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth={'lg'}
      onClose={isClose}
      PaperComponent={PaperComponent}
      aria-labelledby="dialog-confirmation-deletion"
    >
      <MyDialogTitle
        id="dialog-confirmation-deletion"
        onClose={isClose}
      >
        <Grid container alignItems={'center'}>
          <Grid item>
            <Typography variant='h3'>
              {grz}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleCopyGrz}>
              <CopyIcon color={'rgba(134, 152, 177, 0.3)'}/>
            </IconButton>
          </Grid>
          <Grid item>
            <Typography variant={'subtitle1'} className={classes.headerTime}>
              Время фиксации: <Moment format={'DD.MM.YYYY HH:mm:ss'}>{report.datetime}</Moment>
            </Typography>
          </Grid>
        </Grid>
      </MyDialogTitle>
      <DialogContent style={{padding: 0, paddingBottom: 26}}>
        <Box pl={3} pr={3}>
          <Grid container className={classes.gridHeight}>
            <Grid item xs={12} className={classes.tabsContent}>
              <ImageMapInformation
                images={report.images}
                mapPosition={mapPosition}
              />
            </Grid>
            <Grid item xs={12}>
              <Box mt={3} mb={3}>
                <Table className={classes.table}>
                  <TableHead className={classes.tableHeader}>
                    <TableRow className={classes.tableHeaderRow}>
                      <TableCell align={'left'} className={classes.tableHeaderCell}>
                        Время фиксации
                      </TableCell>
                      <TableCell align={'left'} className={classes.tableHeaderCell}>
                        Время загрузки
                      </TableCell>
                      <TableCell align={'left'} className={classes.tableHeaderCell}>
                        Марка
                      </TableCell>
                      <TableCell align={'left'} className={classes.tableHeaderCell}>
                        Скорость
                      </TableCell>
                      <TableCell align={'left'} className={classes.tableHeaderCell}>
                        Модель
                      </TableCell>
                      <TableCell align={'left'} className={classes.tableHeaderCell}>
                        Цвет
                      </TableCell>
                      <TableCell align={'left'} className={classes.tableHeaderCell}>
                        Тип
                      </TableCell>
                      <TableCell align={'left'} className={classes.tableHeaderCell}>
                        Тип
                      </TableCell>
                      <TableCell align={'left'} className={classes.tableHeaderCell}>
                        Направление
                      </TableCell>
                      <TableCell align={'left'} className={classes.tableHeaderCell}>
                        Код устройства
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow className={classes.tableBodyRow}>
                      <TableCell>
                        <Moment format="DD.MM.YYYY HH:mm">
                          {report.datetime}
                        </Moment>
                      </TableCell>
                      <TableCell>
                        <Moment format="DD.MM.YYYY HH:mm">
                          {report.created}
                        </Moment>
                      </TableCell>
                      <TableCell>
                        <div>{item.mark}</div>
                      </TableCell>
                      <TableCell>
                        <div>{report.speed} км/ч</div>
                      </TableCell>
                      <TableCell>
                        <div>{item.model}</div>
                      </TableCell>
                      <TableCell>
                        <div className={classes.ceilColor} style={{backgroundColor: item.color}}/>
                      </TableCell>
                      <TableCell>
                        <div>{item.type}</div>
                      </TableCell>
                      <TableCell>
                        <div>{item.location}</div>
                      </TableCell>
                      <TableCell>
                        <div>{report.direction}</div>
                      </TableCell>
                      <TableCell>
                        <div>{report.sensor_id}</div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>

              <Box>
                <Typography variant={'subtitle1'}>
                  {item.comment}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default ModalInformation
