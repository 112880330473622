import React, {useState} from 'react';
import {useLocation, matchPath} from 'react-router';
import {
  useHistory,
  Link as RouterLink
} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {
  AppBar,
  Toolbar,
  Tooltip,
  IconButton,
  Box,
  Badge,
  Typography,
  Grid
} from '@material-ui/core';
import {
  Home as HomeIcon,
  System as SystemIcon,
  Search as SearchIcon,
  Reference as ReferenceIcon,
  TransportLinks as TransportLinksIcon
} from '../../../../icons';
import {ModalFormFeedback} from '../../../../components';
import MenuIcon from '@material-ui/icons/Menu';
import {
  Notifications as NotificationsIcon,
  MessageOutlined as MessageOutlinedIcon,
  DataUsageOutlined as DataUsageOutlinedIcon,
  StayCurrentPortrait as StayCurrentPortraitIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon
} from '@material-ui/icons';
import {
  User,
  HeaderNav
} from './components';
import {
  ArrowLeft as ArrowLeftIcon
} from 'react-feather';
import {compose} from 'recompose';
import {connect} from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
    padding: 0
  },

  logo: {
    width: 125,
    maxHeight: 50
  },
  logoText: {
    fontSize: 22,
    fontWeight: '600',
    color: 'white',

    '&:hover': {
      opacity: 0.8
    },

    [theme.breakpoints.between(0, 1599)]: {
      fontSize: 18,
    },
  },
  logoContent: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: 246,
    height: 64,
    boxShadow: '6px 0px 18px rgba(0, 0, 0, 0.06)',

    [theme.breakpoints.between(0, 1599)]: {
      width: 200,
    },
  },
  logoContentClose: {
    width: 70,
    marginLeft: -24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  gridButtonNotification: {
    [theme.breakpoints.between(0, 1399)]: {
      padding: '0!important'
    }
  },

  buttonOpenMenuMobile: {
    display: 'none',

    [theme.breakpoints.between(0, 1399)]: {
      display: 'block',
    },
  }
}));

const routes = [
  {
    title: 'Главная',
    icon: HomeIcon,
    href: '/dashboard',
    hideBack: true,
    routesNames: ['/dashboard'],
  },
  {
    title: 'Уведомления',
    icon: SearchIcon,
    href: '/notification',
    routesNames: ['/notification'],
    badge: 0
  },
  {
    title: 'Трекер Контроль',
    icon: TransportLinksIcon,
    href: '/track-control',
    routesNames: ['/track-control'],
  },
  {
    title: 'Видеостена',
    icon: TransportLinksIcon,
    href: '/multiscreen',
    routesNames: ['/multiscreen'],
  },
  {
    title: 'Задачи на выгрузку',
    icon: AssignmentTurnedInIcon,
    href: '/unloading-tasks',
    routesNames: ['/unloading-tasks'],
    badge: 0
  },
  // {
  //   title: 'Справка',
  //   icon: ReferenceIcon,
  //   href: '/reference',
  //   routesNames: ['/reference'],
  //   roles: ['admin']
  // },
  // {
  //   title: 'Мобильный',
  //   icon: StayCurrentPortraitIcon,
  //   href: '/mobile',
  //   roles: ['admin', 'mobile.base', 'mobile.list'],
  //   routesNames: ['/mobile'],
  //   routes: [
  //     {
  //       title: 'Списки розыска',
  //       icon: DataUsageOutlinedIcon,
  //       href: '/mobile/notification',
  //       roles: ['admin', 'mobile.list']
  //     },
  //     {
  //       title: 'Розыск',
  //       icon: DataUsageOutlinedIcon,
  //       href: '/mobile/notification-list',
  //       roles: ['admin', 'mobile.base']
  //     },
  //     {
  //       title: 'Архив',
  //       icon: DataUsageOutlinedIcon,
  //       href: '/mobile/archive',
  //       roles: ['admin', 'mobile.base']
  //     }
  //   ],
  // },
];

const Topbar = props => {
  const {
    open,
    className,
    onSidebarRightOpen,
    userExit,
    invisibleBadgeNotifications,
    badgeContentNotifications,
    user,
    onChangeOpenSidebar,
    countBadgeWanted,
    ...rest
  } = props;

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [openModalFeedback, setOpenModalFeedback] = useState(false);

  const handleRouteBack = () => {
    history.goBack()
  }

  let pathname = location.pathname;
  let openPage = null;

  routes.map(route => {
    if (route.routes && route.routes.length > 0) {
      route.routes.map(route => {
        if (route.href === pathname) {
          openPage = route;
        }
      })
    } else {
      if (route.href === pathname) {
        openPage = route;
      }
    }
  })
  routes.find(route => route.href === '/notification').badge = countBadgeWanted

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          onClick={onChangeOpenSidebar}
          className={classes.buttonOpenMenuMobile}
        >
          <MenuIcon/>
        </IconButton>
        <Box mr={2} className={clsx([classes.logoContent, (!open) ? classes.logoContentClose : ''])}>
          <RouterLink to="/">
            <Typography className={classes.logoText}>{open ? 'Цифровой патруль' : 'TC'}</Typography>
          </RouterLink>
        </Box>

        {
          (openPage && !openPage.hideBack || !openPage) && (
            <IconButton onClick={handleRouteBack}>
              <ArrowLeftIcon color={'white'}/>
            </IconButton>
          )
        }

        <HeaderNav
          routes={routes}
        />

        <div className={classes.flexGrow}/>

        <Grid container spacing={3} style={{width: 'auto'}} alignItems={'center'}>
          <Grid item>
            <svg width="1" height="32" viewBox="0 0 1 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line opacity="0.2" x1="0.5" y1="-2.18557e-08" x2="0.500001" y2="32" stroke="white"/>
            </svg>
          </Grid>
          <Grid item>
            <User
              user={user}
              userExit={userExit}
            />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default compose(
  connect(
    state => ({
      countBadgeWanted: state.app.countBadgeWanted
    })
  ),
)(Topbar);
