import React, {useState, useRef} from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import {
  Box,
  Button, Grid,
  makeStyles,
  TextField, Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {Formik} from 'formik';
import axios from '../../../../../../plugins/axios';
import {store} from "react-notifications-component";

const useStyles = makeStyles(() => ({
  root: {}
}));

const AddPersonalSearch = (props) => {
  const refFormik = useRef(null);
  const { personalListId } = props;
  const classes = useStyles();
  const [formSearch, setFormSearch] = useState({
    license_plate: '',
    mark: '',
    model: '',
  })


  const handleCreateItem = (form, setStatus, setErrors) => {
    let body = {...form};
    body['list_id'] = personalListId;

    axios('post', '/search/item/create', body).then(response => {
      handleSuccessSave()
    }).catch(error => {
      const message = (error.response && error.response.data.message) || 'Что-то пошло не так';

      setStatus({ success: false });
      setErrors({ submit: message });
    })
  }
  const handleSuccessSave = () => {
    store.addNotification({
      title: 'Успешно',
      message: 'ГРЗ успешно добавлен в персональный список уведомлений.',
      type: 'success',
      insert: 'top',
      container: 'bottom-left',
      dismiss: {
        duration: 3000,
        onScreen: false,
        pauseOnHover: true,
        delay: 0
      }
    });

    handleClearForm()
  }
  const handleClearForm = () => {
    refFormik.current.resetForm()
  }

  return (
    <Formik
      innerRef={refFormik}
      initialValues={formSearch}
      validationSchema={Yup.object().shape({
        license_plate: Yup.string().max(255).required('Заполните поле')
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus
      }) => {
        try {
          handleCreateItem(values, setStatus, setErrors)
        } catch (error) {
          const message = (error.response && error.response.data.message) || 'Что-то пошло не так';

          setStatus({success: false});
          setErrors({submit: message});
        }
      }}
    >
      {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
        <form
          noValidate
          className={clsx(classes.root)}
          onSubmit={handleSubmit}
          autoComplete={'off'}
          style={{ paddingRight: 20 }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant={'formTitle'}>ГРЗ<sup>*</sup></Typography>
              <TextField
                error={Boolean(touched.license_plate && errors.license_plate)}
                fullWidth
                autoFocus
                helperText={touched.license_plate && errors.license_plate}
                name="license_plate"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.license_plate}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'formTitle'}>Марка</Typography>
              <TextField
                error={Boolean(touched.mark && errors.mark)}
                fullWidth
                helperText={touched.mark && errors.mark}
                name="mark"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.mark}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'formTitle'}>Модель</Typography>
              <TextField
                error={Boolean(touched.model && errors.model)}
                fullWidth
                helperText={touched.model && errors.model}
                name="model"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.model}
                variant="outlined"
              />
            </Grid>
          </Grid>

          <Box mt={2}>
            <Grid justify={'flex-end'} style={{display: 'flex'}}>
              <Button
                size="small"
                variant="formCancel"
                style={{ marginRight: 10 }}
                onClick={handleClearForm}
              >
                Сбросить
              </Button>
              <Button
                disabled={!values.license_plate || values.license_plate === ''}
                size="small"
                type="submit"
                variant="formSubmit"
                onClick={handleSubmit}
              >
                Добавить
              </Button>
            </Grid>

            {errors.submit && (
              <Box mt={2}>
                <Alert severity="error">{errors.submit}</Alert>
              </Box>
            )}
          </Box>
        </form>
      )}
    </Formik>
  )
}

export default AddPersonalSearch
