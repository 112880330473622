import React, {Component} from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  Button, Tooltip
} from '@material-ui/core';
import {
  Page
} from '../../../../components';
import {
  Table,
  ModalLoadFile,
  ModalCreateList,
  ModalDelete,
  ModalEditList,
  ModalNotifications
} from './components';
import axios from '../../../../plugins/axios';
import {
  CustomMoment as Moment
} from '../../../../components';
import moment from 'moment';
import {withStyles} from '@material-ui/styles';

const styles = {
  searchContent: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  searchInput: {
    backgroundColor: 'white',
    width: '100%',
    maxWidth: 350,
    borderRadius: 4,
    overflow: 'hidden',

    '& .MuiOutlinedInput-input': {
      backgroundColor: 'white'
    }
  },
};

class WantedLists extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      columns: [
        {
          label: 'Название списка',
          field: 'title',
          align: 'left',
        },
        {
          label: 'Статус',
          field: 'status',
          type: 'status',
          align: 'left',
        },
        {
          label: 'Состояние',
          field: 'type',
          type: 'type',
          align: 'left',
        },
        {
          label: 'Количество строк',
          field: 'item_count',
          align: 'left',
        },
      ],
      showColumns: ['title', 'status', 'item_count'],

      pagination: {
        rowsPerPage: 20,
        page: 1,
        orderBy: null,
        order: 'asc',
        totalCount: 0
      },
      filter: {
        limit: 20,
        page: 1,
        type: 20,
        list_ud: '5f116d7da53d5940bc42fbf0'
      },
      createNewList: {
        title: '',
        color: '',
        type: 20
      },
      confirmationDeleteList: {},
      editList: {},
      uploadFileList: {},
      fileUploadInfo: {
        failed: 0,
        imported: 0
      },

      dateTimeRequest: null,
      searchTableName: '',

      isLoading: true,
      openModalFileUpload: false,
      openModalCreateList: false,
      openModalConfirmationDelete: false,
      openModalEdit: false,
      openModalNotifications: false
    }
  }

  componentDidMount = () => {
    this.isLoadSearchList()
  }

  getFilterSeachList = () => {

    return this.state.filter
  }
  isLoadSearchList = () => {
    let filter = this.getFilterSeachList();
    this.setState({
      isLoading: true,
      rows: []
    })

    axios('post', '/search/list/get-all', filter).then(response => {
      let rows = response.data;
      let totalCount = response.headers['x-pagination-total-count'];
      let pagination = this.state.pagination;

      if (!rows) {
        rows = []
      }

      pagination.totalCount = totalCount;

      this.setState({
        rows,
        pagination,
        isLoading: false,
        dateTimeRequest: moment(),
      })
    }).catch(error => {})
  }
  isChangeRowPage = (pagination) => {
    this.setState({
      pagination
    })

    if (this.state.workFile) {
      this.specifyReportRearch()
    } else {
      this.getDriveList();
    }
  }

  onCreateList = () => {
    axios('post', '/search/list/create', this.state.createNewList).then(response => {
      this.setState({
        openModalCreateList: false
      })

      this.isLoadSearchList();
    }).catch(error => {

    })
  }
  onDeleteList = (list, confirmation) => {
    if (confirmation) {
      this.setState({
        confirmationDeleteList: {},
        openModalConfirmationDelete: false
      })

      axios('get', '/search/list/delete/' + list.id).then(() => {
        this.isLoadSearchList()
      }).catch(error => {

      })
    } else {
      this.setState({
        confirmationDeleteList: list,
        openModalConfirmationDelete: true
      })
    }
  }
  onEditList = (list) => {
    this.setState({
      editList: {...list},
      openModalEdit: true
    })

  }
  onSaveEditList = () => {
    axios('post', '/search/list/edit/' + this.state.editList.id, this.state.editList).then(response => {
      this.setState({
        editList: {},
        openModalEdit: false
      })

      this.isLoadSearchList()
    }).catch(error => {

    })
  }
  onChangeCreateListForm = (createNewList) => {
    this.setState({
      createNewList
    })
  }
  onChangeEditListForm = (editList) => {
    this.setState({
      editList
    })
  }

  onUploadFile = (list) => {
    this.setState({
      uploadFileList: list,
      openModalFileUpload: true
    })
  }
  onChangeLoadFile = (uploadFileList) => {
    this.setState({uploadFileList})
  }
  onLoadFile = () => {
    let form = this.state.uploadFileList;
    let json = {
      list_id: form.id,
      type: 'csv'
    };

    let data = new FormData();

    data.append('file', form.file);
    data.append('data', JSON.stringify(json));

    axios('post', '/search/list/upload', data).then(response => {
      let data = response.data;
      let failed = data.failed;
      let imported = data.imported;

      let fileUploadInfo = {
        failed,
        imported
      };
      this.setState({
        fileUploadInfo,

        openModalFileUpload: false,
        openModalNotifications: true
      })
    }).catch(error => {

    })
  }

  onChangeSearchTableName = (event) => {}

  changeFilters = (filter) => {
    this.setState({
      filter
    })

    setTimeout(() => {
      this.isLoadSearchList()
    }, 500)
  }

  render() {
    const {classes} = this.props;

    return (
      <Page title={'Устройства'}>
        <Grid container justify={'center'}>
          <Grid item xs={12}>
            <Box mb={2}>
              <Grid container alignItems={'center'}>
                <Box mr={2}>
                  <Typography variant={'h3'}>
                    Общедоступные списки уведомлений:&nbsp;
                    {
                      (!this.state.isLoading) ? this.state.pagination.totalCount : (
                        <CircularProgress size={20} color={'inherit'}/>
                      )
                    }
                  </Typography>
                </Box>

                <Box>
                  <Typography style={smallTextGray}>
                    Данные актуальны на&nbsp;
                    <Moment
                      format="DD.MM.YYYY HH:mm:ss"
                      value={this.state.dateTimeRequest}
                    >
                      {this.state.dateTimeRequest}
                    </Moment>
                  </Typography>
                </Box>

                <Box style={{marginLeft: 'auto'}}>
                  <Tooltip title={'Добавление списка уведомлений'}>
                    <Button
                      variant={'contained'}
                      onClick={() => {
                        this.setState({openModalCreateList: true})
                      }}
                    >
                      Добавить список
                    </Button>
                  </Tooltip>
                </Box>
              </Grid>
            </Box>


            <Table
              columns={this.state.columns}
              showColumns={this.state.showColumns}
              rows={this.state.rows}
              pagination={this.state.pagination}
              filter={this.state.filter}
              isLoading={this.state.isLoading}

              isChangeRowPage={this.isChangeRowPage}
              isChangeFilter={this.changeFilters}
              onDeleteList={this.onDeleteList}
              onEditList={this.onEditList}
              onUploadFile={this.onUploadFile}
            />
          </Grid>
        </Grid>


        <ModalLoadFile
          open={this.state.openModalFileUpload}
          onClose={() => {
            this.setState({openModalFileUpload: false})
          }}
          form={this.state.uploadFileList}
          onChangeLoadFile={this.onChangeLoadFile}
          onLoadFile={this.onLoadFile}
        />

        <ModalCreateList
          open={this.state.openModalCreateList}
          onClose={() => {
            this.setState({openModalCreateList: false})
          }}

          form={this.state.createNewList}
          onCreateList={this.onCreateList}
          onChangeForm={this.onChangeCreateListForm}
        />

        <ModalEditList
          open={this.state.openModalEdit}
          onClose={() => {
            this.setState({openModalEdit: false})
          }}

          form={this.state.editList}
          onSaveList={this.onSaveEditList}
          onChangeForm={this.onChangeEditListForm}
        />

        <ModalDelete
          isDelete={() => this.onDeleteList(this.state.confirmationDeleteList, true)}
          item={this.state.confirmationDeleteList}
          open={this.state.openModalConfirmationDelete}
          isClose={() => {
            this.setState({openModalConfirmationDelete: false})
          }}
        />

        <ModalNotifications
          open={this.state.openModalNotifications}
          isClose={() => {
            this.setState({openModalNotifications: false})
          }}
        >
          <Box mb={2}>
            <Typography variant={'h5'}>Информация о загруженном файле</Typography>
          </Box>

          <Grid container>
            <Grid item xs={12} container spacing={1}>
              <Grid item>
                <Typography variant={'h6'} style={{color: '#3CB11F'}}>Успешно импортировано: </Typography>
              </Grid>
              <Grid item>
                <Typography>{this.state.fileUploadInfo.imported}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={1}>
              <Grid item>
                <Typography variant={'h6'} style={{color: '#F59300'}}>Ошибок: </Typography>
              </Grid>
              <Grid item>
                <Typography>{this.state.fileUploadInfo.failed}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </ModalNotifications>
      </Page>
    );
  }
}

const smallTextGray = {
  color: '#647083',
  fontSize: 16
};

export default withStyles(styles)(WantedLists)
