import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,

  Box,
  Grid,
  Button,
  TextField,
  Typography
} from "@material-ui/core";
import {sendMessage} from "../../../../../utils/message";

const _initForm = {
  license_plate: "",
  color: "",
  mark: "",
  model: "",
  type: "",
  comment: "",
};

class PopUpAddGrz extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      form: {..._initForm},
      isOpen: false,
      isEdit: false,
    }
  }

  open = ({ initForm, onSubmit }) => {
    this.setState({
      form: initForm || {..._initForm},
      isOpen: true,
      isEdit: Boolean(initForm),
      onSubmit,
    })
  }
  close = () => {
    this.setState({
      form: {..._initForm},
      isOpen: false
    })
  }

  changeForm = ({ target }) => {
    const { name, value } = target;
    let form = {...this.state.form};
    form[name] = value;
    this.setState({ form });
  }
  submitForm = () => {
    const { form } = this.state;
    if (!form.license_plate) {
      sendMessage({
        type: "danger",
        message: "Введите ГРЗ"
      })
      return
    }
    this.state.onSubmit(form, true);
  }

  render () {
    const {
      form,
      isOpen,
      isEdit
    } = this.state;

    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isOpen}
        onClose={this.close}
      >
        <DialogTitle>
          <Typography variant="h3">
            {isEdit ? 'Редактирование ГРЗ' : 'Добавление ГРЗ'}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                value={form.license_plate}
                label="ГРЗ"
                name="license_plate"
                variant="outlined"
                fullWidth
                onChange={this.changeForm}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={form.mark}
                label="Марка"
                name="mark"
                variant="outlined"
                fullWidth
                onChange={this.changeForm}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={form.model}
                label="Модель"
                name="model"
                variant="outlined"
                fullWidth
                onChange={this.changeForm}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={form.color}
                label="Цвет"
                name="color"
                variant="outlined"
                fullWidth
                onChange={this.changeForm}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={form.type}
                label="Тип"
                name="type"
                variant="outlined"
                fullWidth
                onChange={this.changeForm}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={form.comment}
                label="Комментарий"
                name="comment"
                variant="outlined"
                fullWidth
                multiline
                rows={3}
                onChange={this.changeForm}
              />
            </Grid>
          </Grid>
          <Box mt={2}/>
          <Grid container justify="flex-end" spacing={1}>
            <Grid item>
              <Button variant="outlined" onClick={this.close}>Закрыть</Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={this.submitForm}>
                {isEdit ? 'Изменить' : 'Добавить'}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    )
  }
}

export default PopUpAddGrz
