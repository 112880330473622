const convertEnToRu = (str) => {
	let _str = (str || '').split('').map((char) => dictionary[char] || char).join('');
	return _str
}
export default convertEnToRu

const dictionary = {
	"A": "А",
	"a": "а",
	"B": "В",
	"b": "в",
	"E": "Е",
	"e": "е",
	"K": "К",
	"k": "к",
	"M": "М",
	"m": "м",
	"H": "Н",
	"h": "н",
	"O": "О",
	"o": "о",
	"P": "Р",
	"p": "р",
	"C": "С",
	"c": "с",
	"T": "Т",
	"t": "т",
	"Y": "У",
	"y": "у",
	"X": "Х",
	"x": "х",
};
