import React, {Component} from "react";
import {
  Box,
  Grid,
  Button,
  Tooltip,
  Typography, Backdrop, CircularProgress
} from "@material-ui/core";
import {
  Page,
  WithoutAccess,
  DialogConfirmation
} from "../../../components";
import {getPageFromCount} from "../../../common/pagination";
import {
  Filter,
  Table,
  PopUpCreate
} from "./components";
import axios from "../../../plugins/axios";
import {sendMessages} from "../../../common/messages";

const initialFilter = {
  title: "",

  page: 1,
  limit: 20
};

class UsersGroups extends Component {
  constructor(props) {
    super(props);
    const userRole = props?.app?.user?.role;
    if (userRole !== "admin") {
      props.history.push(`/users-groups/${props?.app?.user?.group_id}`);
    }

    this.state = {
      rows: [],

      filter: {...initialFilter},
      pagination: {
        page: 1
      },
      deleteUser: null,

      isLoading: true,
      isShowBackdrop: false,
      isOpenDialogConfirmation: false
    };
    this.refPopUpCreate = React.createRef();
  }

  // инициализация страницы
  componentDidMount = () => {
    this.getListUsers();
  }

  // получение списка пользователя
  getListUsers = () => {
    this.setState({ isLoading: true });

    const filter = this.getFilter();
    axios('post', "/user/group/get-list", filter).then((response) => {
      const { data, headers } = response;

      let pagination = {...this.state.pagination};
      pagination.page = getPageFromCount(headers['x-pagination-total-count'], this.state.filter.limit);

      this.setState({
        rows: data || [],
        pagination: pagination,

        isLoading: false
      })
    });
  }

  // конвертация фильтров со фронта на бекенд
  getFilter = () => {
    return this.state.filter
  }

  // изменение фильтра
  onChangeFilter = (filter, isFastStart) => {
    this.setState({ filter }, () => {
      if (isFastStart) {
        this.getListUsers();
      }
    });
  }

  // функционал удаления пользователя (удаляется после подтверждения)
  onDeleteUser = (user = this.state.deleteUser, isConfirm) => {
    if (!isConfirm) {
      this.setState({
        deleteUser: user,
        isOpenDialogConfirmation: true
      });

      return null
    }
    this.setState({ isOpenDialogConfirmation: false, deleteUser: null, isShowBackdrop: true });
    axios('get', `/user/group/delete/${ user.id }`).then(() => {
      this.getListUsers();
      sendMessages({
        message: "Пользователь успешно удален",
        type: "success"
      });

      this.setState({ isShowBackdrop: false })
    })
  }

  // функция перехода на страницы создания пользователя
  _routeCreateUser = () => {
    this.refPopUpCreate.current.open({
      onSubmit: this.onCreateUserGroup.bind(this)
    })
  }
  onCreateUserGroup = async (form) => {
    this.setState({ isShowBackdrop: true });

    const res = await axios('post', '/user/group/create', form).then((res) => {
      return res.data
    }).catch((err) => {
      return { error: err.response }
    });
    if (res.error) {
      this.setState({ isShowBackdrop: false });
      sendMessages({
        message: "Группа успешно создана",
        type: "success"
      })
      return
    }

    this.refPopUpCreate.current.close();
    await this.getListUsers();
    this.setState({ isShowBackdrop: false });
  }


  render () {
    const {
      rows, filter, pagination, isLoading,
      isOpenDialogConfirmation, deleteUser,
    } = this.state;
    const isAccept = ['admin', 'group_admin'].includes(this.props?.app?.user?.role);

    if (!isAccept) {
      return <WithoutAccess/>
    }
    return (
      <Page>
        <Box mb={2}>
          <Grid container spacing={2} justify="space-between">
            <Grid item>
              <Typography variant="h1">Группы пользователей</Typography>
            </Grid>
            <Grid item>
              <Tooltip title="Создать пользователя">
                <Button variant="contained" onClick={this._routeCreateUser}>Создать группу</Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>

        <Box mb={4} bgcolor="white" borderRadius={4} p={3}>
          <Filter
            filter={filter}
            initialFilter={initialFilter}

            onChange={this.onChangeFilter}
            onSearch={this.getListUsers}
          />
        </Box>

        <Box>
          <Table
            rows={rows}
            filter={filter}
            pagination={pagination}
            isLoading={isLoading}

            onChangeFilter={this.onChangeFilter}
            onDeleteUser={this.onDeleteUser}
          />
        </Box>

        <DialogConfirmation
          title={`Удаление группы пользователей`}
          comment={`Вы действительно хотите удалить группу пользователей "${ deleteUser?.Title }"? После удаления результат необратим`}
          buttonConfirmation="Удалить"

          isOpen={isOpenDialogConfirmation}

          onClose={() => this.setState({ isOpenDialogConfirmation: false })}
          onConfirmation={() => this.onDeleteUser(deleteUser, true)}
        />

        <Backdrop open={this.state.isShowBackdrop} invisible={this.state.isShowBackdrop}>
          <CircularProgress size={80} style={{color: 'white'}}/>
        </Backdrop>


        <PopUpCreate ref={this.refPopUpCreate}/>
      </Page>
    );
  }
}

export default UsersGroups
