import React from 'react';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import Marker from './Marker';

import {
  makeStyles
} from '@material-ui/core';
import clsx from "clsx";

const useStyles = makeStyles((theme => ({
  marker: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
    height: 30,
    backgroundColor: '#2979FF',
    border: '1px solid rgba(21, 36, 53, 0.1)',
    boxShadow: '0px 4px 4px rgba(72, 93, 210, 0.4)',

    fontWeight: '600',
    fontSize: 16,
    lineHeight: '16px',
    color: 'white',

    '&:hover': {
      zIndex: 999 + '!important',
      boxShadow: '0 0 0 5px #FF4B4B'
    }
  },
  markerStart: {
    backgroundColor: '#3CB11F'
  },
  markerEnd: {
    backgroundColor: '#F59300'
  }
})))

const Points = (props) => {
  const {showCluster, points, showPointNumbers, openInfoMarker} = props;
  const classes = useStyles();

  const handleClickMarker = (point) => {
    openInfoMarker(point)
  }

  if (showCluster) {
    return (
      <MarkerClusterGroup>
        {
          points.map((point, idx) => (
            <Marker
              key={'point-' + idx}
              position={point.position}
              title={(showPointNumbers) ? point.index : point.points.length}
              className={clsx({
                [point.className]: true,
                [classes.marker]: true,
                [classes.markerStart]: point.start,
                [classes.markerEnd]: point.end,
              })}
              onClick={() => handleClickMarker(point)}
            />
          ))
        }
      </MarkerClusterGroup>
    )
  }


  return (
    <div>
      {
        points.map((point, idx) => {
          return (
            <Marker
              key={'point-' + idx}
              position={point.position}
              title={(showPointNumbers) ? point.index : point.points.length}
              className={clsx({
                [point.className]: true,
                [classes.marker]: true,
                [classes.markerStart]: point.start,
                [classes.markerEnd]: point.end,
              })}
              onClick={() => handleClickMarker(point)}
            />
          )
        })
      }
    </div>
  )
}

export default Points

