// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import MobileWanted from './MobileWanted';

export default compose(
  connect(
    state => ({
      user: state?.app?.user || {}
    }),
    dispatch => ({}),
  ),
)(MobileWanted);
