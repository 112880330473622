import {sendMessages} from "../common/messages";

const downloadFile = async (linkFile) => {
	const file = await fetch(linkFile)
		.then((res) => {
			return res.blob()
		})
		.catch((err) => {
			return {error: err}
		});

	if (file.error) {
		sendMessages({
			message: file.error?.message || "Ошибка скачивания файла.",
			type: "danger"
		})
		return
	}

	const fileBlob = file;
	const fileName = (file?.url || "").split("/").pop();

	var link = document.createElement('a');
	link.href = window.URL.createObjectURL(fileBlob);
	link.download = fileName;

	document.body.appendChild(link);

	link.click();
}


export {
	downloadFile
}
