import React, {useState} from 'react';
import {
  makeStyles,
  Button,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio, Typography
} from '@material-ui/core';
import {
  CheckCircle as CheckCircleIcon
} from '@material-ui/icons';
import {
  ModalDevices,
} from './components/';
import {
  Check as CheckIcon
} from 'react-feather';
import './index.scss'

const stylesSelectComponents = makeStyles((theme) => ({
  selectGrid: {
    flex: 1,

    '& .MuiInputBase-root': {
      backgroundColor: 'transparent'
    },
    '& .MuiInputBase-root.Mui-focused': {
      boxShadow: 'none',
      outline: 'none'
    },
  },
  rightGrid: {
    display: 'flex',
    padding: '8px 0',

    '& .MuiFormGroup-root': {
      marginLeft: -14
    },
    '& .MuiFormControlLabel-root': {
      marginLeft: 14,
      marginRight: 0,
      display: 'flex',
      alignItems: 'center'
    },
    '& .MuiIconButton-root': {
      marginRight: 6
    }
  },
  rightGridContent: {
    display: 'flex',
    paddingLeft: 20,
    borderLeft: '1px solid rgba(100, 112, 131, 0.2)'
  },
  button: {
    fontSize: 13,
    lineHeight: '120%',
    fontWeight: '500',

    '& svg': {
      marginLeft: 5
    }
  },

  placeholder: {},
  value: {},

  buttonOpen: {
    cursor: 'pointer',
  },

  radioGroup: {
    '& .MuiRadio-root': {
      color: '#D6DAE1'
    },
    '& .MuiFormControlLabel-label': {
      color: 'rgba(100, 112, 131, 0.8)',
      fontSize: 15,
      lineHeight: '24px',
      fontWeight: 'normal'
    },
    '& .Mui-checked': {
      color: theme.palette.primary.main,

      '&~.MuiFormControlLabel-label': {
        color: theme.palette.primary.main
      },
    }
  }
}));


const SelectComponent = (props) => {
  const {value, onClick, renderButton} = props;
  const [type, setType] = useState(null);
  const classes = stylesSelectComponents();

  const handleClearFilter = () => {
  }
  const handleChangeType = (event) => {
    let item = event.target;
    let name = item.name;
    let value = item.value;

    if (value == type) {
      value = null
    }

    setType(value);
  }

  return (
    <div style={{ display: 'flex' }}>
      <div className={'modal-sensor'}>
        <div className={classes.selectGrid}>
          <div className={classes.buttonOpen} onClick={onClick}>
            {
              (renderButton) ?
                (
                  renderButton
                )
                :
                (
                  <>
                    {
                      (value && value.length > 0) ?
                        (
                          <span className={classes.value}>Устройства ({value.length})</span>
                        ) :
                        (
                          <span className={classes.placeholder}>Устройства</span>
                        )
                    }
                  </>
                )
            }
          </div>
        </div>
      </div>
    </div>
  )
}
const ModalSensorFilter = (props) => {
  const {value, list, onChange, fullWidth, filter, onFilter, renderButton, onStartFilter, onClose} = props;
  const [open, setOpen] = useState(false);

  const handleOpenModal = () => {
    setOpen(true)
  }
  const handleCloseModal = () => {
    setOpen(false);

    if (onClose) {
      onClose();
    }

    handleStartFilter();
  }
  const handleChangeSensor = (list) => {
    onChange(list)
  }

  const handleStartFilter = () => {
    if (onStartFilter){
      onStartFilter()
    }
  }

  return (
    <>
      <SelectComponent
        value={value}
        label={'Устройства'}
        onClick={handleOpenModal}
        renderButton={renderButton}
      />

      <ModalDevices
        open={open}
        isClose={handleCloseModal}
        devicesSensor={list}
        devicesSensorActive={value}
        isChangeDevicesSensor={handleChangeSensor}

        filter={filter}
        onFilter={(filter) => onFilter(filter)}
        onStartFilter={handleStartFilter}
      />
    </>
  )
}

export default ModalSensorFilter
