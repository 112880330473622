import React, {Component} from 'react';
import {
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  CircularProgress
} from '@material-ui/core';
import {CustomCheckbox, Page} from '../../../components';
import {withStyles} from '@material-ui/styles';
import {
  Eye as EyeIcon,
  EyeOff as EyeOffIcon
} from 'react-feather';
import * as Yup from 'yup';
import {Formik} from 'formik';
import axios from '../../../plugins/axios';
import {store} from 'react-notifications-component';
import userRoles from "../../../constant/userRoles";

class Account extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        full_name: '',
        username: '',
        subdivision: '',
        position: '',
        password: '',
        email: '',

        allowedIP: '',

        requirePasswordChange: '',
        loginIssuedHand: '',

        roles: []
      },

      isLoading: true,
      showPassword: false
    }
  }

  componentDidMount = () => {
    let data = this.props.user;

    if (Object.keys(data).length > 0 && this.state.isLoading) {
      this.initUser()
    }
  }

  componentDidUpdate = () => {
    let data = this.props.user;

    if (Object.keys(data).length > 0 && this.state.isLoading) {
      this.initUser()
    }
  }

  initUser = () => {
    let form = this.state.form;
    let data = this.props.user;

    form['full_name'] = data['full_name'];
    form['username'] = data['username'];
    form['password'] = data['password'];
    form['email'] = data['email'];
    form['role'] = data['role'];

    this.setState({
      form,
      isLoading: false
    })
  }

  render() {
    const {classes} = this.props;

    console.log('this.state.form: ', this.state.form);

    return (
      <Page title={'Добавление пользователя'}>
        <Grid container justify={'center'}>
          <Grid item xs={8}>
            <Box mb={4}>
              <Typography variant={'h2'} style={{fontWeight: 500}}></Typography>
            </Box>

            <Box p={4} bgcolor={'white'} boxShadow={1} borderRadius={4} style={{position: 'relative'}}>
              <Grid container spacing={8} className={classes.form} wrap={'nowrap'}>
                <Grid item xs={4} container className={classes.formColumn}>
                  <Grid item>
                    <Grid item xs={12}>
                      <Typography variant={'formTitle'}>Имя</Typography>
                      <TextField
                        fullWidth

                        name="username"
                        placeholder={'Имя пользователя'}
                        value={this.state.form.username}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid item xs={12}>
                      <Typography variant={'formTitle'}>ФИО</Typography>
                      <TextField
                        fullWidth

                        name="full_name"
                        placeholder={'Иванов И.И.'}
                        value={this.state.form.full_name}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid item xs={12}>
                      <Typography variant={'formTitle'}>E-mail</Typography>
                      <TextField
                        fullWidth

                        type={'email'}
                        name="email"
                        placeholder={'Пароль'}
                        value={this.state.form.email}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <div className={classes.divider}/>

                <Grid item xs={4} className={classes.formRoles}>
                  <Box mb={2}>
                    <Typography variant={'formTitle'}>Роли</Typography>
                  </Box>

                  {
                    (Object.keys(userRoles || {})).map((roleKey) => (
                      <CustomCheckbox
                        fullWidth
                        label={userRoles[roleKey]}
                        checked={this.state.form.role === roleKey}
                        value={roleKey}
                      />
                    ))
                  }
                </Grid>
              </Grid>

              {
                (this.state.isLoading) && (
                  <div className={classes.contentLoading}>
                    <CircularProgress size={60} color={'white'}/>
                  </div>
                )
              }

            </Box>
          </Grid>
        </Grid>
      </Page>
    );
  }
}

const styles = {
  form: {},
  formColumn: {
    '& > .MuiGrid-root': {
      marginBottom: 24,
      width: '100%',

      '&:last-child': {
        marginBottom: 0
      }
    }
  },

  divider: {
    width: 1,
    backgroundColor: '#e7eaef',
    marginTop: 32,
    marginBottom: 32
  },
  dividerLine: {
    height: 1,
    width: '100%',
    backgroundColor: '#e7eaef',
    margin: '32px 0'
  },

  lightCheckbox: {
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
      lineHeight: '16px',
      fontWeight: 'normal',
      width: '100%',
      marginBottom: 0
    },
    '& .MuiFormControlLabel-root': {
      width: '100%',
      margin: 0,
      flexDirection: 'row-reverse'
    },
    '& .MuiCheckbox-root': {
      padding: 0
    },
    '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label svg': {
      backgroundColor: '#f3f5f9',
      stroke: '#212529'
    }
  },

  formRoles: {
    '& .MuiFormControlLabel-root': {
      width: '100%',
      margin: 0,
      marginBottom: 12
    },
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
      lineHeight: '16px',
      color: '#727a86'
    },
    '& .MuiCheckbox-root': {
      padding: 0,
      marginRight: 12
    },
    '& .Mui-checked ~ .MuiFormControlLabel-label': {
      color: '#152435'
    }
  },

  contentLoading: {
    backgroundColor: 'rgba(24, 31, 72, 0.5)',
    position: 'absolute',
    zIndex: 9999,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,

    '& .MuiCircularProgress-circleIndeterminate': {
      color: 'white'
    }
  }
};

export default withStyles(styles)(Account)
