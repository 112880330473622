import React, {Component} from 'react';
import {
  Box,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import {
  Close as CloseIcon
} from '@material-ui/icons';
import theme from '../../../theme/index';
import Alert from '@material-ui/lab/Alert';
import {
  Table,
  Filter,
  ExportCreate,
  ModalInformation
} from './components';
import {
  Page,
  WithoutAccess,
  AddNewFavorites
} from '../../../components';
import {
  X as XIcon
} from 'react-feather';
import queryString from 'query-string';
import axios from '../../../plugins/axios';
import {
  CustomMoment as Moment
} from '../../../components';
import moment from 'moment';
import {withStyles} from '@material-ui/styles';
import {store} from 'react-notifications-component';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      columns: [
        {
          label: '',
          field: 'images',
          align: 'left',
          type: 'image'
        },
        {
          label: 'Время фиксации',
          field: 'datetime',
          align: 'left',
          sort: true,
          type: 'date'
        },
        {
          label: 'Время загрузки',
          field: 'created',
          align: 'left',
          type: 'date',
          sort: true,
          filterName: 'datetime'
        },
        {
          label: 'ГРЗ',
          field: 'license_plate',
          align: 'left',
          sort: false,
          filterName: 'license_plate_list',
          type: 'grz'
        },
        {
          label: 'Марка',
          field: 'mark',
          align: 'left',
          sort: true,
          filterName: 'mark'
        },
        {
          label: 'Скорость',
          field: 'speed',
          type: 'speed',
          align: 'left',
          sort: false,
        },
        {
          label: 'Модель',
          field: 'model',
          align: 'left',
          filterName: 'model',
          sort: true,
        },
        {
          label: 'Цвет',
          field: 'color',
          align: 'left',
          sort: true,
        },
        {
          label: 'Тип',
          field: 'type',
          align: 'left',
          sort: true,
        },

        {
          label: 'Местоположение',
          field: 'address',
          align: 'left',
        },
        {
          label: 'Направление',
          field: 'direction',
          align: 'left',
        },
        {
          label: 'Код устройства',
          field: 'sensor_serial',
          align: 'left',
          sort: true,
        },
        {
          label: '',
          field: 'action',
          type: 'action',
          align: 'left',
        },
      ],
      selected: [],
      groupList: [],
      sensorList: [],
      showColumns: ['license_plate', 'created', 'speed', 'datetime', 'images', 'sensor_serial', 'direction', 'address', 'action'],

      pagination: {
        rowsPerPage: 40,
        page: 1,
        orderBy: 'datetime',
        order: 'desc',
        totalCount: 0
      },
      filter: {
        sensor_ids: [],
        license_plate_list: [],
        colors: [],
        license_plate_text: '',
        mark: '',
        model: '',
        datetime_start: null,
        datetime_end: null,
        sort_field: '',
        sort_direction: '',
        license_plate_empty: false,
        license_plate_foreign: false,
        license_plate_invalid: false,

        limit: 40,
        page: 1,
      },
      modalInformation: {},
      sensorFilter: {
        address: '',
        direction: '',
        vendor: '',
        serial: ''
      },

      dateTimeRequest: moment(),
      filterString: 'dashboard',

      tableLoader: false,
      showModalInformation: false,
      useGrzMask: false,
      isInfiniteScroll: false,
      isInfiniteScrollLoading: false,
      isFindResultGrz: false,


      workFile: false,
      workFileName: '',

      errorWorkFile: false,
      errorWorkFileText: '',

      errorMessage: ""
    }

    this.handleGetSensorList = null;
    this.handleInfiniteScroll = null;
  }

  // Начала жизненого цикла главного экрана (проезды)
  componentDidMount = () => {
    let params = queryString.parse(this.props.location.search);
    if (Object.keys(params).length > 0) {
      let filter = this.state.filter;
      let useGrzMask = this.state.useGrzMask;

      for (let key in params) {
        filter[key] = params[key];

        if (key === 'sensor_ids' || key === 'license_plate_list' || key === 'colors') {
          filter[key] = params[key].split(',')
        }
        if (key === 'limit') {
          filter[key] = Number(params[key])
        }
        if (key === 'page') {
          filter[key] = Number(params[key])
        }
        if (key === 'datetime_start' || key === 'datetime_end') {
          let date = new Date();
          date.setTime(params[key]);
          filter[key] = date
        }
        if (key === 'license_plate_empty' || key === 'license_plate_invalid' || key === 'license_plate_foreign') {
          filter[key] = Boolean(filter[key]);
        }
      }

      this.setState({
        filter,
        useGrzMask
      }, async () => {
        if ((filter?.license_plate_list || []).length > 0) {
          await this.getDriveList();
        }
      })
    }
    this.getSensorList()
  }
  componentDidUpdate = (prevProps) => {
    let prevSearch = queryString.parse(prevProps.location.search);
    let currentSearch = queryString.parse(this.props.location.search);
    if (JSON.stringify(prevSearch) !== JSON.stringify(currentSearch)) {
      let params = currentSearch;
      if (Object.keys(params).length > 0) {
        let filter = {
          sensor_ids: [],
          license_plate_text: '',
          license_plate_list: [],
          mark: '',
          model: '',
          datetime_start: null,
          datetime_end: null,
          sort_field: '',
          sort_direction: '',
          license_plate_empty: false,
          license_plate_foreign: false,
          license_plate_invalid: false,

          limit: 40
        }

        for (let key in params) {
          filter[key] = params[key]

          if (key === 'sensor_ids' || key === 'license_plate_list' || key === 'colors') {
            filter[key] = params[key].split(',')
          }
          if (key === 'limit') {
            filter[key] = Number(params[key])
          }
          if (key === 'datetime_start' || key === 'datetime_end') {
            let date = new Date();
            date.setTime(params[key]);
            filter[key] = date
          }
          if (key === 'license_plate_empty' || key === 'license_plate_invalid' || key === 'license_plate_foreign') {
            filter[key] = Boolean(params[key]);
          }
          if (key === 'page') {
            filter[key] = Number(params[key])
          }
        }

        this.setState({
          filter
        })

        setTimeout(async () => {
          if (this.state.workFile) {
            this.specifyReportRearch()
          } else {
            await this.getDriveList();
          }
        }, 500)
      }
    }
  }

  // Конвертация фильтра на фронте для фильтра бекенда (проезды)
  getFilter = () => {
    let filter = {}

    for (let key in this.state.filter) {
      let item = this.state.filter[key];

      if (item) {
        filter[key] = item;

        if (key === 'license_plate_empty' || key === 'license_plate_invalid' || key === 'license_plate_foreign') {
          filter[key] = Boolean(item);
        }
        if (key === 'speed') {
          filter[key] = parseFloat(item);
        }
      }
    }
    if (this.state.pagination.orderBy) {
      filter['sort_direction'] = this.state.pagination.order;
      filter['sort_field'] = this.state.pagination.orderBy;
    }

    return filter
  }

  // Конвертация фильтра на фронте для фильтра бекенда (устройства)
  getFilterSensor = () => {
    let filter = {}

    for (let key in this.state.sensorFilter) {
      let item = this.state.sensorFilter[key];

      if (item) {
        filter[key] = item;
      }
    }

    return filter
  }

  // Метод получения списка устройств (камеры)
  getSensorList = () => {
    let filter = this.getFilterSensor();
    filter['page'] = 1;
    filter['limit'] = 999;

    axios('post', '/operator/sensor/find', filter).then((response) => {
      let sensorList = response.data

      if (!sensorList) {
        sensorList = []
      }

      this.setState({
        sensorList,
        totalMetricaSensor: response.headers['x-pagination-total-count']
      })
    }).catch((error) => {
    })
  }

  // Функция изменения фильтра устройств
  // реализованно с отложенным стартом, что бы
  // не грузить данные пока пользователь вводит фильтр
  onFilterSensor = (sensorFilter) => {
    clearTimeout(this.handleGetSensorList)

    this.setState({sensorFilter});

    this.handleGetSensorList = setTimeout(() => {
      this.getSensorList()
    }, 500)
  }

  // Метод получения списка проездов
  getDriveList = async (infiniteScroll = false) => {
    let filter = this.getFilter();
    if ((filter?.license_plate_list || []).length <= 0) {
      this.setState({
        isFindResultGrz: false
      })
      return
    }

    const response = await axios('post', '/operator/report/find', filter).then((response) => {
      return response
    }).catch((error) => {
      return {error: error.response?.data?.details || "Ошибка сервера"}
    });

    let rows = response?.data || [];
    let totalCount = response?.headers?.['x-pagination-total-count'] || 0;
    let pagination = this.state.pagination;
    pagination.totalCount = totalCount;

    this.setState({
      rows,
      pagination,
      tableLoader: false,
      isFindResultGrz: true,
      isInfiniteScrollLoading: false,
      dateTimeRequest: moment(),
      isInfiniteScroll: rows.length > 0,

      errorMessage: response.error
    })
  }

  // Метод загрузки списка при дохождения до конца списка
  // Пока не работает
  onLoadMore = () => {
    let filter = {...this.state.filter};
    filter['page']++;

    this.setState({
      filter
    });

    this.onLoadContentEager()
  }
  onLoadContentEager = () => {
    let filter = this.getFilter();
    axios('post', '/operator/report/find', filter).then((response) => {
      let rows = response.data;
      let totalCount = response.headers['x-pagination-total-count'];
      let pagination = this.state.pagination;

      if (!rows) {
        rows = []
      }

      pagination.totalCount = totalCount;


      this.setState({
        rows: [...this.state.rows, ...rows],
        pagination,
        tableLoader: false,
        dateTimeRequest: moment(),
      })
    }).catch((error) => {
    })
  }

  // Фунциоанл запуска подробоного изучения сработки
  // модальное окно
  isOpenModalInformation = (modalInformation) => {
    this.setState({
      modalInformation,
      showModalInformation: true
    })
  }

  // Функционал конвертации и устоновки фильтра в URL браузера
  setFilterToUrl = () => {
    let filter = []
    for (let key in this.state.filter) {
      let item = this.state.filter[key];

      if (key === 'sensor_ids') {
        if (item.length <= 0) {
          item = null
        }
      } else if (key === 'datetime_start' || key === 'datetime_end') {
        item = new Date(item).getTime()
      } else if (key === 'license_plate_list') {
        item = item.join(',')
      }

      if (item) {
        filter.push(key + '=' + item);
      }
    }

    this.setState({
      filterString: 'dashboard?' + filter.join('&')
    })
    this.props.history.replace('dashboard?' + filter.join('&'))
  }

  // Фунционал изменений фильтра на странице
  changeFilters = async (filter, offUpdate) => {
    filter.page = 1;
    await this.setState({ filter });

    if (!offUpdate) {
      await this.setFilterToUrl()
    }
  }

  // Фунционал изменения фильтра в таблице (меняем страницу)
  changeFiltersTable = (filter, offUpdate) => {
    clearTimeout(this.handleGetListFilter)

    this.setState({filter})

    if (!offUpdate) {
      this.handleGetListFilter = setTimeout(() => {
        this.setFilterToUrl()
      }, 500)
    }
  }
  isChangeRowPage = (pagination) => {
    this.setState({
      pagination
    })

    if (this.state.workFile) {
      this.specifyReportRearch()
    } else {
      (async () => {
        await this.getDriveList();
      })();
    }
  }

  // Метод поиска проездов через файл
  bulkReportSearch = (file) => {
    let data = new FormData();

    data.append('file', file);
    data.append('data', JSON.stringify(this.getFilter()));

    axios('post', '/operator/report/bulk-report-search', data).then(response => {
      let rows = response.data;
      let totalCount = response.headers['x-pagination-total-count'];
      let pagination = this.state.pagination;

      if (!rows) {
        rows = []
      }

      pagination.totalCount = totalCount;


      this.setState({
        rows,
        pagination,
        tableLoader: false,
        dateTimeRequest: moment(),

        workFile: true,
        workFileName: file.name
      })
    }).catch(error => {
      this.setState({
        errorWorkFile: true,
        errorWorkFileText: error.response.data.error
      })
    })
  }
  // Метод уточнения списка проездов если мы работаем с файлом
  specifyReportRearch = (infiniteScroll = false) => {
    let filter = this.getFilter();

    axios('post', '/operator/report/specify-report-search', filter).then((response) => {
      let rows = response.data;
      let totalCount = response.headers['x-pagination-total-count'];
      let pagination = this.state.pagination;

      if (!rows) {
        rows = []
      }

      pagination.totalCount = totalCount;

      if (infiniteScroll) {
        rows = [...rows, ...this.state.rows]
      }

      this.setState({
        rows,
        pagination,
        tableLoader: false,
        isInfiniteScrollLoading: false,
        dateTimeRequest: moment(),
      })
    }).catch((error) => {
    })
  }
  // Удаление фкционала поиска по файлу (удаляем выбрный файл и запрашиваем простой список)
  removeFilterFile = () => {
    (async () => {
      await this.getDriveList();
    })();

    this.setState({
      workFile: false,
      workFileName: ''
    })
  }

  // infinite scroll table
  onInfiniteScroll = () => {
  }
  // ---

  // Функционал экспорта документов
  exportDocument = (body) => {
    let filter = this.getFilter();

    filter['type'] = body.type;
    filter['encoding'] = Number(body.encoding);
    filter['use_bulk_result'] = this.state.workFile;

    axios('post', '/export/create', filter).then(response => {
      store.addNotification({
        title: 'Успешно',
        message: 'Задача на выгрузку успешно создана',
        type: 'success',
        insert: 'top',
        container: 'bottom-left',
        dismiss: {
          duration: 3000,
          onScreen: false,
          pauseOnHover: true,
          delay: 0
        }
      });
    }).catch(error => {

    })
  }

  hideGraph = (name) => {
    let widgets = this.props.dashboard.widgets;
    widgets[name] = false;
    this.props.setWidgets(widgets);
  }

  render() {
    const {classes} = this.props;
    const {
      errorMessage,
      isFindResultGrz
    } = this.state;
    const totalCountRow = (this.state.pagination.totalCount > 999) ? this.state.pagination.totalCount : this.state.pagination.totalCount;

    return (
      <Page title={'Устройства'}>
        <Grid container justify={'center'}>
          <Grid item xs={12}>
            {(this.state.workFile) && (
              <Box mb={3}>
                <Alert severity="info">
                  <Grid container spacing={2} alignItems={'center'}>
                    <Grid item>
                      <Typography variant={'h5'}>Ведется работа с файлом "{this.state.workFileName}"</Typography>
                    </Grid>
                    <Grid item>
                      <IconButton style={{padding: 0}} onClick={this.removeFilterFile}>
                        <XIcon/>
                      </IconButton>
                    </Grid>
                  </Grid>
                </Alert>
              </Box>
            )}

            {(this.state.errorWorkFile) && (
              <Box mb={3}>
                <Alert severity="error">
                  <Grid container spacing={2} alignItems={'center'}>
                    <Grid item xs={12} container alignItems={'center'}>
                      <Typography variant={'h5'}>Ошибка</Typography>

                      <IconButton style={{padding: 0, marginLeft: 'auto'}} onClick={() => {
                        this.setState({errorWorkFile: false})
                      }}>
                        <XIcon/>
                      </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant={'body1'}>{this.state.errorWorkFileText}</Typography>
                    </Grid>
                  </Grid>
                </Alert>
              </Box>
            )}

            <Box
              boxShadow={1}
              bgcolor={'white'}
              borderRadius={4}
              p={3}
              mb={4}
              className={classes.blockFilter}
            >
              <Box mb={3}>
                <Grid container wrap={'nowrap'}>
                  <Grid item container spacing={2} alignItems={'center'}>
                    <Grid item>
                      <Typography variant={'h3'}>Поиск по устройствам</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              <Box>
                <Filter
                  filters={this.state.filter}
                  onChange={(filter, offUpdate) => this.changeFilters(filter, offUpdate)}
                  onChangeFile={this.bulkReportSearch}
                  sensorList={this.state.sensorList}
                  sensorFilter={this.state.sensorFilter}
                  useGrzMask={this.state.useGrzMask}

                  onChangeUseGrzMask={() => {
                    this.setState({useGrzMask: !this.state.useGrzMask})
                  }}
                  onFilterSensor={(filter) => this.onFilterSensor(filter)}
                  onStartFilter={this.setFilterToUrl}
                />
              </Box>
            </Box>

            <Box>
              {Boolean(isFindResultGrz) && (
                <Box mb={2}>
                  <Grid container alignItems={'flex-end'} spacing={2}>
                    <Grid item>
                      <Typography variant={'h4'}>
                        Всего найдено:&nbsp;
                        {
                          (!this.state.tableLoader) ? totalCountRow : (
                            <CircularProgress size={20} color={'inherit'}/>
                          )
                        }
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography style={smallTextGray}>
                        Данные актуальны на&nbsp;
                        <Moment
                          format="DD.MM.YYYY HH:mm:ss"
                          value={this.state.dateTimeRequest}
                        >
                          {this.state.dateTimeRequest}
                        </Moment>
                      </Typography>
                    </Grid>

                    <Grid item style={{marginLeft: 'auto'}}>
                      <ExportCreate
                        onExportFile={this.exportDocument}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
              {Boolean(!errorMessage && isFindResultGrz) && (
                <Table
                  columns={this.state.columns}
                  showColumns={this.state.showColumns}
                  rows={this.state.rows}
                  selected={this.state.selected}
                  pagination={this.state.pagination}
                  filter={this.state.filter}
                  loading={this.state.tableLoader}

                  isInfiniteScrollLoading={this.state.isInfiniteScrollLoading}
                  isSelectRowTable={this.isSelectRowTable}
                  isDeleteRowTable={this.isDeleteRowTable}
                  isChangeRowPage={this.isChangeRowPage}
                  isChangeFilter={this.changeFiltersTable}
                  isOpenModalInformation={this.isOpenModalInformation}

                  onLoadMore={this.onLoadMore}
                  onInfiniteScroll={this.onInfiniteScroll}
                />
              )}
              {Boolean(errorMessage) && (
                <WithoutAccess
                  message={errorMessage}
                />
              )}
              {Boolean(!isFindResultGrz) && (
                <SectionNecessarilyGrz
                  classes={this.props.classes}
                />
              )}
            </Box>
          </Grid>
        </Grid>

        <ModalInformation
          open={this.state.showModalInformation}
          item={this.state.modalInformation}
          isClose={() => {
            this.setState({showModalInformation: false})
          }}
        />
      </Page>
    );
  }
}

const SectionNecessarilyGrz = React.memo((props) => {
  const {
    classes
  } = props;

  return (
    <Box className={classes.sectionNecessarilyGrz}>
      <img src={require("../../../assets/icons/cars.png")}/>
      <Typography className="--text">
        Для поиска автомобиля введите<br/>государственный номерной знак, и нажмите поиск.
      </Typography>
    </Box>
  )
})

const smallTextGray = {
  color: '#647083',
  fontSize: 16
};


const styles = {
  gridContainerWidget: {
    '&.MuiGrid-spacing-xs-3 > .MuiGrid-item': {
      [theme.breakpoints.between(0, 1599)]: {
        padding: 8
      },
    },
    '& .MuiGrid-grid-xs-6 > .MuiBox-root': {
      [theme.breakpoints.between(0, 1599)]: {
        padding: 16
      },
    },
  },
  loadingWidget: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 999,
    background: 'rgba(255, 255, 255, 0.90)',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& .MuiCircularProgress-circleIndeterminate': {
      color: '#212529'
    }
  },
  widgetCountBig: {
    [theme.breakpoints.between(0, 1599)]: {
      fontSize: 20
    },
    [theme.breakpoints.between(0, 1399)]: {
      fontSize: 18
    },
  },
  blockFilter: {
    [theme.breakpoints.between(0, 1599)]: {
      padding: 16
    },
  },

  sectionNecessarilyGrz: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: '20px',
    borderRadius: '4px',
    background: '#FFF',
    boxShadow: '0px 2px 8px 0px rgba(142, 150, 168, 0.24)',

    "& img": {
      width: 120,
      marginBottom: 12
    },
    "& .--text": {
      color: '#000',
      fontSize: '16px',
      fontWeight: "500",
      lineHeight: '130%',
      textAlign: "center"
    }
  }
};

export default withStyles(styles)(Dashboard)
