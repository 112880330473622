import React, {PureComponent} from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,

    Box,
    Grid,
    Button,
    Tooltip,
    IconButton,
    Typography
} from "@material-ui/core";
import {
    Map,
    Marker,
    TileLayer
} from 'react-leaflet'
import {
    MapTags
} from "../../../../../components";
import 'leaflet-polylinedecorator';
import {divIcon} from "leaflet";
import {renderToStaticMarkup} from 'react-dom/server';
import {withStyles} from "@material-ui/styles";
import moment from "moment";
import {
    X as XIcon,
    Maximize2 as Maximize2Icon
} from "react-feather";

class MapComponent extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            notifications: {},
            mapCenter: {lat: 56.8519000, lon: 60.6122000},

            isFull: false,
            isMapReady: false
        };
    }

    componentDidMount = () => {
        let envMapCenter = process.env.REACT_APP_MAP_CENTER;

        if (!envMapCenter) {
            this.setState({isMapReady: true});

            return null
        }

        this.setState({
            mapCenter: {
                lat: Number.parseFloat(envMapCenter.split(',')[0]),
                lon: Number.parseFloat(envMapCenter.split(',')[1])
            },
            isMapReady: true
        })
    }

    addNotification = (notifications) => {
        let newNotifications = {...this.state.notifications};

        notifications.map((notification) => {
            newNotifications[notification.payload.report.sensor_id] = notification;

            setTimeout(() => {
                this.removeNotification(notification);
            }, 10000);
        });

        this.setState({
            notifications: newNotifications
        })
    }
    removeNotification = (notification) => {
        let newNotifications = {...this.state.notifications};

        delete newNotifications[notification.payload.report.sensor_id];

        this.setState({
            notifications: newNotifications
        })
    }

    _getIcon = (notification) => {
        const {classes} = this.props;

        return renderToStaticMarkup(
            <div className={classes.marker}>
                <span className={classes.markerTitle}>{notification?.payload?.item?.license_plate}</span>
                <span className={classes.markerMessage}>
                    {notification?.payload?.item?.comment.substr(0, 12)}
                </span>
                <span className={classes.markerDate}>{moment(notification.created).format('DD.MM.YYYY HH:mm:ss')}</span>
            </div>
        )
    }

    render() {
        const {notifications, isFull, mapCenter, isMapReady} = this.state;
        const {classes} = this.props;

        return (
            <>

                <Box bgcolor="white" borderRadius={4} height="800px" overflow="hidden" className={classes.map}>

                    <Button className={classes.buttonFullScree} onClick={() => this.setState({isFull: true})}>
                        <Maximize2Icon size={18}/>
                    </Button>

                    <Map
                        style={{width: "100%", height: "100%"}}
                        center={mapCenter}
                        length={4}
                        zoom={12}
                    >
                        <TileLayer
                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                        />

                        {
                            Object.keys(notifications).map((key, idx) => {
                                const notification = notifications[key];
                                console.log('notification: ', notification);
                                const position = notification?.payload?.report?.gps || {lat: 0, lon: 0};
                                const customMarkerIcon = divIcon({
                                    html: this._getIcon(notification),
                                    iconSize: [35, 35]
                                });

                                return (
                                    <Marker
                                        position={position}
                                        icon={customMarkerIcon}
                                        key={`notification-${key}-${idx}`}
                                    />
                                )
                            })
                        }

                        <MapTags/>

                    </Map>
                </Box>

                <Dialog
                    open={isFull}
                    fullScreen
                >

                    <DialogTitle>
                        <Grid container alignItems="center" justify="space-between">
                            <Grid item>
                                <Typography variant="h3">Полноэкранный режим</Typography>
                            </Grid>
                            <Grid item>
                                <Tooltip title="Выйти из полноэкранного режима">
                                    <IconButton onClick={() => this.setState({isFull: false})}>
                                        <XIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </DialogTitle>

                    <DialogContent style={{display: 'flex', flexDirection: 'column'}} className={classes.map}>
                        <Map
                            style={{width: "100%", height: "100%"}}
                            center={mapCenter}
                            length={4}
                            zoom={12}
                        >
                            <TileLayer
                                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                            />

                            {
                                Object.keys(notifications).map((key, idx) => {
                                    const notification = notifications[key];
                                    const position = notification?.payload?.report?.gps || {lat: 0, lon: 0};
                                    const customMarkerIcon = divIcon({
                                        html: this._getIcon(notification),
                                        iconSize: [35, 35]
                                    });

                                    return (
                                        <Marker
                                            position={position}
                                            icon={customMarkerIcon}
                                            key={`notification-${key}-${idx}`}
                                        />
                                    )
                                })
                            }

                            <MapTags/>

                        </Map>
                    </DialogContent>

                    <DialogActions>
                        <Box px={2} width="100%">
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Button variant="outlined" onClick={() => this.setState({isFull: false})}>Выйти из
                                        полноэкранного режима</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogActions>

                </Dialog>

            </>
        )
    }
}

const styles = {
    map: {
        position: 'relative',

        '& .leaflet-div-icon': {
            backgroundColor: 'transparent!important',
            border: 'none!important'
        }
    },

    marker: {
        width: 35,
        height: 35,
        borderRadius: '100%',
        backgroundColor: "#F59300",
        position: 'relative',
        fontFamily: 'Graphik-LCG',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    markerTitle: {
        color: '#000000',
        fontWeight: '500',
        textShadow: '0 0 1px white',
        fontSize: 12,
        lineHeight: '13px'
    },
    markerMessage: {
        color: '#000000',
        fontWeight: '500',
        textShadow: '0 0 1px white',
        fontSize: 10,
        lineHeight: '11px',
        whiteSpace: 'nowrap',
    },
    markerDate: {
        color: '#000000',
        fontWeight: '500',
        textShadow: '0 0 1px white',
        fontSize: 11,
        lineHeight: '12px',
        whiteSpace: 'nowrap',
    },

    buttonFullScree: {
        position: "absolute",
        zIndex: 999,
        backgroundColor: '#fff',
        borderBottom: '1px solid #ccc',
        width: 26,
        height: 26,
        lineHeight: '26px',
        color: 'black',
        right: 10,
        top: 10,
        boxShadow: '0 1px 5px rgb(0 0 0 / 65%)',
        borderRadius: 4,
        padding: 0,
        minWidth: 0,

        '&:hover': {
            backgroundColor: '#f4f4f4',
        }
    }
};

export default withStyles(styles)(MapComponent)
