import React, {useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  SvgIcon,
  Box,
  Tooltip,
  Grid, TableSortLabel
} from '@material-ui/core';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {makeStyles} from '@material-ui/styles';
import {
  ChevronDown as ChevronDownIcon,
  ChevronUp as ChevronUpIcon,
} from 'react-feather';
import {Image} from '../../../../../components';
import {ArrowHeaderTable as ArrowHeaderTableIcon, Pin as PinIcon} from '../../../../../icons';
import clsx from 'clsx';
import moment from 'moment';
import Skeleton from "@material-ui/lab/Skeleton";
import {openNewTab} from "../../../../../common/openUrls";
import varibles from "../../../../../varibles";

const useStyles = makeStyles(theme => ({
  tableRoot: {
    borderSpacing: '0!important'
  },

  form: {
    marginBottom: 30
  },
  gridLines: {
    alignItems: 'flex-end'
  },

  tableSortLabel: {
    fontSize: 12,
    lineHeight: 1.1,

    '& .MuiTableSortLabel-icon': {
      opacity: 0.5
    }
  },
  tableCellAction: {
    display: 'flex',
    alignItems: 'center'
  },
  tableRow: {
    cursor: 'pointer'
  },

  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: '#fafafa'
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },

  image: {
    width: 50,
    height: 50
  },

  stickyActionsColumn: {
    '& table:first-child': {
      '& tr': {
        '& td:last-child, th:last-child': {
          backgroundColor: 'white',
          position: 'sticky',
          right: 0,
          zIndex: 999
        },
        '& th:last-child': {
          backgroundColor: '#fafafa',
        },
        '& th:first-child': {
          zIndex: 9999
        }
      }
    }
  },

  tableRowHover: {
    cursor: 'pointer'
  }
}));
const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  rootOpen: {
    borderSpacing: '0 0!important',

    '& td': {
      '&:first-child': {
        borderRadius: '4px 0 0 0!important'
      },
      '&:last-child': {
        borderRadius: '0 4px 0 0!important'
      },
    }
  },
  image: {
    width: 50,
    height: 50
  },
  rowAppend: {
    '& td': {
      padding: '5px 10px'
    }
  },
  cellArrow: {
    display: 'flex',
    alignItems: 'center'
  },
  cellSeparator: {
    width: 1,
    height: 45,
    backgroundColor: '#8698b0',
    marginLeft: 12
  },

  rowAverageCoincidences: {
    backgroundColor: '#FFDDBE',

    '& .makeStyles-cellSeparator-130': {
      backgroundColor: '#F8B578'
    },
    '& td': {
      borderTop: '1px solid rgba(237, 122, 16, 0.4)',
      borderBottom: '1px solid rgba(237, 122, 16, 0.4)',

      '&:last-child': {
        borderRight: '1px solid rgba(237, 122, 16, 0.4)',
      },
      '&:first-child': {
        borderLeft: '1px solid rgba(237, 122, 16, 0.4)',
      }
    }
  },
  rowAppendAverageCoincidences: {
    backgroundColor: '#FFDDBE',

    '& .makeStyles-cellSeparator-130': {
      backgroundColor: '#F8B578'
    },
    '& td': {
      '&:last-child': {
        borderRight: '1px solid rgba(237, 122, 16, 0.4)',
      },
      '&:first-child': {
        borderLeft: '1px solid rgba(237, 122, 16, 0.4)',
      }
    },
    '&.makeStyles-rowAppendLast-140 td': {
      borderBottom: '1px solid rgba(237, 122, 16, 0.4)',
    }
  },
  rowBigCoincidences: {
    backgroundColor: '#FFD8D6',

    '& .makeStyles-cellSeparator-130': {
      backgroundColor: '#F48F89'
    },
    '& td': {
      borderTop: '1px solid rgba(226, 34, 22, 0.4)',
      borderBottom: '1px solid rgba(226, 34, 22, 0.4)',

      '&:last-child': {
        borderRight: '1px solid rgba(226, 34, 22, 0.4)',
      },
      '&:first-child': {
        borderLeft: '1px solid rgba(226, 34, 22, 0.4)',
      }
    }
  },
  rowAppendBigCoincidences: {
    backgroundColor: '#FFD8D6',

    '& .makeStyles-cellSeparator-130': {
      backgroundColor: '#F48F89'
    },
    '& td': {
      '&:last-child': {
        borderRight: '1px solid rgba(226, 34, 22, 0.4)',
      },
      '&:first-child': {
        borderLeft: '1px solid rgba(226, 34, 22, 0.4)',
      }
    },
    '&.makeStyles-rowAppendLast-140 td': {
      borderBottom: '1px solid rgba(226, 34, 22, 0.4)',
    }
  },

  rowAverageCoincidencesOpen: {
    '& td': {
      borderBottom: 'none'
    }
  },
  rowBigCoincidencesOpen: {
    '& td': {
      borderBottom: 'none'
    }
  },

  rowAppendLast: {
    '& td': {
      '&:first-child': {
        borderRadius: '0 0 0 4px!important'
      },
      '&:last-child': {
        borderRadius: '0 0 4px 0!important'
      },
    }
  },
});

function Row(props) {
  const {row, handleToRoute} = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const handleGetFixationTime = () => {
    let report_list = row.report_list;

    let sortPoints = report_list.sort((a, b) => a.datetime > b.datetime ? 1 : -1);
    let dateStart = new Date(sortPoints[0].datetime);
    let dateEnd = new Date(sortPoints[report_list.length - 1].datetime);

    let dateStartDate = moment(dateStart).format('DD.MM.YYYY');
    let dateStartTime = moment(dateStart).format('HH:mm:ss');
    let dateEndDate = moment(dateEnd).format('DD.MM.YYYY');
    let dateEndTime = moment(dateEnd).format('HH:mm:ss');

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <p>{dateStartDate}</p>
          <p>{dateStartTime}</p>
        </div>

        <span>&nbsp;—&nbsp;</span>

        <div>
          <p>{dateEndDate}</p>
          <p>{dateEndTime}</p>
        </div>
      </div>
    );
  }
  const handleGetDateTime = (dateTime) => {
    let dateStartDate = moment(dateTime).format('DD.MM.YYYY');
    let dateStartTime = moment(dateTime).format('HH:mm:ss');

    return (
      <div>
        <p>{dateStartDate}</p>
        <p>{dateStartTime}</p>
      </div>
    );
  }

  return (
    <React.Fragment>
      <TableRow className={clsx({
        'MuiTableRow-list': true,
        [classes.root]: true,
        [classes.rootOpen]: open,
        [classes.rowAverageCoincidences]: row.report_list.length >= 4,
        [classes.rowBigCoincidences]: row.report_list.length >= 16,
        [classes.rowAverageCoincidencesOpen]: row.report_list.length >= 4 && open,
        [classes.rowBigCoincidencesOpen]: row.report_list.length >= 16 && open,
      })}>
        <TableCell align={'left'}>
          <div className={classes.cellArrow}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <ChevronUpIcon/> : <ChevronDownIcon/>}
            </IconButton>
          </div>
        </TableCell>
        <TableCell align={'left'}>{row.license_plate}</TableCell>
        <TableCell align={'left'}>{row.report_list.length}</TableCell>
        <TableCell align={'left'}>{handleGetFixationTime()}</TableCell>
        <TableCell align={'left'}></TableCell>
        <TableCell align={'right'}>
          <IconButton onClick={() => handleToRoute(row)}>
            <Tooltip title={'Посмотреть маршрут'}>
              <SvgIcon>
                <PinIcon color={'#8698B1'}/>
              </SvgIcon>
            </Tooltip>
          </IconButton>
        </TableCell>
      </TableRow>

      {
        row.report_list.map((item, idx) => (
          <>
            {
              (open) && (
                <TableRow
                  className={clsx({
                    [classes.rowAppend]: true,
                    [classes.rowAppendLast]: idx === row.report_list.length - 1,
                    [classes.rowAppendAverageCoincidences]: row.report_list.length >= 3,
                    [classes.rowAppendBigCoincidences]: row.report_list.length >= 16
                  })}
                  key={item.sensor_id + '-' + row.license_plate + '-' + idx}
                  style={(!open) ? {display: 'none'} : {}}
                >
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  {
                    false && (
                      <TableCell></TableCell>
                    )
                  }

                  <TableCell>
                    {handleGetDateTime(item.datetime)}
                  </TableCell>
                  <TableCell>{item.sensor_serial}</TableCell>
                  <TableCell align={'right'}>
                    <Grid container justify={'flex-end'}>
                      <Grid item>
                        <Image
                          src={varibles.IMAGE_URL + item.images.main}
                          classImage={classes.image}
                        />
                      </Grid>
                      <Grid item>
                        <IconButton onClick={() => handleToRoute(row)}>
                          <Tooltip title={'Посмотреть маршрут'}>
                            <SvgIcon>
                              <PinIcon color={'#8698B1'}/>
                            </SvgIcon>
                          </Tooltip>
                        </IconButton>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              )
            }
          </>
        ))
      }

      <TableRow style={{height: 8}}/>
    </React.Fragment>
  );
}

const TableComponents = props => {
  const {rows, filter, isChangeRowPage, isChangeFilter, isLoading, isToMap, onChangeSort, sort} = props;
  let {pagination} = props;
  const classes = useStyles();
  const history = useHistory();
  const refTable = useRef(null);

  const handleChangeRowPage = (event) => {
    pagination.rowsPerPage = event.target.value;

    isChangeRowPage(pagination)
  }
  const handleChangePage = (event, page) => {
    let newFilter = filter;
    newFilter['page'] = page;

    isChangeFilter(newFilter);
  }
  const handleToRoute = (row) => {
    let urlFilter = [];

    for (let key in filter) {
      let item = filter[key];

      if (key === 'sensor_ids') {
        if (item.length <= 0) {
          item = null
        }
      }
      if (key === 'datetime_start' || key === 'datetime_end') {
        item = new Date(item).getTime()
      }
      if (key === 'license_plate_list') {
        item = null
      }
      if (key === 'limit') {
        item = 40
      }
      if (key === 'page') {
        item = null
      }
      if (key === 'license_plate_empty' || key === 'license_plate_invalid') {
        item = Boolean(item);
      }

      if (item) {
        urlFilter.push(key + '=' + item);
      }
    }

    let url = '/dashboard/route/' + row.report_list[0].license_plate_id + '?' + urlFilter.join('&');
    openNewTab(url)
  }

  return (
    <>
      <PerfectScrollbar>
        <Box>
          <Table
            id={'basic-drive-table'}
            ref={refTable}
            className={clsx(['MuiTable-list', classes.tableRoot])}
          >
            <TableHead className={'MuiTableHead-list'}>
              <TableRow>
                <TableCell align={'left'} className={classes.tableSortLabel}/>
                <TableCell align={'left'} className={classes.tableSortLabel}>
                  ГРЗ
                </TableCell>
                <TableCell align={'left'} className={classes.tableSortLabel}>
                  <TableSortLabel
                    IconComponent={ArrowHeaderTableIcon}
                    className={classes.tableSortLabel}
                    active={sort.order === 'anomaly'}
                    direction={sort.orderBy}
                    onClick={() => onChangeSort('anomaly')}
                  >
                    Количество совпадений
                  </TableSortLabel>
                </TableCell>
                <TableCell align={'left'} className={classes.tableSortLabel}>
                  Временной промежуток фиксации
                </TableCell>
                <TableCell align={'left'} className={classes.tableSortLabel}>
                  Серийный номер устройства
                </TableCell>
                <TableCell align={'left'} className={classes.tableSortLabel}>

                </TableCell>
              </TableRow>
            </TableHead>

            {
              (!isLoading) ?
                (
                  <TableBody>
                    <TableRow style={{height: 8}}/>

                    {rows.map((row, idx) => (
                      <Row
                        key={idx}
                        row={row}
                        handleToRoute={handleToRoute}
                      />
                    ))}
                  </TableBody>
                )
                :
                (
                  <TableBody>
                    <TableRow style={{height: 8}}/>

                    <TableRow>
                      <TableCell align={'left'}><Skeleton variant="rect" /></TableCell>
                      <TableCell align={'left'}><Skeleton variant="rect" /></TableCell>
                      <TableCell align={'left'}><Skeleton variant="rect" /></TableCell>
                      {
                        false && (
                          <TableCell align={'left'}><Skeleton variant="rect" /></TableCell>
                        )
                      }
                      <TableCell align={'left'}><Skeleton variant="rect" /></TableCell>
                      <TableCell align={'left'}><Skeleton variant="rect" /></TableCell>
                      <TableCell align={'right'}><Skeleton variant="rect" /></TableCell>
                    </TableRow>
                  </TableBody>
                )
            }
          </Table>
        </Box>
      </PerfectScrollbar>
    </>
  );
};

TableComponents.propTypes = {
  columns: PropTypes.array,
  showColumns: PropTypes.array,
  rows: PropTypes.array,
  selected: PropTypes.array,
  pagination: PropTypes.object,
  isSelectRowTable: PropTypes.func,
  isDeleteRowTable: PropTypes.func,
  isChangeRowPage: PropTypes.func,
};
TableComponents.defaultProps = {
  showColumns: []
};

export default TableComponents;
