import React from 'react';
import {
  Box,
  Grid,
  Drawer,
  makeStyles,

  Card,
  IconButton,
  Typography,
  CardContent,
  CardActionArea
} from '@material-ui/core';
import {
  Place as PlaceIcon,
  Close as CloseIcon
} from '@material-ui/icons';
import {
  Image,
  CustomMoment
} from '../../../../components/index';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 300,
    backgroundColor: theme.palette.gray.veryLight,
    boxShadow: '-6px 0px 22px rgba(0, 0, 0, 0.15)',
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent'
    }
  },


  media: {
    height: 100,
  },
}));
const stylesCard = makeStyles(() => ({
  root: {},
  header: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 0
  },
  timeFixation: {
    fontSize: 14,
    lineHeight: '100%',
    letterSpacing: '0.01em',
    fontFeatureSettings: "'ss01' on",
    color: '#647083'
  },
  media: {
    height: 150,
    width: '100%'
  },
}));


const CardNotification = (props) => {
  const {notification, list, changeNotification, idx} = props;
  const classesCard = stylesCard();

  const handleDeleteNotification = () => {
    let newList = [...list];

    newList.splice(idx, 1)

    changeNotification(newList)
  }

  return (
    <Card variant={'outlined'} className={classesCard.root}>
      <CardContent className={classesCard.header}>
        <Typography className={classesCard.timeFixation}>
          <CustomMoment format={'DD.MM.YYYY HH:mm'}>{notification.dateTime}</CustomMoment>
        </Typography>

        <Box ml={'auto'}>
          <IconButton size={'small'} onClick={handleDeleteNotification}>
            <CloseIcon style={{color: '#8698B1'}}/>
          </IconButton>
        </Box>

      </CardContent>
      <CardActionArea>
        <CardContent>
          <Image
            src={'https://trafcontrol.ru:8080/' + notification.images.main}
            classImage={classesCard.media}
          />
          <Typography variant={'body1'}>{notification.mark}</Typography>
          <Typography variant={'body1'}>Гос. знак: {notification.goznak}</Typography>
          <Typography variant={'body1'}>Тип: {notification.type}</Typography>
          <Typography variant={'body2'}>Проезд зафиксирован: {notification.travel}</Typography>
          <Typography variant={'body2'}>Код устройства: {notification.deviceCode}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
const RightSidebar = (props) => {
  const {list, open, onClose, changeNotification} = props;
  const classes = useStyles();

  return (
    <Drawer
      anchor="right"
      onClose={onClose}
      classes={{paper: classes.drawer}}
      open={open}
      variant={'temporary'}
      className={classes.root}
    >
      <Box p={2}>
        <Grid container spacing={1}>
          <Box mb={2}>
            <Grid item xs={12}>
              <Typography variant={'h5'}>Обнаружения — {list.length} шт</Typography>
            </Grid>
          </Box>

          {
            list.map((notification, idx) => (
              <Grid item idx={'notification-' + idx}>
                <CardNotification
                  classes={classes}
                  notification={notification}

                  idx={idx}
                  list={list}
                  changeNotification={changeNotification}
                />
              </Grid>
            ))
          }
        </Grid>
      </Box>
    </Drawer>
  )
}

export default RightSidebar
