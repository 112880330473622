import React, {PureComponent} from "react";
import {
	Box,
	Button,
	Grid,
	Tooltip,
	IconButton,
	Typography, CircularProgress
} from "@material-ui/core";
import {
	withStyles
} from "@material-ui/styles";
import {
	X as XIcon
} from "react-feather";
import varibles from "../../../../../varibles";
import {win} from "leaflet/src/core/Browser";


class Camera extends PureComponent {
	render() {
		const {camera, classes, onOpen, onDelete} = this.props;

		if (!camera) {
			return (
				<Box bgcolor="#647083" borderRadius={4} width="100%" height="100%" position="relative" overflow="hidden"
				     display="flex" justifyContent="center" alignItems="center" flexDirection="column">
					<Box className={classes.empty}>
						<Typography variant="h4" style={{color: 'white', marginBottom: 8}}>Камера не назначена</Typography>
					</Box>
				</Box>
			)
		}

		const url = `${varibles.API_URL}/video/sensor-camera-stream/${camera?.id}?token=${localStorage.getItem('session_id')}`

		return (
			<Box bgcolor="white" borderRadius={4} width="100%" height="100%" position="relative" overflow="hidden"
			     className={classes.card}>

				<Box id="camera-header" className={classes.header}>
					<Grid container spacing={1} justify="space-between" wrap="nowrap">
						<Grid item>
							<span className={classes.address}>{camera?.address}</span><br/>
							<span className={classes.direction}>({camera?.direction || camera?.uid})</span>
						</Grid>
						<Grid item>
							<Tooltip title="Удалить камеру">
								<IconButton style={{padding: 0}} onClick={() => onDelete(camera)}>
									<XIcon color="white"/>
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>
				</Box>

				<img
					src={url}
					className={classes.image}
					onClick={() => onOpen(camera)}
				/>

				<Box position="absolute" width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
					<CircularProgress size={60} style={{color: '#3F51B5'}}/>
				</Box>

			</Box>
		)
	}
}

const styles = {
	card: {
		"&:hover": {
			"& #camera-header": {
				top: 0
			}
		}
	},

	header: {
		position: 'absolute',
		left: 0,
		top: -100,
		right: 0,
		zIndex: 2,
		transition: 'all 0.4s',
		padding: 12,
		backgroundColor: 'rgba(24, 31, 72, 0.7)'
	},

	address: {
		fontSize: 14,
		lineHeight: '16px',
		color: 'white'
	},
	direction: {
		fontSize: 10,
		lineHeight: '12px',
		color: 'white'
	},

	image: {
		cursor: 'pointer',
		position: 'absolute',
		left: 0,
		top: 0,
		width: '100%',
		height: '100%',
		objectFit: 'initial',
		zIndex: 1
	},
	empty: {
		position: 'absolute',
		left: 0,
		top: 0,
		width: '100%',
		height: '100%',

		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	},
};

export default withStyles(styles)(Camera)
