const getNavigation = (categories) => {
    let navigations = {};

    categories.map((category) => {
        if (category.parent_id === "000000000000000000000000") {
            navigations[category.id] = getItems(category, categories);
        }
    });


    return navigations
}
const getItems = (category, categories) => {
    let items = {};

    categories.map((item) => {
        if (item.parent_id === category.id) {
            items[item.id] = getItems(item, categories);
        }
    });

    return {
        ...category,
        items: items
    };
}

export {
    getNavigation
}
