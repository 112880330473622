import React from "react";
import {
    Box,
    Grid,
    Tooltip,
    Checkbox,
    Typography
} from "@material-ui/core";
import {
    Check as CheckIcon
} from "react-feather";
import {ExportCreate as ExportCreateCompoent} from "../index";

const ListComponent = (props) => {
    const { routes, shows, onChange } = props;

    return (

        <Box borderRadius={4} p={2} bgcolor="white" width="100%">

            <Box mb={2}>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h3">Маршруты</Typography>
                    </Grid>
                    <Grid item>
                        <ExportCreateCompoent
                            onExportFile={props.onExportFile}
                        />
                    </Grid>
                </Grid>
            </Box>

            <Grid container spacing={2}>
                {
                    routes.map((route, idx) => (
                        <Grid item xs={12}>

                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <Checkbox
                                        style={{padding: 0}}
                                        checkedIcon={<CheckIcon/>}
                                        checked={Boolean(shows.includes(route.index))}
                                        onClick={() => onChange(route.index)}
                                    />
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <Typography variant="subtitle1">Показать только</Typography>
                                            <Typography variant="caption">{route.length} фиксаций</Typography>
                                        </Grid>
                                        <Grid item><Box width={20} height={20} bgcolor={route.color} borderRadius={999}/></Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    ))
                }
            </Grid>

        </Box>

    )
}

export default ListComponent
