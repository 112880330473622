import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,

  Box,
  Grid,
  Button,
  TextField,
  Typography
} from "@material-ui/core";
import {sendMessages} from "../../../../../common/messages";

class PopUpCreate extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      onSubmit: null,
      isOpen: false
    }
  }

  open = ({ onSubmit }) => {
    this.setState({
      title: "",
      onSubmit: onSubmit,
      isOpen: true
    })
  }
  close = () => {
    this.setState({
      title: "",
      onSubmit: null,
      isOpen: false
    })
  }
  submit = () => {
    const { title } = this.state;
    if (!title) {
      sendMessages({
        message: "Введите наименование группы",
        type: "danger"
      });
      return
    }
    this.state.onSubmit({
      title
    });
  }

  changeTitle = ({ target }) => {
    const { value } = target;
    this.setState({ title: value })
  }

  render () {
    const {
      title,
      isOpen
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth="sm"
        onClose={this.close}
      >
        <DialogTitle>
          <Typography variant="h3">Создание группы пользователей</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            value={title}
            title="Наименование группы"
            placeholder="..."
            variant="outlined"
            fullWidth
            onChange={this.changeTitle}
          />
          <Box mt={2}/>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button variant="outlined" fullWidth onClick={this.close}>Отменить</Button>
            </Grid>
            <Grid item xs={6}>
              <Button variant="contained" fullWidth onClick={this.submit}>Создать</Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    )
  }
}

export default PopUpCreate
