import React, {useState} from 'react';
import {
  Button,
  makeStyles,
  TextField, Tooltip,
  Typography
} from '@material-ui/core';
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete';
import {
  X as XIcon,
  Upload as UploadIcon,
  ChevronDown as ChevronDownIcon
} from 'react-feather';
import ModalUpload from './ModalUpload';
import './index.scss'
import clsx from 'clsx';
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    '& .MuiChip-sizeSmall': {
      height: 20
    }
  },

  hideOptions: {
    '& .MuiAutocomplete-popperDisablePortal': {
      display: 'none'
    },
    '& .MuiAutocomplete-popupIndicatorOpen': {
      transform: 'initial'
    }
  },

  root: {
    '& .MuiAutocomplete-option': {
      backgroundColor: 'white!important',
      padding: '0 16px',

      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)!important'
      }
    }
  }
}));
const stylesInputComponents = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
    overflow: 'hidden',
    position: 'relative',
    background: "rgba(167, 172, 176, 0.2)",
    borderRadius: "4px",
    paddingRight: "15px",
  },

  selectGrid: {
    flex: 1,
    maxWidth: '100%',
    overflow: 'hidden',

    '& .MuiInputBase-root': {
      backgroundColor: 'transparent',
      paddingRight: '35px!important',
      flexWrap: 'nowrap!important',
      overflow: 'hidden'
    },
    '& .MuiInputBase-root.Mui-focused': {
      boxShadow: 'none',
      outline: 'none',

      "&::after": {
        content: "''",
        zIndex: -1,
        width: '65px',
        height: '100%',
        position: 'absolute',
        right: 0,
        top: 0,
        background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 10%)'
      },
    },
  },
  rightGrid: {
    display: 'flex',
    padding: '8px 0',
    minWidth: 92
  },
  rightGridContent: {
    display: 'flex',
    borderLeft: '1px solid rgba(100, 112, 131, 0.2)'
  },
  button: {
    fontSize: 12,
    lineHeight: '120%',
    fontWeight: '500',

    '& svg': {
      marginLeft: 3
    }
  },

  bottomLine: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8
  },
}));
const stylesOptionComponents = makeStyles((theme) => ({
  root: {
    height: 40,
    display: 'flex',
    alignItems: 'center'
  }
}));

const InputComponents = (props) => {
  const {fileUpload, setOpenUpload, ...other} = props;
  const classes = stylesInputComponents();

  return (
    <div className={clsx(['multi-select-text', classes.root])}>
      <div className={classes.selectGrid}>
        <Tooltip
          title={
            <React.Fragment>
              <Typography color="inherit">Правила использования маски.</Typography>
              <Typography color="inherit">* — несколько любых символов</Typography>
              <Typography color="inherit">? — один любой симовол</Typography>
            </React.Fragment>
          }
        >
          <TextField
            {...other}
          />
        </Tooltip>
      </div>
      <div className={classes.rightGrid}>
        <div className={classes.rightGridContent}>
          {
            (fileUpload) && (
              <Button className={classes.button} variant={'text'} onClick={() => {
                setOpenUpload(true)
              }}>
                Загрузить <UploadIcon size={13}/>
              </Button>
            )
          }
        </div>
      </div>
    </div>
  )
}
const MultiSelectText = (props) => {
  const [openUpload, setOpenUpload] = useState(false);
  const [listFiltered, setListFiltered] = useState([]);
  const classes = useStyles();
  const classesOption = stylesOptionComponents();
  const {
    size,
    name,
    label,
    value,
    variant,
    onChange,
    fileUpload,
    onChangeFile
  } = props;

  const handleChangeFilter = (event, newValue) => {
    onChange(newValue, name)
  }
  const handleFilterOptions = (options, params) => {
    const filtered = filter(options, params);

    if (params.inputValue !== '') {
      let values = params.inputValue.split(' ');

      filtered.push(...values);
    }
    if (JSON.stringify(filtered) !== JSON.stringify(listFiltered)) {
      setListFiltered(filtered);
    }

    return filtered;
  }
  const handleGetLimitTagsText = (more) => {
    return 'скрыто ' + more
  }
  const handleOnKeyPress = (event) => {
    let charCode = event.charCode;
    if (charCode === 13) {
      let newValue = [...value];
      listFiltered.map(item => {
        if (newValue.indexOf(item) <= -1) {
          newValue.push(item);
        }
      });
      onChange(newValue, name)
    }
  }
  const handleSendFile = (files) => {
    if (files.length > 0) {
      let file = files[0];

      setOpenUpload(false);
      onChangeFile(file);
    }
  }
  const handleCloseSelect = () => {
    let newValue = [...value];

    listFiltered.map(item => {
      if (newValue.indexOf(item) <= -1) {
        newValue.push(item);
      }
    })
    setListFiltered([]);
    onChange(newValue, name)
  }

  const filter = createFilterOptions();

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.hideOptions]: value.length < 3
      })}
    >
      <Autocomplete
        id="combo-box-demo"
        value={value}
        options={value}
        size={size}
        multiple
        limitTags={2}
        getOptionLabel={(option) => option}
        className={classes.autocomplete}
        filterOptions={handleFilterOptions}
        onClose={handleCloseSelect}
        onChange={handleChangeFilter}
        getLimitTagsText={handleGetLimitTagsText}
        popupIcon={<ChevronDownIcon/>}
        onKeyPress={handleOnKeyPress}
        noOptionsText={'Нет выбранных ГРЗ'}
        blurOnSelect={false}
        disablePortal={true}
        closeText={null}
        openText={null}
        closeIcon={(<Tooltip title="Очистить">
            <HighlightOffIcon/>
          </Tooltip>)}
        renderOption={(option) => (
          <React.Fragment>
            <div className={classesOption.root}>
              {option}
            </div>

            <Tooltip title={'Удалить'}>
              <XIcon width={15} height={15} style={{marginLeft: 'auto'}}/>
            </Tooltip>
          </React.Fragment>
        )}
        renderInput={(params) => (
          <InputComponents
            {...params}
            label={label}
            name={name}
            variant={variant}
            fileUpload={fileUpload}
            setOpenUpload={setOpenUpload}
          />
        )}
      />

      <ModalUpload
        open={openUpload}
        isClose={() => {
          setOpenUpload(false)
        }}
        isLoadFile={(file) => handleSendFile(file)}
      />

    </div>
  )
}

export default MultiSelectText
