// @flow
import {compose} from 'recompose';
import {connect} from 'react-redux';
import { setNotification, setCountBadgeWanted } from '../../../AppState';

import WantedList from './WantedList';

export default compose(
  connect(
    state => ({
      notification: state.app.notification
    }),
    dispatch => ({
      setNotification: (list) => dispatch(setNotification(list)),
      setCountBadgeWanted: (count) => dispatch(setCountBadgeWanted(count))
    }),
  ),
)(WantedList);
