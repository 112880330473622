const exportTypes = {
    'export_pdf': 'PDF без фото',
    'export_pdf_photo': 'PDF с фото',
    'export_csv': 'CSV без фото',
    'export_csv_photo': 'архив с фото и CSV',
    'duplicates_pdf': 'дубликаты, PDF с фоток',
    'duplicates_csv': 'дубликаты, архив с фото и CSV',
    'search_pdf': 'результаты уведомлений, PDF без фото',
    'search_pdf_photo': 'результаты уведомлений, PDF с фото',
    'search_csv': 'результаты уведомлений, CSV без фото',
    'search_csv_photo': 'результаты уведомлений, архив с фото и CSV',
    'overlay_pdf': 'пересечения проздов, PDF с фото',
    'overlay_csv': 'пересечения проездов, архив с CSV и фото',
    'export_csv_result': 'CSV мобильный архив сработок',
    'routes_csv': 'Частые маршруты ТС, CSV без фото',
    'routes_pdf': 'Частые маршруты ТС, PDF без фото',
    'parkings_csv': 'Места частых стоянок ТС, CSV без фото',
    'parkings_pdf': 'Места частых стоянок ТС, PDF без фото',
};

export default exportTypes
