import React, {Component} from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  Button,
  Tooltip
} from '@material-ui/core';
import {
  Page
} from '../../../../components';
import {
  Table,
  ModalEditList,
  ModalDelete,
  ModalCreateList
} from './components';
import axios from '../../../../plugins/axios';
import {
  CustomMoment as Moment
} from '../../../../components';
import {withStyles} from '@material-ui/styles';
import moment from 'moment';
import {store} from "react-notifications-component";

class WantedListsItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      columns: [
        {
          label: 'Дата вынесения постановления',
          field: 'datetime',
          type: 'date',
          align: 'left',
        },
        {
          label: 'ГРЗ',
          field: 'license_plate',
          type: 'grz',
          align: 'left',
        },
        {
          label: 'Марка и модель',
          field: 'markModel',
          type: 'markModel',
          align: 'left',
        },
        {
          label: 'Цвет ТС',
          field: 'color',
          type: 'color',
          align: 'left',
        },
        {
          label: 'Тип ТС',
          field: 'type',
          align: 'left',
        },
        {
          label: 'Комментарий',
          field: 'comment',
          type: 'comment',
          align: 'left',
        },
        {
          label: 'Статус',
          field: 'status',
          type: 'status',
          align: 'left',
        }
      ],
      showColumns: ['color', 'comment', 'datetime', 'license_plate', 'markModel', 'status', 'type'],

      filter: {
        list_id: null,
        limit: 20,
        page: 1
      },
      pagination: {
        rowsPerPage: 20,
        page: 1,
        orderBy: null,
        order: 'asc',
        totalCount: 0
      },
      createNewList: {
        list_id: null,
        license_plate: '',
        color: '',
        mark: '',
        model: '',
        type: '',
        comment: ''
      },
      editForm: {},
      confirmationDeleteForm: {},

      nameList: '',
      searchTableGrz: '',

      openModalCreate: false,
      openModalEdit: false,
      openConfirmationDelete: false,
      isLoading: true,
    }
  }

  componentDidMount = () => {
    let filter = this.state.filter;
    filter.list_id = this.props.match.params.list_id;

    this.setState({filter});

    this.isLoadSearchListItems()
  }

  isLoadSearchListItems = () => {
    this.setState({
      isLoading: true,
      rows: []
    })

    let userAgent = navigator.userAgent.toLowerCase();
    let InternetExplorer = false;
    if ((/mozilla/.test(userAgent) && !/firefox/.test(userAgent) && !/chrome/.test(userAgent) && !/safari/.test(userAgent) && !/opera/.test(userAgent)) || /msie/.test(userAgent)) {
      InternetExplorer = true;
    }

    axios('post', '/search/item/get', this.state.filter).then(response => {
      let rows = response.data;
      let totalCount = response.headers['x-pagination-total-count'];
      let pagination = this.state.pagination;
      let nameList = response.headers['x-list-title'];
      nameList = (!InternetExplorer) ? decodeURIComponent(escape(nameList)) : 'Элементы списка уведомлений';

      if (!rows) {
        rows = []
      }

      pagination.totalCount = totalCount;

      this.setState({
        rows,
        pagination,
        isLoading: false,
        nameList: nameList,
        dateTimeRequest: moment()
      })
    }).catch(error => {
    })
  }

  onOpenCreate = () => {
    let createNewList = this.state.createNewList;
    createNewList.list_id = this.state.filter.list_id;

    this.setState({
      createNewList,
      openModalCreate: true
    })
  }
  onOpenEdit = (row) => {
    this.setState({
      openModalEdit: true,
      editForm: row
    })
  }
  onDeleteList = (list, confirmation) => {
    if (confirmation) {
      this.setState({
        confirmationDeleteForm: {},
        openModalConfirmationDelete: false
      })

      axios('get', '/search/item/delete/' + list.id).then(() => {
        this.isLoadSearchListItems()
      }).catch(error => {

      })
    } else {
      this.setState({
        confirmationDeleteForm: list,
        openModalConfirmationDelete: true
      })
    }
  }

  onChangeCreateListForm = (createNewList) => {
    this.setState({createNewList})
  }

  onCreateList = () => {
    this.setState({
      isLoading: true,
      openModalCreate: false
    });

    this.isLoadSearchListItems()
  }
  onSaveEditList = () => {
    this.setState({
      isLoading: true,
      openModalEdit: false,
      editForm: {}
    });

    this.isLoadSearchListItems()
  }

  onChangeStatusItem = (item) => {
    axios('post', '/search/item/edit/' + item.id, item).then(() => {
      let message = 'Статут ГРЗ ' + item.license_plate + ' успешно изменен'
      store.addNotification({
        title: 'Успешно',
        message: message,
        type: 'success',
        insert: 'top',
        container: 'bottom-left',
        dismiss: {
          duration: 3000,
          onScreen: false,
          pauseOnHover: true,
          delay: 0
        }
      });

      this.setState({
        rows: this.state.rows
      })
    }).catch(error => {
      let message = 'Статут ГРЗ ' + item.license_plate + ' не изменен'
      store.addNotification({
        title: 'Ошибка',
        message: message,
        type: 'danger',
        insert: 'top',
        container: 'bottom-left',
        dismiss: {
          duration: 3000,
          onScreen: false,
          pauseOnHover: true,
          delay: 0
        }
      });
    })
  }

  onChangeSearchTableGrz = () => {
  }

  routeBack = () => {
    this.props.history.goBack()
  }

  changeFilters = (filter) => {
    this.setState({filter})

    setTimeout(() => {
      this.isLoadSearchListItems()
    })
  }

  render() {
    const {classes} = this.props;

    return (
      <Page title={'Устройства'}>
        <Grid container justify={'center'}>
          <Grid item xs={12}>
            <Box mb={2}>
              <Grid container alignItems={'center'}>
                <Box mr={2}>
                  <Typography variant={'h3'}>
                    {this.state.nameList}:&nbsp;
                    {
                      (!this.state.isLoading) ? this.state.pagination.totalCount : (
                        <CircularProgress size={20} color={'inherit'}/>
                      )
                    }
                  </Typography>
                </Box>

                <Box>
                  <Typography style={smallTextGray}>
                    Данные актуальны на&nbsp;
                    <Moment
                      format="DD.MM.YYYY HH:mm:ss"
                      value={this.state.dateTimeRequest}
                    >
                      {this.state.dateTimeRequest}
                    </Moment>
                  </Typography>
                </Box>

                <Box style={{marginLeft: 'auto'}}>
                  <Tooltip title={'Добавление элемента списка уведомлений'}>
                    <Button
                      variant={'contained'}
                      onClick={this.onOpenCreate}
                    >
                      Добавить элемент списка уведомлений
                    </Button>
                  </Tooltip>
                </Box>
              </Grid>
            </Box>

            <Table
              columns={this.state.columns}
              showColumns={this.state.showColumns}
              rows={this.state.rows}
              pagination={this.state.pagination}
              filter={this.state.filter}
              isLoading={this.state.isLoading}

              isChangeRowPage={this.isChangeRowPage}
              isChangeFilter={this.changeFilters}
              onDeleteList={this.onDeleteList}
              onEditList={this.onOpenEdit}

              onChangeStatusItem={this.onChangeStatusItem}
            />
          </Grid>
        </Grid>

        <ModalCreateList
          open={this.state.openModalCreate}
          onClose={() => {
            this.setState({openModalCreate: false})
          }}

          form={this.state.createNewList}
          onCreateList={this.onCreateList}
        />

        <ModalEditList
          open={this.state.openModalEdit}
          onClose={() => {
            this.setState({openModalEdit: false})
          }}

          form={this.state.editForm}
          onSaveList={this.onSaveEditList}
        />

        <ModalDelete
          isDelete={() => this.onDeleteList(this.state.confirmationDeleteForm, true)}
          item={this.state.confirmationDeleteForm}
          open={this.state.openModalConfirmationDelete}
          isClose={() => {
            this.setState({openModalConfirmationDelete: false})
          }}
        />
      </Page>
    );
  }
}

const styles = {
  backTypography: {
    fontSize: 18,
    lineHeight: '18px',
    marginLeft: 16,
    color: '#152435'
  },
  backButton: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    minWidth: 'initial'
  },

  searchContent: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  searchInput: {
    backgroundColor: 'white',
    width: '100%',
    maxWidth: 350,
    borderRadius: 4,
    overflow: 'hidden',

    "& .MuiOutlinedInput-input": {
      backgroundColor: 'white'
    }
  },
};

const smallTextGray = {
  color: '#647083',
  fontSize: 16
};

export default withStyles(styles)(WantedListsItem)
