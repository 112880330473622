import React, {useEffect, useState} from 'react';
import {Switch, Redirect} from 'react-router-dom';
import {clearLocalStorage, getLocalStorage} from './helpers/localStorage';
import {useHistory} from 'react-router-dom';
import axios from './plugins/axios';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import {setUser} from './AppState';

import {RouteWithLayout} from './components';
import {Main as MainLayout} from './layouts';
import {Minimal as MinimalLayout} from './layouts';

import {
  Home as HomeView,

  Dashboard as DashboardView,
  DashboardRoute as DashboardRouteView,

  AuthLogin as AuthLoginView,

  Account as AccountView,

  LoadersList as LoadersListView,

  SystemSettings as SystemSettingsView,

  WantedList as WantedListView,

  WantedLists as WantedListsView,
  WantedListsItem as WantedListsItemView,
  WantedListsСoincidences as WantedListsСoincidencesView,
  WantedListPersonal as WantedListPersonalView,

  UsersList as UsersListView,
  UserCreate as UserCreateView,
  UserEdit as UserEditView,

  Logging as LoggingView,

  UnloadingTasks as UnloadingTasksView,

  // ReferenceList as ReferenceListView,
  // ReferencePage as ReferencePageView,

  FeedbackList as FeedbackListView,

  HistoryNotifications as HistoryNotificationsView,

  TransportConnectionsList as TransportConnectionsListView,
  TransportConnectionRoute as TransportConnectionRouteView,

  // Мобильный розыск
  MobileWanted as MobileWantedView,
  WantedMobileList as WantedMobileListView,
  Archive as MobileArchiveView
} from './views';

const pagesMain = [
  {
    path: '/authorization',
    component: AuthLoginView,
    exact: true,
    layout: MinimalLayout,
  },

  {
    path: '/',
    component: HomeView,
    exact: true,
    layout: MainLayout,
  },

  {
    path: '/dashboard',
    component: DashboardView,
    exact: true,
    layout: MainLayout,
  },
  {
    path: '/dashboard/route/:license_plate',
    component: DashboardRouteView,
    exact: true,
    layout: MainLayout,
  },

  {
    path: '/account',
    component: AccountView,
    exact: true,
    layout: MainLayout,
  },

  {
    path: '/loaders',
    component: LoadersListView,
    exact: true,
    layout: MainLayout,
  },

  {
    path: '/system-settings',
    component: SystemSettingsView,
    exact: true,
    layout: MainLayout,
  },


  {
    path: '/notification',
    component: WantedListView,
    exact: true,
    layout: MainLayout,
  },

  {
    path: '/notification-list',
    component: WantedListsView,
    exact: true,
    layout: MainLayout,
  },
  {
    path: '/notification-list/:list_id',
    component: WantedListsItemView,
    exact: true,
    layout: MainLayout
  },
  {
    path: '/notification-lists-coincidences/:list_id',
    component: WantedListsСoincidencesView,
    exact: true,
    layout: MainLayout,
  },
  {
    path: '/notification-list-personal',
    component: WantedListPersonalView,
    exact: true,
    layout: MainLayout,
  },


  {
    path: '/users',
    component: UsersListView,
    exact: true,
    layout: MainLayout,
  },
  {
    path: '/users/created',
    component: UserCreateView,
    exact: true,
    layout: MainLayout,
  },
  {
    path: '/users/edit/:id',
    component: UserEditView,
    exact: true,
    layout: MainLayout,
  },


  {
    path: '/logging',
    component: LoggingView,
    exact: true,
    layout: MainLayout,
  },


  {
    path: '/unloading-tasks',
    component: UnloadingTasksView,
    exact: true,
    layout: MainLayout,
  },


  {
    path: '/feedback-list',
    component: FeedbackListView,
    exact: true,
    layout: MainLayout,
  },


  {
    path: '/history-notifications',
    component: HistoryNotificationsView,
    exact: true,
    layout: MainLayout,
  },

  {
    path: '/mobile/notification',
    component: MobileWantedView,
    exact: true,
    layout: MainLayout,
  },
  {
    path: '/mobile/notification/create',
    component: MobileWantedView,
    exact: true,
    layout: MainLayout,
  },
  {
    path: '/mobile/notification-list',
    component: WantedMobileListView,
    exact: true,
    layout: MainLayout,
  },
  {
    path: '/mobile/archive',
    component: MobileArchiveView,
    exact: true,
    layout: MainLayout,
  },

  {
    path: '/track-control',
    component: TransportConnectionsListView,
    exact: true,
    layout: MainLayout,
  },
  {
    path: '/track-control/route/:license_plate',
    component: TransportConnectionRouteView,
    exact: true,
    layout: MainLayout,
  },
];

const Routes = (props) => {
  const {app} = props;

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    checkSessionId()
  }, []);

  const history = useHistory();
  const userExit = () => {
    props.setUser(null);
    clearLocalStorage('session_id');
    history.push('/authorization')
  }
  const checkSessionId = () => {
    const sessionId = getLocalStorage('session_id');
    if (!sessionId) {
      setIsLoading(false);

      history.replace('/authorization')

      return false
    }

    axios('post', '/auth/session', {session_id: sessionId}).then((response) => {
      const data = response.data;
      if (!data.status) {
        userExit()

        return null
      }

      props.setUser(data.user);

      setIsLoading(false);
    }).catch((error) => {
      userExit()

      setIsLoading(false);
    })
  }

  if (isLoading) {
    return null
  }

  return (
    <Switch>
      {
        pagesMain.map((page, idx) => (
          <RouteWithLayout
            key={'page-' + idx}
            {...page}
          />
        ))
      }

      <Redirect to="/not-found"/>
    </Switch>
  );
};

export default compose(
  connect(
    state => ({
      app: state.app
    }),
    dispatch => ({
      setUser: (user) => dispatch(setUser(user))
    }),
  ),
)(Routes);
