import React from "react";
import {
    Box,
    Grid,
    Typography,
    Button,
    Tooltip,
    Checkbox,
    FormControlLabel,
} from "@material-ui/core";
import {
    Check as CheckIcon
} from "@material-ui/icons";

const WantedList = (props) => {
    const {wanted, activeFilter, onChangeActiveFilter, onSetActiveFilter} = props;

    const handleChange = ({id}) => {
        let newActiveFilter = {...activeFilter};
        let list_ids = newActiveFilter.list_ids;

        if (list_ids.indexOf(id) > -1) {
            list_ids.splice(list_ids.indexOf(id), 1);
        } else {
            list_ids.push(id)
        }

        newActiveFilter.list_ids = list_ids;

        onChangeActiveFilter(newActiveFilter)
    }

    return (
        <Box bgcolor="white" borderRadius={4} p={3}>
            <Box mb={2}>
                <Typography variant="h3">Мобильные списки уведомлений</Typography>
            </Box>

            <Grid container spacing={2}>
                {
                    wanted.map((wanted, idx) => {
                        const active = activeFilter.list_ids.indexOf(wanted.id) > -1;

                        return (
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name={wanted.id}
                                            onChange={() => handleChange(wanted)}
                                            checkedIcon={(
                                                <CheckIcon
                                                    style={{
                                                        backgroundColor: wanted.color
                                                    }}
                                                />
                                            )}
                                            checked={active}
                                            style={{backgroundColor: wanted.color}}
                                        />
                                    }
                                    label={wanted.title}
                                />
                            </Grid>
                        )
                    })
                }
            </Grid>

            <Box mt={2}>
                <Tooltip title="Установить список">
                    <Button variant="contained" onClick={onSetActiveFilter}>Установить</Button>
                </Tooltip>
            </Box>
        </Box>
    )
};

export default WantedList
