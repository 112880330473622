import React, {Component} from 'react';
import {
  Box,
  Grid,
  Button,
  Backdrop,
  Typography,
  CircularProgress
} from '@material-ui/core';
import {
  Table,
  PopUpAddGrz
} from "./components";
import {
  Page,
  PopUpConfirm
} from "../../../components";
import {
  sendMessage
} from "../../../utils/message";
import axiosAgent from "../../../plugins/axios";

class WantedListPersonal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      personalSearch: {},
      totalCount: 0,
      currentPage: 1,
      isBackdrop: false,
      isLoadItems: true
    }
    this.refInputFile = React.createRef();
    this.refPopUpAddGrz = React.createRef();
    this.refPopUpConfirm = React.createRef();
  }

  componentDidMount = async () => {
    await this.getPersonalSearch();
    await this.getItems();
  }

  getPersonalSearch = async () => {
    const res = await axiosAgent('get', '/search/list/get-personal').then((res) => {
      return res.data
    }).catch(() => {
      return null
    });
    this.setState({ personalSearch: res })
  }
  getItems = async () => {
    this.setState({ isLoadItems: true })
    const { currentPage, personalSearch } = this.state;
    const filter = {
      list_id: personalSearch.id,
      page: currentPage,
      limit: 20,
    };
    const res = await axiosAgent('post', '/search/item/get', filter).then((res) => {
      return res
    }).catch(() => {
      return null
    });
    const totalCount = Math.ceil(Number.parseFloat(res?.headers?.['x-pagination-total-count'] || 0) / 20);

    this.setState({
      items: res?.data || [],
      totalCount: totalCount,
      isLoadItems: false
    })
  }

  editGrz = async (form, isSubmit) => {
    if (!isSubmit) {
      this.refPopUpAddGrz.current.open({
        initForm: form,
        onSubmit: this.editGrz.bind(this)
      })
      return
    }
    this.setState({ isBackdrop: true })
    const res = await axiosAgent('post', `/search/item/edit/${ form.id }`, form).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: err.response || true}
    });
    if (res.error) {
      this.setState({ isBackdrop: false });
      sendMessage({
        message: res.error?.data?.details,
        type: "danger"
      });
      return
    }
    this.refPopUpAddGrz.current.close();
    await this.getItems();
    this.setState({ isBackdrop: false });
    sendMessage({
      message: "Элемент уведомлений успешно изменен",
      type: "success"
    })
  }
  deleteGrz = async (item, confirm) => {
    if (!confirm) {
      this.refPopUpConfirm.current.open({
        message: `Вы действительно хотите удалить элемент уведомлений ${ item.license_plate }`,
        onSubmit: this.deleteGrz.bind(this, item, true)
      });
      return
    }
    this.setState({ isBackdrop: true })
    const res = await axiosAgent('get', `/search/item/delete/${ item.id }`).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: err.response || true}
    })
    await this.getItems();
    this.setState({ isBackdrop: false })
    sendMessage({
      message: "Элемент уведомлений успешно удален",
      type: "success"
    })
  }
  createFromGrz = async (form) => {
    if (!form) {
      this.refPopUpAddGrz.current.open({
        onSubmit: this.createFromGrz.bind(this)
      })
      return
    }

    this.setState({ isBackdrop: true });

    const body = {
      list_id: this.state.personalSearch.id,
      ...form
    };
    const res = await axiosAgent('post', '/search/item/create', body).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: err.response || true}
    });
    if (res.error) {
      this.setState({ isBackdrop: false });
      sendMessage({
        message: res.error?.data?.details,
        type: "danger"
      });
      return
    }
    this.refPopUpAddGrz.current.close();
    await this.getItems();
    this.setState({ isBackdrop: false });
    sendMessage({
      message: "ГРЗ добавлен в Персональный список",
      type: "success"
    })
  }

  changeStatusWanted = async (item) => {
    this.setState({ isLoadItems: true });
    const res = await axiosAgent('post', `/search/item/edit/${ item.id }`, item).then((res) => {
      return res?.data
    }).catch((err) => {
      return {error: err.response}
    });
    await this.getItems();
  }

  uploadFileGrz = async ({ target }) => {
    const { files } = target;
    if (files.length <= 0) {
      return
    }

    this.setState({ isBackdrop: true })

    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("data", JSON.stringify({
      type: "csv",
      list_id: this.state.personalSearch.id
    }));

    const res = await axiosAgent('post', '/search/list/upload', formData).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: err.response}
    });
    await this.getItems();

    this.setState({ isBackdrop: false })
    sendMessage({
      type: "success",
      message: `Успешно загружено: ${res?.imported}; Загруженно с ошибкой: ${res?.failed};`,
    });

    this.refInputFile.current.value = null;
  }

  onChangePage = async (currentPage) => {
    await this.setState({ currentPage });
    await this.getItems();
  }

  render () {
    const {
      items,
      totalCount,
      currentPage,
      isBackdrop,
      isLoadItems
    } = this.state;

    return (
      <Page>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h1">Персональный список</Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Button variant="contained" onClick={this.createFromGrz.bind(this, null)}>
                  Добавить ГРЗ
                </Button>
              </Grid>
              <Grid item>
                <label>
                  <Button variant="contained" component="span">
                    Загрузить файл
                  </Button>

                  <input ref={this.refInputFile} type="file" accept=".csv" hidden onChange={this.uploadFileGrz}/>
                </label>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box mt={1}/>

        <Table
          data={items}
          total={totalCount}
          current={currentPage}
          isLoad={isLoadItems}

          onEdit={this.editGrz}
          onDelete={this.deleteGrz}
          onChangePage={this.onChangePage}
          onChangeStatusWanted={this.changeStatusWanted}
        />


        <PopUpAddGrz ref={this.refPopUpAddGrz}/>
        <PopUpConfirm ref={this.refPopUpConfirm}/>
        <Backdrop open={isBackdrop} invisible={isBackdrop}>
          <CircularProgress/>
        </Backdrop>
      </Page>
    );
  }
}


export default WantedListPersonal
