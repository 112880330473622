import React from 'react';
import {divIcon} from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import {Marker} from 'react-leaflet';

const marker = (props) => {
  let { position, children, title, className, onClick } = props;
  let iconClassName = 'marker ' + className;

  const iconMarkup = renderToStaticMarkup(
    <div>
      <span>{ title }</span>
    </div>
  );

  const customMarkerIcon = divIcon({
    html: iconMarkup,
    className: iconClassName,
    iconSize: [25, 25],
  });

  return (
    <Marker position={position} icon={customMarkerIcon} onClick={onClick}>
      { children }
    </Marker>
  )
};

export default marker
