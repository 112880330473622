import React, { PureComponent } from "react";
import {
    Box,
    Grid,
    Button,
    Typography
} from "@material-ui/core";
import Editor from "./Editor";
import Content from "./Content";
import {useHistory} from "react-router-dom";

class Context extends PureComponent{
    constructor(props) {
        super(props);

        this.state = {
            content: props.content
        }

    }

    onChangeContent = ({target}) => {
        let content = {...this.props.content};
        content.content = target.value;

        this.props.onEdit(content);
    }

    _routeSubCategory = (slug) => {
        const {history} = this.props;
        history.push(`${history.location.pathname}/${slug}`)
    }

    render() {
        const { content, categories, isEdit } = this.props;

        return (
            <>

                <Box mb={2}>
                    <Grid container spacing={1}>
                        {
                            categories.map((subcategory) => (
                                <Grid item>
                                    <Button variant="contained" onClick={() => this._routeSubCategory(subcategory.id)}>{subcategory.title}</Button>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Box>

                <Box bgcolor="white" borderRadius={4}>

                    {
                        isEdit ? (
                            <Editor html={content.content} onChange={this.onChangeContent}/>
                        ) : (
                            <Content html={content.content}/>
                        )
                    }
                </Box>

            </>
        )
    }
}

export default Context
