import React, {Component, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Grid,
  CircularProgress
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {Sidebar, Topbar, RightSidebar} from './components';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import {setUser, setNotification, setOpenLeftSidebar} from '../../AppState';
import {clearLocalStorage, getLocalStorage} from '../../helpers/localStorage';
import {useHistory} from 'react-router-dom';
import {setFavorites} from '../../AppState';

import logoUgmk from './../../assets/image/logo-ugmk.png';
import axios from '../../plugins/axios';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
  shiftContent: {},
  closeLeftSidebar: {
    paddingLeft: 70,

    [theme.breakpoints.between(0, 1399)]: {
      paddingLeft: 0,
    },
  },
  content: {
    height: '100%'
  },
  contentLoading: {},

  rootLoading: {
    padding: '0!important',
    margin: '0!important'
  },
  preLoadingView: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.primary.main,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  preLoadingLogo: {
    width: 270
  },
  preLoadingContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

const Main = props => {
  const {children} = props;
  const classes = useStyles();
  const history = useHistory();
  const openSidebarLeft = props.app.openSidebarLeft;
  const {notification} = props.app;
  const favorites = props.favorites;

  let { user } = props;
  let token = getLocalStorage('session_id');
  if (!user) {
    user = {}
  }

  const [openSidebarRight, setOpenSidebarRight] = useState(false);

  const [listId, setListId] = useState(null);
  useEffect(() => {
    if (!listId){
      handleGetListId()
    }
  }, [listId]);
  const handleGetListId = () => {
    axios('get', '/search/list/get-personal').then(response => {
      setListId(response.data.id)
    })
  }

  const handleSidebarRightOpen = () => {
    if (openSidebarRight) {
      setOpenSidebarRight(false);
    } else {
      setOpenSidebarRight(true);
    }
  };
  const handleSidebarRightClose = () => {
    setOpenSidebarRight(false);
  };
  const handleOpenSidebarLeft = () => {
    localStorage.setItem('openSidebarLeft', !openSidebarLeft);

    props.setOpenLeftSidebar(!openSidebarLeft);
  }
  const handleSetNotificationsList = (list) => {
    props.setNotification(list);
  }
  const userExit = () => {
    props.setUser(null);
    clearLocalStorage('session_id');
    history.push('/')
  }

  const invisibleBadgeNotifications = (notification.length > 0) ? false : true;
  let showLoading = (Object.keys(user).length <= 0) ? true : false;
  if (showLoading && !token){
    showLoading = false
  }

  return (
    <div className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: true,
        [classes.rootLoading]: showLoading,
      })}>
      {
        (showLoading)
          ?
          (
            <div className={classes.preLoadingView}>
              <Grid className={classes.preLoadingContent}>

                <img
                  src={ logoUgmk }
                  className={classes.preLoadingLogo}
                />

                <CircularProgress
                  size={60}
                  style={{color: 'white'}}
                />
              </Grid>
            </div>
          )
          :
          (
            <>
              <Topbar
                user={user}
                userExit={userExit}
                invisibleBadgeNotifications={invisibleBadgeNotifications}
                badgeContentNotifications={notification.length}
              />

              <main id={'app-main'} className={classes.content}>
                {children}
              </main>

              <RightSidebar
                onClose={handleSidebarRightClose}
                open={openSidebarRight}

                changeNotification={handleSetNotificationsList}
                list={notification}
              />
            </>
          )
      }
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default compose(
  connect(
    state => ({
      app: state.app,
      user: state.app.user,
      dashboard: state.dashboard,
      login: state.login,
      favorites: state.app.favorites
    }),
    dispatch => ({
      setUser: (user) => dispatch(setUser(user)),
      setNotification: (notification) => dispatch(setNotification(notification)),
      setFavorites: (favorites) => dispatch(setFavorites(favorites)),
      setOpenLeftSidebar: (open) => dispatch(setOpenLeftSidebar(open))
    }),
  ),
)(Main);
