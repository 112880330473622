import React, { useState } from 'react';
import {
  Button,
  Tooltip
} from '@material-ui/core';
import Modal from './Modal';
import {SaveAltRounded as SaveAltRoundedIcon} from '@material-ui/icons';

const ExportCreate = (props) => {
  const { onExportFile } = props;
  const [isOpenModal, setOpenModal] = useState(false);

  // event об запуске создания файла экспорта
  const handleExportFile = function (body) {
    setOpenModal(false)

    onExportFile(body)
  }

  return (
    <div>
      <Tooltip title="Постановка задач на экспорт материалов">
        <Button
          variant="text"
          color="primary"
          style={{ padding: 0 }}
          onClick={() => { setOpenModal(true) }}
        >
          <span style={{marginRight: 5}}>Экспорт</span>

          <SaveAltRoundedIcon style={{width: 18}}/>
        </Button>
      </Tooltip>

      <Modal
        open={isOpenModal}
        onClose={() => { setOpenModal(false) }}
        onExportFile={handleExportFile}
      />
    </div>
  )
}

export default ExportCreate
