import React, {Component} from 'react';
import {
    Box,
    Grid,
    Button,
    Tooltip,
    Typography,

    Tabs,
    Tab,

  Backdrop,
  CircularProgress
} from '@material-ui/core';
import {Page} from "../../../components";
import {
    Table as TableComponent
} from "./components";
import axios from "../../../plugins/axios";
import {getPageFromCount} from "../../../common/pagination";
import varibles from "../../../varibles";
import axiosAgent from "../../../plugins/axios";
import {getLocalStorage} from "../../../helpers/localStorage";
import originAxios from "axios";
import {downloadFile} from "../../../helpers/files";

class UnloadingTasks extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rows: [],

            filter: {
                page: 1,
                limit: 20,
            },
            pagination: {
                page: 1
            },
            statusWork: {},

            activeTab: "digital-patrol",

            isLoading: true,
            isBackdrop: false
        }
    }

    componentDidMount = () => {
        this.executeRequest();
        this.getStatusWork();
    }

    // получение списка выгрузок в зависимости от выбранного таба
    // Цифровой патруль / мобильный розыск
    executeRequest = () => {
        if (this.state.activeTab === "digital-patrol") {
            this.getListDigitalPatrol();
        } else {
            this.getListMobileSearch();
        }
    }
    getListDigitalPatrol = () => {
        this.setState({ isLoading: true });

        let filter = this.getFilter();

        axios("post", "/export/get-list", filter).then((response) => {
            const { data, headers } = response;

            let pagination = {...this.state.pagination};
            pagination.page = getPageFromCount(headers['x-pagination-total-count'], this.state.filter.limit);
            pagination.totalItems = headers['x-pagination-total-count'];

            this.setState({
                rows: data || [],
                pagination: pagination,

                isLoading: false
            })
        })
    }
    getListMobileSearch = () => {
        this.setState({ isLoading: true });

        const filter = this.getFilter();

        axios("post", "/mobile/export/get-list", filter).then((response) => {
            const { data, headers } = response;

            let pagination = {...this.state.pagination};
            pagination.page = getPageFromCount(headers['x-pagination-total-count'], this.state.filter.limit);
            pagination.totalItems = headers['x-pagination-total-count'];

            this.setState({
                rows: data || [],
                pagination: pagination,

                isLoading: false
            })
        })
    }
    getFilter = () => {
        return this.state.filter
    }

    // получение статусов выгрузок
    getStatusWork = () => {
        axios('get', '/export/queue-status').then((response) => {
            this.setState({
                statusWork: response.data
            })
        })
    }

    onChangeTab = (activeTab) => {
        this.setState({activeTab}, () => {
            this.executeRequest();
        });
    }

    onChangeFilter = (filter, isFastStart) => {
        this.setState({
            filter
        }, () => {
            if (isFastStart) {
                this.executeRequest();
            }
        })
    }
    onDownloadFile = async (row) => {
        await this.setState({ isBackdrop: true });
        const url = varibles.API_URL + row.url;
        const token = getLocalStorage('session_id');
        const fullUrlDownload = `${ url }?token=${ token }`;
        await downloadFile(fullUrlDownload);
        await this.setState({ isBackdrop: false });
    }

    render() {
        const {
            rows,
            filter,
            pagination,
            isLoading,
            activeTab,
            statusWork,
            isBackdrop
        } = this.state;

        return (
            <Page title={'Задачи на выгрузку'}>

                <Box mb={3}>
                    <Grid container alignItems="flex-end" justify="space-between">
                        <Grid item>
                            <Typography variant="h1">Задачи на выгрузку</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h6">Задач на выгрузке: CSV — {statusWork?.csv || 0}, PDF — {statusWork?.pdf || 0}, Дубликаты - {statusWork?.duplicates || 0}</Typography>
                        </Grid>
                    </Grid>
                </Box>

                <TableComponent
                    rows={rows}
                    filter={filter}
                    pagination={pagination}
                    isLoading={isLoading}

                    onChangeFilter={this.onChangeFilter}
                    onDownloadFile={this.onDownloadFile}
                />

                <Backdrop open={isBackdrop} invisible={isBackdrop}>
                    <CircularProgress/>
                </Backdrop>
            </Page>
        );
    }
}

export default UnloadingTasks
