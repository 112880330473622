import React from "react";
import {
    Box,
    Grid,
    Button,
    Tooltip,
    Checkbox,
    Typography,
    FormControlLabel,
} from "@material-ui/core";
import {} from "@material-ui/lab";
import {
    Check as CheckIcon
} from "react-feather";

const BlockWantedLists = (props) => {
    const {title, items, itemsActive, onChange} = props;

    const handleChangeListWanted = (id, isActive) => {
        let newItemsActive = [...itemsActive];

        if (isActive) {
            newItemsActive.splice(newItemsActive.findIndex(t => t === id), 1);
        } else {
            newItemsActive.push(id)
        }

        onChange(newItemsActive);
    }

    return (
        <Box
            borderRadius={4}
            boxShadow={1}
            p={3}
            bgcolor="white"
        >
            <Box mb={3}>
                <Typography variant="h3">{title}</Typography>
            </Box>

            <Box>
                <Grid container spacing={2}>
                    {items.map((item, idx) => {
                        const isActive = itemsActive.indexOf(item.id) > -1;

                        return (
                            <Grid key={`wanted-list-${idx}`} item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name={item.id}
                                            onChange={() => handleChangeListWanted(item.id, isActive)}
                                            checked={isActive}
                                            checkedIcon={<CheckIcon/>}
                                            style={{backgroundColor: item.color}}
                                        />
                                    }
                                    label={item.title}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
        </Box>
    )
};

BlockWantedLists.defaultProps = {
    title: "Списки уведомлений",
    items: [],
};

export default BlockWantedLists
