import React, {useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  SvgIcon,
  TableSortLabel,
  Box,
  Grid,
  Menu,
  Tooltip,
  MenuItem
} from '@material-ui/core';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {makeStyles} from '@material-ui/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  ArrowUp as ArrowUpIcon,
  MoreVertical as MoreVerticalIcon
} from 'react-feather';
import {
  ArrowHeaderTable as ArrowHeaderTableIcon,
  Pin as PinIcon
} from '../../../../../icons/index'
import {
  Image,
  CustomMoment as Moment,
  CustomTablePagination as CustomTablePagination
} from "../../../../../components";
import varibles from "../../../../../varibles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  form: {
    marginBottom: 30
  },
  gridLines: {
    alignItems: 'flex-end'
  },

  tableSortLabel: {
    '& .MuiTableSortLabel-icon': {
      opacity: 0.5
    }
  },
  tableCellAction: {
    display: 'flex',
    alignItems: 'center'
  },
  tableRow: {
    cursor: 'pointer'
  },

  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: '#fafafa'
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },

  image: {
    width: 65,
    minWidth: 65,
    height: 65,
    marginTop: -5,
    marginBottom: -10,

    position: 'relative',
    zIndex: 555
  },

  stickyActionsColumn: {
    '& table:first-child': {
      '& tr': {
        '&:hover td:last-child': {
          backgroundColor: theme.palette.background.default,
        },
      }
    }
  },

  tableAddress: {
    width: 200
  },

  ceilColor: {
    width: 20,
    height: 45,
    borderRadius: 2
  },

  newRow: {
    backgroundColor: '#FFDDBE',
    '& td': {
      borderTop: '1px solid rgba(237, 122, 16, 0.4)',
      borderBottom: '1px solid rgba(237, 122, 16, 0.4)',

      '&:last-child': {
        borderRight: '1px solid rgba(237, 122, 16, 0.4)'
      },
      '&:first-child': {
        borderLeft: '1px solid rgba(237, 122, 16, 0.4)'
      },
    }
  }
}));

const initialState = {
  rightValue: '',
  rightFilterName: '',

  contextMenuX: null,
  contextMenuY: null,
  contextMenuOpen: false,
}

const TableComponents = props => {
  const {
    rows, columns, showColumns, filter,
    loading, isChangeRowPage, isChangeFilter,
    listWanted, listNewWantedItems
  } = props;
  let {pagination} = props;
  const classes = useStyles();
  const history = useHistory();
  const refTable = useRef(null);

  const handleChangeRowPage = (event) => {
    pagination.rowsPerPage = event.target.value;

    isChangeRowPage(pagination)
  }
  const handleChangePage = (event, page) => {
    let newFilter = filter;
    newFilter['page'] = page;

    isChangeFilter(newFilter);
  }
  const createSortHandler = (field) => {
    const isAsc = pagination.orderBy === field && pagination.order === 'asc';
    pagination.order = isAsc ? 'desc' : 'asc';
    pagination.orderBy = field;

    isChangeRowPage(pagination);
  };

  const handleGetUrlImage = (images) => {
    let hostImage = varibles.IMAGE_URL;

    if (images){
      hostImage += images.main;
    }

    return hostImage
  }

  const handleRenderColorList = (row) => {
    let wanted = listWanted.find(item => item.id == row.list_id);

    if (!wanted){
      return null
    }

    return (
      <div className={classes.ceilColor} style={{ backgroundColor: wanted.color }}></div>
    )
  }

  return (
    <div>
      <CustomTablePagination
        rows={rows}
        filter={filter}
        pagination={pagination}
        onChangePage={(event, page) => handleChangePage(event, page + 1)}
        onChangeRowsPerPage={handleChangeRowPage}
      />

      <PerfectScrollbar>
        <Box>
          <Table
            id={'basic-drive-table'}
            ref={refTable}
            className={'MuiTable-list'}
          >
            <TableHead className={'MuiTableHead-list'}>
              <TableRow>
                {
                  columns.map((column, idx) => {
                    if (showColumns.indexOf(column.field) > -1) {
                      let titleSort = column.label + ' по ' + (pagination.orderBy === column.field ? (pagination.order === 'desc') ? 'возрастанию' : 'убыванию' : 'возрастанию');

                      if (column.sort) {
                        return (
                          <TableCell
                            key={'table-cell-' + idx}
                            align={'left'}
                            width={(column.width) ? column.width : ''}
                            scope={'col'}
                          >
                            <Tooltip title={titleSort}>
                              <TableSortLabel
                                IconComponent={ArrowHeaderTableIcon}
                                className={classes.tableSortLabel}
                                active={pagination.orderBy === column.field}
                                direction={pagination.orderBy === column.field ? pagination.order : 'asc'}
                                onClick={() => createSortHandler(column.field)}
                              >
                                {column.label}
                              </TableSortLabel>
                            </Tooltip>
                          </TableCell>
                        )
                      } else {
                        return (
                          <TableCell
                            key={'table-cell-' + idx}
                            align={'left'}
                            className={classes.tableSortLabel}
                            width={(column.width) ? column.width : ''}
                            scope={'col'}
                          >
                            {column.label}
                          </TableCell>
                        )
                      }
                    }
                  })
                }
              </TableRow>
            </TableHead>

            {
              (!loading) ?
                (
                  <TableBody>
                    {rows.map((row, idx) => {
                      return (
                        <TableRow
                          key={row.name + '-' + idx}
                          className={clsx({
                            'MuiTableRow-list': true,
                            [classes.newRow]: listNewWantedItems.indexOf(row.id) > -1
                          })}
                        >
                          <TableCell width={100}>
                            <Moment format="DD.MM.YYYY HH:mm:ss">
                              {row.report.datetime}
                            </Moment>
                          </TableCell>
                          <TableCell width={70}>
                            <Image
                              src={handleGetUrlImage(row.report.images)}
                              classImage={classes.image}
                            />
                          </TableCell>
                          <TableCell width={150}>
                            {row.item.license_plate}
                          </TableCell>
                          <TableCell width={200}>
                            <div className={classes.tableAddress}>
                              {row.report.address}
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                )
                :
                (
                  <TableBody>
                    <TableRow className={'MuiTableRow-list'}>
                      {
                        columns.map((item, idx) => {
                          if (showColumns.indexOf(item.field) === -1) {
                            return null
                          }

                          if (item.type === 'image') {
                            return (
                              <TableCell
                                key={item.field + '-' + idx}
                                name={'image'}
                              >
                                <Skeleton variant="rect" width={65} height={65}/>
                              </TableCell>
                            )
                          }
                          if (item.type === 'date') {
                            return (
                              <TableCell key={item.field + '-' + idx}>
                                <Skeleton variant="rect"/>
                              </TableCell>
                            )
                          }
                          if (item.type === 'action') {
                            return (
                              <TableCell key={item.field + '-' + idx}/>
                            )
                          }
                          if (item.type === 'grz') {
                            return (
                              <TableCell key={item.field + '-' + idx}>
                                <div style={{whiteSpace: 'nowrap'}}><Skeleton variant="rect"/></div>
                              </TableCell>
                            )
                          }

                          return (
                            <TableCell key={item.field + '-' + idx}><Skeleton variant="rect"/></TableCell>
                          )
                        })
                      }
                    </TableRow>
                  </TableBody>
                )
            }
          </Table>
        </Box>
      </PerfectScrollbar>

      <CustomTablePagination
        rows={rows}
        filter={filter}
        pagination={pagination}
        onChangePage={(event, page) => handleChangePage(event, page + 1)}
        onChangeRowsPerPage={handleChangeRowPage}
      />

    </div>
  );
};

TableComponents.propTypes = {
  columns: PropTypes.array,
  showColumns: PropTypes.array,
  rows: PropTypes.array,
  selected: PropTypes.array,
  pagination: PropTypes.object,
  isSelectRowTable: PropTypes.func,
  isDeleteRowTable: PropTypes.func,
  isChangeRowPage: PropTypes.func,
};
TableComponents.defaultProps = {
  showColumns: []
};

export default TableComponents;
