import React from 'react';
import {SvgIcon} from '@material-ui/core';

export default function Home(props) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.3">
          <path d="M12.0002 4.92892L9.87884 7.05024C9.48993 7.43915 9.48993 8.07555 9.87884 8.46445C10.2677 8.85336 10.9041 8.85336 11.293 8.46445L13.4144 6.34313C14.5811 5.17641 16.4903 5.17641 17.657 6.34313C18.8237 7.50986 18.8237 9.41905 17.657 10.5858L15.5357 12.7071C15.1468 13.096 15.1468 13.7324 15.5357 14.1213C15.9246 14.5102 16.561 14.5102 16.9499 14.1213L19.0712 12C21.0228 10.0484 21.0228 6.88054 19.0712 4.92892C17.1196 2.97731 13.9518 2.97731 12.0002 4.92892ZM9.17173 14.8284C9.56064 15.2173 10.197 15.2173 10.5859 14.8284L14.8286 10.5858C15.2175 10.1969 15.2175 9.56047 14.8286 9.17156C14.4397 8.78265 13.8033 8.78265 13.4144 9.17156L9.17173 13.4142C8.78282 13.8031 8.78282 14.4395 9.17173 14.8284ZM12.7073 15.5355L10.5859 17.6568C9.41922 18.8236 7.51003 18.8236 6.3433 17.6568C5.17658 16.4901 5.17658 14.5809 6.3433 13.4142L8.46462 11.2929C8.85353 10.904 8.85353 10.2676 8.46462 9.87867C8.07571 9.48976 7.43932 9.48976 7.05041 9.87867L4.92909 12C2.97747 13.9516 2.97747 17.1194 4.92909 19.0711C6.8807 21.0227 10.0485 21.0227 12.0002 19.0711L14.1215 16.9497C14.5104 16.5608 14.5104 15.9244 14.1215 15.5355C13.7326 15.1466 13.0962 15.1466 12.7073 15.5355Z" fill="white"/>
        </g>
      </svg>
    </SvgIcon>
  );
}
