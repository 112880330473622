import React from "react";
import {
	Box,
	Grid,
	Typography,
	TextField,

	Tooltip,
	Button,

	FormControl,
	Select,
	MenuItem,
} from "@material-ui/core";
import userRoles from "../../../../../constant/userRoles";

const Filter = (props) => {
	const {filter, initialFilter, onChange, onSearch} = props;

	const handleChange = ({target}) => {
		const {name, value} = target;
		let newFilter = {...filter};

		newFilter[name] = value;
		newFilter.page = 1;

		onChange(newFilter);
	}
	const handleClear = () => {
		onChange(initialFilter, true);
	}

	return (
		<>

			<Box mb={2}>
				<Grid container spacing={2}>
					<Grid item xs={3}>
						<Typography variant="formTitle">Email</Typography>

						<TextField
							placeholder="email@test.net"
							variant="outlined"
							name="email"

							value={filter.email}

							fullWidth

							onChange={handleChange}
						/>
					</Grid>
					<Grid item xs={3}>
						<Typography variant="formTitle">Роли</Typography>

						<FormControl fullWidth>
							<Select
								variant="outlined"
								name="roles"

								value={filter.roles}

								fullWidth
								multiple


								onChange={handleChange}
							>
								{
									Object.keys(userRoles).map((key) => {
										const label = userRoles[key];
										const value = key;

										return (
											<MenuItem value={value}>{label}</MenuItem>
										)
									})
								}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={2}>
						<Typography variant="formTitle">Логин</Typography>

						<TextField
							placeholder="Иван"
							variant="outlined"
							name="username"

							value={filter.username}

							fullWidth

							onChange={handleChange}
						/>
					</Grid>
					<Grid item xs={2}>
						<Typography variant="formTitle">Полное имя пользователя</Typography>

						<TextField
							placeholder="Иванов И. И."
							variant="outlined"
							name="full_name"

							value={filter.full_name}

							fullWidth

							onChange={handleChange}
						/>
					</Grid>
					<Grid item xs={2}>
						<Typography variant={'formTitle'}>Организация</Typography>
						<TextField
							fullWidth
							variant="outlined"
							value={filter.organization}
							name="organization"
							onChange={handleChange}
						/>
					</Grid>
				</Grid>
			</Box>

			<Box>
				<Grid container spacing={2}>
					<Grid item>
						<Tooltip title="Поиск">
							<Button variant="contained" onClick={onSearch}>Поиск</Button>
						</Tooltip>
					</Grid>
					<Grid item>
						<Tooltip title="Очистить фильтр">
							<Button variant="outlined" onClick={handleClear}>Очистить</Button>
						</Tooltip>
					</Grid>
				</Grid>
			</Box>
		</>
	)
};

export default Filter
