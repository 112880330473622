import React from 'react';
import {
  TableCell,
  TableRow
} from '@material-ui/core';
import {
  CustomMoment as Moment
} from '../../../../../components';

const LoggingElementsSearchListUpload = (props) => {
  const {row, classes} = props;
  const {payload, username, user_id, ip} = row;
  let {status, mark, model, license_plate} = payload;

  status = (status === 10) ? 'активный' : 'неактивный';
  license_plate = (license_plate) ? license_plate : 'не заполнено';
  mark = (mark) ? mark : 'не заполнено';
  model = (model) ? model : 'не заполнено';

  return (
    <TableRow key={props.key} className={classes.className}>
      <TableCell width={120}>
        <div className={classes.cellPayload}>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <Moment
              format="DD.MM.YYYY"
              value={row['created']}
            >
              {row['created']}
            </Moment>
            <Moment
              format="HH:mm:ss"
              value={props.row['created']}
            >
              {row['created']}
            </Moment>
          </div>
        </div>
      </TableCell>
      <TableCell width={250}>
        <span style={{ fontWeight: 500 }}>Создание элемента списка уведомлений</span>
      </TableCell>
      <TableCell width={800}>
        { `Создан элемент списка уведомлений. ГРЗ — ${ license_plate }. Марка автомобиля: ${ mark }. Модель автомобиля: ${ model }.` }
      </TableCell>
      <TableCell>{ username }</TableCell>
      <TableCell>{ ip }</TableCell>
    </TableRow>
  )
}

export default LoggingElementsSearchListUpload
