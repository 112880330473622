import React from 'react';
import L from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import Marker from './Marker';

import {
  makeStyles
} from '@material-ui/core';
import clsx from "clsx";

const useStyles = makeStyles((theme => ({
  marker: {
    display: 'flex!important',
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
    height: 30,
    backgroundColor: '#212529',
    border: '1px solid rgba(21, 36, 53, 0.1)',
    boxShadow: '0px 4px 4px rgba(72, 93, 210, 0.4)',

    fontWeight: '600',
    fontSize: 16,
    lineHeight: '16px',
    color: 'white',

    '&:hover': {
      zIndex: 999 + '!important',
      boxShadow: '0 0 0 5px #FF4B4B'
    }
  },
  markerHide: {
    display: 'none'
  },
  markerStart: {
    backgroundColor: '#3CB11F'
  },
  markerEnd: {
    backgroundColor: '#F59300'
  },

  activePointNavigation: {
    boxShadow: '0 0 0 5px #3CB11F'
  },

  markerClaster: {
    display: 'flex!important',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px!important',
    height: '30px!important',
    backgroundColor: '#212529',
    border: '1px solid rgba(21, 36, 53, 0.1)',
    boxShadow: '0px 4px 4px rgba(72, 93, 210, 0.4)',
    borderRadius: '100%',

    fontWeight: '600',
    fontSize: 16,
    lineHeight: '16px',
    color: 'white',

    '&:hover': {
      zIndex: 999 + '!important',
      boxShadow: '0 0 0 5px #FF4B4B'
    }
  },
  markerClasterStart: {
    backgroundColor: '#3CB11F'
  },
  markerClasterEnd: {
    backgroundColor: '#F59300'
  },
  markerClasterStartEnd: {
    background: 'linear-gradient(to right, #3CB11F 50%, #F59300 50%)'
  }
})))

const Points = (props) => {
  const {showCluster, points, showPointNumbers, openInfoMarker, activeIndexLinePoint} = props;
  const classes = useStyles();

  const handleClickMarker = (point) => {
    openInfoMarker(point)
  }

  const createClusterCustomIcon = function (cluster, point) {
    let startPoint = false;
    let endPoint = false;
    let title = ''

    point.points.map(point => {
      if (point.start){ startPoint = true }
      if (point.end){ endPoint = true }
    })

    return L.divIcon({
      html: `<span>${title}</span>`,
      className: clsx({
        [classes.markerClaster]: true,
        [classes.markerClasterStart]: startPoint,
        [classes.markerClasterEnd]: endPoint,
        [classes.markerClasterStartEnd]: (startPoint && endPoint),
        ['route-claster-group-id-' + point.objectPointIndex]: true
      }),
      iconSize: L.point(40, 40, true),
    });
  }

  const handleClickMarkerClaster = (point, points) => {
    let newSendPoint = {...points};
    newSendPoint['countRecords'] = 1;
    newSendPoint['points'] = [point];

    openInfoMarker(newSendPoint)
  }

  if (showCluster) {
    return (
      <MarkerClusterGroup iconCreateFunction={createClusterCustomIcon}>
        {
          points.map((point, idx) => (
            <Marker
              key={'point-' + idx}
              position={point.position}
              title={(showPointNumbers) ? point.index : point.points.length}
              className={clsx({
                [point.className]: true,
                [classes.marker]: true,
                [classes.markerStart]: point.start,
                [classes.markerEnd]: point.end,
              })}
              onClick={() => handleClickMarker(point)}
            />
          ))
        }
      </MarkerClusterGroup>
    )
  }


  return (
    <div>
      {
        points.map((point, idx) => {
          if (point.points.length > 1) {
            return (
              <MarkerClusterGroup
                iconCreateFunction={(event) => createClusterCustomIcon(event, point)}
              >
                {
                  point.points.map((pointP, idx) => {
                    return (
                      <Marker
                        key={'point-' + idx}
                        position={point.position}
                        title={pointP.numberIndex}
                        className={clsx({
                          [point.className]: true,
                          [classes.marker]: true,
                          [classes.markerStart]: pointP.start,
                          [classes.markerEnd]: pointP.end,
                          [classes.activePointNavigation]: pointP.numberIndex === activeIndexLinePoint,
                        })}
                        onClick={() => handleClickMarkerClaster(pointP, point)}
                        activeIndexLinePoint={activeIndexLinePoint}
                      />
                    )
                  })
                }
              </MarkerClusterGroup>
            )
          } else {
            return (
              <MarkerClusterGroup
                iconCreateFunction={(event) => createClusterCustomIcon(event, point)}
              >
                {
                  point.points.map((pointP, idx) => (
                    <Marker
                      key={'point-' + idx}
                      position={point.position}
                      title={point.index}
                      className={clsx({
                        [point.className]: true,
                        [classes.marker]: true,
                        [classes.markerStart]: pointP.start,
                        [classes.markerEnd]: pointP.end,
                        [classes.activePointNavigation]: pointP.numberIndex === activeIndexLinePoint,
                      })}
                      onClick={() => handleClickMarkerClaster(pointP, point)}
                      activeIndexLinePoint={activeIndexLinePoint}
                    />
                  ))
                }
                {
                  point.points.map((pointP, idx) => (
                    <Marker
                      key={'point-' + idx}
                      position={point.position}
                      title={point.index}
                      className={clsx({
                        [point.className]: true,
                        [classes.markerHide]: true,
                        [classes.markerStart]: pointP.start,
                        [classes.markerEnd]: pointP.end,
                        [classes.activePointNavigation]: pointP.numberIndex === activeIndexLinePoint,
                      })}
                      onClick={() => handleClickMarkerClaster(pointP, point)}
                      activeIndexLinePoint={activeIndexLinePoint}
                    />
                  ))
                }
              </MarkerClusterGroup>
            )
          }
        })
      }
    </div>
  )
}

export default Points

