import React from "react";
import {
    Box,
    Grid,
    Button,
    Tooltip,
    TextField,
    Typography
} from "@material-ui/core";

const Filter = (props) => {
    const { filter, onChange, onSearch, onClear } = props;

    const handleOnChange = ({target}) => {
        const { name, value } = target;

        let newFilter = {...filter};
        newFilter[name] = value;

        onChange(newFilter);
    }

    return (
        <Box bgcolor="white" borderRadius={4} p={2}>
            <Box mb={1}>
                <Grid container spacing={2} justify="space-between">
                    <Grid item xs={12}>
                        <Typography variant="formTitle">ГРЗ</Typography>

                        <TextField
                            placeholder="В888ВВ96"
                            variant="outlined"
                            name="license_plate"
                            fullWidth
                            value={filter.license_plate}
                            onChange={handleOnChange}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Grid container spacing={2} justify="flex-end">
                <Grid item xs={12} lg={6}>
                    <Tooltip title="Поиск">
                        <Button variant="contained" fullWidth onClick={onSearch}>Поиск</Button>
                    </Tooltip>
                </Grid>
                <Grid item  xs={12} lg={6}>
                    <Tooltip title="Очистить фильтр">
                        <Button variant="outlined" fullWidth onClick={onClear}>Очистить</Button>
                    </Tooltip>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Filter
