import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router';
import {useHistory} from 'react-router-dom';
import {connect, useSelector} from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  makeStyles,
  Grid,
  IconButton,
  Divider,
  TextField,
  Button
} from '@material-ui/core';
import {
  MoreVertical as MoreVerticalIcon,
  ChevronsLeft as ChevronsLeftIcon,
  ChevronsRight as ChevronsRightIcon
} from 'react-feather';

import {
  Favorites as FavoritesIcon,
  UpdateWantedDatabases as UpdateWantedDatabasesIcon,
  CreateNewReport as CreateNewReportIcon,
  PersonalSearch as PersonalSearchIcon,
  ChartWidgets as ChartWidgetsIcon,
  MenuSearchIcon as MenuSearchIcon
} from '../../../../../../icons';

import {
  Charts,
  Favorites,
  AddPersonalSearch
} from '../index';
import SidebarMenuItem from './SidebarMenuItem';
import clsx from 'clsx';
import {compose} from 'recompose';
import {setFavorites} from '../../../../../../AppState';

const useStyles = makeStyles((theme) => ({
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',

    [theme.breakpoints.between(0, 1399)]: {
      top: 0,
      height: '100%',
    },
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  title: {
    display: 'flex',
    alignItems: 'center',

    fontSize: 15,
    lineHeight: '17px',
    fontFeatureSettings: "'ss01' on",
    color: theme.palette.black,
    marginBottom: 8,

    [theme.breakpoints.between(0, 1599)]: {
      fontSize: 14,
      lineHeight: '16px',
    },
  },
  leftIcon: {
    color: theme.palette.gray.light,
    marginRight: 8
  },
  leftIconClose: {
    color: theme.palette.gray.default,
    margin: '0 auto'
  },

  collapse: {
    paddingLeft: 20
  },

  divider: {
    backgroundColor: theme.palette.gray.default,
    margin: '25px 0',
    opacity: 0.2
  },

  input: {
    '& .MuiInputBase-root': {
      backgroundColor: 'white'
    }
  },

  contentOpenSidebar: {
    marginTop: 'auto',

    [theme.breakpoints.between(0, 1399)]: {
      display: 'none'
    },
  },

  rootPersonalSearch: {
    '& svg': {
      width: 20
    },
    '& svg path': {
      fill: 'rgb(134, 152, 177)'
    },

  }
}));

function NavBar({setOpenSidebarLeft, openMobile, onMobileClose, dashboard, open, favorites, personalListId}) {
  const classes = useStyles();
  const location = useLocation();

  const handleOpenSidebarLeft = () => {
    if (!open) {
      setOpenSidebarLeft()
    }
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const initOpenFavorites = favorites.length > 0;

  return (
    <>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
      >
        <PerfectScrollbar options={{suppressScrollX: true}}>
          <div onClick={handleOpenSidebarLeft}>
            <SidebarMenuItem
              title={'Избранное'}
              iconName={FavoritesIcon}
              children={<Favorites/>}
              initOpenWidgets={initOpenFavorites}

              openSidebar={open}
            />

            <Divider variant="middle" className={classes.divider}/>


            <SidebarMenuItem
              openSidebar={open}
              title={'Персональный розыск'}
              iconName={MenuSearchIcon}
              children={<AddPersonalSearch personalListId={personalListId}/>}
              classRoot={classes.rootPersonalSearch}
            />

            <Divider variant="middle" className={classes.divider}/>

            <SidebarMenuItem
              openSidebar={open}
              title={'Графики и виджеты'}
              iconName={ChartWidgetsIcon}
              children={<Charts/>}
            />
          </div>
        </PerfectScrollbar>

        <div className={classes.contentOpenSidebar}>
          {
            open ? (
              <Typography
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  padding: '20px 0'
                }}
                onClick={setOpenSidebarLeft}
              >
                <ChevronsLeftIcon color={'#8698B1'}/>
                Свернуть
              </Typography>
            ) : (
              <Typography
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  padding: '20px 0'
                }}
                onClick={setOpenSidebarLeft}
              >
                <ChevronsRightIcon color={'#8698B1'}/>
              </Typography>
            )
          }
        </div>
      </Box>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
