import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  makeStyles,
  Grid,
  Paper,
  Box,
  IconButton,
  Button,
  TextField, FormControl, Select, MenuItem, Checkbox, FormControlLabel
} from '@material-ui/core';
import Draggable from 'react-draggable';
import {withStyles} from '@material-ui/core/styles';
import {
  Check as CheckIcon,
  X as XIcon
} from 'react-feather';
import {ColorPicker} from "../../../../../components";

const useStyles = makeStyles((theme) => ({
  fileLabel: {
    flex: 1,
    display: 'flex'
  },
  fileTextField: {
    flex: 1
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
    position: 'relative'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    transform: 'translate(0, -50%)',
    color: '#152435',
  }
});

function PaperComponent(props) {
  return (
    <Draggable
      handle="#dialog-confirmation-deletion"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const MyDialogTitle = withStyles(styles)((props) => {
  const {children, classes, onClose, ...other} = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <XIcon/>
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const ModalEditList = (props) => {
  const classes = useStyles();
  const {
    onClose,
    open,
    form,
    onSaveList,
    onChangeForm
  } = props;

  const handleChangeForm = (event) => {
    let newForm = form;

    let item = event.target;
    let name = item.name;
    let value = item.value;

    newForm[name] = value;

    onChangeForm(newForm)
  }
  const handleChangeFormBoolean = ({target}, value) => {
    const { name } = target;
    let newForm = form;

    newForm[name] = value;

    onChangeForm(newForm)
  }

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth={'md'}
      onClose={onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="dialog-confirmation-deletion"
    >
      <MyDialogTitle
        id="dialog-confirmation-deletion"
        onClose={onClose}
      >
        <Grid>
          <Grid container alignItems={'center'}>
            <Grid item>
              <Typography variant='h3'>
                Редактирование списка уведомлений
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </MyDialogTitle>
      <DialogContent style={{padding: 0, paddingBottom: 26}}>
        <Box pl={3} pr={3} mb={4}>
          <Grid container className={classes.gridHeight} spacing={2}>
            <Grid item xs={12}>
              <Typography variant={'formTitle'}>Наименование списка уведомлений</Typography>
              <TextField
                value={form.title}
                name={'title'}
                variant={'outlined'}
                fullWidth
                placeholder={'Введите...'}
                onChange={handleChangeForm}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    onSaveList()
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'formTitle'}>Статус</Typography>

              <FormControl fullWidth>
                <Select
                  fullWidth
                  name="status"
                  value={form.status}
                  variant="outlined"
                  onChange={handleChangeForm}
                >
                  <MenuItem value={10}>Активный</MenuItem>
                  <MenuItem value={0}>Неактивный</MenuItem>
                  <MenuItem value={-1}>В архиве</MenuItem>
                </Select>
              </FormControl>

            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                  control={<Checkbox
                      checked={form.store_archive}
                      name="store_archive"
                      checkedIcon={<CheckIcon/>}
                      onChange={(event) => handleChangeFormBoolean(event, !form.store_archive)}
                  />}
                  label="Загружать и отображать фотографии"
              />
            </Grid>
          </Grid>
        </Box>


        <Box pl={3} pr={3}>
          <Grid justify={'flex-end'} style={{ display: 'flex' }}>
            <Button
              size="large"
              variant="formCancel"
              style={{ marginRight: 10 }}
              onClick={() => onClose()}
            >
              Отменить
            </Button>
            <Button
              disabled={!form.title || form.title == ''}
              size="large"
              type="submit"
              variant="formSubmit"
              onClick={onSaveList}
            >
              Применить
            </Button>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default ModalEditList
