import React, {Component} from 'react';
import {
  Box,
  Grid,
  IconButton,
  Switch,
  Typography,
  FormControlLabel,
  Button,
  Divider,
  makeStyles,
  Card,
  CircularProgress, Select, MenuItem, FormControl
} from '@material-ui/core';
import Draggable from 'react-draggable';
import {withStyles} from '@material-ui/styles';
import {
  X as XIcon,
} from 'react-feather';
import {
  Map
} from './components';
import {Page, CustomCheckbox, DateRange} from '../../../components';
import queryString from 'query-string';
import axios from '../../../plugins/axios';
import moment from 'moment';
import {
  Image,
  CustomMoment as Moment
} from '../../../components';
import clsx from 'clsx';
import theme from '../../../theme';
import varibles from '../../../varibles';
import L from 'leaflet';

const styles = {
  root: {
    minHeight: 'calc(100vh - 128px)'
  },

  backTypography: {
    fontSize: 24,
    lineHeight: '24px',
    marginLeft: 16,
    color: '#152435'
  },
  backButton: {
    display: 'flex',
    alignItems: 'center',
    padding: 0
  },

  switchStyle: {
    padding: 0,
    marginLeft: 0,
    width: '100%',
    justifyContent: 'flex-end',

    '& .MuiSwitch-root': {
      marginLeft: 'auto'
    }
  },

  dividerStyle: {
    margin: 0
  },

  typographyCheckbox: {
    fontSize: 13,
    lineHeight: '13px',
    color: '#647083',
    fontFeatureSettings: "'ss01' on",

    marginRight: 18
  },

  legend: {
    display: 'flex',
    alightItems: 'center'
  },
  legendColor: {
    width: 18,
    height: 18,
    borderRadius: '100%',
    marginRight: 5
  },
  legendTypography: {
    fontSize: 14,
    lineHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    fontFeatureSettings: "'ss01' on",
    color: '#647083'
  },
  gridContainerLegends: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',

    [theme.breakpoints.between(0, 1599)]: {
      marginLeft: 'initial',
    },
  },

  boxMap: {
    overflow: 'hidden',
    position: 'relative',
    height: 'calc(100% - 60px)'
  },
  contentLoading: {
    backgroundColor: 'rgba(24, 31, 72, 0.5)',
    position: 'absolute',
    zIndex: 9999,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& .MuiCircularProgress-circleIndeterminate': {
      color: 'white'
    }
  },

  settingsSwitch: {
    '& .MuiFormControlLabel-label': {
      whiteSpace: 'nowrap'
    }
  },

  containerFastClickPoint: {
    maxHeight: 'calc(100vh - 380px)',
    overflow: 'auto'
  },
  buttonToPoint: {
    color: '#3cb11f',
    fontWeight: '500'
  }
};
const useStylesMarker = makeStyles((theme => ({
  root: {
    padding: '10px 24px',
    boxSizing: 'border-box',
    maxHeight: '100%',
    overflow: 'hidden'
  },
  divider: {
    width: '100%',
    margin: 0,
    backgroundColor: '#e7eaef'
  },

  header: {
    padding: '16px 0',
    display: 'flex',
    alignItems: 'center'
  },
  headerSeparator: {
    fontSize: 20,
    lineHeight: '110%',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.02em',
    fontFeatureSettings: "'ss01' on",
    color: '#8698B1',
    margin: '0 16px'
  },
  headerTime: {
    fontWeight: '500',
    fontSize: 16,
    lineHeight: '110%',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.02em',
    fontFeatureSettings: "'ss01' on",
    color: '#152435'
  },

  body: {
    padding: '16px 0',
    overflow: 'auto',

    '&::-webkit-scrollbar': {
      width: 6
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#aaa'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#999'
    }
  },

  gridTypes: {
    display: 'flex',
    alignItems: 'center'
  },
  typeTitle: {
    marginRight: 20,
    fontSize: 13,
    color: theme.palette.gray.dark
  },

  buttonPoint: {
    padding: 0,
    marginBottom: 10,
    textAlign: 'left',

    '&:hover': {
      backgroundColor: 'transparent',

      '& .makeStyles-typographyPoint-104': {
        color: theme.palette.gray.light
      }
    }
  },
  typographyPoint: {
    fontSize: 15,
    lineHeight: '100%',
    textDecorationLine: 'underline',
    fontFeatureSettings: "'ss01' on",
    color: theme.palette.gray.default,
    whiteSpace: 'nowrap',
    pointerEvents: 'none'
  },
  typographyPointActive: {
    color: theme.palette.primary.main,
    textDecorationLine: 'none',
  }
})));
const useStylesFixed = makeStyles((theme => ({
  root: {
    position: 'absolute',
    left: 42,
    bottom: 14,
    zIndex: 999,
    width: 270,
    padding: 18,
    boxSizing: 'border-box',

    "& > *": {
      padding: 0 + '!important'
    }
  },
  rootTopLeft: {
    left: 42,
    top: 14,
    bottom: 'initial',
  },
  rootTopRight: {
    right: 42,
    top: 14,
    bottom: 'initial',
    left: 'initial',
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    marginBottom: 12,
    cursor: 'grab',

    '&:active': {
      cursor: 'grabbing',
    }
  },
  headerTitle: {
    fontSize: 14,
    lineHeight: '100%',
    letterSpacing: '0.01em',
    fontFeatureSettings: "'ss01'on",
    color: theme.palette.gray.dark
  },
  headerClose: {
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translate(0px, -50%)',
    padding: 0,
    marginRight: -5
  },

  imageContent: {
    marginBottom: 18
  },
  image: {
    width: '100%',
    height: 135,
    objectFit: 'cover',
    borderRadius: 2
  },

  body: {},
  bodyText: {
    fontSize: 14,
    lineHeight: '18px',
    color: theme.palette.black
  },
  bodyTextBold: {
    fontWeight: '500'
  },
  bodyTextGray: {
    color: '#8698b0',
    fontWeight: '500'
  },
})));

const InformationMarker = (props) => {
  const {marker, openInfo, activeFixed, showLegend} = props;
  const classes = useStylesMarker();
  let timeStart = null;
  let timeEnd = null;

  const handleOpenInfo = (point) => {
    openInfo(point)
  }

  if (marker.points.length === 1) {
    let date = new Date(marker.points[0].datetime);

    timeStart = {
      date: moment(date).subtract(5, 'hours').format('DD.MM.YYYY'),
      time: moment(date).subtract(5, 'hours').format('HH:mm:ss'),
    };
    timeEnd = {
      date: moment(date).subtract(5, 'hours').format('DD.MM.YYYY'),
      time: moment(date).subtract(5, 'hours').format('HH:mm:ss'),
    };
  }
  if (marker.points.length > 1) {
    let sortPoints = marker.points.sort((a, b) => a.datetime > b.datetime ? 1 : -1);
    let dateStart = new Date(sortPoints[0].datetime);
    let dateEnd = new Date(sortPoints[marker.points.length - 1].datetime);

    timeStart = {
      date: moment(dateStart).subtract(5, 'hours').format('DD.MM.YYYY'),
      time: moment(dateStart).subtract(5, 'hours').format('HH:mm:ss'),
    };
    timeEnd = {
      date: moment(dateEnd).subtract(5, 'hours').format('DD.MM.YYYY'),
      time: moment(dateEnd).subtract(5, 'hours').format('HH:mm:ss'),
    };
  }

  return (
    <Grid container className={clsx([classes.root, 'adaptive-page-route__information-marker-content'])}>
      <Grid item xs={12} className={classes.header} spacing={2}>
        <Grid item>
          <Typography className={classes.headerTime}>{timeStart.date}</Typography>
          <Typography className={classes.headerTime}>{timeStart.time}</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.headerSeparator}>—</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.headerTime}>{timeEnd.date}</Typography>
          <Typography className={classes.headerTime}>{timeEnd.time}</Typography>
        </Grid>
      </Grid>

      {
        false && (
          <>
            <Divider variant="middle" className={classes.divider}/>

            <Grid item xs={12} className={classes.body}>
              <Grid className={classes.gridTypes}>
                <Typography variant={'body2'} className={classes.typeTitle}>Показать: </Typography>
                <CustomCheckbox
                  label={'Фото'}
                />
                <CustomCheckbox
                  label={'Видео'}
                />
              </Grid>
            </Grid>
          </>
        )
      }

      <Divider variant="middle" className={classes.divider}/>

      <div
        className={clsx([classes.body, 'router-body-fixed-style'])}
        style={(!showLegend) ? {maxHeight: '31vh'} : {maxHeight: '16vh'}}
      >
        <Grid container item xs={12} spacing={1}>
          {
            marker.points.map((point, idx) => {
              return (
                <Grid key={'point-' + idx} item xs={12}>
                  <Button variant={'text'} className={classes.buttonPoint} onClick={() => handleOpenInfo(point)}>
                    <Typography className={clsx({
                      [classes.typographyPoint]: true,
                      [classes.typographyPointActive]: point.id === activeFixed.id
                    })}>
                      {point.license_plate}
                      &nbsp;
                      <Moment format="DD.MM.YYYY HH:mm">
                        {point.datetime}
                      </Moment>
                    </Typography>
                  </Button>
                </Grid>
              )
            })
          }
        </Grid>
      </div>
    </Grid>
  )

}
const InformationFixed = (props) => {
  const {fixed, onClose, position} = props;
  const classes = useStylesFixed();
  const grz = fixed.license_plate;

  return (
    <Draggable bounds="parent" handle=".card-header-draggable">
      <Card
        className={clsx(
          {
            [classes.root]: true,
            [classes.rootTopLeft]: position === 'top-left',
            [classes.rootTopRight]: position === 'top-right',
          })
        }
      >
        <Grid className={clsx([classes.header, 'card-header-draggable'])}>
          <Typography
            className={classes.headerTitle}
          >
            {fixed.numberIndex}) <Moment format="DD.MM.YYYY HH:mm">{fixed.datetime}</Moment>
          </Typography>

          <IconButton size={'small'} className={classes.headerClose} onClick={onClose}>
            <XIcon color={'#8698B1'}/>
          </IconButton>
        </Grid>

        <Grid className={classes.imageContent}>
          <Image
            classImage={classes.image}
            src={varibles.IMAGE_URL + fixed.images.main}
          />
        </Grid>

        <Grid container spacing={1} className={classes.body}>
          <Grid item xs={12}>
            {
              false && (
                <Typography className={clsx([classes.bodyText, classes.bodyTextBold])}>Volkswagen Polo
                  белый</Typography>
              )
            }
            <Typography className={clsx([classes.bodyText, classes.bodyTextBold])}>{grz}</Typography>
          </Grid>

          {
            false && (
              <Grid item xs={12}>
                <Typography className={classes.bodyText}>
                  <Typography className={classes.bodyTextGray}>Тип: </Typography>злостный нарушитель
                </Typography>
              </Grid>
            )
          }

          <Grid item xs={12}>
            <Typography className={classes.bodyText}>
              <Typography className={classes.bodyTextGray}>Проезд зафиксирован: </Typography>{fixed.address}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.bodyText}>
              <Typography className={classes.bodyTextGray}>Код устройства: </Typography> {fixed.sensor_serial}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </Draggable>
  )
}
const HeaderInformationFilter = (props) => {
  const {filter, totalPointCount, grzFind, onChangeFilter, showCountPoint, onChangeShowCountPoint} = props;
  let timeInterval = '';
  let {datetime_start, datetime_end} = filter;

  if (datetime_start && datetime_end) {
    timeInterval = `с ${moment(datetime_start).format('DD.MM.YYYY HH:mm')} до ${moment(datetime_end).format('DD.MM.YYYY HH:mm')}`
  } else {
    timeInterval = 'не задан'
  }

  const handleChangeDate = (range) => {
    let newFilter = {...filter};

    newFilter.datetime_start = moment(range.startDate * 1000);
    newFilter.datetime_end = moment(range.endDate * 1000);

    onChangeFilter(newFilter)
  }

  const handleChangeShowCount = (event) => {
    onChangeShowCountPoint(event.target.value)
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant={'h4'}>Всего отображено: {totalPointCount}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'body1'}>ГРЗ: <span style={{ fontWeight: '500' }}>{grzFind}</span></Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container wrap={'nowrap'} alignItems={'center'} spacing={1}>
          <Grid item><Typography variant={'body1'}>Показывать:</Typography></Grid>
          <Grid item style={{ flex: 1 }}>
            <FormControl
              variant='outlined'
              size='small'
              fullWidth
            >
              <Select
                labelId="demo-mutiple-type-label"
                id="demo-mutiple-type"
                name="type"
                value={showCountPoint}
                onChange={handleChangeShowCount}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  }
                }}
                fullWidth
              >
                <MenuItem value={20}>по 20</MenuItem>
                <MenuItem value={40}>по 40</MenuItem>
                <MenuItem value={60}>по 60</MenuItem>
                <MenuItem value={80}>по 80</MenuItem>
                <MenuItem value={100}>по 100</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

class DashboardRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      points: [],
      apiPoints: [],
      pointsApi: [],
      mapPositionFromClick: [],
      listMarkersOpen: {
        0: null,
        1: null,
        2: null
      },

      map: {
        connectDots: true,
        showLegend: true,
      },
      marker: {},
      fixed: {},
      filter: {},

      licensePlate: '',

      showCountPoint: 40,

      totalPointCount: 0,
      indexListMarkersOpen: 0,
      activeIndexLinePoint: null,
      activeIndexGroup: null,

      openModalInformation: false,
      openModalInformationVideo: false,
      openMarker: false,
      openFixed: false,

      isLoading: true
    };

    this.refMap = React.createRef(null);
  }

  // инициализация страницы
  componentDidMount = () => {
    let licensePlate = this.props.match.params.license_plate;
    let params = queryString.parse(this.props.location.search);
    let filter = this.state.filter;

    if (Object.keys(params).length > 0) {
      for (let key in params) {
        filter[key] = params[key];

        if (key === 'sensor_ids' || key === 'license_plate_list') {
          filter[key] = params[key].split(',')
        }
        if (key === 'limit') {
          filter[key] = Number(params[key])
        }
        if (key === 'speed') {
          filter[key] = parseFloat(params[key])
        }
        if (key === 'datetime_start' || key === 'datetime_end') {
          let date = new Date();
          date.setTime(params[key]);
          filter[key] = date
        }
        if (key === 'license_plate_empty' || key === 'license_plate_invalid') {
          filter[key] = Boolean(params[key]);
        }
      }
    }

    filter['license_plate_id'] = licensePlate;

    this.setState({
      filter,
      licensePlate
    }, () => {
      this.getPointsList()
    })
  }

  // конвертация фильтров со фронта на бек для получения точек
  getFilter = () => {
    let filter = {}

    for (let key in this.state.filter) {
      let item = this.state.filter[key];

      if (item) {
        filter[key] = item;
      }
    }

    filter['sort_field'] = 'datetime';
    filter['sort_direction'] = 'desc';
    filter['limit'] = this.state.showCountPoint;

    return filter
  }

  // метод получения точек
  getPointsList = () => {
    let filter = this.getFilter();

    axios('post', '/operator/report/find', filter).then((response) => {
      let pointsApi = response.data;
      let totalPointCount = response.headers['x-pagination-total-count'];
      let grzFind = '';

      if (!pointsApi) {
        pointsApi = []
      }
      if (pointsApi.length > 0) {
        grzFind = pointsApi[0]['license_plate']
      }
      if (totalPointCount > this.state.showCountPoint) {
        totalPointCount = this.state.showCountPoint
      }

      pointsApi = pointsApi.reverse();

      this.setState({
        pointsApi,
        grzFind: grzFind,
        totalPointCount: totalPointCount
      })

      this.setMapPoints();
    }).catch((error) => {
    })
  }

  // устоновка меток на карту
  setMapPoints = () => {
    let objectPoints = {}
    let points = []
    let pointsApi = this.state.pointsApi;
    let objectPointIndex = 1;

    pointsApi.map((point, idx) => {
      let interval = 1 * 60 * 1000;
      let sensor_id = point.sensor_id;
      let datetime = moment(Math.ceil(moment(point.datetime) / interval) * interval).format('HH:mm:ss');
      let key = point.gps.lat + '_' + point.gps.lon;

      if (!objectPoints[key]) {
        objectPoints[key] = {
          index: idx + 1,
          points: [],
          position: point.gps,
          type: '',
          start: false,
          end: false,
          objectPointIndex: objectPointIndex
        }

        objectPointIndex++
      }

      point['start'] = idx === 0;
      point['end'] = idx === pointsApi.length - 1;
      point['numberIndex'] = idx + 1;
      point['numberIndexGroup'] = objectPoints[key]['objectPointIndex'];

      if (idx === 0) {
        objectPoints[key]['start'] = true
      }
      if (idx === pointsApi.length - 1) {
        objectPoints[key]['end'] = true
      }

      objectPoints[key]['points'].push(point)
    })

    for (let key in objectPoints) {
      let point = objectPoints[key]

      points.push({
        objectPointIndex: point.objectPointIndex,
        countRecords: point.points.length,
        position: point.position,
        points: point.points,
        className: point.className,
        start: point.start,
        end: point.end,
        index: point.index
      })
    }

    this.setState({
      isLoading: false,
      points,
      apiPoints: pointsApi
    })
  }

  // деталиция метки на карте (открывается попак на карте)
  openInfoMarker = (marker) => {
    let listMarkersOpen = {...this.state.listMarkersOpen};
    let indexListMarkersOpen = this.state.indexListMarkersOpen;
    let indexListMarkersOpenNull = null;
    let point = [...marker.points];
    if (point.length <= 0) {
      return null
    }
    ;

    for (let key in listMarkersOpen) {
      if (!listMarkersOpen[key] && indexListMarkersOpenNull === null) {
        indexListMarkersOpenNull = Number(key)
      }
    }

    if (indexListMarkersOpenNull === indexListMarkersOpen || !indexListMarkersOpenNull) {
      listMarkersOpen[indexListMarkersOpen] = point[0];
      indexListMarkersOpen++
    } else {
      listMarkersOpen[indexListMarkersOpenNull] = point[0];
    }

    if (indexListMarkersOpen >= 3) {
      indexListMarkersOpen = 0
    }

    this.setState({
      listMarkersOpen,
      indexListMarkersOpen
    })
  }
  openInfoFixed = (fixed) => {
    this.setState({
      fixed,
      openFixed: true
    })
  }
  closeInfoFixed = (index) => {
    let indexListMarkersOpen = this.state.indexListMarkersOpen;
    let listMarkersOpen = {...this.state.listMarkersOpen};
    let allNull = true;

    listMarkersOpen[index] = null;

    for (let key in listMarkersOpen) {
      if (listMarkersOpen[key]) {
        allNull = false
      }
    }

    this.setState({
      listMarkersOpen,
      indexListMarkersOpen: (allNull) ? 0 : indexListMarkersOpen
    })
  }

  // навигация назад
  routeBack = () => {
    this.props.history.goBack()
  }

  // перемеще к точке на карта через боковую панель
  goToPoint = (point) => {
    let activeIndexLinePoint = this.state.activeIndexLinePoint;
    let position = [point.gps.lat, point.gps.lon];
    let positionJoin = [...position].join('_');

    if (activeIndexLinePoint === point.numberIndex) {
      activeIndexLinePoint = null
    } else {
      activeIndexLinePoint = point.numberIndex
    }

    if (positionJoin === this.state.mapPositionFromClick.join('_')) {
      position[0] = position[0] + 0.00000000000001
    }

    this.setState({
      mapPositionFromClick: position,
      activeIndexLinePoint: activeIndexLinePoint,
      activeIndexGroup: point.numberIndexGroup
    })
  }


  onMoveend = () => {
    setTimeout(() => {
      let divClaster = document.getElementsByClassName('route-claster-group-id-' + this.state.activeIndexGroup)[0];
      if (divClaster) {
        divClaster.click()
      }
    }, 800)
  }

  onChangeFilter = (filter) => {
    this.onClearAllPolylineDecorator()

    this.setState({
      filter,
      apiPoints: [],
      isLoading: true
    }, () => {
      this.getPointsList()
    })
  }
  onClearAllPolylineDecorator = () => {
    let map = this.refMap.current;

    if (map){
      let m = map.contextValue.map;
      let _layers = map.contextValue.map._layers;
      if (Object.keys(_layers).length > 0){
        for(let i in _layers) {
          var lg = L.polylineDecorator([_layers[i]]);
          m.removeLayer(lg);
        }
      }
    }
  }

  onChangeShowCountPoint = (showCountPoint) => {
    this.onClearAllPolylineDecorator()

    this.setState({
      showCountPoint,
      apiPoints: [],
      isLoading: true
    }, () => {
      this.getPointsList()
    });
  }

  render() {
    const {classes} = this.props;

    return (
      <Page title={'Устройства'}>
        <Grid container spacing={3} className={clsx([classes.root, 'adaptive-page-route'])}>
          <Grid item xs={3} style={{maxHeight: 'inherit'}}>
            <Box
              boxShadow={1}
              bgcolor={'white'}
              borderRadius={4}
              mb={2}
              p={3}
            >
              <HeaderInformationFilter
                filter={this.state.filter}
                totalPointCount={this.state.totalPointCount}
                grzFind={this.state.grzFind}

                onChangeFilter={this.onChangeFilter}

                showCountPoint={this.state.showCountPoint}
                onChangeShowCountPoint={this.onChangeShowCountPoint}
              />
            </Box>


            <Box
              boxShadow={1}
              bgcolor={'white'}
              borderRadius={4}
              p={3}
            >
              <Box mb={2}>
                <Typography variant={'h5'}>График фиксаций</Typography>
              </Box>

              <Grid container spacing={1} className={classes.containerFastClickPoint}>
                {
                  this.state.pointsApi.map((point, idx) => {
                    return (
                      <Grid item xs={12} key={'list-key-' + idx}>
                        <Button
                          variant={'text'}
                          onClick={() => this.goToPoint(point)}
                          className={clsx({
                            [classes.buttonToPoint]: point.numberIndex === this.state.activeIndexLinePoint
                          })}
                        >
                          {point.numberIndex})&nbsp;
                          <Moment format={'DD.MM.YYYY HH:mm:ss'}>{point.datetime}</Moment>
                        </Button>
                      </Grid>
                    )
                  })
                }
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={9} className={'router-view-map'}>
            <Box
              boxShadow={1}
              bgcolor={'white'}
              borderRadius={4}
              p={1}
              pl={2}
              pr={2}
              mb={1}
            >
              <Grid container spacing={2} className={classes.settingsSwitch}>
                <Grid item>
                  <FormControlLabel
                    className={clsx([classes.switchStyle, 'router-switch-style'])}
                    control={<Switch
                      checked={this.state.map.connectDots}
                      onChange={() => {
                        this.state.map.connectDots = !this.state.map.connectDots;
                        this.setState({map: this.state.map})
                      }}
                    />}
                    label="Соединять точки линиями"
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    className={clsx([classes.switchStyle, 'router-switch-style'])}
                    control={<Switch
                      checked={this.state.map.showLegend}
                      onChange={() => {
                        this.state.map.showLegend = !this.state.map.showLegend;
                        this.setState({map: this.state.map})
                      }}
                    />}
                    label="Показать легенду"
                    labelPlacement="start"
                  />
                </Grid>

                <Grid item className={classes.gridContainerLegends}>
                  {
                    this.state.map.showLegend && (
                      <Grid container spacing={1}>
                        <Grid item alignItems={'center'} className={classes.legend}>
                          <div className={classes.legendColor} style={{backgroundColor: '#3CB11F'}}></div>
                          <Typography className={classes.legendTypography}> — Начальная точка маршрута</Typography>
                        </Grid>
                        <Grid item alignItems={'center'} className={classes.legend}>
                          <div className={classes.legendColor} style={{backgroundColor: '#F59300'}}></div>
                          <Typography className={classes.legendTypography}> — Конечная точка маршрута</Typography>
                        </Grid>
                        <Grid item alignItems={'center'} className={classes.legend}>
                          <div className={classes.legendColor} style={{backgroundColor: '#212529'}}></div>
                          <Typography className={classes.legendTypography}> — Промежуточные точки</Typography>
                        </Grid>
                      </Grid>
                    )
                  }
                </Grid>
              </Grid>
            </Box>

            <Box
              boxShadow={1}
              bgcolor={'white'}
              borderRadius={4}

              className={classes.boxMap}
            >
              <Map
                position={(this.state.mapPositionFromClick.length === 2) ? this.state.mapPositionFromClick : [56.8519000, 60.6122000]}
                zoom={(this.state.mapPositionFromClick.length === 2) ? 16 : 10}
                points={this.state.points}
                apiPoints={this.state.apiPoints}
                showCluster={this.state.map.showCluster}
                connectDots={this.state.map.connectDots}
                showPointNumbers={this.state.map.showPointNumbers}
                openInfo={this.openInfo}
                openInfoMarker={this.openInfoMarker}

                activeIndexLinePoint={this.state.activeIndexLinePoint}
                onMoveend={this.onMoveend}
                isLoading={this.state.isLoading}

                refMap={this.refMap}
              />

              {
                (this.state.listMarkersOpen['0']) && (
                  <InformationFixed
                    fixed={this.state.listMarkersOpen['0']}
                    onClose={() => this.closeInfoFixed('0')}
                    position={'bottom-left'}
                  />
                )
              }
              {
                (this.state.listMarkersOpen['1']) && (
                  <InformationFixed
                    fixed={this.state.listMarkersOpen['1']}
                    onClose={() => this.closeInfoFixed('1')}
                    position={'top-left'}
                  />
                )
              }
              {
                (this.state.listMarkersOpen['2']) && (
                  <InformationFixed
                    fixed={this.state.listMarkersOpen['2']}
                    onClose={() => this.closeInfoFixed('2')}
                    position={'top-right'}
                  />
                )
              }

              {
                (this.state.isLoading) && (
                  <div className={classes.contentLoading}>
                    <CircularProgress size={60} color={'white'}/>
                  </div>
                )
              }
            </Box>
          </Grid>
        </Grid>
      </Page>
    )
  }
}


export default withStyles(styles)(DashboardRoute)
