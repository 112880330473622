import React, {useRef} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Tooltip,
  Button
} from '@material-ui/core';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {makeStyles} from '@material-ui/styles';
import {useHistory} from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  CustomTablePagination,
  CustomMoment as Moment,
} from '../../../../../components';
import clsx from 'clsx';
import moment from 'moment';
import {
  ExternalLink as ExternalLinkIcon
} from 'react-feather';

const useStyles = makeStyles(theme => ({
  form: {
    marginBottom: 30
  },
  gridLines: {
    alignItems: 'flex-end'
  },

  tableSortLabel: {
    '& .MuiTableSortLabel-icon': {
      opacity: 0.5
    }
  },
  tableCellAction: {
    display: 'flex',
    alignItems: 'center'
  },
  tableRow: {
    '& td': {
      padding: '10px!important'
    }
  },

  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: '#fafafa'
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },

  image: {
    width: 65,
    height: 65,
    marginBottom: -10,

    position: 'relative',
    zIndex: 555
  },

  stickyActionsColumn: {
    '& table:first-child': {
      '& tr': {
        '&:hover td:last-child': {
          backgroundColor: theme.palette.background.default,
        },
        '& td:last-child, th:last-child': {
          backgroundColor: 'white',
          position: 'sticky',
          right: 0,
          zIndex: 999
        },
        '& th:last-child': {
          backgroundColor: 'rgb(237, 241, 246)',
        },
        '& th:first-child': {
          zIndex: 9999
        }
      }
    }
  },

  cellPayload: {},
  ceilColor: {
    width: 20,
    height: 45,
    borderRadius: 2
  }
}));

const TableComponents = props => {
  const {
    rows,
    columns,
    showColumns,
    isChangeRowPage,
    isLoading,
    filter,
    isChangeFilter
  } = props;
  let {pagination} = props;
  const classes = useStyles();
  const refTable = useRef(null);
  const history = useHistory();

  const handleChangeRowPage = (event) => {
    pagination.rowsPerPage = event.target.value;

    isChangeRowPage(pagination)
  }
  const handleChangePage = (event, page) => {
    let newFilter = filter;
    newFilter['page'] = page;

    isChangeFilter(newFilter);
  }
  const handleGetMessage = (column) => {
    let {payload, type, title, created} = column;
    let message = '';

    if (type == 'search') {
      let { item, report } = payload;
      let { license_plate } = item;
      let { address, license_plate_id } = report;

      let link = `/#/dashboard/route/${license_plate_id}?colors=&limit=40`

      message = `Найдена машина <b>${license_plate}</b> из списка <b>${ title }</b> адрес <b>${ address }</b> <Tooltip title="Открыть маршрут"><a href="${link}" target="_blank" style="color: #212529; font-weight: 500">Открыть маршрут</a></Tooltip>`
    }
    if (type == 'sensor') {
      let {id, status} = payload;
      let statusName = '';

      if (status == '10') {
        statusName = 'на <b>Активный</b>'
      }
      if (status == '20') {
        statusName = 'в <b>Ошибке</b>'
      }
      if (status == '0') {
        statusName = 'на <b>Неактивный</b>'
      }

      let link = `/#/sensor/edit/${ id }`

      message = `Устройство <b>${title}</b> изменил свой статус ${statusName} <Tooltip title="Открыть устройства"><a href="${link}" target="_blank" style="color: #212529; font-weight: 500">Открыть</a></Tooltip>`
    }
    if (type == 'duplicate') {
      let datetime_start = moment(created).subtract(1, 'hours').unix() * 1000;
      let datetime_end = moment(created).unix() * 1000;

      let link = `/#/duplicates?license_plate=${ title }&datetime_start=${ datetime_start }&datetime_end=${ datetime_end }&limit=20&page=1`

      message = `Найден дубликат ГРЗ <b>${ title }</b> <Tooltip title="Открыть дубликаты"><a href="${link}" target="_blank" style="color: #212529; font-weight: 500">Открыть</a></Tooltip>`
    }

    return message
  }

  return (
    <>
      <CustomTablePagination
        rows={rows}
        filter={filter}
        pagination={pagination}
        onChangePage={(event, page) => handleChangePage(event, page + 1)}
        onChangeRowsPerPage={handleChangeRowPage}
      />

      <PerfectScrollbar style={{height: 'auto'}}>
        <Box>
          <Table
            id={'basic-drive-table'}
            ref={refTable}
            className={'MuiTable-list'}
          >
            <TableHead className={'MuiTableHead-list'}>
              <TableRow>
                {
                  columns.map((column, idx) => {
                    if (showColumns.indexOf(column.field) > -1) {
                      return (
                        <TableCell
                          key={'table-cell-' + idx}
                          align={'left'}
                          className={classes.tableSortLabel}
                          width={column.width}
                        >
                          {column.label}
                        </TableCell>
                      )
                    }
                  })
                }
              </TableRow>
            </TableHead>

            {
              (!isLoading) ?
                (
                  <TableBody>
                    {rows.map((row, idx) => {
                      return (
                        <TableRow
                          key={row.name + '-' + idx}
                          className={clsx(['MuiTableRow-list', classes.tableRow])}
                        >
                          {
                            columns.map((item, idx) => {
                              if (showColumns.indexOf(item.field) === -1) {
                                return null
                              }

                              if (item.type === 'type') {
                                switch (row[item.field]) {
                                  case 'search': {
                                    return (
                                      <TableCell key={item.field + '-' + idx}>
                                        Уведомления
                                      </TableCell>
                                    )
                                  }
                                  case 'sensor': {
                                    return (
                                      <TableCell key={item.field + '-' + idx}>
                                        Устройства
                                      </TableCell>
                                    )
                                  }
                                  case 'duplicate': {
                                    return (
                                      <TableCell key={item.field + '-' + idx}>
                                        Дубликаты
                                      </TableCell>
                                    )
                                  }
                                }
                              }
                              if (item.type === 'payload') {
                                const message = handleGetMessage(row, item.field)

                                return (
                                  <TableCell key={item.field + '-' + idx}>
                                    <div
                                      className={classes.cellPayload}
                                      dangerouslySetInnerHTML={{__html: message}}
                                    />
                                  </TableCell>
                                )
                              }
                              if (item.type === 'dateTime') {
                                return (
                                  <TableCell key={item.field + '-' + idx}>
                                    <div className={classes.cellPayload}>
                                      <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <Moment
                                          format="DD.MM.YYYY"
                                          value={row[item.field]}
                                          filter-name={item.filterName}
                                          style={item.filterName ? {cursor: 'context-menu'} : {}}
                                        >
                                          {row[item.field]}
                                        </Moment>
                                        <Moment
                                          format="HH:mm:ss"
                                          value={row[item.field]}
                                          filter-name={item.filterName}
                                          style={item.filterName ? {cursor: 'context-menu'} : {}}
                                        >
                                          {row[item.field]}
                                        </Moment>
                                      </div>
                                    </div>
                                  </TableCell>
                                )
                              }
                              if (typeof row[item.field] === 'object') {
                                return (
                                  <TableCell key={item.field + '-' + idx}>1</TableCell>
                                )
                              }

                              return (
                                <TableCell
                                  key={item.field + '-' + idx}
                                  value={row[item.field]}
                                  filter-name={item.filterName}
                                  style={item.filterName ? {cursor: 'context-menu'} : {}}
                                >{row[item.field]}</TableCell>
                              )
                            })
                          }
                        </TableRow>
                      )
                    })}
                  </TableBody>
                )
                :
                (
                  <TableBody>
                    <TableRow className={'MuiTableRow-list'}>
                      {
                        columns.map((item, idx) => {
                          return (
                            <TableCell key={item.field + '-' + idx}>
                              <Skeleton variant="rect"/>
                            </TableCell>
                          )
                        })
                      }
                    </TableRow>
                  </TableBody>
                )
            }
          </Table>
        </Box>
      </PerfectScrollbar>

      <CustomTablePagination
        rows={rows}
        filter={filter}
        pagination={pagination}
        onChangePage={(event, page) => handleChangePage(event, page + 1)}
        onChangeRowsPerPage={handleChangeRowPage}
      />
    </>
  );
};

TableComponents.propTypes = {
  columns: PropTypes.array,
  showColumns: PropTypes.array,
  rows: PropTypes.array,
  selected: PropTypes.array,
  pagination: PropTypes.object,
  isSelectRowTable: PropTypes.func,
  isDeleteRowTable: PropTypes.func,
  isChangeRowPage: PropTypes.func,
};
TableComponents.defaultProps = {
  showColumns: []
};

export default TableComponents;
