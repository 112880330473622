import React, {Component} from 'react';
import {
    Backdrop,
    Box, CircularProgress,
    Grid,
    Typography,
} from '@material-ui/core';
import {
    Map as MapComponent,
    List as ListCompoent
} from "./components";
import {Page} from "../../../components";
import axiosAgent from "../../../plugins/axios";
import urls from "../../../constant/urls";
import {sendMessage} from "../../../utils/message";
import {generateColor} from "../../../helpers/colors";
import colorPalettes from "../../../constant/colorPalettes";

class FrequentRoutes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            frequentRoutes: [],
            shows: [],

            isEmpty: false,
            isLoading: true,
            isPermission: true,
            isShowBackdrop: false
        };

        this.licensePlate = props.match.params.license_plate;
        this.refMap = React.createRef(null);
    }

    // инициализация страницы
    componentDidMount = () => {
        this.getFrequentRoutes();
    }

    // Получение списка частых маршрутов
    getFrequentRoutes = () => {
        axiosAgent('post', `${urls["frequent-routes-get"]}`, {
            license_plate: this.licensePlate
        }).then((response) => {
            let frequentRoutes = response?.data?.route_list || [];
            frequentRoutes.map((route, idx) => {
                route.index = idx;
                route.color = colorPalettes[idx];
            })

            this.setState({
                frequentRoutes: frequentRoutes,

                isEmpty: Boolean(frequentRoutes.length <= 0),
                isLoading: false,
            })
        });
    }

    // изменение отобажения маршрутов
    // вкл / выкл частого маршрута
    onChangeShows = (routeIndex) => {
        let newShows = [...this.state.shows];

        if (newShows.includes(routeIndex)) {
            newShows.splice(newShows.indexOf(routeIndex), 1);
        } else {
            newShows.push(routeIndex);
        }

        // const map = this.refMap.current;
        // if (map) {
        //     const _layers = map.contextValue?.map?._layers || {};
        //     const list = Object.keys(_layers);
        //
        //     await Promise.all(list.map(async (id) => {
        //         if (_layers[id]._path !== undefined) {
        //             const layer = _layers[id];
        //             await map.contextValue.map.removeLayer(layer);
        //         }
        //     }));
        // }

        this.setState({
            shows: newShows
        });
    }

    // создание запроса на экспорт частых маршрутов
    onExportFile = (props) => {
        this.setState({isShowBackdrop: true});

        let filter = {
            license_plate: this.licensePlate,
            type: props.type,
            encoding: Number(props.encoding),
        };

        axiosAgent('post', '/export/create', filter).then((response) => {
            this.setState({isShowBackdrop: false});

            sendMessage({
                type: "success",
                message: "Задача на выгрузку успешно создана"
            })

        }).catch((error) => {
            this.setState({isShowBackdrop: false});

            sendMessage({
                type: "danger",
                message: error?.response?.data?.error
            })

        })
    }

    render() {
        const {
            frequentRoutes,
            shows,

            isEmpty,
            isLoading,
            isPermission,
            isShowBackdrop
        } = this.state;

        if (isLoading) {
            return (
                <Page>

                    <Box mb={3}>
                        <Typography variant="h1">Частые маршруты ГРЗ "<Typography variant="h1" component="span"
                                                                                  color="primary">{this.licensePlate}</Typography>"</Typography>
                    </Box>

                    {
                        isLoading && (
                            <Typography variant="h3">Загружаем частые маршруты ГРЗ</Typography>
                        )
                    }

                </Page>
            )
        }

        return (
            <Page className="flex flex-col">

                <Box mb={3}>
                    <Typography variant="h1">
                        Частые маршруты ГРЗ "<Typography variant="h1" component="span"
                                                         color="primary">{this.licensePlate}</Typography>"
                    </Typography>
                </Box>

                <Grid container spacing={2} style={{flex: 1}}>
                    <Grid item xs={2}>
                        <ListCompoent
                            routes={frequentRoutes}
                            shows={shows}

                            onChange={this.onChangeShows}
                            onExportFile={this.onExportFile}
                        />
                    </Grid>
                    <Grid item xs={10} className="flex">
                        <MapComponent
                            refMap={this.refMap}
                            routes={frequentRoutes}
                            shows={shows}
                        />
                    </Grid>
                </Grid>

                <Backdrop open={isShowBackdrop} invisible={isShowBackdrop} style={{position: "absolute"}}>
                    <CircularProgress size={60} style={{color: 'white'}}/>
                </Backdrop>

            </Page>
        );
    }

}



export default FrequentRoutes
