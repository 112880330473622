import React, {Component} from 'react';
import {
	Box,
	Grid,
	Button,
	Tooltip,
	IconButton,
	Typography, Backdrop,
} from '@material-ui/core';
import {
	Page,
	WithoutAccess,
	DialogConfirmation,
	ModalSensorMapStream,
	ModalSensorCameraStream,
} from "../../../components";
import {
	Camera
} from "./components";
import {
	Trash as TrashIcon,
	Lock as LockIcon,
	Unlock as UnlockIcon
} from "react-feather";
import _ from "lodash";
import {withStyles} from "@material-ui/styles";
import GridLayout from "react-grid-layout";
import clsx from "clsx";

class CamerasDashboard extends Component {
	constructor(props) {
		super(props);

		const isPermissions = true;

		this.state = {
			layouts: [],

			sensor: null,
			cameras: [],

			isPermissions,

			isOpenConfirm: false,
			isEditForm: false
		};

		this.refContainer = React.createRef();
		this.refModalSensorMapStream = React.createRef();
	}

	componentDidMount = () => {
		this.initLayout();
	}
	componentWillUnmount = () => {
		window.stop()
	}

	// Инициализация сетки
	initLayout = (updateLayout = false) => {
		// const availableHandles = ["s", "w", "e", "n", "sw", "nw", "se", "ne"];
		const availableHandles = ["s", "w", "e", "n"];

		const layouts = JSON.parse(localStorage.getItem('cameras-dashboard-layouts') || "[]");
		const cameras = JSON.parse(localStorage.getItem('camera_dashboard') || "[]");
		let newCameras = [];
		let newLayouts = [];
		let index = 0;

		cameras.map((camera, idx) => {
			if (index >= 3) {
				index = 0;
			}

			const x = index * 4;
			const y = Math.floor(idx / 3);

			if (Boolean(layouts.find(t => t.i === camera.id))) {
				newLayouts.push({
					i: camera.id,
					...layouts.find(t => t.i === camera.id)
				});
			} else {
				newLayouts.push({
					i: camera.id,
					x: x,
					y: y,
					w: 4,
					h: 2,
					resizeHandles: availableHandles
				});
			}

			newCameras.push({
				key: camera.id,
				...camera
			});

			index++
		});

		this.setState({
			layouts: newLayouts,
			cameras: newCameras
		}, () => {
			if (updateLayout) {
				this.onLayoutChange(newLayouts);
			}
		});
	}

	// Удаление камера
	onDeleteCamera = (camera) => {
		const newCameras = [...this.state.cameras];
		const index = newCameras.findIndex((t) => t.id === camera.id);

		newCameras.splice(index, 1);

		localStorage.setItem('camera_dashboard', JSON.stringify(newCameras))
		this.setState({
			cameras: newCameras
		}, () => {
			this.initLayout(true);
		})
	}

	// Запуска камеры в модальном окне
	onOpenCamera = (camera) => {
		const sensor = {
			title: camera?.serial,
			address: camera?.address,
			camera
		};

		this.setState({
			sensor
		})
	}

	// УДаление всех камер
	onClear = (isConfirm = false) => {
		if (!isConfirm) {
			this.setState({isOpenConfirm: true})

			return null
		}

		localStorage.removeItem('camera_dashboard');
		this.setState({
			cameras: [],
			isOpenConfirm: false
		})
	}

	// Изменение сетки камер и сохранение в локальное хранилище
	onLayoutChange = (layouts) => {
		this.setState({layouts});
		localStorage.setItem('cameras-dashboard-layouts', JSON.stringify(layouts));
	}

	// Включение / выключение редактирование сетки
	onChangeEditForm = () => {
		if (this.state.isEditForm) {
			this.setState({isEditForm: false});

			return null
		}

		this.setState({isEditForm: true});
	}

	openModalSensorMapStream = () => {
		this.refModalSensorMapStream.current.open();
	}
	closeModalSensorMapStream = () => {
		this.initLayout();
	}

	render() {
		const {user} = this.props.app;
		const {classes} = this.props;
		const {isEditForm, layouts, cameras, sensor, isPermissions, isOpenConfirm} = this.state;

		if (!isPermissions) {
			return (
				<WithoutAccess/>
			)
		}

		return (
			<Page className={classes.page}>
				<Box mb={2}>
					<Grid container spacing={2} alignItems="center">
						<Grid item>
							<Typography variant="h1">Видеостена</Typography>
						</Grid>
						<Grid item>
							<Tooltip title="Очистить">
								<IconButton onClick={() => this.onClear(false)} style={{padding: 0}}>
									<TrashIcon/>
								</IconButton>
							</Tooltip>
						</Grid>
						<Grid item>
							<Tooltip title={isEditForm ? 'Сохранить текущий вид' : 'Редактировать текущий вид'}>
								<IconButton onClick={this.onChangeEditForm} style={{padding: 0}}>
									{isEditForm ? (<UnlockIcon/>) : (<LockIcon/>)}
								</IconButton>
							</Tooltip>
						</Grid>
						<Grid item>
							<Tooltip title="Добавить камеры">
								<Button variant="contained" onClick={this.openModalSensorMapStream}>
									Добавить камеру
								</Button>
							</Tooltip>
						</Grid>
					</Grid>
				</Box>

				<Box flex="1" ref={this.refContainer} style={{margin: '0 -10px'}}>
					{
						layouts.length > 0 && (
							<GridLayout
								isDraggable={isEditForm}
								isResizable={isEditForm}

								cols={12}
								layouts={layouts}
								rowHeight={this.refContainer?.current?.clientHeight / 4}
								width={this.refContainer?.current?.clientWidth}
								style={{height: `${this.refContainer?.current?.clientHeight}px`}}
								onLayoutChange={this.onLayoutChange}
							>
								{
									layouts.map((item, idx) => (
										<Box
											key={item.i}
											bgcolor="white"
											borderRadius={10}
											data-grid={item}
											className={clsx({
												[classes.card]: true,
												[classes.cardEdit]: isEditForm,
											})}
										>
											<Box position="relative" width="100%" height="100%">
												<Camera camera={cameras.find((t) => t.id === item.i)} onDelete={this.onDeleteCamera}
												        onOpen={this.onOpenCamera}/>
												<Backdrop open={isEditForm} invisible={isEditForm}/>
											</Box>
										</Box>
									))
								}
							</GridLayout>
						)
					}
				</Box>



				<ModalSensorCameraStream
					sensor={sensor}
					isOpen={Boolean(sensor)}
					onClose={() => this.setState({sensor: null})}
					isHideButtonAdd
				/>

				<DialogConfirmation
					isOpen={isOpenConfirm}
					comment="Вы действительно хотите очистить мультиэкран?"
					onClose={() => this.setState({isOpenConfirm: false})}
					onConfirmation={() => this.onClear(true)}
				/>

				<ModalSensorMapStream
					ref={this.refModalSensorMapStream}
					onClose={this.closeModalSensorMapStream}
				/>
			</Page>
		);
	}
}

const styles = {
	page: {
		display: "flex",
		flexDirection: "column"
	},

	cameras: {
		display: 'flex',
		flexWrap: 'wrap',
		height: 'calc(100vh - 175px)',
		overflow: 'hidden',
		marginLeft: -8,
		marginBottom: -8,
	},
	camera: {
		marginLeft: 8,
		marginBottom: 8,
		height: "calc(100% / 2 - 8px)",
		width: "calc(100% / 3 - 8px)",
	},

	card: {
		overflow: "hidden",
		position: "relative",

		'& .react-resizable-handle': {
			display: 'none',

			width: 30,
			height: 30,
			backgroundColor: '#C2CFE0',
			position: 'absolute',
			zIndex: 9999999999999,

			'&.react-resizable-handle-s': {
				bottom: 0,
				left: '50%',
				transform: 'translate(-50%, 0)',

				'&:after': {
					content: "'↓'",
					fontSize: 30,
					lineHeight: '27px',
					color: '#3f51b5',
					display: 'block',
					textAlign: 'center'
				}
			},
			'&.react-resizable-handle-w': {
				top: '50%',
				left: 0,
				transform: 'translate(0, -50%)',

				'&:after': {
					content: "'←'",
					fontSize: 30,
					lineHeight: '23px',
					color: '#3f51b5',
					display: 'block'
				}
			},
			'&.react-resizable-handle-e': {
				top: '50%',
				right: 0,
				transform: 'translate(0, -50%)',

				'&:after': {
					content: "'→'",
					fontSize: 30,
					lineHeight: '23px',
					color: '#3f51b5',
					display: 'block',
				}
			},
			'&.react-resizable-handle-n': {
				top: 0,
				left: '50%',
				transform: 'translate(-50%, 0)',

				'&:after': {
					content: "'↑'",
					fontSize: 30,
					lineHeight: '27px',
					color: '#3f51b5',
					display: 'block',
					textAlign: 'center'
				}
			},
			'&.react-resizable-handle-sw': {
				bottom: 0,
				left: 0,
			},
			'&.react-resizable-handle-nw': {
				top: 0,
				left: 0,
			},
			'&.react-resizable-handle-ne': {
				top: 0,
				right: 0,
			},
			'&.react-resizable-handle-se': {
				bottom: 0,
				right: 0,
			},
		}
	},
	cardEdit: {
		'& .react-resizable-handle': {
			display: 'block'
		}
	},
}

export default withStyles(styles)(CamerasDashboard)
