import React from 'react';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import {Box, Grid, makeStyles, Tooltip, Typography} from '@material-ui/core';
import clsx from 'clsx';
import {
  VisibilityOffOutlined as VisibilityOffOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon
} from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  list: {
    marginBottom: 15,
    marginLeft: -5
  },
  box: {
    display: 'flex',
    alignItems: 'center',

    marginLeft: 5,
    marginBottom: 5,

    borderRadius: 1000,
    padding: '5px 10px',

    backgroundColor: '#c2c4c7',

    '&:hover': {
      opacity: 0.7
    }
  },
  name: {
    fontSize: 14,
    lineHeight: '16px',
    color: 'white',
    fontWeight: '500'
  }
}));

const Charts = (props) => {
  const {listCharts} = props;
  const classes = useStyles();

  const handleChangeList = (name) => {
    listCharts.find(item => item.name === name).active = !listCharts.find(item => item.name === name).active;

    let showCharts = localStorage.getItem('show-charts');
    if (showCharts){
      showCharts = JSON.parse(showCharts);
      showCharts[name] = !showCharts[name];

      localStorage.setItem('show-charts', JSON.stringify(showCharts));
    }
  }
  const handleUpdateStore = (listCharts) => {}

  return (
    <>
      {
        listCharts.map((item) => {
          const isActive = item.active;
          const ButtonIcon = isActive ? VisibilityOffOutlinedIcon : VisibilityOutlinedIcon;

          return (
            <Box
              borderRadius={4}
              boxShadow={2}
              mb={1}
              p={1}
              className={clsx({
                [classes.root]: true
              })}
              style={{ cursor: 'pointer' }}
              onClick={() => handleChangeList(item.name)}
            >
              <Tooltip title={
                <React.Fragment>
                  <img src={item.image} style={{width: 300}}/>
                </React.Fragment>
              }>
                <Grid container spacing={1} alignItems={'center'} wrap={'nowrap'}>
                  <Grid item>
                    <Typography variant={'body2'}>{item.title}</Typography>
                  </Grid>
                  <Grid item style={{marginLeft: 'auto'}}>
                    <ButtonIcon style={{ width: 16 }}/>
                  </Grid>
                </Grid>
              </Tooltip>
            </Box>
          )
        })
      }
    </>
  )
}

export default compose(
  connect(
    state => ({
      charts: state.charts,
      listCharts: state.charts.listCharts
    }),
    dispatch => ({}),
  ),
)(Charts);
