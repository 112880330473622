import React, {Component} from 'react';
import {
  Box,
  Grid,
  IconButton,
  Switch,
  Typography,
  FormControlLabel,
  Button,
  Divider,
  makeStyles,
  Card, CircularProgress,
} from '@material-ui/core';
import Draggable from 'react-draggable';
import {withStyles} from '@material-ui/styles';
import {
  X as XIcon,
  ArrowLeft as ArrowLeftIcon
} from 'react-feather';
import ReactPlayer from 'react-player/lazy'
import {
  Map
} from './components';
import {Page, CustomCheckbox, CustomMoment as Moment} from '../../../components';
import queryString from 'query-string';
import axios from '../../../plugins/axios';
import moment from 'moment';
import {
  Image
} from '../../../components';
import clsx from 'clsx';
import varibles from "../../../varibles";

const styles = {
  root: {
    minHeight: 'calc(100vh - 128px)'
  },

  backTypography: {
    fontSize: 24,
    lineHeight: '24px',
    marginLeft: 16,
    color: '#152435'
  },
  backButton: {
    display: 'flex',
    alignItems: 'center',
    padding: 0
  },

  switchStyle: {
    padding: 0,
    marginLeft: 0,
    width: '100%',
    justifyContent: 'flex-end',

    '& .MuiSwitch-root': {
      marginLeft: 'auto'
    }
  },

  dividerStyle: {
    margin: 0
  },

  typographyCheckbox: {
    fontSize: 13,
    lineHeight: '13px',
    color: '#647083',
    fontFeatureSettings: "'ss01' on",

    marginRight: 18
  },

  legend: {
    display: 'flex',
    alightItems: 'center'
  },
  legendColor: {
    width: 18,
    height: 18,
    borderRadius: '100%',
    marginRight: 5
  },
  legendTypography: {
    fontSize: 14,
    lineHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    fontFeatureSettings: "'ss01' on",
    color: '#647083'
  },

  boxMap: {
    overflow: 'hidden',
    position: 'relative',
    height: '100%'
  },
  contentLoading: {
    backgroundColor: 'rgba(24, 31, 72, 0.5)',
    position: 'absolute',
    zIndex: 9999,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& .MuiCircularProgress-circleIndeterminate': {
      color: 'white'
    }
  }
};
const useStylesMarker = makeStyles((theme => ({
  root: {
    padding: '10px 24px',
    boxSizing: 'border-box',
    maxHeight: '100%',
    overflow: 'hidden'
  },
  divider: {
    width: '100%',
    margin: 0,
    backgroundColor: '#e7eaef'
  },

  header: {
    padding: '16px 0',
    display: 'flex',
    alignItems: 'center'
  },
  headerSeparator: {
    fontSize: 20,
    lineHeight: '110%',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.02em',
    fontFeatureSettings: "'ss01' on",
    color: '#8698B1',
    margin: '0 16px'
  },
  headerTime: {
    fontWeight: '500',
    fontSize: 16,
    lineHeight: '110%',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.02em',
    fontFeatureSettings: "'ss01' on",
    color: '#152435'
  },

  body: {
    padding: '16px 0',
    overflow: 'auto',

    '&::-webkit-scrollbar': {
      width: 6
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#aaa'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#999'
    }
  },

  gridTypes: {
    display: 'flex',
    alignItems: 'center'
  },
  typeTitle: {
    marginRight: 20,
    fontSize: 13,
    color: theme.palette.gray.dark
  },

  buttonPoint: {
    padding: 0,
    marginBottom: 10,
    textAlign: 'left',

    '&:hover': {
      backgroundColor: 'transparent',

      '& .makeStyles-typographyPoint-104': {
        color: theme.palette.gray.light
      }
    }
  },
  typographyPoint: {
    fontSize: 15,
    lineHeight: '100%',
    textDecorationLine: 'underline',
    fontFeatureSettings: "'ss01' on",
    color: theme.palette.gray.default
  },
  typographyPointActive: {
    color: theme.palette.primary.main,
    textDecorationLine: 'none',
  }
})));
const useStylesFixed = makeStyles((theme => ({
  root: {
    position: 'absolute',
    left: 14,
    bottom: 14,
    zIndex: 999,
    width: 270,
    padding: 18,
    boxSizing: 'border-box',

    "& > *": {
      padding: 0 + '!important'
    }
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    marginBottom: 12
  },
  headerTitle: {
    fontSize: 14,
    lineHeight: '100%',
    letterSpacing: '0.01em',
    fontFeatureSettings: "'ss01'on",
    color: theme.palette.gray.dark
  },
  headerClose: {
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translate(0px, -50%)',
    padding: 0,
    marginRight: -5
  },

  imageContent: {
    marginBottom: 18
  },
  image: {
    width: '100%',
    height: 135,
    objectFit: 'cover',
    borderRadius: 2
  },

  body: {},
  bodyText: {
    fontSize: 14,
    lineHeight: '18px',
    color: theme.palette.black
  },
  bodyTextBold: {
    fontWeight: '500'
  },
  bodyTextGray: {
    color: '#8698b0',
    fontWeight: '500'
  },
})));

const InformationMarker = (props) => {
  const {marker, openInfo, activeFixed, showLegend} = props;
  const classes = useStylesMarker();
  let timeStart = null;
  let timeEnd = null;

  const handleOpenInfo = (point) => {
    openInfo(point)
  }

  if (marker.points.length === 1) {
    let date = new Date(marker.points[0].datetime);

    timeStart = {
      date: moment(date).subtract(5, 'hours').format('DD.MM.YYYY'),
      time: moment(date).subtract(5, 'hours').format('HH:mm:ss'),
    };
    timeEnd = {
      date: moment(date).subtract(5, 'hours').format('DD.MM.YYYY'),
      time: moment(date).subtract(5, 'hours').format('HH:mm:ss'),
    };
  }
  if (marker.points.length > 1) {
    let sortPoints = marker.points.sort((a, b) => a.datetime > b.datetime ? 1 : -1);
    let dateStart = new Date(sortPoints[0].datetime);
    let dateEnd = new Date(sortPoints[marker.points.length - 1].datetime);

    timeStart = {
      date: moment(dateStart).subtract(5, 'hours').format('DD.MM.YYYY'),
      time: moment(dateStart).subtract(5, 'hours').format('HH:mm:ss'),
    };
    timeEnd = {
      date: moment(dateEnd).subtract(5, 'hours').format('DD.MM.YYYY'),
      time: moment(dateEnd).subtract(5, 'hours').format('HH:mm:ss'),
    };
  }

  return (
    <Grid container className={clsx([classes.root, 'adaptive-page-route__information-marker-content'])}>
      <Grid item xs={12} className={classes.header} spacing={2}>
        <Grid item>
          <Typography className={classes.headerTime}>{timeStart.date}</Typography>
          <Typography className={classes.headerTime}>{timeStart.time}</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.headerSeparator}>—</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.headerTime}>{timeEnd.date}</Typography>
          <Typography className={classes.headerTime}>{timeEnd.time}</Typography>
        </Grid>
      </Grid>

      {
        false && (
          <>
            <Divider variant="middle" className={classes.divider}/>

            <Grid item xs={12} className={classes.body}>
              <Grid className={classes.gridTypes}>
                <Typography variant={'body2'} className={classes.typeTitle}>Показать: </Typography>
                <CustomCheckbox
                  label={'Фото'}
                />
                <CustomCheckbox
                  label={'Видео'}
                />
              </Grid>
            </Grid>
          </>
        )
      }

      <Divider variant="middle" className={classes.divider}/>

      <div
        className={classes.body}
        style={(!showLegend) ? {maxHeight: '31vh'} : {maxHeight: '16vh'}}
      >
        <Grid container item xs={12} spacing={1}>
          {
            marker.points.map((point, idx) => {
              return (
                <Grid key={'point-' + idx} item xs={12}>
                  <Button variant={'text'} className={classes.buttonPoint} onClick={() => handleOpenInfo(point)}>
                    <Typography className={clsx({
                      [classes.typographyPoint]: true,
                      [classes.typographyPointActive]: point.id === activeFixed.id
                    })}>
                      { point.license_plate }
                      &nbsp;
                      <Moment format="DD.MM.YYYY HH:mm">
                        { point.datetime }
                      </Moment>
                    </Typography>
                  </Button>
                </Grid>
              )
            })
          }
        </Grid>
      </div>
    </Grid>
  )

}
const InformationFixed = (props) => {
  const {fixed, onClose} = props;
  const classes = useStylesFixed();
  const grz = fixed.license_plate;

  return (
    <Draggable bounds="parent" handle=".card-header-draggable">
      <Card className={classes.root}>
        <Grid className={clsx([classes.header, 'card-header-draggable'])}>
          <Typography
            className={classes.headerTitle}
          >
            <Moment format="DD.MM.YYYY HH:mm">{ fixed.datetime }</Moment>
          </Typography>

          <IconButton size={'small'} className={classes.headerClose} onClick={onClose}>
            <XIcon color={'#8698B1'}/>
          </IconButton>
        </Grid>

        <Grid className={classes.imageContent}>
          <Image
            classImage={classes.image}
            src={varibles.IMAGE_URL + fixed.images.main}
          />
        </Grid>

        <Grid container spacing={1} className={classes.body}>
          <Grid item xs={12}>
            {
              false && (
                <Typography className={clsx([classes.bodyText, classes.bodyTextBold])}>Volkswagen Polo белый</Typography>
              )
            }
            <Typography className={clsx([classes.bodyText, classes.bodyTextBold])}>{ grz }</Typography>
          </Grid>

          {
            false && (
              <Grid item xs={12}>
                <Typography className={classes.bodyText}>
                  <Typography className={classes.bodyTextGray}>Тип: </Typography>злостный нарушитель
                </Typography>
              </Grid>
            )
          }

          <Grid item xs={12}>
            <Typography className={classes.bodyText}>
              <Typography className={classes.bodyTextGray}>Проезд зафиксирован: </Typography>{ fixed.address }
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.bodyText}>
              <Typography className={classes.bodyTextGray}>Код устройства: </Typography> { fixed.sensor_id }
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </Draggable>
  )
}

class TransportConnectionRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      points: [],
      apiPoints: [],
      pointsApi: [],

      map: {
        connectDots: true,
        showPointNumbers: true,
        showCluster: true,
        showLegend: true,
      },
      marker: {},
      fixed: {},
      filter: {},

      licensePlate: '',

      openModalInformation: false,
      openModalInformationVideo: false,
      openMarker: false,
      openFixed: false,
      isLoading: true
    }
  }

  componentDidMount = () => {
    let licensePlate = this.props.match.params.license_plate;
    let params = queryString.parse(this.props.location.search);
    let filter = this.state.filter;

    if (Object.keys(params).length > 0) {
      for (let key in params) {
        filter[key] = params[key];

        if (key === 'sensor_ids' || key === 'license_plate_list') {
          filter[key] = params[key].split(',')
        }
        if (key === 'limit') {
          filter[key] = Number(params[key])
        }
        if (key === 'datetime_start' || key === 'datetime_end') {
          let date = new Date();
          date.setTime(params[key]);
          filter[key] = date
        }
        if (key === 'license_plate_empty' || key === 'license_plate_invalid') {
          filter[key] = Boolean(params[key]);
        }
      }
    }

    filter['license_plate_id'] = licensePlate;

    this.setState({
      filter,
      licensePlate
    })

    this.getPointsList()
  }

  getFilter = () => {
    let filter = {}

    for (let key in this.state.filter) {
      let item = this.state.filter[key];

      if (item) {
        filter[key] = item;
      }
    }

    filter['sort_field'] = 'datetime';
    filter['sort_direction'] = 'desc';

    return filter
  }
  getPointsList = () => {
    let filter = this.getFilter();

    axios('post', '/operator/report/find', filter).then((response) => {
      let pointsApi = response.data;

      if (!pointsApi) {
        pointsApi = []
      }

      pointsApi = pointsApi.reverse();

      this.setState({
        isLoading: false,
        pointsApi
      })

      this.setMapPoints();
    }).catch((error) => {})
  }
  setMapPoints = () => {
    let objectPoints = {}
    let points = []
    let pointsApi = this.state.pointsApi;

    pointsApi.map((point, idx) => {
      let key = point.gps.lat + '_' + point.gps.lon;

      if (!objectPoints[key]) {
        objectPoints[key] = {
          index: idx + 1,
          points: [],
          position: point.gps,
          type: '',
          start: false,
          end: false
        }
      }

      if (idx === 0) {
        objectPoints[key]['start'] = true
      }
      if (idx === pointsApi.length - 1) {
        objectPoints[key]['end'] = true
      }

      objectPoints[key]['points'].push(point)
    })

    for (let key in objectPoints) {
      let point = objectPoints[key]

      points.push({
        countRecords: point.points.length,
        position: point.position,
        points: point.points,
        className: point.className,
        start: point.start,
        end: point.end,
        index: point.index,
      })
    }

    this.setState({
      points,
      apiPoints: pointsApi
    })
  }
  openInfoMarker = (marker) => {
    this.setState({
      openMarker: true,
      marker
    })
  }
  openInfoFixed = (fixed) => {
    this.setState({
      fixed,
      openFixed: true
    })
  }
  closeInfoFixed = () => {
    this.setState({
      fixed: {},
      openFixed: false
    })
  }
  routeBack = () => {
    this.props.history.goBack()
  }

  render() {
    const {classes} = this.props;

    return (
      <Page title={'Устройства'}>
        <Grid container spacing={3} className={clsx([classes.root, 'adaptive-page-route'])}>
          <Grid item xs={3} style={{maxHeight: 'inherit'}}>
            {
              (this.state.openMarker) && (
                <Box
                  boxShadow={1}
                  bgcolor={'white'}
                  borderRadius={4}
                  mb={2}
                  className={'adaptive-page-route__information-marker'}
                >
                  <InformationMarker
                    marker={this.state.marker}
                    openInfo={this.openInfoFixed}
                    activeFixed={this.state.fixed}
                    showLegend={this.state.map.showLegend}
                  />
                </Box>
              )
            }

            <Box
              boxShadow={1}
              bgcolor={'white'}
              borderRadius={4}
              p={3}
              mb={(this.state.map.showLegend) ? 2 : 0}
            >
              <Grid container spacing={2}>
                <Grid item container>
                  <Grid item xs={12}>
                    <FormControlLabel
                      className={clsx([classes.switchStyle, 'router-switch-style'])}
                      control={<Switch
                        checked={this.state.map.connectDots}
                        onChange={() => {
                          this.state.map.connectDots = !this.state.map.connectDots;
                          this.setState({map: this.state.map})
                        }}
                      />}
                      label="Соединять точки линиями"
                      labelPlacement="start"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      className={clsx([classes.switchStyle, 'router-switch-style'])}
                      control={<Switch
                        checked={this.state.map.showPointNumbers}
                        onChange={() => {
                          this.state.map.showPointNumbers = !this.state.map.showPointNumbers;
                          this.setState({map: this.state.map})
                        }}
                      />}
                      label="Показывать номера точек"
                      labelPlacement="start"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      className={clsx([classes.switchStyle, 'router-switch-style'])}
                      control={<Switch
                        checked={this.state.map.showCluster}
                        onChange={() => {
                          this.state.map.showCluster = !this.state.map.showCluster;
                          this.setState({map: this.state.map})
                        }}
                      />}
                      label="Объединить точки"
                      labelPlacement="start"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      className={clsx([classes.switchStyle, 'router-switch-style'])}
                      control={<Switch
                        checked={this.state.map.showLegend}
                        onChange={() => {
                          this.state.map.showLegend = !this.state.map.showLegend;
                          this.setState({map: this.state.map})
                        }}
                      />}
                      label="Показать легенду"
                      labelPlacement="start"
                    />
                  </Grid>
                </Grid>

                {
                  false && (
                    <>
                      <Grid item xs={12}>
                        <Divider variant="middle" className={classes.dividerStyle}/>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid alignItems={'center'}>
                          <Typography className={classes.typographyCheckbox}>Показывать: </Typography>

                          <CustomCheckbox
                            label="Видео"
                          />

                          <CustomCheckbox
                            label="Фото"
                          />
                        </Grid>
                      </Grid>
                    </>
                  )
                }
              </Grid>
            </Box>

            {
              this.state.map.showLegend && (
                <Box
                  boxShadow={1}
                  bgcolor={'white'}
                  borderRadius={4}
                  p={3}
                >
                  <Grid container spacing={1}>
                    <Grid item alignItems={'center'} className={classes.legend}>
                      <div className={classes.legendColor} style={{backgroundColor: '#3CB11F'}}></div>
                      <Typography className={classes.legendTypography}> — Начальная точка маршрута</Typography>
                    </Grid>
                    <Grid item alignItems={'center'} className={classes.legend}>
                      <div className={classes.legendColor} style={{backgroundColor: '#F59300'}}></div>
                      <Typography className={classes.legendTypography}> — Конечная точка маршрута</Typography>
                    </Grid>
                    <Grid item alignItems={'center'} className={classes.legend}>
                      <div className={classes.legendColor} style={{backgroundColor: '#2979FF'}}></div>
                      <Typography className={classes.legendTypography}> — Промежуточные точки</Typography>
                    </Grid>
                  </Grid>
                </Box>
              )
            }

          </Grid>
          <Grid item xs={9}>
            <Box
              boxShadow={1}
              bgcolor={'white'}
              borderRadius={4}

              className={classes.boxMap}
            >
              <Map
                position={[56.8519000, 60.6122000]}
                points={this.state.points}
                apiPoints={this.state.apiPoints}
                showCluster={this.state.map.showCluster}
                connectDots={this.state.map.connectDots}
                showPointNumbers={this.state.map.showPointNumbers}
                openInfo={this.openInfo}
                openInfoMarker={this.openInfoMarker}
              />

              {
                (this.state.openFixed) && (
                  <InformationFixed
                    fixed={this.state.fixed}
                    onClose={this.closeInfoFixed}
                  />
                )
              }

              {
                (this.state.isLoading) && (
                  <div className={classes.contentLoading}>
                    <CircularProgress size={60} color={'white'}/>
                  </div>
                )
              }
            </Box>
          </Grid>
        </Grid>
      </Page>
    )
  }
}


export default withStyles(styles)(TransportConnectionRoute)
