import React from "react";
import {
    Box,
    Typography
} from "@material-ui/core";
import {
    makeStyles
} from "@material-ui/styles";

const Content = (props) => {
    const classes = useStyles();

    return (
        <Box p={4}>
            <Typography className={classes.root} dangerouslySetInnerHTML={{__html: props.html}}/>
        </Box>
    )
}

const useStyles = makeStyles(() => ({
    root: {
        "& img": {
            margin: '16px 0'
        }
    }
}));

export default Content
